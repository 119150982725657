import { useEffect, useState, type FC } from 'react';
import { batch } from 'react-redux';
import { LoadingContainer } from 'src/common/components/loading-container';
import type { Template, TemplateCategory } from 'src/hooks/template/types';
import { useFetchTemplates } from 'src/hooks/template/use-fetch-templates';
import { Category } from './category';
import type { RightPanelProps } from './template-panel';

export const PurchasedTemplates: FC<RightPanelProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState<TemplateCategory>();
  const fetchTemplates = useFetchTemplates();

  useEffect(() => {
    const fetchPurchase = async () => {
      // 这里有个问题，就是
      const templateCategories = await fetchTemplates();
      const allTemplates: Template[] = [];
      const loop = (categories: TemplateCategory[]) => {
        categories.forEach((v) => {
          v.subGroups && loop(v.subGroups);
          v.templates?.forEach((t) => {
            if (t.purchased) {
              allTemplates.push(t);
            }
          });
        });
      };
      templateCategories && loop(templateCategories);
      const category: TemplateCategory = {
        name: '已购买',
        templates: allTemplates,
      };
      batch(() => {
        setResults(category);
        setLoading(false);
      });
    };
    void fetchPurchase();
  }, [fetchTemplates]);

  if (loading) {
    return <LoadingContainer />;
  }
  if (!results) return null;
  return (
    <div className="relative w-full px-[30px] overflow-auto h-full">
      <Category
        targetId={props.targetId}
        key={results.name}
        category={results}
        onPreviewOpen={props.openTemplatePreview}
      />
    </div>
  );
};
