import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useCallback } from 'react';
import { useIsInRight } from 'src/utils/right-utils';

type DrawerRight =
  | 'page-feeds-right'
  | 'page-resolved-discussions-right'
  | 'service-center-right'
  | 'ai-chat-right';
type DrawerMain = 'page-feeds' | 'page-resolved-discussions' | 'service-center' | 'ai-chat';

export const drawerOpenState = atom(false as DrawerMain | DrawerRight | false);

export const supportSpaceChatState = atom(false);

const $showChatPanelState = atom(true);

export const isRightDrawer = (drawerState: DrawerMain | DrawerRight | boolean) =>
  typeof drawerState === 'string' && drawerState.includes('right');

export const useShowQAChat = () => {
  const [showChatPanelState, setShowChatPanelState] = useAtom($showChatPanelState);
  const supportChat = useAtomValue(supportSpaceChatState);
  const showChatPanel = showChatPanelState && supportChat;

  return { showChatPanel, setShowChatPanelState, supportChat };
};

export const useDrawerOpenState = (): [
  DrawerMain | DrawerRight | false,
  (state: DrawerMain | DrawerRight | false) => void
] => {
  const drawState = useDrawerOpenStateValue();
  const setDrawState = useSetDrawerOpenState();

  return [drawState, setDrawState];
};

export const useSetDrawerOpenState = () => {
  const _setDrawState = useSetAtom(drawerOpenState);
  const isInRight = useIsInRight();

  const setDrawState = (state: DrawerMain | DrawerRight | false) => {
    if (state === false) {
      _setDrawState(false);
      return;
    }
    const targetState = isInRight ? (`${state}-right` as DrawerRight) : (state as DrawerMain);
    _setDrawState(targetState);
  };

  return setDrawState;
};

export const useDrawerOpenStateValue = () => {
  return useAtomValue(drawerOpenState);
};

export const useToggleDrawer = () => {
  const setDrawState = useSetDrawerOpenState();
  const currentState = useDrawerOpenStateValue();
  const isInRight = useIsInRight();

  return useCallback(
    (state: DrawerMain) => {
      const targetState = isInRight ? (`${state}-right` as DrawerRight) : state;
      if (currentState === targetState) {
        setDrawState(false);
      } else {
        setDrawState(state);
      }
    },
    [setDrawState, currentState, isInRight]
  );
};
