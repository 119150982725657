/* eslint-disable react-hooks/exhaustive-deps */
import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { useEditorModel } from './context';
import type { BlockVisibleProps } from './types';

export const BlockVisible: FC<BlockVisibleProps> = memo((props) => {
  const { children, placeholder, className } = props;
  const blockId = useRef(v4());
  const elementRef = useRef<HTMLDivElement>(null);
  const editorModel = useEditorModel();
  const [version, setVersion] = useState(0);
  const height = useRef<number | undefined>(undefined);

  const handleVisibilityChange = useCallback(() => {
    height.current = elementRef.current?.clientHeight;
    setVersion((v) => v + 1);
  }, []);

  useEffect(() => {
    if (!editorModel || !elementRef.current) return;

    const unsubscribe = editorModel.subscribe(blockId.current, handleVisibilityChange);
    editorModel?.observer?.observe(elementRef.current);

    return () => {
      if (elementRef.current) {
        editorModel.observer?.unobserve(elementRef.current);
      }
      unsubscribe();
    };
  }, [editorModel, handleVisibilityChange]);

  if (!editorModel) return <>{children}</>;

  const shouldRender = editorModel.shouldRender(blockId.current);

  return (
    <div
      ref={elementRef}
      data-block-visible-id={blockId.current}
      data-provider-id={editorModel.providerId}
      data-version={version}
      data-shouldRender={shouldRender}
      className={cx(
        shouldRender ? 'flex w-full' : cx('w-full whitespace-pre-wrap break-words', className)
      )}
      style={{ height: shouldRender ? undefined : height.current }}
    >
      {shouldRender ? children : placeholder || null}
    </div>
  );
});
