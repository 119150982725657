import type { CSSProperties, FC, RefObject } from 'react';
import type { SpringConfig } from 'react-spring';
import { animated, Transition, useSpring } from 'react-spring';
// import { useIsLowConfig } from 'src/services/app';

export const OpacityTransitionBox: FC<{ className?: string }> = (props) => {
  const { children, className } = props;

  return (
    <Transition
      items={[children]}
      {...{
        config: { duration: 200 },
        from: { opacity: 0.3 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        to: { opacity: 1 },
      }}
    >
      {(style: CSSProperties, item) =>
        item && (
          <animated.div className={className} style={style}>
            {item}
          </animated.div>
        )
      }
    </Transition>
  );
};

interface TransitionBoxProps extends Omit<JSX.IntrinsicElements['div'], 'ref'> {
  className?: string;
  domRef?: RefObject<HTMLDivElement>;
  style?: CSSProperties;
  tagName?: 'main' | 'span' | 'aside';
  config?: SpringConfig;
  springProp?: any;
  disableSpring?: boolean;
}
export const TransitionBox: FC<TransitionBoxProps> = (props) => {
  const { config: _config, springProp: _springProp, tagName: _tagName } = props;

  return <TransitionBox0 {...props} />;
};

const TransitionBox0: FC<TransitionBoxProps> = (props) => {
  const {
    children,
    className,
    domRef,
    style = {},
    tagName = 'div',
    config = {},
    springProp = {},
    disableSpring,
    ...reset
  } = props;
  const springProps = useSpring({
    ...style,
    ...springProp,
    config: {
      ...config,
    },
  });

  const Tag = animated[tagName];

  if (disableSpring) {
    return (
      <Tag ref={domRef} className={className} style={style} {...reset}>
        {children}
      </Tag>
    );
  }

  return (
    <Tag ref={domRef} className={className} style={springProps} {...reset}>
      {children}
    </Tag>
  );
};
