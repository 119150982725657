import { fastEqual } from '@flowus/common/utils/tools';
import { createSetState, createStore } from '@flowus/common/zustand/utils';
import type { SpacePlanType } from '@flowus/shared';
import type { PlanConfigDTO, SpacePlanDTO } from '@next-space/fe-api-idl';
import produce from 'immer';
import { assign } from 'lodash-es';
import { request } from 'src/common/request';
import { getIsGuest } from 'src/hooks/share/use-permission-utils';
import { getCurrentSpace, useCurrentSpace } from 'src/hooks/space';
import { getCurrentSpaceId, getSpaceBySpaceId } from 'src/hooks/space/get-space';
import { getSpaceRolePermission } from 'src/hooks/space/use-get-space-role-permission';
import { getCurrentUser } from 'src/hooks/user';
import type { AsyncReturnType } from 'type-fest';

interface PlanInfo {
  /** 空间套餐列表 */
  spacePlans: (AsyncReturnType<typeof request.infra.getSpacePlans>[number] & PlanConfigDTO)[];
  currentPlan?: AsyncReturnType<typeof request.infra.getCurrentPlan>;
  /** 当前空间已有的套餐 */
  spacePlan?: SpacePlanDTO;
  spaceShareInfo?: {
    inviterId: string;
    createdAt: number;
    expirationAt: number;
  };
}
type SpacePlanInfoType = Record<string, PlanInfo>;

const $spacePlanInfo = createStore<SpacePlanInfoType>(() => ({}));
const $setSpacePlanInfo = createSetState($spacePlanInfo);

const setSpacePlanInfo = (spaceId: string, value: Partial<PlanInfo>) => {
  $setSpacePlanInfo(
    produce((pre) => {
      if (!pre[spaceId]) {
        pre[spaceId] = { spacePlans: [] };
      }
      pre[spaceId] = assign(pre[spaceId], value);
    })
  );
};

export const useCurrentSpacePlanInfo = () => {
  return useSpacePlanInfo();
};

export const useSpacePlanInfo = (spaceId?: string) => {
  const currentSpace = useCurrentSpace();
  const id = spaceId ?? currentSpace.uuid;
  return $spacePlanInfo(() => getSpacePlanInfo(id), fastEqual);
};

export const getSpacePlanInfo = (spaceId = getCurrentSpaceId()) => {
  const info = $spacePlanInfo.getState()[spaceId];
  return formatPlanInfo(spaceId, info);
};

// 组合currentSpacePlan
const formatPlanInfo = (spaceId: string, info?: PlanInfo) => {
  const space = getSpaceBySpaceId(spaceId);
  const curSpaceConfig = info?.spacePlans?.find((it) => it.planType === space.planType);

  return {
    ...info,
    /** 这玩意和 currentPlan 的区别是，这玩意是固定的内容，而 currentSpace 会根据空间特殊情况灵活加大某些字段 */
    currentSpacePlanConfig: curSpaceConfig,
  };
};

/** 更新空间套餐列表 */
export const fetchCurrentSpacePlans = async (spaceId: string) => {
  const res = await request.infra.getSpacePlans();
  const currentPlanRes = await request.infra.getCurrentPlan.raw(spaceId);
  const planConfig = await request.infra.getUserPlanConfig(getCurrentUser().uuid);
  const arr = res.map((it) => {
    const cur: PlanConfigDTO | undefined =
      planConfig[
        it.planType as SpacePlanType.freeTeam | SpacePlanType.pro | SpacePlanType.freePersonal
      ];
    if (cur) {
      // 带chat?space_id=xxx必报错
      // Object.assign(it, cur);
      return { ...it, ...cur };
    }
    return it;
  });

  setSpacePlanInfo(spaceId, {
    spacePlans: arr,
    currentPlan: currentPlanRes.code === 200 ? currentPlanRes.data : undefined,
  });
  return arr;
};

/** 更新当前空间套餐 */
export const fetchCurrentSpacePlan = async (spaceId?: string) => {
  const currentSpace = getCurrentSpace();
  const isGuest = getIsGuest(currentSpace);
  if (isGuest) return;

  const res = await request.infra.getSpacePlan(spaceId ?? currentSpace.uuid);
  setSpacePlanInfo(spaceId ?? currentSpace.uuid, {
    spacePlan: res,
  });

  return res;
};

/** 更新当前空间分享链接 */
export const fetchCurrentShareInfo = async (value?: PlanInfo['spaceShareInfo']) => {
  const currentSpace = getCurrentSpace();
  const currentUser = getCurrentUser();
  const spaceEditor = getSpaceRolePermission(currentUser.uuid).editor;

  if (value) {
    setSpacePlanInfo(currentSpace.uuid, {
      spaceShareInfo: {
        inviterId: value.inviterId,
        createdAt: value.createdAt,
        expirationAt: value.expirationAt,
      },
    });
    return;
  }

  if (spaceEditor) {
    const res = await request.infra.getSpaceInvitation(currentSpace.uuid);
    setSpacePlanInfo(currentSpace.uuid, {
      spaceShareInfo: {
        inviterId: res.uuid,
        createdAt: res.createdAt,
        expirationAt: res.expirationAt,
      },
    });
  } else {
    setSpacePlanInfo(currentSpace.uuid, {
      spaceShareInfo: {
        inviterId: '',
        createdAt: 0,
        expirationAt: 0,
      },
    });
  }
};
