import { useCallback } from 'react';
import { isMobile } from 'react-device-detect';
import { v4 } from 'uuid';
import { API } from '../api';
import { sleep } from '../async';
import { cx } from '../cx';
import { useOpenModal } from '../next-modal';
import { Button } from './button';
import { Input } from './input';
import { message } from './message';

const PayAccessFeeCodeId = v4();
export const useOpenPayAccessFeeCode = (config: { isLogin: boolean; openLogin: Function }) => {
  const openModal = useOpenModal();

  return useCallback(() => {
    if (!config.isLogin) {
      config.openLogin();
      return;
    }
    openModal.modal({
      modalId: PayAccessFeeCodeId,
      content: () => <PayAccessFeeCode />,
    });
  }, [config, openModal]);
};

const PayAccessFeeCode = () => {
  const openModal = useOpenModal();

  const active = async (value: string) => {
    if (!value) return;
    if (message.exists(PayAccessFeeCodeId)) return;
    message.success({ key: PayAccessFeeCodeId, content: '正在兑换' });
    await API.infra.putWelfareCode(value, {});
    message.success('兑换成功');
    await sleep(1000);
    location.reload();
  };

  return (
    <form
      className={cx('p-6 space-y-5 text-t2', isMobile ? 'w-[80vw]' : 'w-72')}
      onSubmit={(e) => {
        e.preventDefault();
        // @ts-ignore is input
        void active(e.target[0]?.value as string);
      }}
    >
      <div className="text-t1-medium">使用兑换码</div>
      <Input className="py-2" autoFocus placeholder="输入兑换码" />
      <div className="grid grid-cols-2 gap-2">
        <Button colorType="active" type="submit">
          确认兑换
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            openModal.closeModal(PayAccessFeeCodeId);
          }}
        >
          取消
        </Button>
      </div>
    </form>
  );
};
