import { contentToString, newContent, newElement, sliceContent } from '@next-space/fe-inlined';
import isHotkey from 'is-hotkey';
import { useCallback } from 'react';
import { InlineContentType } from 'src/editor/component/menu-list/types';
import { INLINE_EMOJI_TAG } from 'src/editor/editor/inline/const';
import { emojiRegexG } from 'src/editor/editor/inline/emoji-utils';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import type { SegmentType } from 'src/redux/types';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { isSlashSymbol, slashSymbol } from 'src/utils/slash-symbol';
import { $appUiStateCache, setAppUiState, useSetIsEditingPage } from '../../services/app';
import { useTabKey } from '../page/use-page-shortcut/use-tab-key';
import { useSendPresenceData } from '../user/use-send-presence-data';
import { DEFAULT_KEYDOWN_OPTION } from './config';
import { useIsMarkDownKey } from './helper';
import { useArrowDownKey, useArrowLeftKey, useArrowRightKey, useArrowUpKey } from './use-arrow-key';
import { useBackspaceKey } from './use-backspace-key';
import { useBracketRightKey } from './use-bracket-right-key';
import { useEnterKey } from './use-enter-key';
import { useInputAtSymbol } from './use-input-at-symbol';
import { useLigatures } from './use-ligatures';
import { useMarkDownKey } from './use-mark-down-key';
import { useMinusKey } from './use-minus-key';
import { useShortcutKey } from './use-shortcut-key';
import { useSlashKey } from './use-slash-key';
import { useSpaceKey } from './use-space-key';
import { useDeleteKey } from './use-delete-key';

export const useEditorInput = (
  uuid: string,
  segmentType: SegmentType,
  keydownOption = DEFAULT_KEYDOWN_OPTION
) => {
  const isMarkDownKey = useIsMarkDownKey(uuid);
  const inputAtSymbol = useInputAtSymbol(uuid);
  const markDownKey = useMarkDownKey(uuid, segmentType);
  const ligatures = useLigatures(uuid);

  // 某些操作系统，比如说windows，在开启输入法的时候，不会触发keydown事件，由input事件兜底。
  const slashKey = useSlashKey(uuid);
  const spaceKey = useSpaceKey(uuid);
  const bracketRightKey = useBracketRightKey(uuid);
  const minusKey = useMinusKey(uuid);
  const getEditorModel = useGetOrInitEditorModel();

  return useCallback(
    (event: CustomEvent) => {
      const { content } = event.detail;
      if (!keydownOption.editorInputKey) return;
      const model = getEditorModel(uuid);

      if (typeof content === 'string') {
        switch (content) {
          case '@':
            return inputAtSymbol(event, InlineContentType.DATE, '@');
          case '【':
          case '[': {
            if (!model || !model.selection) return;
            if (model.selection.focusOffset > 0) {
              const piece = sliceContent(
                model.content,
                model.selection.focusOffset - 1,
                model.selection.focusOffset
              );
              const preCharacter = contentToString(piece);
              if (preCharacter === '[' && content === '[') {
                inputAtSymbol(event, InlineContentType.LINK_PAGE, '[[');
              } else if (preCharacter === '【' && content === '【') {
                inputAtSymbol(event, InlineContentType.LINK_PAGE, '【【');
              }
            }
            break;
          }
          case ']':
          case '】':
            if (keydownOption.bracketRightKey) {
              bracketRightKey();
            }
            break;
          // 涉及到外国人名的·
          // case '·':
          case '`':
          case '~':
          case '～':
          case '*':
          case '_':
          case '$':
          case '＊':
            void markDownKey(event);
            break;
          case '>':
          case '》':
          case '-':
          case '－':
          case '=':
            if (model) {
              void isMarkDownKey(content).then((isMarkDown) => {
                if (isMarkDown) {
                  void markDownKey(event);
                  return;
                }
                if (keydownOption.minusKey) {
                  minusKey(event, 2);
                }
                ligatures(content);
              });
            }
            break;
          case ' ':
          case '　':
            if (keydownOption.spaceKey) {
              spaceKey(event, content);
            }
            break;
          case ':': {
            setAppUiState({ $allowEmojiPanel: true });
            break;
          }
          default: {
            if (isSlashSymbol(content) && keydownOption.slashKey) {
              slashKey();
              break;
            }
            const model = getEditorModel(uuid);
            if (!model || !model.selection) return;
            // 如果是emoji就转为element
            if (emojiRegexG.exec(content)) {
              event.preventDefault();
              model.performChange((ctx) => {
                ctx
                  .insert(newContent([newElement(INLINE_EMOJI_TAG, { text: content })]))
                  .collapse('focus');
              });
              return;
            }

            if (model.selection.focusOffset > 0 && $appUiStateCache.$allowEmojiPanel) {
              const piece = sliceContent(
                model.content,
                model.selection.focusOffset - 1,
                model.selection.focusOffset
              );
              const preCharacter = contentToString(piece);
              if (preCharacter === ':') {
                inputAtSymbol(event, InlineContentType.EMOJI, ':', content);
              }
            }
          }
        }
      }
    },
    [
      bracketRightKey,
      getEditorModel,
      inputAtSymbol,
      isMarkDownKey,
      keydownOption.bracketRightKey,
      keydownOption.editorInputKey,
      keydownOption.minusKey,
      keydownOption.slashKey,
      keydownOption.spaceKey,
      ligatures,
      markDownKey,
      minusKey,
      slashKey,
      spaceKey,
      uuid,
    ]
  );
};

export const useEditorKeydown = (
  uuid: string,
  segmentType: SegmentType,
  keydownOption = DEFAULT_KEYDOWN_OPTION
) => {
  const arrowDownKey = useArrowDownKey(uuid);
  const arrowLeftKey = useArrowLeftKey(uuid);
  const arrowRightKey = useArrowRightKey(uuid);
  const arrowUpKey = useArrowUpKey(uuid);
  const tabKey = useTabKey(uuid);
  const backspaceKey = useBackspaceKey(uuid, segmentType);
  const enterKey = useEnterKey(uuid, segmentType);
  const bracketRightKey = useBracketRightKey(uuid);
  const minusKey = useMinusKey(uuid);
  const spaceKey = useSpaceKey(uuid, true);
  const shortcutKey = useShortcutKey(uuid);
  const slashKey = useSlashKey(uuid);
  const pageId = useGetPageId();
  const sendPresenceData = useSendPresenceData();
  const isShare = judgeSharePage();
  const setIsEditingPage = useSetIsEditingPage();
  const deleteKey = useDeleteKey(uuid, segmentType);

  const handleKeydown = (event_: React.KeyboardEvent) => {
    if (!$appUiStateCache.$isEditingPage && !isShare) {
      setIsEditingPage(true);
    }
    sendPresenceData(pageId, uuid);

    const event = event_.nativeEvent;

    switch (true) {
      case keydownOption.arrowKey && isHotkey('ArrowUp')(event):
        return arrowUpKey(event);

      case keydownOption.arrowKey && isHotkey('ArrowDown')(event):
        return arrowDownKey(event);

      case keydownOption.arrowKey && isHotkey('ArrowLeft')(event):
        return arrowLeftKey(event);

      case keydownOption.arrowKey && isHotkey('ArrowRight')(event):
        return arrowRightKey(event);

      case keydownOption.tabKey && isHotkey(['Tab', 'Shift+Tab'])(event):
        return tabKey(event);

      case keydownOption.backspaceKey && isHotkey('Backspace')(event):
        return backspaceKey(event);

      case keydownOption.spaceKey && isHotkey(['Space', 'Shift+Space'])(event):
        return spaceKey(event, event.key);

      case keydownOption.enterKey && isHotkey(['Enter', 'Shift+Enter'])(event):
        return enterKey(event);

      case keydownOption.bracketRightKey && isHotkey([']', '】'], { byKey: true })(event):
        return bracketRightKey(event);

      case keydownOption.minusKey && isHotkey(['-', '*', '＊', '－'], { byKey: true })(event):
        return minusKey(event, 2);

      case keydownOption.slashKey &&
        event.code === 'Slash' &&
        isHotkey(slashSymbol)(event) &&
        !event.shiftKey &&
        !event.ctrlKey &&
        !event.metaKey:
        return slashKey();
      case isHotkey('Delete')(event): {
        deleteKey(event);
        break;
      }
      default:
        if (keydownOption.shortcutKey) {
          return shortcutKey(event);
        }
    }
  };

  return useCallback(handleKeydown, [
    isShare,
    sendPresenceData,
    pageId,
    uuid,
    setIsEditingPage,
    keydownOption.arrowKey,
    keydownOption.tabKey,
    keydownOption.backspaceKey,
    keydownOption.spaceKey,
    keydownOption.enterKey,
    keydownOption.bracketRightKey,
    keydownOption.minusKey,
    keydownOption.slashKey,
    keydownOption.shortcutKey,
    arrowUpKey,
    arrowDownKey,
    arrowLeftKey,
    arrowRightKey,
    tabKey,
    backspaceKey,
    spaceKey,
    enterKey,
    bracketRightKey,
    minusKey,
    slashKey,
    deleteKey,
    shortcutKey,
  ]);
};
