import type {
  AIChatDTO,
  BlockDTO,
  ConversationDetailDTO,
  QAQuestionDTO,
} from '@next-space/fe-api-idl';
import { flatMap } from 'lodash-es';
import { API } from '../../api/index';
import { createSetState, createStore } from '../../zustand/utils';
import type { ConversationEditStore } from './types';
import {
  AI_PAGE_ID,
  ChatStatus,
  getDefaultConversation,
  getDefaultConversationEditStore,
  SearchPageStatus,
} from './types';

// #region 会话编辑操作
export const conversationEditStore = createStore<Record<string, ConversationEditStore>>(
  // 'conversationEditStore',
  () => ({ [AI_PAGE_ID]: getDefaultConversationEditStore() })
  // {
  //   omitPaths: ['*.qaQueryOption.searchBlocks', '*.qaQueryOption.qaSearchSource', '*.keywords'],
  // }
);

export const setConversationEditStore = createSetState(conversationEditStore);

export const setConversationEdit = (id: string, params: Partial<ConversationEditStore>) => {
  setConversationEditStore((state) => {
    let conversationEdit = state[id];
    if (!conversationEdit) {
      conversationEdit = getDefaultConversationEditStore();
    }
    state[id] = {
      ...conversationEdit,
      ...params,
    };
    return state;
  });
};

export const getConversationEdit = (id: string) => {
  return conversationEditStore.getState()[id] || getDefaultConversationEditStore();
};
//#endregion

// #region 会话
export const conversationStore = createStore<Record<string, ConversationDetailDTO>>(() => ({}));

export const setConversationStore = createSetState(conversationStore);

export const getConversation = (id: string) => {
  return conversationStore.getState()[id] || getDefaultConversation();
};

// #endregion

// #region 搜索页面
const defaultSearchPageStore = {
  pages: {},
  searchStatus: SearchPageStatus.DEFAULT,
};
export const searchPageStore = createStore<{
  pages: Record<string, BlockDTO>;
  searchStatus: SearchPageStatus;
}>(() => defaultSearchPageStore);

export const setSearchPageStore = createSetState(searchPageStore);

export const setAiChatPages = (pages: Record<string, BlockDTO>) => {
  setSearchPageStore((state) => {
    Object.assign(state.pages, pages);
    return state;
  });
};

const fetchChatBlocks = async (conversationId: string) => {
  const chatIds = new Set(
    flatMap(getConversation(conversationId).questions || [], (question) =>
      (question.chatList || []).map((chat) => chat.uuid)
    )
  );

  const res = await API.ai.getBlocksByChatId({
    chatIds: Array.from(chatIds),
  });
  res.recordMap.blocks && setAiChatPages(res.recordMap.blocks);
};

export const setSearchStatus = (searchStatus: SearchPageStatus) => {
  setSearchPageStore((state) => {
    state.searchStatus = searchStatus;
    return state;
  });
};

// #endregion

// #region 创建或更新 conversation
export const setUpdateConversation = (
  conversationId: string,
  conversationUpdate: Partial<ConversationDetailDTO>
) => {
  setConversationStore((state) => {
    if (!state[conversationId]) {
      state[conversationId] = getDefaultConversation();
    }
    Object.assign(state[conversationId], conversationUpdate);
    return state;
  });
};
export const fetchConversation = async (conversationId: string) => {
  const res = await API.ai.getConversationDetail(conversationId);
  setUpdateConversation(conversationId, res);
  await fetchChatBlocks(conversationId);
  return res;
};
// #endregion

// #region 修改 question
/** 添加 question 到 conversation */
export const addQuestion = (conversationId: string, question: QAQuestionDTO) => {
  setConversationStore((state) => {
    if (!state[conversationId]) {
      state[conversationId] = getDefaultConversation();
    }

    const conversation = state[conversationId] as ConversationDetailDTO;

    if (!conversation?.questions) {
      conversation.questions = [];
    }

    conversation.questions.push(question);

    return state;
  });
};

/** 更新 question */
export const setUpdateQuestion = (
  conversationId: string,
  questionId: string,
  questionUpdate: Partial<QAQuestionDTO>
) => {
  setConversationStore((state) => {
    if (!state[conversationId]) {
      state[conversationId] = {
        ...getDefaultConversation(),
        questions: [],
      } as ConversationDetailDTO;
    }
    const conversation = state[conversationId] as ConversationDetailDTO;
    if (!conversation.questions) {
      conversation.questions = [];
    }

    conversation.questions = conversation.questions.map((q) =>
      q.id === questionId ? { ...q, ...questionUpdate } : q
    );
    return state;
  });
};

/** 添加 chat 到 question 的 chatList */
export const addChat = (conversationId: string, questionId: string, chat: AIChatDTO) => {
  setConversationStore((state) => {
    if (!state[conversationId]) {
      state[conversationId] = {
        ...getDefaultConversation(),
        questions: [],
      } as ConversationDetailDTO;
    }
    const conversation = state[conversationId] as ConversationDetailDTO;
    if (!conversation.questions) {
      conversation.questions = [];
    }

    const question = conversation.questions.find((q) => q.id === questionId);
    if (question) {
      if (!question.chatList) {
        question.chatList = [];
      }
      question.chatList.push(chat);
    }
    return state;
  });
};

/** 更新 chat */
export const setUpdateChat = (
  conversationId: string,
  questionId: string,
  chatId: string,
  chatUpdate: Partial<AIChatDTO>
) => {
  setConversationStore((state) => {
    if (!state[conversationId]) {
      state[conversationId] = {
        ...getDefaultConversation(),
        questions: [],
      } as ConversationDetailDTO;
    }
    const conversation = state[conversationId] as ConversationDetailDTO;
    if (!conversation.questions) {
      conversation.questions = [];
    }

    const question = conversation.questions.find((q) => q.id === questionId);
    if (question && question.chatList) {
      const chat = question.chatList.find((c) => c.uuid === chatId);
      if (chat) {
        Object.assign(chat, chatUpdate);
      }
    }
    return state;
  });
};
//#endregion

// #region lite chat operations
export const findLiteChatByParentId = (parentId: string): ConversationDetailDTO | undefined => {
  const state = conversationStore.getState();
  return Object.values(state).find((conversation) => conversation.parentId === parentId);
};

export const fetchLiteChatByParentId = async (parentId: string): Promise<string | undefined> => {
  let conversationId = findLiteChatByParentId(parentId)?.id;
  if (!conversationId) {
    const res = await API.ai.getConversationDetailWithParentId.raw(parentId);
    if (res.code === 200) {
      conversationId = res.data.id;
      setUpdateConversation(conversationId, res.data);
      await fetchChatBlocks(conversationId);
    }
  }
  return conversationId;
};

export const createLiteChat = (parentId: string, title: string): ConversationDetailDTO => {
  const id = `lite-${Date.now()}`;
  const conversation: ConversationDetailDTO = {
    ...getDefaultConversation(),
    id,
    question: title,
    parentId,
    createdAt: Date.now(),
    updatedAt: Date.now(),
  };

  setConversationStore((state) => {
    state[id] = conversation;
    return state;
  });

  return conversation;
};
// #endregion

// #region 会话状态
export const chatStatusStore = createStore<Record<string, ChatStatus>>(() => ({}));

export const setChatStatusStore = createSetState(chatStatusStore);

export const setChatStatus = (id: string, status: ChatStatus) => {
  setChatStatusStore((state) => {
    state[id] = status;
    return state;
  });
};

export const getChatStatus = (id: string) => {
  return chatStatusStore.getState()[id] ?? ChatStatus.DEFAULT;
};
// #endregion

export const resetAIStore = () => {
  setConversationStore({}, { replaceAll: true });
  setSearchPageStore(defaultSearchPageStore);
  setChatStatusStore({}, { replaceAll: true });
  setConversationEditStore({}, { replaceAll: true });
};
