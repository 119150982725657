import { emitter, EmitterEvent } from '../../utils';
import { getToken } from '../../utils/cookie';

// Track all running streams and their controllers
const runningStreams = new Map<string, AbortController>();

interface AIResponse {
  code?: number;
  content?: string;
  reasoning?: string;
}

class StreamProcessor {
  private decoder = new TextDecoder();

  constructor(private onChunk: (chunk: AIResponse) => void) {}

  private handleResponse(text: string) {
    // 处理可能包含多个 data: 的情况
    const dataChunks = text.split('data: ');

    // 跳过第一个元素（因为它是在第一个 'data:' 之前的内容）
    // 并过滤掉空白块
    const validChunks = dataChunks.slice(1).filter((chunk) => chunk.trim());

    for (const chunk of validChunks) {
      try {
        // 移除可能的结尾换行符
        const cleanedChunk = chunk.trim();
        const data = JSON.parse(cleanedChunk);
        this.onChunk({ ...data, reasoning: data.reasoning_content } as AIResponse);

        if (data.code === 1000) {
          emitter.emit(EmitterEvent.chatAiLimit);
          throw new Error(data.content || 'AI response error');
        }
      } catch (error) {
        console.error('Error parsing response:', error);
      }
    }
  }

  public async processStream(reader: ReadableStreamDefaultReader<Uint8Array>) {
    try {
      let done = false; // 初始化 done 状态
      while (!done) {
        const { done: isDone, value } = await reader.read();
        done = isDone; // 更新 done 状态

        if (done) break;

        const text = this.decoder.decode(value, { stream: true });
        // 直接处理读取到的文本
        this.handleResponse(text);
      }
    } catch (error) {
      console.error('Error streaming AI response:', error);
    } finally {
      reader.releaseLock();
    }
  }
}

// Utility function to make real API streaming call
export const streamAIResponse = async (
  chatId: string,
  spaceId: string,
  onChunk: (chunk: AIResponse) => void
) => {
  const controller = new AbortController();
  runningStreams.set(chatId, controller);

  try {
    const response = await fetch(`/api/chat/${spaceId}/qa`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({ chatId }),
      signal: controller.signal,
    });

    if (!response.ok) {
      throw new Error(`API request failed with status ${response.status}`);
    }

    const reader = response.body?.getReader();
    if (!reader) {
      throw new Error('Response body is not readable');
    }

    const processor = new StreamProcessor(onChunk);
    await processor.processStream(reader);
  } catch (error) {
    console.error('Error streaming AI response:', error);
  } finally {
    runningStreams.delete(chatId);
  }
};

// Stop a specific stream by ID
export const stopStream = (id: string) => {
  const controller = runningStreams.get(id);
  if (controller) {
    controller.abort();
    runningStreams.delete(id);
  }
};
