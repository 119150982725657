import { fastEqual } from '@flowus/common';
import type { BlockType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { createSelector } from 'reselect';
import { getState } from 'src/redux/store';
import type { RootState } from 'src/redux/types';

export const descendantsSelector = createSelector(
  (state: RootState, _id: string) => state.blocks,
  (_state: RootState, id: string) => id,
  (_state: RootState, id: string, filterTypes: BlockType[]) => filterTypes,
  (blocks, id, filterTypes) => {
    const result = new Set<string>();
    const loop = (item: string) => {
      if (!filterTypes.includes(blocks[item]?.type ?? 0)) {
        blocks[item]?.subNodes.forEach((k) => {
          result.add(k);
          loop(k);
        });
      }
    };

    loop(id);
    return result;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: fastEqual,
      maxSize: 100,
    },
  }
);

export const descendantsSelectorByIgnoreTypes = createSelector(
  (state: RootState, _id: string) => state.blocks,
  (_state: RootState, id: string) => id,
  (_state: RootState, id: string, ignoreTypes: BlockType[]) => ignoreTypes,
  (blocks, id, ignoreTypes) => {
    const result = new Set<string>();
    const loop = (item: string) => {
      if (ignoreTypes.includes(blocks[item]?.type ?? 0)) {
        blocks[item]?.subNodes.forEach((k) => {
          result.add(k);
          loop(k);
        });
      }
    };

    loop(id);
    return result;
  },
  {
    memoizeOptions: {
      resultEqualityCheck: fastEqual,
    },
  }
);

export const useGetDescendants = () => {
  const getDescendants = (id: string, filterTypes: BlockType[] = []) => {
    return descendantsSelector(getState(), id, filterTypes);
  };

  return useCallback(getDescendants, []);
};
