import type { FC } from 'react';
import type { FormulaValueProps } from '../type';
import { formula } from '@flowus/formula';
import { InlinePage } from 'src/editor/editor/inline/inline-page';
import { cx } from '@flowus/common/cx';

export const FormulaRelationValue: FC<FormulaValueProps> = (props) => {
  const pages = formula.ValueTool.asList(props.value).elements.map((v) => {
    return v as formula.RelationPage;
  });
  return (
    <div className="flex items-center">
      {pages.map((v) => {
        return (
          <InlinePage
            openRight={false}
            uuid={v.recordId}
            key={v.recordId}
            className={cx('leading-none p-0 mr-2 whitespace-nowrap text-ellipsis')}
          />
        );
      })}
    </div>
  );
};
export const FormulaSingleRelationValue: FC<FormulaValueProps> = (props) => {
  const page = formula.ValueTool.asPage(props.value);
  return (
    <InlinePage
      openRight={false}
      uuid={page.recordId}
      key={page.recordId}
      className={cx('leading-none p-0 mr-2 whitespace-nowrap text-ellipsis')}
    />
  );
};
