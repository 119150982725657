import { IpcMessage } from '@flowus/shared';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTransferStatus } from 'src/components/transfer-list/hooks';
import { TransferStatus } from 'src/components/transfer-list/types';
import { useIsLogin } from 'src/hooks/user/use-is-login';
import { ipcSendMessage, isFlowUsApp } from 'src/utils/electron-util';
import { $appUiStateCache, useGetOffline } from '..';

/** 检查更新版本弹窗 */
export const useCheckVersion = () => {
  const { pathname } = useLocation();
  const isLogin = useIsLogin();
  const transferStatus = useTransferStatus();
  const offline = useGetOffline();

  useEffect(() => {
    if (offline) return;
    if (transferStatus !== TransferStatus.NONE) return;
    const checkHouse = dayjs().diff($appUiStateCache.$lastRefreshTime, 'hours');
    const check = $appUiStateCache.$readyUpdate || checkHouse >= 24;
    if (check) {
      if (isFlowUsApp.check) {
        ipcSendMessage(IpcMessage.Reload);
      } else {
        location.reload();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // const checkVersion = async () => {
  //   if (offline) return;
  //   if ($appUiStateCache.$readyUpdate) return;

  //   const versionRes = await getOnlineVersion();
  //   const onlineVersion: string = versionRes?.version ?? '';
  //   const lastLocalVersion = getLocalVersion();
  //   if (!onlineVersion) return;

  //   // 本地没记录过，视本地为最新版
  //   if (!lastLocalVersion.version) {
  //     void updateLocalVersion(onlineVersion, { open: false });
  //     return;
  //   }

  //   // 本地和线上版本不符，准备更新
  //   if (lastLocalVersion.version !== onlineVersion) {
  //     const last = getLargeVersion(lastLocalVersion.version);
  //     const online = getLargeVersion(onlineVersion);

  //     void updateLocalVersion(onlineVersion, { open: last < online });
  //     setAppUiState({ $readyUpdate: true });
  //   }
  // };

  useEffect(() => {
    // if (isFlowUsApp.check) return;
    if (!isLogin) return;
    if (!__HOST_PRODUCTION__ || __BUILD_IN__) return;
    // const checkVersionTimer = setInterval(checkVersion, 12 * 60 * 60 * 1000);
    return () => {
      // clearInterval(checkVersionTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogin]);
};
