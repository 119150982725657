import type { Editor } from '@tiptap/react';
import type { TableOfContentData } from '@tiptap-pro/extension-table-of-contents';

const MAX_EDITORS = 10;
const editorMap = new Map<string, Editor>();
const editorQueue: string[] = [];

// Add TOC store
type TOCItem = TableOfContentData[number];

const tocMap = new Map<string, TOCItem[]>();

export const setEditor = (id: string, editor: Editor) => {
  // Clean up existing editor if any
  destroyEditor(id);

  // If we're at capacity and this is a new editor, remove the oldest one
  if (editorMap.size >= MAX_EDITORS && !editorMap.has(id)) {
    const oldestId = editorQueue.shift();
    if (oldestId) {
      const oldEditor = editorMap.get(oldestId);
      if (oldEditor) {
        oldEditor.destroy();
        editorMap.delete(oldestId);
      }
    }
  }

  editorMap.set(id, editor);
  // Remove existing id from queue if present
  const existingIndex = editorQueue.indexOf(id);
  if (existingIndex !== -1) {
    editorQueue.splice(existingIndex, 1);
  }
  editorQueue.push(id);
};

export const getEditor = (id: string): Editor | undefined => {
  return editorMap.get(id);
};

export const destroyEditor = (id: string) => {
  const editor = editorMap.get(id);
  if (editor) {
    editor.destroy();
    editorMap.delete(id);
    const index = editorQueue.indexOf(id);
    if (index !== -1) {
      editorQueue.splice(index, 1);
    }
  }
};

export const setTOC = (id: string, items: TOCItem[]) => {
  tocMap.set(id, items);
};

export const getTOC = (id: string): TOCItem[] | undefined => {
  return tocMap.get(id);
};

export const destroyTOC = (id: string) => {
  tocMap.delete(id);
};
