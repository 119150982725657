import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { useIsInRight } from 'src/utils/right-utils';
import { PageFeedTabIndex, setPageFeedIndex } from '../drawer/page-feeds/page-feeds';
import { useDrawerOpenState, useToggleDrawer } from '../drawer/shared';

export const PageFeedsButton: FC<{ className?: string }> = ({ className }) => {
  const [drawState] = useDrawerOpenState();
  const toggleDrawer = useToggleDrawer();
  const isInRight = useIsInRight();
  const hover =
    (isInRight && drawState === 'page-feeds-right') || (!isInRight && drawState === 'page-feeds');
  return (
    <Tooltip
      popup={drawState ? '关闭页面信息' : '查看页面信息'}
      className={cx(
        'p-1 flex items-center justify-center animate-hover',
        hover && 'bg-black_006',
        className
      )}
      onClick={() => {
        setPageFeedIndex(PageFeedTabIndex.PAGE_INFO);
        toggleDrawer('page-feeds');
      }}
    >
      <Icon name={'IcInfo'} size="middle" />
    </Tooltip>
  );
};
