import type { ReactNode } from 'react';

export enum RenderMode {
  innerHtml = 'innerHtml',
  blank = 'blank',
}

interface Props {
  blockId?: string;
  renderMode?: RenderMode;
  rootMargin?: string;
  placeHolder?: ReactNode;
  updateByBlock?: boolean;
  trackVisibility?: boolean;
  delay?: number;
  defaultItemHeight?: number;
}
export type VisibleRenderPropsType = Props & JSX.IntrinsicElements['div'];
