import { CollectionSchemaType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { DateEditor } from './date';
import { FilesEditor } from './files';
import { FormulaEditor } from './formula';
import { UrlEditor } from './link';
import { NumEditor } from './num-editor';
import { PersonEditor } from './person-editor';
import type { RelationEditor as RelationEditor0 } from './relation/relation-editor';
import { RichTextEditor } from './rich-text-editor';
import { MultiSelectEditor, SelectEditor } from './select';
import { TextEditor } from './text-editor';
import type { CellEditorProps } from './types';

export const NO_CLICK_AWAY_CLASS_NAME = 'cell-editor-no-click-away';

let RelationEditor: typeof RelationEditor0;

export const antiCycleSet_RelationEditor = (RelationEditor0: typeof RelationEditor) => {
  RelationEditor = RelationEditor0;
};

/**
 * 根据 valueType 获取对应渲染的组件
 */
export const getCellEditorComponent = (type?: CollectionSchemaType): FC<CellEditorProps> => {
  if (type === CollectionSchemaType.SELECT) return SelectEditor;
  if (type === CollectionSchemaType.MULTI_SELECT) return MultiSelectEditor;
  if (type === CollectionSchemaType.DATE) return DateEditor;
  if (type === CollectionSchemaType.PERSON) return PersonEditor;
  if (type === CollectionSchemaType.TEXT) return RichTextEditor;
  if (type === CollectionSchemaType.NUMBER) return NumEditor;
  if (type === CollectionSchemaType.TITLE) return RichTextEditor;
  if (type === CollectionSchemaType.URL) return UrlEditor;
  if (type === CollectionSchemaType.EMAIL) return UrlEditor;
  if (type === CollectionSchemaType.PHONE) return UrlEditor;
  if (type === CollectionSchemaType.FILE) return FilesEditor;
  if (type === CollectionSchemaType.FORMULA) return FormulaEditor;
  if (type === CollectionSchemaType.RELATION) return RelationEditor;

  return TextEditor;
};

export const CellEditor: FC<CellEditorProps> = (props) => {
  const cellType = useObservableStore(
    ({ blocks }) => {
      const collectionId = blocks[props.recordId]?.parentId;
      return blocks[collectionId ?? '']?.data.schema?.[props.propertyId]?.type;
    },
    [props.recordId, props.propertyId]
  );
  const CellEditorComponent = getCellEditorComponent(cellType);
  return <CellEditorComponent {...props} />;
};
