import type { TextType } from '@next-space/fe-api-idl';
import type { ReactNode } from 'react';

export enum Site {
  // 单元格
  CELL,
  // 属性页
  FIELD,
  // 卡片
  CARD,
  // 列表
  LIST,
  // 日历
  CALENDAR,
  // 公式预览
  FORMULA_PREVIEW,
}

export interface DateSegmentInfo {
  dateString: string;
  timestamp: number;
  endDateString?: string;
  endTimestamp?: number;
  textType: TextType.DATE | TextType.DATETIME;
}
export interface CellViewProps {
  site: Site;
  recordId: string;
  propertyId: string;
  groupValue?: string;
  className?: string;
  groupProperty?: string;
  isLastCell?: boolean;
  level?: number;
  expandNode?: ReactNode;
  propertyIndex?: number;
  idnumberIndex?: number;
}

export interface CellEditorProps {
  site: Site;
  recordId: string;
  propertyId: string;
  viewId?: string;
  onUpdate(): void;
  onClose(): void;
  width?: number;
  recordIds?: string[];
}
