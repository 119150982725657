import { getIconsColor } from '@flowus/common/block/color/get-block-color';
import { MoreButtonList } from '@flowus/common/components/more-button-list';
import { useFinalValue } from '@flowus/common/hooks/react-utils';
import { DEFAULT_FONT_FAMILY } from '@flowus/common/style';
import { MindMapContext, MindMapEngine } from '@flowus/mind-map';
import type { FC, MouseEvent } from 'react';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { DEFAULT_PAGE_WIDTH } from 'src/common/const';
import { NotFound } from 'src/components/empty';
import { ResizeElement } from 'src/editor/component/resize-element';
import { EmbedContext, ReadonlyContext } from 'src/hooks/block/use-block-locked';
import { useResize } from 'src/hooks/block/use-resize';
import { useNormalizePath, useReadonly } from 'src/hooks/page';
import { MindMapRender } from 'src/mind-map/mind-map-render';
import {
  getMindMapScale,
  saveMindMapScale,
  supportAppendChild,
} from 'src/mind-map/utils/mind-engine-utils';
import { useIsMobileSize } from 'src/services/app/hook';
import { StoreContextProvider } from 'src/services/store-context/provider';
import { judgeSharePage } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { PageScrollRefContext } from 'src/views/main/page-doc/context';
import type { BlockElement } from '../core/type';
import { useOpenBlockRightMenuList } from '../uikit/hover-menu/block-menu-list/hook';
import { BlockDrop } from './dnd/block-drop';
import { NextModalProvider } from '@flowus/common/next-modal';
import { ObsStoreContext } from 'src/services/rxjs-redux/context';

export const EmbedMindMap: BlockElement = ({ id, root }) => {
  const block = usePickBlock(id, []);
  const scrollRef = useRef<HTMLDivElement>(null);
  const readonly = useReadonly(id);
  const isMobileSize = useIsMobileSize();
  const path = useNormalizePath(id);
  const history = useHistory();
  const {
    onRenderSize,
    changeSize,
    containerWidth,
    containerRef,
    defaultWidth,
    defaultHeight,
    defaultBlockFullWidth,
  } = useResize({
    id,
    defaultWidth: DEFAULT_PAGE_WIDTH,
    defaultHeight: 330,
    root,
    defaultBlockFullWidth: true,
  });
  const engine = useFinalValue(
    () =>
      new MindMapEngine({
        getAllElement: () => {
          return scrollRef.current?.querySelectorAll('[data-block-id]');
        },
        getCanvasPosition: (instance, x, y) => {
          if (scrollRef.current) {
            const state = instance.getState();
            const scaleFactor = state.scale * 0.01;
            const nodeDrawer = scrollRef.current.querySelector('.node-drawer');
            const nodeDrawerRect = nodeDrawer?.getBoundingClientRect();
            if (!nodeDrawerRect) return [x, y];
            // 缩放后偏移量
            const convertX = x - nodeDrawerRect.left;
            const convertY = y - nodeDrawerRect.top;
            return [convertX / scaleFactor, convertY / scaleFactor];
          }

          return [x, y];
        },
        supportAppendChild: (id: string) => {
          return supportAppendChild(id);
        },
        getLineColor: (themeColor: string) => {
          return getIconsColor(themeColor);
        },
      })
  );

  useEffect(() => {
    const removeListenerFun = engine.addScaleListener({
      onScaleChange: (context, scale) => {
        saveMindMapScale(context, scale, true);
      },
    });
    engine.zoom(getMindMapScale(id, true) ?? 80);
    return removeListenerFun;
  }, [engine, id]);

  if (!block) return <NotFound />;
  return (
    <BlockDrop
      id={id}
      className="my-2 self-center w-full"
      blockRef={containerRef}
      fullWidth={!root || isMobileSize}
      horizontal={root}
      onDoubleClick={(e) => {
        if (e.target instanceof Element) {
          if (e.target.closest('[data-ignore-double-click]')) return;
        }
        history.push(path);
      }}
    >
      <ResizeElement
        className="bg-grey9"
        resizeHeight
        readonly={readonly}
        doubleResize={root}
        defaultWidth={defaultWidth || DEFAULT_PAGE_WIDTH}
        defaultHeight={defaultHeight}
        defaultBlockFullWidth={defaultBlockFullWidth}
        maxWidth={containerWidth}
        minHeight={250}
        resizeWidth={false}
        onChange={changeSize}
        onRenderSize={onRenderSize}
      >
        <StoreContextProvider wait={800} waitMode="debounce">
          <EmbedContext.Provider value={true}>
            <ReadonlyContext.Provider value={true}>
              <MindMapContext.Provider value={engine}>
                <NextModalProvider>
                  <ObsStoreContext.Provider value={{ enableAuditTime: false }}>
                    <PageScrollRefContext.Provider value={scrollRef}>
                      <div
                        className="w-full next-space-page embed-mind-page h-full overflow-hidden line-default"
                        ref={scrollRef}
                        data-mind-page-id={id}
                        style={{ cursor: 'pointer', fontFamily: DEFAULT_FONT_FAMILY }}
                      >
                        <MindMapRender id={id} key={id} />
                      </div>
                    </PageScrollRefContext.Provider>
                  </ObsStoreContext.Provider>
                  <RightButtons id={id} />
                </NextModalProvider>
              </MindMapContext.Provider>
            </ReadonlyContext.Provider>
          </EmbedContext.Provider>
        </StoreContextProvider>
      </ResizeElement>
    </BlockDrop>
  );
};

const RightButtons: FC<{ id: string }> = ({ id }) => {
  const openBlockRightMenuList = useOpenBlockRightMenuList();
  const path = useNormalizePath(id);
  return (
    <MoreButtonList
      renderList={[
        {
          title: '新窗口查看',
          icon: 'IcMenuOpenNew',
          click: () => window.open(path),
        },
        {
          title: '菜单',
          icon: 'IcMore',
          click: (event: MouseEvent) => {
            openBlockRightMenuList({
              blockId: id,
              blockNode: event.currentTarget as HTMLElement,
              placement: 'left',
              offset: [0, 5],
            });
          },
          hidden: judgeSharePage(),
        },
      ]}
    />
  );
};
