import { useEffect, useState } from 'react';
import { isBuildIn } from '../build-in';
import { getCurrentLanguage, Language } from '../hooks/use-language';
import { isElectron } from 'react-device-detect';

export const detectRegionIsRu = {
  isRu: null as boolean | null,

  // 检测浏览器语言
  language: () => {
    const lang = navigator.language?.toLowerCase();
    return lang?.startsWith('ru');
  },

  path: () => {
    return window.location.href.includes('/ru/');
  },

  // 检测时区
  timezone: () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return tz?.includes('Moscow') || tz?.includes('/Vladivostok');
  },

  // 检测 IP (使用免费 API)
  async ip() {
    try {
      const res = await fetch('https://ipapi.co/country_code/');
      const country = await res.text();
      return country === 'RU';
    } catch {
      return false;
    }
  },

  // 综合检测
  isRussian() {
    return !!this.isRu;
  },

  async init() {
    try {
      // @ts-ignore global
      if (!__HOST_PRODUCTION__) {
        this.isRu = true;
        return this.isRu;
      }
    } catch {
      //
    }
    if (isBuildIn()) {
      if (isElectron) {
        this.isRu = true;
        return this.isRu;
      }

      const cookie = getCurrentLanguage() === Language.ru;

      if (this.language() || this.timezone() || this.path() || cookie) {
        this.isRu = true;
        return this.isRu;
      }

      const res = await this.ip();
      this.isRu = res;

      return this.isRu;
    }

    this.isRu = false;

    return this.isRu;
  },
};

export const useSystemIsRu = () => {
  const [isRu, setIsRu] = useState(detectRegionIsRu.isRu);

  useEffect(() => {
    void detectRegionIsRu.init().then(setIsRu);
  }, []);

  return !!isRu;
};
