import { getCurrencySymbols } from '@flowus/common';
import { ScrollLoadTable } from '@flowus/common/components/scroll-load-list';
import { useCloseModal } from '@flowus/common/next-modal';
import { Tooltip } from '@flowus/common/tooltip';
import { Modals } from '@flowus/shared';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { request } from 'src/common/request/request';
import { ViewPath } from 'src/utils';
import { getLocationOrigin } from 'src/utils/location-utils';
import type { AsyncReturnType } from 'type-fest';

/** 累计金额记录 */
export const OrderRecordModal: FC<{ type: 'page' | 'user' | 'all' }> = (props) => {
  const { type } = props;
  const closeModal = useCloseModal();

  const getWalletListApi = useCallback(
    async (params: { pageIndex: number; pageSize: number }) => {
      const from = {
        page: 0,
        user: 1,
        all: 2,
      };

      const res = await request.infra.distributionAlliancePayRecords(
        params.pageIndex,
        params.pageSize,
        from[type]
      );

      const list = res.results
        .map((item) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const userInfo = res.recordMap.users?.[item.userId!];

          return {
            ...item,
            userInfo,
          };
        })
        .filter(Boolean);

      return {
        list,
        more: res.more,
      };
    },
    [type]
  );

  const { titleList, dataList } = useMemo(() => {
    type ItemType = AsyncReturnType<typeof getWalletListApi>['list'][number];

    const dataInfo = {
      total: {
        title: '金额',
        id: 'total',
        render: (item: ItemType) => `${getCurrencySymbols()}${item.total}`,
      },
      type: {
        title: '佣金类型',
        id: 'type',
        render: (item: ItemType) => (item.from === 0 ? `订阅分销` : `空间会员`),
      },
      name: {
        title: '付费用户昵称',
        id: 'name',
        render: (item: ItemType) => `${item.userInfo?.nickname}`,
      },
      status: {
        title: '状态',
        id: 'status',
        render: (item: ItemType) => {
          if (item.inProgress) return '入账中';
          if (item.status === 2) return '已取消';
          if (item.status === 0) return '未付款';
          return '已入账';
        },
      },
      createdAt: {
        title: '时间',
        id: 'createdAt',
        render: (item: ItemType) => {
          return dayjs(item.payAt).format('YYYY-MM-DD HH:mm');
        },
      },
      pageFrom: {
        title: '页面来源',
        id: 'sharePageId',
        render: (item: ItemType) => {
          return (
            <Tooltip popup={item.sharePageId} className="inline-block">
              <a
                target="_blank"
                href={`${getLocationOrigin()}${ViewPath.share}${item.sharePageId}`}
                className="text-active_color underline underline-offset-4"
              >
                {item.sharePageId?.substring(0, 8)}
              </a>
            </Tooltip>
          );
        },
      },
    };

    const _dataList: typeof dataInfo.total[] = [];

    if (type === 'all') {
      _dataList.push(
        ...[dataInfo.total, dataInfo.type, dataInfo.name, dataInfo.status, dataInfo.createdAt]
      );
    }

    if (type === 'user') {
      _dataList.push(
        // @ts-ignore element
        ...[dataInfo.total, dataInfo.pageFrom, dataInfo.name, dataInfo.status, dataInfo.createdAt]
      );
    }

    if (type === 'page') {
      _dataList.push(...[dataInfo.total, dataInfo.name, dataInfo.status, dataInfo.createdAt]);
    }

    return {
      titleList: _dataList,
      dataList: _dataList,
    };
  }, [type]);

  return (
    <ScrollLoadTable
      className="min-w-[500px]"
      modalHeader={{
        title: '累计获得佣金',
        closeModal: () => closeModal(Modals.ORDER_RECORD_MODAL),
      }}
      getApi={getWalletListApi}
      titleList={titleList}
      dateList={dataList}
    />
  );
};
