/**
 * 引导程序
 */
import * as Sentry from '@sentry/react';
import { getCurrentUser } from './hooks/user';
// import { Integrations } from '@sentry/tracing';
// import { getState } from './redux/store';

const initSentry = () => {
  if (!__PRIVATE__) {
    if (__HOST_STAGING__ || __HOST_PRODUCTION__) {
      const environment = __HOST_STAGING__ ? 'staging' : 'production';
      // buildin sentry返回503,考虑到这个没人看了，就先不加了
      if (__BUILD_IN__) return;
      Sentry.init({
        dsn: __BUILD_IN__
          ? 'https://6eeae899ed19149027b00b592f489cf7@sentry.buildin.ai:443/3'
          : 'https://1cc4406cb8a38b141a2fab1f100ec022@sentry.allflow.cn:443/5',
        autoSessionTracking: true,
        tracesSampleRate: 0.5,
        ignoreErrors: [
          // https://stackoverflow.com/a/50387233/3382144
          'ResizeObserver loop limit exceeded',
          'ResizeObserver loop completed with undelivered notifications.',
          //
          "Unexpected token '<'",
        ],
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        // integrations: [new Integrations.BrowserTracing()],
        environment,
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        release: import.meta.env.VITE_GIT_COMMIT,
        beforeSend(event) {
          const user = getCurrentUser();
          event.user = { id: user.uuid, username: user.nickname };
          return event;
        },
      });
    }
  }
};

initSentry();
