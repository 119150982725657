import type { BlockDataDTO, BlockType } from '@next-space/fe-api-idl';
import { BlockStatus } from '@next-space/fe-api-idl';
import { v4 } from 'uuid';
import type { HandleDataType } from '../../types';
import type { NextBlock } from 'src/redux/types';

export const createBlockFactory =
  (pageBlock: NextBlock, handleData: HandleDataType) =>
  (
    type: BlockType,
    parentId: string,
    data: BlockDataDTO & { backgroundColor?: string; localUrl?: string } = {}
  ) => {
    const uuid = v4();
    const newBlock = {
      uuid,
      type,
      spaceId: pageBlock.spaceId,
      parentId,
      textColor: '',
      backgroundColor: data.backgroundColor || '',
      permissions: [],
      subNodes: [],
      data,
      status: BlockStatus.NORMAL,
      version: 0,
    };
    handleData.blocks[uuid] = newBlock;
    handleData.blockPos[parentId] = (handleData.blockPos[parentId] || []).concat(uuid);
    return uuid;
  };
