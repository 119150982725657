import type { DateFormat, PermissionDTO, TimeFormat } from '@next-space/fe-api-idl';

export interface PickerDate {
  from: Date;
  to?: Date;
  dateFormat?: DateFormat;
  timeFormat?: TimeFormat;
  includeTime?: boolean; // 是否包含时间
  reminder?: Reminder;
  includeDateRange?: boolean;
}
export interface Reminder {
  time?: string;
  value?: number;
  unit?: 'day' | 'hour' | 'minute';
  userPermissions?: PermissionDTO[];
}
/** 因为要通过segment.reminder来算出具体的选项，因此选项的字段跟reminder里的字段保持一致 */
// unit?: 'day' | 'hour' | 'minute',
interface RemindOption {
  name: string;
  value: number;
  time?: string;
  unit?: 'day' | 'hour' | 'minute';
}
export const remindDayOptions: RemindOption[] = [
  {
    name: '无',
    value: 0,
    time: '',
  },
  {
    name: '当天(上午9:00)',
    value: 0,
    time: '09:00',
    unit: 'day',
  },
  {
    name: '提前1天(上午9:00)',
    value: 1,
    time: '09:00',
    unit: 'day',
  },
  {
    name: '提前2天(上午9:00)',
    value: 2,
    time: '09:00',
    unit: 'day',
  },
  {
    name: '提前1周(上午9:00)',
    value: 7,
    time: '09:00',
    unit: 'day',
  },
];
export const remindTimeOptions: RemindOption[] = [
  {
    name: '无',
    value: 0,
  },
  {
    name: '当时',
    value: 0,
    unit: 'minute',
  },
  {
    name: '提前5分钟',
    value: 5,
    unit: 'minute',
  },
  {
    name: '提前10分钟',
    value: 10,
    unit: 'minute',
  },
  {
    name: '提前15分钟',
    value: 15,
    unit: 'minute',
  },
  {
    name: '提前30分钟',
    value: 30,
    unit: 'minute',
  },
  {
    name: '提前1小时',
    value: 1,
    unit: 'hour',
  },
  {
    name: '提前2小时',
    value: 2,
    unit: 'hour',
  },
  {
    name: '提前1天',
    value: 1,
    unit: 'day',
  },
  {
    name: '提前2天',
    value: 2,
    unit: 'day',
  },
  {
    name: '提前1周',
    value: 7,
    unit: 'day',
  },
];
