import { getFormatImageUrl } from '@flowus/common';
import { cx } from '@flowus/common/cx';
import { useDebounceElementSize } from '@flowus/common/hooks/use-debounce-element-size';
import { getOssName, ossImageSnapshotUrl } from '@flowus/common/url';
import type { FC, MouseEvent } from 'react';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import { releaseLocalUrl } from 'src/common/utils/release-local-url';
import { compressImageSupport } from 'src/common/utils/url-utils';
import { ImageUploadBox } from 'src/components/image-upload-box';
import { CoverImages } from 'src/editor/component/cover-images';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useReadonly } from 'src/hooks/page';
import { useMove } from 'src/hooks/public/use-move';
import { useTransaction } from 'src/hooks/use-transaction';
import { Modals } from 'src/modals';
import { updateBlock } from 'src/redux/managers/block/update';
import { useBodyHeight } from 'src/services/app';
import { getFileNameInfo, getImageSizeInfo } from 'src/utils/file';
import { usePickBlock } from 'src/utils/pick-block';
import { urlFetcher } from 'src/utils/url-fetcher';
import isURL from 'validator/lib/isURL';
import { calcPageHeaderCoverHeight } from './page-header';

interface Props {
  /** 如果传入了url则使用这个url，但无法使用编辑功能 */
  ossName?: string;
  uuid: string;
  width?: number;
  height?: number;
  className?: string;
  shareId?: string;
  isCardCover?: boolean;
  isGalleryCard?: boolean;
  /* 竖向展示 */
  isVertical?: boolean;
  fitImage?: boolean;
  hiddenEditorButton?: boolean;
}

export const HeaderCover: FC<Props> = memo(
  ({
    uuid,
    height,
    className,
    isCardCover,
    isGalleryCard,
    isVertical,
    width,
    fitImage,
    hiddenEditorButton,
    ossName,
    shareId,
  }) => {
    const updateTask = useUpdateTask();
    const block = usePickBlock(uuid, ['data'], ['cover', 'coverPos', 'localUrl', 'size']);
    const coverPos = block?.data.coverPos ?? 50;
    const [position, setPosition] = useState(coverPos);
    const positionRef = useRef(position);
    const [startMove, setStartMove] = useState(false);
    const imageContainerRef = useRef<HTMLImageElement>(null);
    const imageRef = useRef<HTMLDivElement>(null);
    const bodyHeight = useBodyHeight();
    const imageHeight = useDebounceElementSize(imageRef.current, { type: 'height' }).height ?? 0;
    const containerWidth =
      useDebounceElementSize(imageContainerRef.current, { type: 'width' }).width ?? width;
    const [imageUrl, setImageUrl] = useState(block?.data.localUrl ?? '');
    const readonly = useReadonly(uuid);
    const openModal = useOpenModal();
    const transaction = useTransaction();
    const originUrl = useRef('');
    const cover = useMemo(() => {
      const oss = ossName ?? block?.data.cover ?? '';
      return getOssName(oss) || oss;
    }, [block?.data.cover, ossName]);

    let containerHeight = height ?? calcPageHeaderCoverHeight(bodyHeight);
    if ((isCardCover || isGalleryCard) && containerWidth) {
      containerHeight = containerWidth * (isVertical ? 1.5 : 0.55);
    }

    useEffect(() => {
      if (isURL(cover)) {
        setImageUrl(cover);
        return;
      }

      if (block?.data.localUrl) {
        setImageUrl(block.data.localUrl);
      } else {
        void urlFetcher
          .fetchImageUrl({
            blockId: uuid,
            ossName: cover,
            opt: { shareId },
          })
          .then((url) => {
            const { extName } = getFileNameInfo(cover);
            originUrl.current = getFormatImageUrl(url, extName);
            if (compressImageSupport.test(extName)) {
              let imageUrl = `${url}&x-oss-process=image/quality,q_100/`;
              if ((isGalleryCard || isCardCover) && width) {
                imageUrl = ossImageSnapshotUrl(
                  url,
                  Math.floor(
                    isGalleryCard
                      ? width * 2 * Math.ceil(window.devicePixelRatio)
                      : width * Math.ceil(window.devicePixelRatio)
                  )
                );
              }
              setImageUrl(getFormatImageUrl(imageUrl, extName));
            } else {
              setImageUrl(getFormatImageUrl(url, extName));
            }
          });
      }
    }, [cover, block?.data.localUrl, isCardCover, isGalleryCard, width, uuid, ossName, shareId]);

    useEffect(() => setPosition(coverPos), [coverPos]);

    // 保存位置
    const saveImagePosition = () => {
      transaction(() => {
        updateBlock(uuid, { data: { coverPos: positionRef.current } });
      });
      setStartMove(false);
    };

    // 不保存位置
    const cancelImagePosition = () => {
      setPosition(coverPos);
      setStartMove(false);
    };

    const { moveProps } = useMove({
      onMove(e) {
        const clamp = (pos: number) =>
          Number(Math.min(Math.max(pos, -(imageHeight - containerHeight))).toFixed(2));

        setPosition((y) => {
          let newY = clamp(y - e.deltaY / 5);
          if (newY <= 0) {
            newY = 0;
          } else if (newY > 100) {
            newY = 100;
          }
          // 防止超出盒子
          positionRef.current = newY;
          return newY;
        });
      },
    });

    // 选择图库封面
    const selectImage = async (file: { ossName?: string; imageFile?: File }) => {
      let localUrl = '';
      // 本地上传图片避免鉴权报错，先使用本地地址
      if (file.imageFile) {
        const imageInfo = await getImageSizeInfo(file.imageFile);
        localUrl = imageInfo.url;
      }
      transaction(() => {
        updateBlock(uuid, {
          data: {
            cover: file.ossName,
            coverPos: 50,
            localUrl,
          },
        });
      });
    };

    // 清空封面
    const deleteCoverImage = () => {
      transaction(() => {
        updateBlock(uuid, { data: { cover: '', localUrl: undefined } });
      });
    };

    // 打开图库
    const openCoverImages = (event: MouseEvent<HTMLElement>) => {
      if (!event.currentTarget.parentElement) return;
      openModal.dropdown({
        modalId: Modals.HEADER_COVER_IMAGE,
        popcorn: event.currentTarget.parentElement,
        placement: 'bottom-end',
        offset: [75, 0],
        content: ({ onCloseModal }) => (
          <CoverImages
            blockId={uuid}
            selectImage={({ ossName, type, file, url }) => {
              if (type === 'http') {
                transaction(() => {
                  updateBlock(uuid, {
                    data: {
                      cover: url,
                      coverPos: 50,
                    },
                  });
                });
                return;
              }
              void selectImage({ ossName, imageFile: file });
              if (type === 'upload') {
                void updateTask(
                  ActivityIds.GUIDE_FIRST_TIME_UPLOAD_COVER,
                  ActivitiesListType.basicList
                );
                onCloseModal();
              }
            }}
            deleteCoverImage={() => {
              onCloseModal();
              deleteCoverImage();
            }}
          />
        ),
      });
    };

    return (
      <div
        className={cx(
          'bg-cover group absolute top-0 left-0 w-full overflow-hidden transition-all',
          className
        )}
        ref={imageContainerRef}
        style={{ height: containerHeight }}
      >
        {startMove && (
          <span className="tran text-t4 pointer-events-none absolute top-1/2 left-1/2 z-10 -translate-x-1/2 -translate-y-1/2 rounded-sm bg-black-base/75 px-6 py-1 text-white">
            拖动图片以调整位置
          </span>
        )}
        {imageUrl && (
          <div ref={imageRef}>
            <ImageUploadBox
              uuid={uuid}
              {...(startMove ? moveProps : {})}
              src={imageUrl}
              onError={() => {
                if (block?.data.localUrl) {
                  releaseLocalUrl(block);
                }
                if (originUrl.current) {
                  setImageUrl(originUrl.current);
                }
              }}
              className={cx(
                'w-full after:absolute after:top-0 after:left-0 after:h-full after:w-full after:bg-white1 after:content-[""] select-none',
                startMove ? 'relative z-[1] cursor-move' : 'pointer-events-none',
                fitImage ? 'object-contain' : 'object-cover'
              )}
              style={{
                height: containerHeight,
                objectPosition: `center ${position}%`,
                transition: 'height 0.2s',
              }}
            />
          </div>
        )}
        {!readonly && !isCardCover && !hiddenEditorButton && !ossName && (
          <div
            className={cx(
              'text-t4 absolute bottom-2.5 right-[90px] z-10 flex items-center rounded-sm bg-black-base/50 text-white opacity-0 transition-opacity',
              startMove && 'opacity-100',
              !startMove && 'group-hover:opacity-100'
            )}
          >
            {startMove && (
              <>
                <span
                  onClick={saveImagePosition}
                  className="cursor-pointer border-r border-white/10 px-2 leading-[26px] hover:opacity-50"
                >
                  保存
                </span>
                <span
                  onClick={cancelImagePosition}
                  className="cursor-pointer px-2 leading-[26px] hover:opacity-50"
                >
                  取消
                </span>
              </>
            )}
            {!startMove && (
              <>
                <span
                  onClick={openCoverImages}
                  className="cursor-pointer border-r border-white/10 px-2 leading-[26px] hover:opacity-50"
                >
                  更换封面
                </span>
                <span
                  onClick={() => setStartMove(true)}
                  className="cursor-pointer px-2 leading-[26px] hover:opacity-50"
                >
                  调整位置
                </span>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
);
