import { useStateRef } from '@flowus/common/hooks/react-utils';
import { useDeepCompareMemo } from '@react-hookz/web';
import { useDeepCompareEffect } from 'ahooks';
import { useCallback, useEffect, useState } from 'react';
import { createModel, useModel } from 'src/common/create-model';
import { useBitablePageSize, useLoadingMode } from 'src/hooks/collection-view/use-collection-view';
import { getState } from 'src/redux/store';
import { $appUiStateCache } from 'src/services/app';
import { $searchParams } from 'src/utils';
import { AUTO_LOAD_COUNT, MAX_BITABLE_PAGE_SIZE } from './const';
import { useBitable } from './context';

export const getPropertySegments = (
  recordId: string,
  propertyId: string,
  blocks = getState().blocks
) => {
  return blocks[recordId]?.data.collectionProperties?.[propertyId];
};
/**
 * 2种加载方式，滑动加载/分页加载，其中滑动加载一般是点击加载更多才会加载，但如果选择了每页大小选择了【无限】，则不需要会自动加载
 */
const useBitableLoadMore = (props: {
  disable?: boolean;
  recordIds?: string[];
  pagination?: boolean;
}) => {
  const { viewId } = useBitable();
  const { disable, recordIds: originRecordIds = [], pagination } = props;
  const pageSize = useBitablePageSize(viewId);
  const isMax = pageSize === MAX_BITABLE_PAGE_SIZE;
  const loadingMode = useLoadingMode(viewId);
  // 只有表格/列表/时间轴视图才分页,pagination才为true
  const isPagination = pagination && !isMax && loadingMode === 'pagination';
  /** 总共有多少 count */
  const totalCount = originRecordIds.length;
  const [pageNo, setPageNo] = useState(1);

  /** 当前显示 count */
  const [count, setCount, countRef] = useStateRef(isPagination ? totalCount : AUTO_LOAD_COUNT);
  const [maxAutoLoadCount, setMaxAutoLoadCount, maxAutoLoadCountRef] = useStateRef(pageSize);

  const setPageNumber = useCallback(
    (pageNo: number) => {
      setPageNo(pageNo);
    },
    [setPageNo]
  );

  const loadMore = useCallback(
    (isAutoLoad?: boolean) => {
      if (disable) {
        return;
      }

      if (countRef.current >= totalCount) {
        return;
      }

      if (!isAutoLoad) {
        setMaxAutoLoadCount(maxAutoLoadCountRef.current + pageSize);
      }

      setCount(countRef.current + AUTO_LOAD_COUNT);
    },
    [disable, countRef, totalCount, setCount, setMaxAutoLoadCount, maxAutoLoadCountRef, pageSize]
  );

  useDeepCompareEffect(() => {
    if (pageSize !== MAX_BITABLE_PAGE_SIZE) {
      setCount(isPagination ? totalCount : pageSize);
      setMaxAutoLoadCount(isPagination ? totalCount : pageSize);
      setPageNo(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageSize, isPagination]);

  // 渲染的ids
  const recordIds = useDeepCompareMemo(() => {
    if (disable || $searchParams.print) return originRecordIds;
    let ids;
    if (isPagination) {
      const start = (pageNo - 1) * pageSize;
      const end = pageNo * pageSize;
      ids = originRecordIds.slice(start, end);
    } else {
      ids = originRecordIds.slice(0, count);
    }
    const newCreateRecord = $appUiStateCache.$newCreatedRecord;
    if (newCreateRecord) {
      if (
        viewId === newCreateRecord.viewId &&
        originRecordIds.includes(newCreateRecord.id) &&
        !ids.includes(newCreateRecord.id)
      ) {
        ids.push(newCreateRecord.id);
      }
    }
    return ids;
  }, [disable, originRecordIds, count, viewId, pageNo, pageSize]);

  const context = useDeepCompareMemo(
    () => ({
      totalCount,
      loadMore,
      pageNo,
      setPageNumber,
      showMore: isPagination
        ? false
        : Boolean(!disable && !isMax && count < totalCount && pageSize < totalCount),
      recordIds,
      state: count,
      autoLoad: isPagination
        ? false
        : Boolean(!disable && (isMax ? isMax : count < maxAutoLoadCount) && count < totalCount),
    }),
    [disable, isMax, loadMore, pageSize, recordIds, count, totalCount, pageNo, isPagination]
  );

  return context;
};

export const BitableLoadMoreContext = createModel(useBitableLoadMore);

export const useBitableLoadMoreContext = () => useModel(BitableLoadMoreContext);
