import {
  createCache,
  createSetState,
  createStore,
  setSpaceLocalPersistKey,
} from '@flowus/common/zustand/utils';
import type { UserDTO } from '@next-space/fe-api-idl';
import { BlockStatus } from '@next-space/fe-api-idl';
import produce from 'immer';
import { assign } from 'lodash-es';
import type { ToolbarShortcutDTO } from 'src/editor/editor/uikit/editable-toolbar/type';
import { toolbarBaseBtns } from 'src/editor/editor/uikit/editable-toolbar/type';
import { shareInvalidateAll, tocInvalidateAll } from 'src/redux/middlewares/cache-lift';

type UseSetting = UserDTO['setting'] & {
  toolbarShortcut?: ToolbarShortcutDTO;
};

export type UserInfo = UserDTO & {
  currentSpaceViewId: string;
  setting: UseSetting;
  timeZone?: string;
};

export const initialUserState: UserInfo = {
  uuid: '',
  phone: '',
  email: '',
  nickname: '',
  backgroundColor: '',
  spaceViews: [],
  inviteCode: '',
  version: 0,
  status: BlockStatus.NORMAL,
  currentSpaceViewId: '',
  occupation: 0,
  avatar: '',
  setting: {
    toolbarShortcut: toolbarBaseBtns,
  },
};

export const $currentUser = createStore<UserInfo>(() => initialUserState);
export const $currentUserCache = createCache($currentUser);
const $setUser = createSetState($currentUser);

export const updateCurrentUser = (payload: Partial<UserInfo>) => {
  const { setting, ...rest } = payload;

  shareInvalidateAll();
  tocInvalidateAll();

  // 以空间为主体的持久化数据
  setSpaceLocalPersistKey(`${rest.uuid}-${rest.currentSpaceViewId}`);

  $setUser(
    produce((state: UserInfo) => {
      if (setting) {
        if (!state.setting) {
          state.setting = {};
        }
        assign(state.setting, setting);
      }
      assign(state, rest);
    })
  );
};

export const clearCurrentUser = () => {
  shareInvalidateAll();
  tocInvalidateAll();
  $setUser(() => initialUserState);
};

export const deleteUserSpaceView = (payload: string) => {
  $setUser(
    produce((state: UserInfo) => {
      const index = state.spaceViews.findIndex((uuid) => uuid === payload);
      if (index !== -1) {
        state.spaceViews.splice(index, 1);
      }
    })
  );
};
