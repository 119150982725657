import { dispatch, getState } from 'src/redux/store';
import type { SegmentType } from 'src/redux/types';
import { getOwnerPage } from '../block/use-get-owner-page';
import { useFocusEditableByBlockId } from './use-focus-by-id';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { BlockType } from '@next-space/fe-api-idl';
import { useCallback } from 'react';
import { getCaretInfo } from './helper';
import { getAllEditorInfoFromDomContent } from 'src/utils/block-utils';
import { useIsInRight } from 'src/utils/right-utils';
import { useSyncId } from 'src/editor/editor/plugin/sync-block-context';
import { transaction } from '../use-transaction';
import { outdentBlock } from 'src/redux/managers/block/outdent';
import { convertBlock } from 'src/redux/managers/block/convert';
import { mergeBlock } from 'src/redux/managers/block/merge';
import { HISTORY_EFFECTS } from 'src/redux/actions';

export const useDeleteKey = (uuid: string, segmentType: SegmentType) => {
  const focusEditableAt = useFocusEditableByBlockId();
  const getEditorModel = useGetOrInitEditorModel();
  const syncId = useSyncId();
  const isInRight = useIsInRight();
  const deleteKey = (event: globalThis.KeyboardEvent) => {
    if (segmentType !== 'segments') return;
    const { blocks } = getState();
    const block = blocks[uuid];
    if (!block) return;

    const pageId = getOwnerPage(uuid);
    if (!pageId) return;
    if (blocks[pageId]?.type !== BlockType.PAGE) return;

    /**
     * 如果是当前光标不在最后，则直接交给系统处理，如果是在最后，则我们自己处理:
     * 1.找到当前文本块的下一个可编辑的块
     * 2.如果是在当前组里，则先解组
     * 3.如果当前是不是code/textarea块则转为textarea块
     * 4.合并到当前块
     */
    const parent = blocks[block.parentId];
    if (!parent) return;

    const editorModal = getEditorModel(uuid);
    const selection = editorModal?.selection;
    const caret = getCaretInfo(uuid, editorModal);

    if (!caret?.collapsed) return;
    if (!caret?.isLastChar) return;
    if (!selection) return;
    event.stopPropagation();
    event.preventDefault();
    // 找到当前文本块的下一个可编辑的块
    const editableList = getAllEditorInfoFromDomContent(isInRight);
    const index = editableList.findIndex((v) => v.blockId === uuid && v.syncId === syncId);
    if (index === -1) return;
    const nextEditable = editableList[index + 1];
    if (!nextEditable) return;
    // 如果是在当前组里，则先解组
    if (block.subNodes.includes(nextEditable.blockId)) {
      transaction(() => {
        outdentBlock([nextEditable.blockId]);
      });
      return;
    }
    const nextBlock = blocks[nextEditable.blockId];
    if (!nextBlock) return;
    // 如果当前是不是code/textarea块则转为textarea块
    if (nextBlock?.type !== BlockType.CODE && nextBlock?.type !== BlockType.TEXTAREA) {
      transaction(() => {
        convertBlock([nextBlock.uuid], {
          type: BlockType.TEXTAREA,
        });
      });
      return;
    }
    // 合并到当前块
    transaction(() => {
      mergeBlock(nextBlock.uuid, uuid);
      dispatch(
        HISTORY_EFFECTS({
          init() {
            focusEditableAt(uuid, selection.focusOffset ?? 0, 'segments', {
              syncId,
            });
          },
          redo() {
            focusEditableAt(uuid, selection.focusOffset ?? 0, 'segments', {
              syncId,
            });
          },
          undo() {
            focusEditableAt(uuid, selection.focusOffset ?? 0, 'segments', {
              syncId,
            });
          },
        })
      );
    });
  };
  return useCallback(deleteKey, [
    focusEditableAt,
    getEditorModel,
    isInRight,
    segmentType,
    syncId,
    uuid,
  ]);
};
