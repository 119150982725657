import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useBitable } from '../../context';
import { useBitablePageSize } from 'src/hooks/collection-view/use-collection-view';
import { MAX_BITABLE_PAGE_SIZE } from 'src/bitable/const';

interface LoadingModeProps {
  closeSelf: () => void;
}

const loadingModeItems = [
  {
    title: '滑动加载',
    value: 'scroll',
  },
  {
    title: '分页加载',
    value: 'pagination',
  },
];

export const LoadingMode: FC<LoadingModeProps> = ({ closeSelf }) => {
  const { viewId, isLocked } = useBitable();

  return (
    <div className="next-modal w-36 py-2.5">
      <ListView
        items={loadingModeItems.map((item) => ({
          type: ListItemType.OPERATION,
          data: {
            title: item.title,
            value: item.value,
            onClick: () => {
              if (isLocked) return;
              updateViewFormat(viewId, {
                // @ts-ignore ignore
                loadingMode: item.value,
              });
              closeSelf();
            },
          },
        }))}
      />
    </div>
  );
};
