import { BlockType, ImportType } from '@next-space/fe-api-idl';
import type { ChangeEvent } from 'react';
import type { IconName } from 'src/common/components/icon';
import { Icon } from 'src/common/components/icon';
import { message } from 'src/common/components/message';
import { publicLink } from 'src/common/const';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { useUpdateActivity } from 'src/hooks/activities/use-update-activity';
import { useUpload } from 'src/hooks/space/use-upload';
import { removeBlock } from 'src/redux/managers/block/remove';
import { sequence } from 'src/utils/async-utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { getDynamicPageId } from 'src/utils/getPageId';
import { enqueueTask } from 'src/utils/tasks';
import { TocType } from 'src/views/main/aside/toc/types';
import type { FileTypeButtonProps } from './types';
import { btnConfig, NOTION_MODAL_ID } from './configs';
import { ImportNotion } from './notion';
import { useCommon } from './hook';

export const Import = ({ from, pageId }: Pick<FileTypeButtonProps, 'from' | 'pageId'>) => {
  return (
    <div className="w-125 p-8">
      <div className="flex justify-between items-center mb-6">
        <div className="text-h2">导入</div>
        <a
          target="_blank"
          href={publicLink.howToImport}
          hidden={__BUILD_IN__}
          className="flex items-center text-grey4 animate-click"
        >
          <Icon size="small" name="IcInviteHelp" className="mr-1.5" />
          <div className="text-t2 text-grey4">如何导入</div>
        </a>
      </div>
      <div className="grid grid-cols-2 gap-5">
        {(Object.keys(btnConfig) as ImportType[]).map((fileType) => (
          <FileTypeButton key={fileType} fileType={fileType} from={from} pageId={pageId} />
        ))}
      </div>
    </div>
  );
};

const FileTypeButton = ({ fileType, from, pageId }: FileTypeButtonProps) => {
  const {
    createDocPage,
    fetchPage,
    currentSpace,
    closeAllModal,
    history,
    openModal,
    openLoading,
    closeLoading,
  } = useCommon();

  const updateActivity = useUpdateActivity();
  const upload = useUpload();

  const config = btnConfig[fileType];
  if (!config) return null;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (!fileList || fileList.length === 0) return;
    const file = fileList[0];
    if (fileList.length > 1) {
      message.warning('仅支持单文件上传');
      return;
    }
    if (!file) return;
    // 1GB
    if (file.size >= 1024 * 1024 * 1024) {
      message.warning({
        content: '文件过大容易失败，建议分批导入，文件最大不能超过1GB',
        duration: 1000,
      });
      return;
    }

    const eventName = from === 'page' ? 'empty_import' : 'import';

    bizTracker.event(eventName, { file_type: fileType });

    openLoading(`正在上传... (0%)`);
    void upload({
      file,
      type: 'temp',
      onProgress(progress) {
        if (progress === 100) {
          openLoading('正在导入，请稍候...');
        } else {
          openLoading(`正在上传... (${progress}%)`);
        }
      },
      onComplete(ret) {
        if (!ret.success) {
          closeLoading();
          return;
        }

        const { ossName } = ret;
        if (ossName) {
          let _pageId: string | undefined = pageId || getDynamicPageId();

          if (from === 'button') {
            _pageId = createDocPage(
              BlockType.PAGE,
              { parentId: currentSpace.uuid },
              TocType.PRIVATE
            );
          }

          if (!_pageId) {
            message.error('导入失败，请重试');
            return;
          }

          // 这里使用 sequence 是为了 from = button 时，保证创建成功后再导入
          void sequence(() =>
            enqueueTask(
              {
                eventName: 'import',
                request: {
                  blockId: _pageId as string,
                  spaceId: currentSpace.uuid,
                  importOptions: {
                    type: fileType,
                    ossName,
                  },
                },
              },
              {
                success: async ({ uuid }) => {
                  uuid && void fetchPage(uuid, { force: true });
                  await updateActivity(ActivityIds.STANDING_IMPORT);
                  closeLoading();
                  closeAllModal();
                  if (from === 'button') {
                    history.push(`/${_pageId}`);
                  }
                },
                fail() {
                  if (from === 'button' && _pageId) {
                    removeBlock(_pageId);
                  }
                  closeLoading();
                },
              }
            )
          );
        }
      },
    });
  };

  const isNotNotion = fileType !== ImportType.NOTION;

  const onClick = () => {
    if (isNotNotion) {
      return;
    }

    openModal.modal({
      modalId: NOTION_MODAL_ID,
      content: ImportNotion,
    });
  };

  return (
    <label
      className="border-grey5 animate-hover animate-click flex h-[70px] items-center rounded-md border px-4 shadow-sm"
      onClick={onClick}
    >
      {isNotNotion && (
        <input type="file" accept={config.accept} className="hidden" onChange={onChange} />
      )}
      <Icon name={config.icon as IconName} size="large" className="text-normal" />
      <div className="ml-2.5">
        <div className="flex">
          <div className="text-t1-medium text-normal">{config.title}</div>
          {config.help && (
            <a target="_blank" href={config.help} className="animate-click">
              <Icon size="small" name="IcInviteHelp" className="text-grey3 ml-2" />
            </a>
          )}
        </div>
        {!!config.description && <div className="text-t4 text-grey3">{config.description}</div>}
      </div>
    </label>
  );
};
