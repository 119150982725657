import { getIconsColor } from '@flowus/common/block/color/get-block-color';
import { MindMapContext, MindMapEngine } from '@flowus/mind-map';
import { debounce } from 'lodash-es';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { NextModalProvider } from 'src/common/components/next-modal';
import { UUID_NAMESPACE } from 'src/common/const';
import { NotFound } from 'src/components/empty';
import { EditableNamespace } from 'src/editor/editor/uikit/editable-context';
import { MindMapEditor } from 'src/mind-map/mind-map-editor';
import {
  getMindMapScale,
  saveMindMapScale,
  supportAppendChild,
} from 'src/mind-map/utils/mind-engine-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { useFinalValue } from '@flowus/common/hooks/react-utils';
import { useIsInRight } from 'src/utils/right-utils';
import { PageScrollRefContext } from '../page-doc/context';
import { ObsStoreContext } from 'src/services/rxjs-redux/context';

interface Props {
  uuid: string;
}
export const PageMind: FC<Props> = (props) => {
  const { uuid } = props;
  const block = usePickBlock(uuid, []);
  const scrollRef = useRef<HTMLDivElement>(null);
  const isRight = useIsInRight();
  const engine = useFinalValue(
    () =>
      new MindMapEngine({
        getAllElement: () => {
          return scrollRef.current?.querySelectorAll('[data-block-id]');
        },
        getCanvasPosition: (instance, x, y) => {
          if (scrollRef.current) {
            const state = instance.getState();
            const scaleFactor = state.scale * 0.01;
            const nodeDrawer = scrollRef.current.querySelector('.node-drawer');
            const nodeDrawerRect = nodeDrawer?.getBoundingClientRect();
            if (!nodeDrawerRect) return [x, y];
            // 缩放后偏移量
            const convertX = x - nodeDrawerRect.left;
            const convertY = y - nodeDrawerRect.top;
            return [convertX / scaleFactor, convertY / scaleFactor];
          }

          return [x, y];
        },
        supportAppendChild: (id: string) => {
          return supportAppendChild(id);
        },
        getLineColor: (themeColor: string) => {
          return getIconsColor(themeColor);
        },
      })
  );

  useEffect(() => {
    const saveMindMapScaleDebounce = debounce(saveMindMapScale, 200);
    const removeListenerFun = engine.addScaleListener({
      onScaleChange: (context, scale) => {
        saveMindMapScaleDebounce(context, scale, false);
      },
    });
    engine.zoom(getMindMapScale(uuid, false) ?? 100);
    return removeListenerFun;
  }, [engine, uuid]);

  if (!block) return <NotFound />;

  return (
    <MindMapContext.Provider value={engine}>
      <NextModalProvider>
        <EditableNamespace.Provider value={isRight ? UUID_NAMESPACE.RIGHT_PAGE_MIND : undefined}>
          <PageScrollRefContext.Provider value={scrollRef}>
            <ObsStoreContext.Provider value={{ enableAuditTime: false }}>
              <div
                className="w-full next-space-page mind-page overflow-auto h-full"
                ref={scrollRef}
                data-mind-page-id={uuid}
              >
                <MindMapEditor id={uuid} key={uuid} />
              </div>
            </ObsStoreContext.Provider>
          </PageScrollRefContext.Provider>
        </EditableNamespace.Provider>
      </NextModalProvider>
    </MindMapContext.Provider>
  );
};
