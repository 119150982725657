import { Button } from '@flowus/common/components';
import { cx } from '@flowus/common/cx';
import { Icon } from '@flowus/common/icon-svg/pc-icon';
import { debounce } from 'lodash-es';
import type { FC, RefObject } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useBitable } from 'src/bitable/context';
import { useBitableLoadMoreContext } from 'src/bitable/hooks';
import { Input } from 'src/common/components/input';
import { useBitablePageSize, useLoadingMode } from 'src/hooks/collection-view/use-collection-view';
import { useHScrollRef } from 'src/views/main/page-doc/context';

interface PaginationProps {
  className?: string;
}
/**
 * 70% AI写的
 */
export const Pagination: FC<PaginationProps> = (props) => {
  const { viewId } = useBitable();
  const loadingMode = useLoadingMode(viewId);
  const { pageNo, setPageNumber, totalCount } = useBitableLoadMoreContext();
  const pageSize = useBitablePageSize(viewId);
  const totalPages = Math.ceil(totalCount / pageSize);
  const [tempPageNo, setTempPageNo] = useState(pageNo);
  const paginationRef = useRef<HTMLDivElement>(null);
  const hScrollRef = useHScrollRef() as RefObject<HTMLElement>;

  const updatePosition = useRef(
    debounce(() => {
      const paginationNode = paginationRef.current;
      if (!paginationNode || !hScrollRef?.current) return;

      const containerWidth = hScrollRef.current.clientWidth || 0;
      const paginationWidth = paginationNode.getBoundingClientRect().width;
      paginationNode.style.left = `${Math.max(0, containerWidth - paginationWidth)}px`;
    }, 50)
  ).current;

  useEffect(() => {
    if (!hScrollRef?.current) return;

    const observer = new ResizeObserver(updatePosition);
    observer.observe(hScrollRef.current);
    updatePosition();

    return () => {
      observer.disconnect();
      updatePosition.cancel?.();
    };
  }, [hScrollRef, updatePosition, loadingMode]);

  const handlePageChange = (_page: string) => {
    let page = parseInt(_page, 10);
    if (page < 1) {
      page = 1;
    }
    if (page > totalPages) {
      page = totalPages;
    }
    setTempPageNo(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPagesToShow = 8; // 总共显示10页
    const showEllipsis = totalPages > maxPagesToShow + 2; // +2是因为要显示首页和尾页

    // 始终显示第一页
    pageNumbers.push(
      <button
        key={1}
        className={cx('px-2 animate-hover text-grey3', {
          'text-black': pageNo === 1,
        })}
        onClick={() => setPageNumber(1)}
      >
        1
      </button>
    );

    let startPage, endPage;
    if (showEllipsis) {
      if (pageNo <= maxPagesToShow / 2 + 1) {
        // 当前页靠近开始
        startPage = 2;
        endPage = maxPagesToShow + 1;
      } else if (pageNo >= totalPages - maxPagesToShow / 2) {
        // 当前页靠近结束
        startPage = totalPages - maxPagesToShow;
        endPage = totalPages - 1;
      } else {
        // 当前页在中间
        startPage = pageNo - Math.floor(maxPagesToShow / 2);
        endPage = pageNo + Math.floor(maxPagesToShow / 2) - 1;
      }

      if (startPage > 2) {
        pageNumbers.push(
          <Icon key="ellipsis1" name="IcMore" size="small" className="text-grey3" />
        );
      }
    } else {
      startPage = 2;
      endPage = totalPages - 1;
    }

    // 显示页码
    for (let i = startPage; i <= endPage; i++) {
      if (i <= totalPages - 1 && i >= 2) {
        pageNumbers.push(
          <button
            key={i}
            className={cx(
              'text-grey3 px-1.5 animate-hover hover:text-black',
              pageNo === i && 'text-black'
            )}
            onClick={() => setPageNumber(i)}
          >
            {i}
          </button>
        );
      }
    }

    if (showEllipsis && endPage < totalPages - 1) {
      pageNumbers.push(<Icon key="ellipsis2" name="IcMore" size="small" className="text-grey3" />);
    }

    // 始终显示最后一页
    if (totalPages > 1) {
      pageNumbers.push(
        <button
          key={totalPages}
          className={cx('px-2 animate-hover text-grey3', {
            'text-black': pageNo === totalPages,
          })}
          onClick={() => setPageNumber(totalPages)}
        >
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };
  if (loadingMode === 'scroll') return null;

  return (
    <div
      className={cx('text-t2 inline-flex items-center pb-5 sticky px-16', props.className)}
      ref={paginationRef}
    >
      <button
        className={cx(
          'rounded animate-click flex items-center px-2',
          pageNo === 1 && 'opacity-50 cursor-not-allowed'
        )}
        onClick={() => setPageNumber(pageNo - 1)}
        disabled={pageNo === 1}
      >
        <Icon name="IcArrowLeft01" size="small" />
      </button>
      <div className="flex items-center space-x-1">{renderPageNumbers()}</div>
      <button
        className={cx(
          'rounded animate-click flex items-center px-2',
          pageNo === totalPages && 'opacity-50 cursor-not-allowed'
        )}
        onClick={() => setPageNumber(pageNo + 1)}
        disabled={pageNo === totalPages}
      >
        <Icon name="IcArrowRight01" size="small" />
      </button>
      <div className="flex items-center space-x-2">
        <Input
          value={`${tempPageNo}`}
          inputType="number"
          className="w-8"
          inputClassName="px-0 text-center"
          defaultValue={tempPageNo}
          onChange={handlePageChange}
        />
        <span>页</span>
        <Button
          onClick={() => {
            if (tempPageNo !== pageNo) {
              setPageNumber(parseInt(`${tempPageNo}`, 10));
            }
          }}
        >
          跳转
        </Button>
      </div>
    </div>
  );
};
