import { aiLimitStore } from '@flowus/common/chat-ai/hooks/ai-limit-store';
import { MB } from '@flowus/common/const';
import {
  createCache,
  createPersistStore,
  createSetState,
  createStore,
} from '@flowus/common/zustand/utils';
import dayjs from 'dayjs';
import produce from 'immer';
import { assign, cloneDeep, debounce } from 'lodash-es';
import { request } from 'src/common/request';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import type { AsyncReturnType } from 'type-fest';

export const capacityDefaultValue = {
  currentCapacity: 0,
  maxCapacity: 200 * MB,
  singleFileMaxSize: 2 * MB,
  dataBaseSingleFileMaxSize: 2 * MB,
  isNovice: false,
  peopleCapacity: 0,
  buyCapacity: 0,
  expirationCapacity: 0,
  baseCapacity: 0,
  giveCapacity: 0,
  autoRenewal: false,
};

export const aiCapacityDefaultValue = {
  usage: 0,
  basic: 0,
  seat: 0,
  additional: 0,
  resetDate: 0,
  aiRestUsage: 0,
  monthlyEndDate: 0,
  pdf: {
    todayUsed: 0,
    monthUsed: 0,
    totalLimit: 0,
    dayLimit: 0,
    monthLimit: 0,
    leftReward: 0,
  },
  // gpt3_5: {
  //   todayUsed: 0,
  //   monthUsed: 0,
  //   totalLimit: 0,
  //   dayLimit: 0,
  //   monthLimit: 0,
  //   leftReward: 0,
  // },
  baseModel: {
    todayUsed: 0,
    monthUsed: 0,
    totalLimit: 0,
    dayLimit: 0,
    monthLimit: 0,
    leftReward: 0,
  },
};

const defaultValue = {
  ...capacityDefaultValue,
  ...aiCapacityDefaultValue,
};

type CapacityBlockType = AsyncReturnType<typeof request.editor.capacityBlock>;
type AiUsageType = AsyncReturnType<typeof request.infra.getSpaceAiUsage>;

const $capacity = createStore<Record<string, CapacityBlockType & AiUsageType>>(() => ({}));

const $setCapacity = createSetState($capacity);

export const useSpaceCapacity = (spaceId = getCurrentSpaceId()) => {
  return $capacity(() => cloneDeep(getSpaceCapacity(spaceId)));
};

export const useIsAiPlan = (spaceId = getCurrentSpaceId()) => {
  return $capacity(() => {
    const capacitySpace = getSpaceCapacity(spaceId);
    return dayjs().isBefore(capacitySpace.monthlyEndDate);
  });
};

export const getSpaceCapacity = (spaceId: string, opt?: { isDatabase?: boolean }) => {
  const { isDatabase } = opt || {};
  const result = defaultValue;
  const capacitySpace = $capacity.getState()[spaceId];

  if (capacitySpace) {
    assign(result, capacitySpace);
  }

  if (isDatabase) {
    result.singleFileMaxSize = result.dataBaseSingleFileMaxSize;
  }

  // if (getIsProSpace(spaceId) || __BUILD_IN__) {
  result.isNovice = false;
  // }

  result.aiRestUsage = result.basic + result.seat + result.additional;
  return result;
};

export const hasSpaceCapacity = (spaceId: string) => {
  return Boolean($capacity.getState()[spaceId]);
};

export const setSpaceCapacity = (spaceId: string, value: CapacityBlockType | AiUsageType) => {
  $setCapacity(
    produce((pre) => {
      assign(pre, {
        [spaceId]: {
          ...defaultValue,
          ...(pre[spaceId] || {}),
          ...value,
        },
      });
    })
  );
};

export const fetchAiCapacity = async (spaceId = getCurrentSpaceId()) => {
  const res = await request.infra.getSpaceAiUsage(spaceId);
  setSpaceCapacity(spaceId, res);
  aiLimitStore.setState({ [spaceId]: res });
  return res;
};

const $checkCopySubNodes = createPersistStore<
  Record<string, AsyncReturnType<typeof request.editor.checkCapacityForCopy>>
>('$checkCopySubNodes', () => ({}), { isSpaceScope: true });

const $setCheckCopySubNodes = createSetState($checkCopySubNodes, { assign: true });

export const $checkCopySubNodesCache = createCache($checkCopySubNodes);

export const refreshCheckCopySubNode = debounce(
  async (params: { spaceId: string; ids: string[] }) => {
    if (__PRIVATE__) {
      return;
    }
    const res = await request.editor.checkCapacityForCopy.raw({
      targetSpaceId: params.spaceId,
      ids: params.ids,
    });
    if (res.code === 200) {
      $setCheckCopySubNodes({ [params.spaceId]: res.data });
    }
  },
  5000,
  {
    leading: true,
  }
);
