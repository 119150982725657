import { getFormatImageUrl, Role } from '@flowus/common';
import { MB } from '@flowus/common/const';
import { cx } from '@flowus/common/cx';
import { getSrcSetParams } from '@flowus/common/utils/image';
import { BlockType, ContentGravity, PermissionRole } from '@next-space/fe-api-idl';
import { omit } from 'lodash-es';
import type { CSSProperties, FC, ReactNode } from 'react';
import { memo, useCallback, useContext, useMemo, useRef, useState } from 'react';
import type { EnhanceImageProps } from 'src/common/components/enhance-image';
import { LoadingContainer } from 'src/common/components/loading-container';
import { deepEqual } from '@flowus/common/utils/tools';
import { compressImageSupport } from 'src/common/utils/url-utils';
import { ImageUploadBox } from 'src/components/image-upload-box';
import { useOpenImagePreview } from 'src/components/images-provider/provider';
import {
  useAlign,
  useImageAlignStyle,
  useRootImageAlignStyle,
} from 'src/hooks/block/use-align-style';
import { useResize } from 'src/hooks/block/use-resize';
import { useDownloadFile } from 'src/hooks/drive/use-download';
import { useReadonly } from 'src/hooks/page';
import { useDebounceElementSize } from 'src/hooks/public/use-debounce-element-size';
import { useResource } from 'src/hooks/public/use-resource';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useIsSelected } from 'src/redux/managers/ui';
import { uiActions } from 'src/redux/reducers/ui';
import { dispatch } from 'src/redux/store';
import { UploadStatus } from 'src/redux/types';
import { useUploadInfoById } from 'src/services/upload';
import { $searchParams } from 'src/utils';
import { getFileNameInfo } from 'src/utils/file';
import { useGetPageId } from 'src/utils/getPageId';
import { calcImageMaxWidthZoomSize } from 'src/utils/image-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { OverlayContainerContext } from '@flowus/common/hooks/react-utils';
import { useOpenNewDiscussionPopup } from 'src/views/comments/use-open-new-discussion-popup';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import isURL from 'validator/lib/isURL';
import { Caption } from '../../component/caption';
import { useSelectFile } from '../../component/file/empty-file';
import { EmptyImage } from '../../component/image/empty-img';
import { ImageMoreMenu } from '../../component/image/more-menu';
import { UploadError } from '../../component/image/upload-error';
import { ResizeElement } from '../../component/resize-element';
import type { BlockElement } from '../core/type';
import { BlockChildren } from '../uikit/block-children';
import { BlockDrop } from './dnd/block-drop';
import { useSyncId } from './sync-block-context';
import { useShowImageBorder } from 'src/hooks/block/use-show-image-border';
import { fixUrl } from '@flowus/common/embed-website';
import { useOpenImageWatermark } from 'src/hooks/space/use-open-image-watermark';
import { addImageWatermark } from '@flowus/common/url';

export const ImageBlockElement: BlockElement = memo((props) => {
  const { id: uuid, root, ownerBlockId, children } = props;
  const blockId = ownerBlockId ?? uuid;
  const fileBlock = usePickBlock(
    uuid,
    ['data', 'local'],
    ['ossName', 'height', 'width', 'format', 'size', 'link']
  );
  const readonly = useReadonly(blockId);
  const uploadInfo = useUploadInfoById(blockId);
  const pageId = useGetPageId();
  const { role } = usePermissions(pageId);
  const openCommentPopup = useOpenNewDiscussionPopup();
  const downloadFile = useDownloadFile();

  const {
    renderSize,
    onRenderSize,
    changeSize,
    containerWidth,
    containerRef,
    isDisable,
    defaultWidth,
    defaultHeight,
    defaultBlockFullWidth,
    widthBreakPoints,
  } = useResize({
    id: blockId,
    root,
    fullPageContentWidth: true,
  });

  const container = useContext(OverlayContainerContext);
  const pageWidth = useDebounceElementSize(container?.current, { type: 'width' }).width;
  const rootImageStyle = useRootImageAlignStyle(
    blockId,
    pageWidth || Number.MAX_SAFE_INTEGER,
    !!root
  );
  const imageBoxAlignStyle = useImageAlignStyle(blockId);
  const { contentGravity } = useAlign(blockId);
  const scene = usePageScene();
  const { formatUrl, loading, loadingCalcSize, srcSetParams, url, imageLink } = useImageParams(
    uuid,
    {
      containerWidth,
      defaultWidth: defaultWidth ?? pageWidth,
    }
  );

  /** 判断此url有没有加载失败，如果加载失败了就记录下失败的url，如果url被替换，被记录的error url也会随之失效 */
  const showError =
    uploadInfo?.status === UploadStatus.failure ||
    (!isURL(formatUrl) && !formatUrl.startsWith('blob') && !formatUrl.endsWith('=') && formatUrl);
  const selectFile = useSelectFile({ uuid });

  const openSelectFile = useCallback(
    (element: HTMLElement) => {
      const rect = element.getBoundingClientRect();
      selectFile({ getBoundingClientRect: () => rect }, 'bottom', [0, -(rect.height * 0.6)]);
    },
    [selectFile]
  );

  const onReplace = useCallback(() => {
    if (!containerRef.current) return;
    openSelectFile(containerRef.current);
  }, [containerRef, openSelectFile]);

  const captionStyle = useMemo(
    () =>
      ({
        width: $searchParams.print ? 'auto' : renderSize.width || loadingCalcSize.width,
        textAlign: $searchParams.print ? 'center' : 'left',
      } as CSSProperties),
    [loadingCalcSize.width, renderSize.width]
  );

  const imageContent = useMemo(
    () => (
      <RenderImageContent
        uuid={uuid}
        srcSetParams={srcSetParams}
        blockId={blockId}
        formatUrl={formatUrl}
        url={url}
        key={url || fileBlock?.data.link}
        ownerBlockId={ownerBlockId}
        maxWidth={containerWidth}
        imageLink={imageLink}
      />
    ),
    [
      blockId,
      containerWidth,
      fileBlock?.data.link,
      formatUrl,
      imageLink,
      ownerBlockId,
      srcSetParams,
      url,
      uuid,
    ]
  );

  const content = useMemo(
    () => (
      <ResizeElement
        fullHeight
        resizeWidth
        doubleResize={contentGravity === ContentGravity.CENTER}
        readonly={readonly || isDisable}
        disableStyle={!defaultWidth}
        defaultWidth={defaultWidth ?? 0}
        defaultHeight={defaultHeight}
        defaultBlockFullWidth={defaultBlockFullWidth}
        maxWidth={containerWidth}
        widthBreakPoints={widthBreakPoints}
        minHeight={36}
        minWidth={36}
        onChange={changeSize}
        onRenderSize={onRenderSize}
        outerLayoutClassName={cx(ownerBlockId && 'pl-1 border-dashed border-grey5 border-l-[2px]')}
        outerLayoutStyle={imageBoxAlignStyle}
        className={cx(
          'max-w-full cursor-pointer overflow-visible',
          isDisable && 'pointer-events-none',
          $searchParams.print && 'my-2.5',
          $searchParams.print && root && 'mx-auto'
        )}
        customFooter={<Caption blockId={blockId} style={captionStyle} />}
      >
        {loading ? (
          <LoaderImagePlaceholder
            uuid={blockId}
            maxWidth={fileBlock?.data.format?.width || containerWidth}
          />
        ) : (
          imageContent
        )}
        {!$searchParams.print && !readonly && !isDisable && !ownerBlockId && (
          <ImageMoreMenu
            showAlign={true}
            blockId={uuid}
            onReplace={onReplace}
            onDownload={() => downloadFile(uuid)}
            onAddComment={
              Role.contains(role, PermissionRole.COMMENTER) && !ownerBlockId
                ? () => {
                    openCommentPopup({ blockId: uuid });
                  }
                : undefined
            }
            externalLink={fileBlock?.type === BlockType.EXTERNAL_FILE}
            link={fileBlock?.data.link}
          />
        )}
      </ResizeElement>
    ),
    [
      blockId,
      captionStyle,
      changeSize,
      containerWidth,
      contentGravity,
      defaultBlockFullWidth,
      defaultHeight,
      defaultWidth,
      downloadFile,
      fileBlock?.data.format?.width,
      fileBlock?.data.link,
      fileBlock?.type,
      imageBoxAlignStyle,
      imageContent,
      isDisable,
      loading,
      onRenderSize,
      onReplace,
      openCommentPopup,
      ownerBlockId,
      readonly,
      role,
      root,
      uuid,
      widthBreakPoints,
    ]
  );

  if (!fileBlock) return null;

  if ((!formatUrl || showError) && !imageLink && !loading) {
    return (
      <BlockDrop
        id={blockId}
        blockRef={containerRef}
        horizontal={root}
        className="my-1"
        style={{
          height: loadingCalcSize.height > 0 ? loadingCalcSize.height : undefined,
          ...rootImageStyle,
        }}
      >
        {showError ? (
          <>
            <UploadError readonly={readonly} onClick={(e) => openSelectFile(e.currentTarget)} />
            <Caption blockId={blockId} />
          </>
        ) : (
          <EmptyImage
            readonly={readonly}
            defaultOpenPanel={Boolean(fileBlock.local)}
            onClick={(e) => openSelectFile(e.currentTarget)}
          />
        )}
        {children && <BlockChildren blockId={blockId}>{children}</BlockChildren>}
      </BlockDrop>
    );
  }

  // 导出 pdf 时，如果嵌套 BlockDrop 会导致 pdf 中的图片与其他块重叠
  if ($searchParams.print) {
    return (
      <div>
        {content}
        {children && <BlockChildren blockId={blockId}>{children}</BlockChildren>}
      </div>
    );
  }
  if (scene && scene === PageScene.ONLY_IMAGE) {
    return imageContent;
  }
  if (
    scene &&
    [PageScene.NOTIFICATION, PageScene.PAGE_FEEDS, PageScene.UPDATE_LOG].includes(scene)
  ) {
    return (
      <div className="flex flex-col">
        {imageContent}
        <Caption
          blockId={blockId}
          style={{
            width: '100%',
            textAlign: 'center',
          }}
        />
      </div>
    );
  }

  return (
    <BlockDrop
      blockRef={containerRef}
      id={blockId}
      horizontal={root}
      fullWidth={false}
      data-draggable
      className={cx('my-2.5 group', root && 'self-center')}
      style={{ ...rootImageStyle }}
    >
      {content}
      {children && <BlockChildren blockId={blockId}>{children}</BlockChildren>}
    </BlockDrop>
  );
});

interface RenderImageContentProps {
  blockId: string;
  uuid: string;
  ownerBlockId?: string;
  formatUrl: string;
  url: string;
  imageLink?: string;
  loaderStyle?: CSSProperties;
  maxWidth?: number;
  srcSetParams?: {
    srcSet?: string;
    sizes?: string;
  };
}
const RenderImageContent: FC<RenderImageContentProps> = memo((props) => {
  const { blockId, maxWidth, uuid } = props;
  const syncId = useSyncId();
  const isSelected = useIsSelected(blockId);
  const openImagePreview = useOpenImagePreview();
  const showOnlyImage = usePageScene() === PageScene.ONLY_IMAGE;
  const showBorder = useShowImageBorder(blockId);
  const block = usePickBlock(blockId, ['data'], ['openLink']);
  const doubleRef = useRef(false);
  const timerRef = useRef<ReturnType<typeof setTimeout>>();
  const isOurUrl = props.url && props.url.includes(__BUILD_IN__ ? 'buildin.ai' : 'flowus.cn');
  return (
    <ImageElement
      {...props}
      data-image-id={uuid}
      data-draggable
      maxWidth={maxWidth}
      className={cx('transition-all w-full preview-file outline outline-2 outline-transparent', {
        'cursor-zoom-in outline-active_color': isSelected,
        'object-cover': showOnlyImage,
        'outline-grey6': !isSelected && showBorder,
      })}
      unloader={(p) => (
        <div
          {...omit(p, ['unloader', 'loader', 'src', 'onError'])}
          className={cx(
            'flex h-24 text-t3 flex-col items-center justify-center bg-grey8 p-2',
            p.className
          )}
        >
          <div>加载失败</div>
          {!isOurUrl && <div>外部图片链接已失效</div>}
        </div>
      )}
      onDoubleClick={(e) => {
        doubleRef.current = true;
        setTimeout(() => {
          doubleRef.current = false;
        }, 1000);
        openImagePreview({ uuid, src: (e.target as HTMLImageElement).src });
      }}
      onClick={(e) => {
        const timeout = block?.data.openLink ? 400 : 0;
        // 如果设置了双击打开预览，单击就打开超链
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          if (doubleRef.current) return;
          if (block?.data.openLink) {
            window.open(fixUrl(block?.data.openLink));
            return;
          }
          if ($searchParams.blog) return;
          if (!isSelected && !syncId) {
            dispatch(uiActions.updateSelectBlocks([{ blockId }]));
            return;
          }
          openImagePreview({ uuid, src: (e.target as HTMLImageElement).src });
        }, timeout);
      }}
    />
  );
}, deepEqual);

const LoaderImagePlaceholder: FC<{ uuid: string; maxWidth?: number }> = (props) => {
  const fileBlock = usePickBlock(props.uuid, ['data'], ['width', 'height', 'format']);

  const loadingCalcSize = calcImageMaxWidthZoomSize({
    width: fileBlock?.data.width || fileBlock?.data.format?.width,
    height: fileBlock?.data.height || fileBlock?.data.format?.height,
    maxWidth: props.maxWidth,
  });

  return (
    <LoadingContainer
      size="middle"
      style={{
        height: loadingCalcSize.height,
        width: fileBlock?.data.format?.blockFullWidth ? '100%' : loadingCalcSize.width,
        maxWidth: '100%',
      }}
    />
  );
};

export const useImageParams = (
  uuid: string,
  opt: {
    containerWidth?: number;
    defaultWidth?: number;
  }
) => {
  const { containerWidth, defaultWidth } = opt;
  const { url: _url, loading, isOssNameUrl } = useResource(uuid, { isImage: true });
  let url = _url;

  const fileBlock = usePickBlock(
    uuid,
    ['data', 'local'],
    ['ossName', 'height', 'width', 'format', 'size', 'link']
  );
  const { openImageWatermark, imageWatermarkText } = useOpenImageWatermark(fileBlock?.spaceId);
  if (openImageWatermark && imageWatermarkText) {
    url = addImageWatermark(_url, imageWatermarkText);
  }

  const isAnnotation = fileBlock?.type === BlockType.PDF_ANNOTATION;

  const formatUrl = useMemo(() => {
    if (isAnnotation || !isOssNameUrl) return url;
    if (!fileBlock) return '';
    const { extName } = getFileNameInfo(fileBlock.data.ossName);
    const src = getFormatImageUrl(url, extName);
    return src;
  }, [fileBlock, isAnnotation, url, isOssNameUrl]);

  const srcSetParams = useMemo(() => {
    if (!isOssNameUrl || !fileBlock || !formatUrl || $searchParams.print) return {};
    const { extName } = getFileNameInfo(fileBlock.data.ossName);
    if (!compressImageSupport.test(extName) || (fileBlock.data?.size ?? 0) >= 50 * MB) return {};
    return getSrcSetParams(formatUrl, fileBlock?.data.width, defaultWidth);
  }, [fileBlock, isOssNameUrl, defaultWidth, formatUrl]);

  const loadingCalcSize = useMemo(
    () =>
      calcImageMaxWidthZoomSize({
        width: fileBlock?.data.width || fileBlock?.data.format?.width,
        height: fileBlock?.data.height || fileBlock?.data.format?.height,
        maxWidth: fileBlock?.data.format?.width || containerWidth,
      }),
    [
      containerWidth,
      fileBlock?.data.format?.height,
      fileBlock?.data.format?.width,
      fileBlock?.data.height,
      fileBlock?.data.width,
    ]
  );

  return {
    srcSetParams,
    loadingCalcSize,
    url,
    loading,
    isOssNameUrl,
    formatUrl,
    imageLink: fileBlock?.data.link,
  };
};

// 拆开可以给外部用
export interface ImageElementProps extends Omit<RenderImageContentProps, 'uuid'> {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  style?: CSSProperties;
  unloader?: (p: EnhanceImageProps) => ReactNode;
  onDoubleClick?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
}
export const ImageElement: FC<ImageElementProps> = memo((props) => {
  const { blockId, formatUrl, srcSetParams, maxWidth, url, imageLink, unloader, ...reset } = props;
  const [showLinkImage, setShowLinkImage] = useState(!!imageLink);
  const [isUseFormatUrl, setIsUseFormatUrl] = useState(true);
  const [renderUrl, setParams] = useMemo(() => {
    if (showLinkImage && imageLink) {
      return [imageLink, {}];
    }
    if (isUseFormatUrl) {
      return [formatUrl, srcSetParams ?? {}];
    }
    return [url, {}];
  }, [formatUrl, imageLink, isUseFormatUrl, showLinkImage, srcSetParams, url]);

  const onError = () => {
    if (showLinkImage) {
      setShowLinkImage(false);
    } else if (isUseFormatUrl) {
      setIsUseFormatUrl(false);
    }
  };

  return (
    <ImageUploadBox
      showLoading={!showLinkImage}
      loader={
        $searchParams.print ? undefined : (
          <>
            {srcSetParams ? (
              <ImageElement {...omit(props, 'srcSetParams')} />
            ) : (
              <LoaderImagePlaceholder uuid={blockId} maxWidth={maxWidth} />
            )}
          </>
        )
      }
      unloader={(p) => {
        onError();
        return unloader?.(p);
      }}
      uuid={blockId}
      src={renderUrl}
      {...setParams}
      onError={onError}
      {...reset}
      data-no-cancel-selected
    />
  );
}, deepEqual);
