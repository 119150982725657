import { cx } from '@flowus/common/cx';
import type { CSSProperties, FC } from 'react';
import { useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { Icon } from 'src/common/components/icon';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { useVisible } from 'src/common/hooks/use-visible';
import type { MentionBlockMenu, MentionBlockMenuProps } from 'src/editor/editor/inline/inline-page';
import { segmentsToText } from 'src/editor/utils/editor';
import { useNormalizePath } from 'src/hooks/page';
import { ID_DRIVE } from 'src/hooks/page/use-dnd/helper';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { judgeSharePage } from 'src/utils/getPageId';
import { getLocationOrigin } from 'src/utils/location-utils';
import { usePickBlock } from 'src/utils/pick-block';
import { elementToGetBoundingClientRect } from 'src/utils/virtualElement';
import { BlockDefaultIcon } from '../block-default-icon';
import { IconTrigger } from '../icon-trigger';
import { LitePage } from './lite-page';
import { useCanShowPagePreview } from './use-can-show-page-preview';
import { useTryFetchPageBlock } from './use-try-fetch-page-block';

let MentionBlockMenu0: typeof MentionBlockMenu;
export const set_mention_block_menu = (obj: typeof MentionBlockMenu) => {
  MentionBlockMenu0 = obj;
};

interface Props {
  isLink?: boolean;
  pageId: string;
  blockId?: string;
  onCloseOutsideModel?: () => void;
  mentionBlockMenuProps?: MentionBlockMenuProps;
  showMoreBtn?: boolean;
  hideOpenRightBtn?: boolean;
  className?: string;
  style?: CSSProperties;
  onlyPreview?: boolean;
}

export const PagePreview: FC<Props> = (props) => {
  const {
    isLink,
    pageId,
    blockId,
    mentionBlockMenuProps,
    showMoreBtn,
    hideOpenRightBtn,
    onlyPreview,
  } = props;
  const pageBlock = usePickBlock(pageId, ['data']);
  const divRef = useRef<HTMLDivElement>();
  const pageTitle = useMemo(() => {
    return segmentsToText(pageBlock?.data.segments) || getUntitledName(pageBlock?.type);
  }, [pageBlock?.data.segments, pageBlock?.type]);

  const [visible, setRef] = useVisible<HTMLDivElement>();
  const canShowPagePreview = useCanShowPagePreview();
  const loading = useTryFetchPageBlock(pageId, visible && canShowPagePreview);
  const path = useNormalizePath(pageId);
  const openPage = useOpenPage();
  const sharePage = judgeSharePage();
  const openModal = useOpenModal();
  const closeModal = useCloseModal();
  const mentionBlocModalId = useRef<string>();

  useEffect(() => {
    return () => {
      if (mentionBlocModalId.current) {
        closeModal(mentionBlocModalId.current);
      }
    };
  }, [closeModal]);

  useLayoutEffect(() => {
    if (!visible || loading) return;

    // treat一下，适配一下ui
    const pageHeader = divRef.current?.querySelector('.page-header');
    if (pageHeader instanceof HTMLElement) {
      pageHeader.hidden = true;
    }
    const footer = divRef.current?.querySelector('.footer');
    if (footer instanceof HTMLElement) {
      footer.hidden = true;
    }
    const pageContainer =
      divRef.current?.querySelector(`#${ID_DRIVE}`) ??
      divRef.current?.querySelector('.next-space-page');
    if (pageContainer instanceof HTMLElement) {
      pageContainer.childNodes.forEach((c) => {
        if (c instanceof HTMLElement) {
          c.setAttribute('style', `padding-left:12px;padding-right:12px`);
        }
      });
    }
  }, [loading, visible]);

  if (!canShowPagePreview) return null;

  const children = props.children ?? (
    <LitePage pageId={pageId} blockId={blockId} loading={loading} />
  );

  return (
    <div
      id="modalContent"
      ref={(ref) => {
        setRef(ref);
        if (ref) {
          divRef.current = ref;
        }
      }}
      className={cx(
        'page-preview rounded w-[500px] h-[300px] flex flex-col text-t2',
        props.className
      )}
      style={props.style}
    >
      <div hidden={onlyPreview} className="flex items-center justify-between p-4">
        <div className="flex items-center">
          <IconTrigger
            trigger={false}
            iconSize={20}
            tooltipClassName="flex-shrink-0"
            hasOverlay={isLink}
            blockId={pageId}
            offset={[100, 0]}
            defaultIcon={<BlockDefaultIcon uuid={pageId} />}
          />
          <div className="ml-2 text-t2 h-6 overflow-hidden break-words">{pageTitle}</div>
        </div>
        {!sharePage && (
          <div className="flex items-center shrink-0">
            {!showMoreBtn && (
              <Tooltip
                popup="复制链接"
                onClick={() => {
                  let appendHash = '';
                  if (blockId) {
                    appendHash = `#${blockId}`;
                  }
                  void writeTextInClipboard(`${getLocationOrigin()}${path}${appendHash}`);
                }}
              >
                <Icon name={'IcLink'} size="middle" className="cursor-pointer mr-4" />
              </Tooltip>
            )}
            {!hideOpenRightBtn && (
              <Tooltip
                popup="侧边栏窗口打开"
                onClick={() => {
                  openPage(pageId, {
                    forceOpenInRight: true,
                    hash: blockId ? `#${blockId}` : undefined,
                  });
                  props.onCloseOutsideModel?.();
                }}
              >
                <Icon
                  name={'IcInSidePeek'}
                  size="middle"
                  className={cx('cursor-pointer', {
                    'mr-4': showMoreBtn,
                  })}
                />
              </Tooltip>
            )}
            {showMoreBtn && mentionBlockMenuProps && (
              <Tooltip
                popup="更多菜单"
                onClick={(e) => {
                  mentionBlocModalId.current = openModal.dropdown({
                    popcorn: elementToGetBoundingClientRect(e.currentTarget),
                    content: ({ onCloseModal }) => {
                      return (
                        <MentionBlockMenu0
                          {...mentionBlockMenuProps}
                          onCloseOutModel={() => {
                            // 关掉最外层的两个弹窗
                            onCloseModal();
                            props.onCloseOutsideModel?.();
                          }}
                        />
                      );
                    },
                  }).modalId;
                }}
              >
                <Icon name={'IcMore'} size="middle" className="cursor-pointer" />
              </Tooltip>
            )}
          </div>
        )}
      </div>
      <div hidden={onlyPreview} className="h-px mx-4 bg-grey6" />
      {children}
    </div>
  );
};
