import { useResource } from 'src/hooks/public/use-resource';
import { useUploadInfoById } from 'src/services/upload';
import { $searchParams } from 'src/utils';
import { usePickBlock } from 'src/utils/pick-block';
import { Video } from '../../component/video';
import type { BlockElement } from '../core/type';
import { PrintMedium } from './print';
import { usePermissions } from 'src/hooks/share/use-permissions';

export const VideoBlockElement: BlockElement = ({ id, root, ownerBlockId, children }) => {
  const block = usePickBlock(id, ['data', 'local'], ['extName']);
  const ownerBlock = usePickBlock(ownerBlockId, ['local']);
  const uploadInfo = useUploadInfoById(id);
  const { url } = useResource(id);
  const permission = usePermissions(id);

  const isPrint = $searchParams.print;

  return (
    <>
      {isPrint ? (
        <PrintMedium uuid={id} />
      ) : (
        <Video
          id={id}
          ownerBlockId={ownerBlockId}
          extName={block?.data.extName}
          root={root}
          progress={uploadInfo?.progress ?? -1}
          url={url}
          defaultOpenPanel={Boolean((ownerBlock || block)?.local)}
          allowDownload={permission.allowDownload}
        />
      )}
      {children}
    </>
  );
};
