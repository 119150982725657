import type { FC, ReactNode } from 'react';
import { memo, useEffect, useRef, useState } from 'react';

import * as clipboard from 'clipboard-polyfill';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import { cx } from '../cx';
import './style.css';
import 'katex/dist/katex.min.css';

import 'highlight.js/styles/atom-one-dark.css';
import { deepEqual } from '../utils';

export const MarkdownRender: FC<{
  className?: string;
  copyButton?: ReactNode;
  content?: string;
}> = memo(({ copyButton, className, content = '', ...rest }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const needAutoScroll = useRef(true);

  useEffect(() => {
    const node = containerRef.current;
    if (!node) return;

    const handleScroll = () => {
      needAutoScroll.current = false;
    };

    node.addEventListener('wheel', handleScroll);
    return () => {
      node.removeEventListener('wheel', handleScroll);
    };
  }, []);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    if (!needAutoScroll.current) return;

    if (container.scrollHeight > container.offsetHeight) {
      container.scrollTop = container.scrollHeight;
    }
  }, [content]);

  return (
    <div
      dir="auto"
      className={cx('text-t2 markdown-render space-y-1', className)}
      ref={containerRef}
    >
      <ReactMarkdown
        remarkPlugins={[remarkGfm, remarkBreaks, remarkMath]}
        rehypePlugins={[rehypeRaw, rehypeKatex, rehypeHighlight]}
        components={{
          a: ({ href, children }) => {
            return (
              <a
                href={href}
                target="_blank"
                className="border-b border-p6 border-dashed"
                rel="nofollow noopener noreferrer"
              >
                {children}
              </a>
            );
          },
          pre: ({ children, ...props }) => (
            <PreRender copyButton={copyButton} {...props}>
              {children}
            </PreRender>
          ),
        }}
        {...rest}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
}, deepEqual);

const PreRender: FC<any> = memo(({ copyButton, className, children }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  return (
    <pre
      className={cx(
        className,
        'group group/pre_render relative pre-tag rounded overflow-hidden whitespace-pre-wrap'
      )}
    >
      {children}

      {copyButton ? (
        copyButton
      ) : (
        <button
          onClick={async (event) => {
            const code = (event.target as HTMLButtonElement).previousElementSibling;
            if (code) {
              await clipboard.writeText(code.textContent ?? '');
              setCopySuccess(true);
              setTimeout(() => {
                setCopySuccess(false);
              }, 3000);
            }
          }}
          className="group-hover:opacity-100 opacity-0 absolute right-2 top-2 flex justify-center items-center p-2 rounded border bg-white1 duration-200"
        >
          {copySuccess ? '已复制' : '复制'}
        </button>
      )}
    </pre>
  );
}, deepEqual);
