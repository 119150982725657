import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';
import { DEFAULT_PAGE_WIDTH } from 'src/common/const';
import { VideoJS } from 'src/components/video';
import { BlockDrop } from 'src/editor/editor/plugin/dnd/block-drop';
import { useResize } from 'src/hooks/block/use-resize';
import { useReadonly } from 'src/hooks/page';
import { useIsMobileSize } from 'src/services/app/hook';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { usePickBlock } from 'src/utils/pick-block';
import { Caption } from '../caption';
import { useSelectFile } from '../file/empty-file';
import { InfiniteProgress } from '../image/infinite-progress';
import { ResizeElement } from '../resize-element';
import { EmptyVideo } from './empty-video';

interface Props {
  id: string;
  root?: boolean;
  defaultOpenPanel: boolean;
  url?: string;
  progress?: number;
  extName?: string;
  ownerBlockId?: string;
  allowDownload?: boolean;
}
const MIN_WIDTH = 96;
const DEFAULT_HEIGHT = 480;

export const Video: FC<Props> = (props) => {
  const { id, ownerBlockId, url, progress, root, defaultOpenPanel } = props;
  const blockId = ownerBlockId ?? id;
  const block = usePickBlock(id, ['data'], ['format', 'localUrl', 'size', 'link']);
  const readonly = useReadonly(blockId);
  const showProgress = progress !== -1 && !url;
  const isMobileSize = useIsMobileSize();
  const selectFile = useSelectFile({ uuid: blockId });

  const {
    renderSize,
    onRenderSize,
    changeSize,
    containerWidth,
    containerRef,
    isDisable,
    defaultBlockFullWidth,
    defaultWidth,
    defaultHeight,
    widthBreakPoints,
  } = useResize({
    id: blockId,
    root,
    defaultHeight: block?.data.format?.height ?? DEFAULT_HEIGHT,
    fullPageContentWidth: true,
  });

  const handleCopyLink = () => {
    if (!block?.data.link) return;
    void writeTextInClipboard(block.data.link);
  };

  if (!url) {
    return (
      <BlockDrop id={blockId} horizontal={root} className="my-1">
        <EmptyVideo
          readonly={readonly}
          defaultOpenPanel={defaultOpenPanel}
          onClick={(e) => {
            if (showProgress) return;
            selectFile(e.currentTarget, 'bottom');
          }}
          text={showProgress ? `${progress}%` : undefined}
        />
      </BlockDrop>
    );
  }
  return (
    <BlockDrop
      blockRef={containerRef}
      id={blockId}
      horizontal={root}
      fullWidth={isMobileSize}
      className={cx('my-2.5 group', root && 'self-center')}
    >
      <ResizeElement
        fullHeight
        resizeWidth
        widthBreakPoints={widthBreakPoints}
        doubleResize={root}
        readonly={readonly || isDisable}
        defaultBlockFullWidth={defaultBlockFullWidth}
        minWidth={MIN_WIDTH}
        maxWidth={containerWidth ? containerWidth : undefined}
        defaultWidth={defaultWidth || DEFAULT_PAGE_WIDTH}
        defaultHeight={defaultHeight}
        onChange={changeSize}
        onRenderSize={onRenderSize}
        outerLayoutClassName={cx(ownerBlockId && 'pl-1 border-dashed border-grey5 border-l-[2px]')}
        className={cx('max-w-full', showProgress && 'bg-black', isDisable && 'pointer-events-none')}
        customFooter={<Caption blockId={blockId} style={{ width: renderSize.width }} />}
      >
        {url && <VideoJS videoUrl={url} allowDownload={props.allowDownload} />}
        {showProgress && (
          <div className="absolute flex items-center px-1.5 right-2.5 top-2.5 rounded-sm bg-black1/75">
            <InfiniteProgress />
            <div className="text-white text-t4">{progress}%</div>
          </div>
        )}
        {block?.data.link && (
          <div
            className="z-10 absolute flex items-center top-2.5 right-2.5 bg-black/75 group-hover:opacity-100 opacity-0 rounded-sm animate-click"
            onClick={handleCopyLink}
          >
            <Icon size="middle" className="text-white" name={'IcGlobeShare'} />
          </div>
        )}
      </ResizeElement>
    </BlockDrop>
  );
};
