import { addSubscribe } from '@flowus/common';
import { BuildinSubscribePay } from '@flowus/common/components';
import { LogoSlogan } from '@flowus/common/components/logo-slogan';
import { useOpenPayAccessFeeCode } from '@flowus/common/components/pay-access-fee-code';
import { cx } from '@flowus/common/cx';
import { useApiState } from '@flowus/common/hooks/use-api-state';
import { useOpenModal } from '@flowus/common/next-modal';
import { getSessionOp } from '@flowus/common/storage';
import { PayType } from '@flowus/common/utils/pay-info';
import { useSystemIsRu } from '@flowus/common/utils/system';
import { useMemo, useState, type FC } from 'react';
import { Avatar } from 'src/common/components/avatar';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { UNTITLED } from 'src/common/const';
import { request } from 'src/common/request';
import { Placeholder } from 'src/components/empty/placeholder';
import { HEADER_HEIGHT } from 'src/const/public';
import { PRODUCT_NAME } from 'src/const/title';
import { isBuildIn } from 'src/env';
import { useBlock } from 'src/hooks/block/use-block';
import { useFetchPage } from 'src/hooks/page';
import { usePayInfo } from 'src/hooks/page/use-pay-info';
import { getCurrentUser } from 'src/hooks/user';
import { useIsLogin } from 'src/hooks/user/use-is-login';
import { Modals } from 'src/modals';
import { cache } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { findFirstAllowSubscribePage } from 'src/utils/block-utils';
import { getCurrencySymbols } from 'src/utils/currency-format';
import { useGetPageId } from 'src/utils/getPageId';
import {
  MESSAGE_CODE_HIDE_SUBSCRIBE_CARD,
  MESSAGE_CODE_SHOW_SUBSCRIBE_CARD,
} from 'src/utils/message-code';
import { ShareRebateUrlButton } from 'src/views/main/header/subscribe-button';
import { Page } from 'src/views/main/page';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { useCheckOrder } from 'src/views/upgrade/h5/use-check-order';
import { PayQrModal } from '../select-upgrade-plan/pay-qr-modal';
import { computePeriod } from '../share/share-pay';
import { useOpenBuildInPay } from '../stripe';
import { useOpenLogin } from './open-login';
import { PayAccessFeeContext } from './pay-access-fee-context';

interface Props {}
export const PayAccessFee: FC<Props> = () => {
  const pageId = useGetPageId();
  const block = useBlock(pageId);
  const { payInfo } = usePayInfo(pageId);

  if (payInfo?.openPreview && block) {
    return (
      <PayAccessFeeContext.Provider value={payInfo.spaceId}>
        <div className="w-full relative main-panel h-full min-h-0 min-w-0 flex-1 gt-xl:min-w-[640px]">
          <Page uuid={pageId}>
            <PayAccessFeeContent payInfo={payInfo} uuid={pageId} className="border-t" />
          </Page>
        </div>
      </PayAccessFeeContext.Provider>
    );
  }

  return <PayAccessFeeContent payInfo={payInfo} uuid={pageId} />;
};

interface PayTipProps {
  uuid: string;
  className?: string;
  payInfo: ReturnType<typeof usePayInfo>['payInfo'];
}
export const PayAccessFeeContent: FC<PayTipProps> = (props) => {
  const { uuid, className, payInfo } = props;
  const shareUserCode = getSessionOp.inviteCode.get();
  const [shareUserInfo] = useApiState({
    getApi: () => request.infra.getUserByCode(shareUserCode),
    enable: !!shareUserCode,
  });
  const pageScene = usePageScene();
  const isInTemplateCenter = pageScene === PageScene.TEMPLATE;

  if (!payInfo) return null;

  const payContent = (
    <div className="pb-10 px-10 flex items-center justify-between text-t2 flex-col max-w-lg">
      <div>
        <div className="flex flex-col items-center space-y-2.5">
          {payInfo && (
            <>
              <div className="text-h4">{payInfo.publishAccessPageTitle ?? UNTITLED}</div>
            </>
          )}
          <div className="flex flex-col space-y-2">
            <span className="flex text-t2 space-x-2">
              <Avatar
                className="flex-shrink-0 w-[26px] h-[26px]"
                icon={payInfo.spaceIcon}
                name={payInfo.spaceTitle}
                color={payInfo.spaceBackgroundColor}
              />
              <span className="ml-0.5 text-t2-medium text-ellipsis">{payInfo.spaceTitle}</span>
            </span>
            {shareUserInfo && (
              <span className="space-x-1 text-t2 text-center">
                <span className="text-t4 text-grey3">推荐人:</span>
                <span className="text-t4">{shareUserInfo?.nickname}</span>
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="mt-5 w-full text-center text-t2">付费须知</div>
      <div className="text-t4 text-grey3 space-y-1 mb-10 mt-1">
        <div>1. 付费后，你可以通过「{PRODUCT_NAME}」App端、Web端、小程序端查看页面内容。</div>
        <div>
          2. 当前分享页面由创作者自行创建，有删除页面内容或停止分享等风险，购买前请确认，「
          {PRODUCT_NAME}」平台不提供相关保证。
        </div>
      </div>
      <LogoSlogan />
    </div>
  );

  return (
    <Placeholder className={cx('!pt-24', className)}>
      <div
        className="fixed bg-white1 top-0 left-0 w-full flex items-center justify-end px-5 space-x-2.5 z-50"
        style={{ height: HEADER_HEIGHT, top: isInTemplateCenter ? '55px' : '0px' }}
      >
        <ShareRebateUrlButton />
      </div>
      {payContent}
      {/* <Footer /> */}
      <PayCard payInfo={payInfo} uuid={uuid} />
    </Placeholder>
  );
};

const PayCard: FC<PayTipProps> = (props) => {
  const isLogin = useIsLogin();
  const { payInfo, uuid } = props;
  const openLogin = useOpenLogin();
  const openPayAccessFeeCode = useOpenPayAccessFeeCode({
    isLogin,
    openLogin,
  });
  const shareUserCode = getSessionOp.inviteCode.get();
  const fetchPage = useFetchPage();
  const openModal = useOpenModal();
  const isTemplate = usePageScene() === PageScene.TEMPLATE;
  const [orderId, setOrderId] = useState<string>();
  const isSystemIsRu = useSystemIsRu();
  // 只有buildin里面的逻辑才会执行，flowus显示的是二维码，不需要检查订单状态
  useCheckOrder(
    orderId,
    () => {
      void fetchPage(uuid);
    },
    false
  );
  const openBuildPay = useOpenBuildInPay();
  const payButton = useMemo(() => {
    if (!payInfo) return;
    const getPaidOrder = async (payType?: PayType) => {
      const ret = await request.infra.createSharePaidOrder({
        payType: __BUILD_IN__ ? payType ?? PayType.webpay : PayType.wxpay,
        spaceId: payInfo.spaceId,
        sharePageId: payInfo.accessPageId,
        distributorCode: shareUserCode,
      });
      return ret;
    };
    const clickPay = async () => {
      if (!isLogin) {
        openLogin();
        return;
      }
      window.parent.postMessage(
        {
          code: MESSAGE_CODE_HIDE_SUBSCRIBE_CARD,
        },
        '*'
      );

      if (isBuildIn()) {
        openModal.modal({
          closeAfterCallBack: () => {
            window.parent.postMessage(
              {
                code: MESSAGE_CODE_SHOW_SUBSCRIBE_CARD,
              },
              '*'
            );
          },
          content: ({ onCloseModal }) => {
            return (
              <BuildinSubscribePay
                className="min-w-[320px]"
                showRuPay={isSystemIsRu}
                onPayClick={async (_payType) => {
                  const order = await getPaidOrder(_payType);
                  setOrderId(order.orderId);
                  openBuildPay(order);
                  onCloseModal();
                }}
              />
            );
          },
        });
        return;
      }

      const order = await getPaidOrder();

      openModal.modal({
        modalId: Modals.PAY_MODAL,
        closeAfterCallBack: () => {
          window.parent.postMessage(
            {
              code: MESSAGE_CODE_SHOW_SUBSCRIBE_CARD,
            },
            '*'
          );
        },
        content: () => (
          <PayQrModal
            rePayOrder={getPaidOrder}
            onFinish={async () => {
              bizTracker.event('pay_access_fee', {
                spaceId: payInfo.spaceId,
                duration: `${payInfo.accessFeeValidDays || 'forever'}`,
                amount: order.amount,
              });
              const targetId =
                findFirstAllowSubscribePage(payInfo.accessPageId) ?? payInfo.accessPageId;
              setTimeout(async () => {
                void fetchPage(uuid);
                const createBy = cache.blocks[payInfo.accessPageId]?.createdBy;
                await addSubscribe(
                  {
                    targetId,
                    subscribed: true,
                    addSubscribe: request.infra.addSubscribe,
                    cancelSubscribe: request.infra.cancelSubscribe,
                    user: payInfo?.ownerUser,
                    openDialog: openModal.warning,
                    callback: (status) => {
                      if (!isTemplate) {
                        return;
                      }
                      status && location.reload();
                    },
                  },
                  {
                    pageId: payInfo.accessPageId,
                    needPay: true,
                    userId: getCurrentUser().uuid,
                    createdBy: createBy,
                    spaceId: payInfo.spaceId,
                    event: bizTracker.event,
                  }
                );
              }, 1000);
            }}
            payType={__BUILD_IN__ ? PayType.webpay : PayType.wxpay}
            // 后期海外版需要amountUnit这个字段
            priceTitle={`${getCurrencySymbols(undefined)}${order.amount}`}
            orderId={`${order.orderId}`}
            qrCodeUrl={`${order.qrCodeUrl}`}
          />
        ),
      });
    };

    return (
      <Button size="large" className="w-full h-10" colorType="active" onClick={clickPay}>
        <span className="text-t2-medium">购买查看完整内容：</span>
        <span className="text-h4 font-medium">{`${getCurrencySymbols(undefined)}${
          payInfo?.accessFee
        }`}</span>
        {payInfo.publicOriginPrice && payInfo.publicOriginPrice > 0 && (
          <span className="line-through text-t2 text-white/70 ml-1" style={{}}>
            {['原价', getCurrencySymbols(undefined)].join()}
            <span>{payInfo.publicOriginPrice}</span>
          </span>
        )}
      </Button>
    );
  }, [
    payInfo,
    shareUserCode,
    isLogin,
    openModal,
    openLogin,
    isSystemIsRu,
    openBuildPay,
    fetchPage,
    uuid,
    isTemplate,
  ]);

  return (
    <div
      className={cx('w-full flex justify-center z-[60]', {
        'fixed bottom-8 mt-8': payInfo?.openPreview,
      })}
    >
      <div
        className={cx('w-[452px] p-4', {
          'next-modal': payInfo?.openPreview,
        })}
      >
        <>{payButton}</>
        <>
          <div className="mt-4 space-x-2 text-t2 w-full flex  items-center justify-center text-grey3">
            <Icon name={'IcHistory'} size="middle" />
            <div>{['有效期', [':'], computePeriod(payInfo?.accessFeeValidDays)].join('')}</div>
            <div className="animate-click text-active_color" onClick={openPayAccessFeeCode}>
              使用兑换码
            </div>
          </div>
        </>
      </div>
    </div>
  );
};

// const Footer: FC = () => {
//   const report = useReportIllegal();
//   const isLogin = useIsLogin();
//   const openLogin = useOpenLogin();
//   return (
//     <div className="w-full bg-white1 border-t">
//       <div className="mt-8 text-t2 flex items-center space-x-9 justify-center">
//         <a className="animate-click" href={publicLink.learnMore} target="_blank">
//           了解 FlowUs
//         </a>
//         <a className="animate-click" href={publicLink.shareDataPanel} target="_blank">
//           问题反馈
//         </a>
//         <a className="animate-click" href={publicLink.subscribeRule} target="_blank">
//           使用帮助
//         </a>
//         <a className="animate-click" onClick={report}>
//           举报违法信息
//         </a>
//         {isLogin && (
//           <a className="animate-click" onClick={() => openLogin()}>
//             快速注册 FlowUs
//           </a>
//         )}
//       </div>
//     </div>
//   );
// };
