import { cx } from '@flowus/common/cx';
import type { IconSize } from '@flowus/common/icon-svg/types';
import type { FC, MouseEvent } from 'react';
import { useCallback } from 'react';
import { Icon } from 'src/common/components/icon';
import { Tooltip } from 'src/common/components/tooltip';
import { TOC_COLLAPSED } from 'src/common/const';
import { IconUiSizeBox } from 'src/components/icon-ui-size-box';
import { setAppUiState, useIsCollapse } from 'src/services/app';
import { $searchParams } from 'src/utils';
import { judgeSharePage, judgeSubscribePage } from 'src/utils/getPageId';
import { setLocalStorage } from 'src/utils/local-storage';
import { ShortcutSystemSymbol } from 'src/utils/shortcut';

/**
 *  @param status 是否展开侧边栏
 * */
export const useToggleCollapse = () => {
  return useCallback((status: boolean) => {
    setAppUiState({ $isCollapsed: status });
    if (status) {
      setLocalStorage(TOC_COLLAPSED, 'true');
    } else {
      localStorage.removeItem(TOC_COLLAPSED);
    }
  }, []);
};

export const TocCollapseButton: FC<{
  className?: string;
  visibilityCallback?: (isCollapsed: boolean) => boolean;
  size?: IconSize;
}> = ({ className, visibilityCallback, size = 'middle' }) => {
  const toggleCollapse = useToggleCollapse();
  const isCollapsed = useIsCollapse();
  const collapseToc = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      toggleCollapse(!isCollapsed);
    },
    [isCollapsed, toggleCollapse]
  );

  if (visibilityCallback && !visibilityCallback(isCollapsed)) {
    return null;
  }

  return (
    <Tooltip
      popup={
        <span>
          {isCollapsed ? '展开侧边栏' : '收起侧边栏'}
          <span className="text-grey3 px-1">{ShortcutSystemSymbol}+\</span>
        </span>
      }
      className={cx(
        'flex items-center justify-center w-5 h-5 ml-2 transition-all duration-200 rounded opacity-0 toc-collapse-trigger sm:opacity-100 animate-click',
        className
      )}
      onClick={collapseToc}
    >
      <Icon
        name="IcFold"
        className={cx('text-grey2 animate-hover', isCollapsed && 'transform rotate-180')}
        size={size}
      />
    </Tooltip>
  );
};

export const TocShowButton: FC<{ showInSharePage?: boolean }> = ({ showInSharePage }) => {
  const isCollapsed = useIsCollapse();
  const isSharePage = judgeSharePage();
  const toggleCollapse = useToggleCollapse();
  const isSubscribePage = judgeSubscribePage();

  const showToc = (event: MouseEvent) => {
    event.stopPropagation();
    toggleCollapse(false);
  };

  if (!isCollapsed) return null;
  if (isSharePage && !showInSharePage && !isSubscribePage) return null;
  if ($searchParams.embed) return null;

  return (
    <Tooltip
      popup={
        <span>
          展开侧边栏<span className="text-grey3 ml-0.5">{ShortcutSystemSymbol}+\ </span>
        </span>
      }
      className={
        'toc-collapse flex items-center justify-center mr-0.5 rounded animate-hover group-hover:flex relative'
      }
      onClick={showToc}
    >
      <IconUiSizeBox size={28}>
        <Icon name="IcUnfold" className="text-grey3" size="middle" />
      </IconUiSizeBox>
    </Tooltip>
  );
};
