import type { FC } from 'react';
interface Props {
  example: string;
  richText: React.ReactNode;
}
export const EvalValue: FC<Props> = (props) => {
  const { richText } = props;
  const node = (
    <div className="rounded flex items-center px-2.5 py-1.5">
      {`${props.example} ==`}
      &nbsp;
      {richText}
    </div>
  );
  return <div className="bg-grey6">{node}</div>;
};
