import type { Placement } from '@floating-ui/react';
import type { TippyProps } from '@tippyjs/react';
import Tippy from '@tippyjs/react';
import { omit, pick } from 'lodash-es';
import type { MouseEvent, ReactNode } from 'react';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { sleep } from '../../async';
import { cx } from '../../cx';
import { Tooltip as ITooltip } from '../../tooltip';
import { deepEqual } from '../../utils';
import './index.css';

export interface TooltipProps extends Omit<TippyProps, 'children'> {
  popup?: ReactNode | undefined;
  popupClass?: string;
  equalWidth?: boolean;
  popupClick?: (event: MouseEvent) => void;
  interactive?: boolean;
  maxWidth?: number | string;
  theme?: 'next-tooltip' | 'members-tooltip' | 'next-modal' | 'none';
  lazyLoad?: boolean;
  children?: any;
  style?: React.CSSProperties;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  id?: string;
}

// 将数组移到组件外部作为常量
const MOVE_REST_ATTRIBUTES = [
  'data-no-cancel-selected',
  'data-test-id',
  'id',
  'data-menu-id',
] as const;

export const Tooltip = memo((props: TooltipProps) => {
  const {
    popup,
    offset = [0, 10],
    placement = 'top',
    children,
    className,
    duration = [200, 50],
    delay = [200, 50],
    popupClass = '',
    popupClick,
    equalWidth,
    arrow = false,
    onClick,
    style,
    trigger = 'mouseenter',
    interactive = false,
    maxWidth = 200,
    theme = 'next-tooltip',
    animation = 'fade',
    visible,
    appendTo = () => document.body,
    disabled,
    lazyLoad = false,
    onShow,
    onShown,
    onHidden,
    ...rest
  } = props;
  const ref = useRef<HTMLDivElement>(null);

  // @tippyjs/react: Cannot specify `trigger` prop in controlled mode (`visible` prop)
  const visibleProps = useMemo(
    () => (typeof visible === 'boolean' ? { visible } : { trigger }),
    [trigger, visible]
  );

  /** 有一些特殊的类似data-set属性需要从tippy上挪给div。在这里记录 */
  const moveRestAttribute = MOVE_REST_ATTRIBUTES;
  const [showContent, setShowContent] = useState(!lazyLoad);
  const disable = !!(disabled || !popup);

  useEffect(() => {
    let mounted = true;

    if (visible !== undefined) {
      if (!visible) {
        void sleep(100).then(() => {
          if (mounted) {
            setShowContent(visible);
          }
        });
      } else {
        setShowContent(visible);
      }
    }

    return () => {
      mounted = false;
    };
  }, [visible]);

  const content = useMemo(
    () => (
      <div
        data-no-cancel-selected
        ref={ref as any}
        className={cx('flex', className)}
        style={style}
        onClick={onClick}
        {...(pick(rest, moveRestAttribute) as React.HTMLAttributes<HTMLDivElement>)}
      >
        {children}
      </div>
    ),
    [children, className, moveRestAttribute, onClick, rest, style]
  );

  const delayOption = useMemo(() => {
    let _open = 200;
    let _close = 0;
    if (typeof delay === 'number') {
      _open = delay;
    } else if (Array.isArray(delay)) {
      _open = delay[0] ?? 200;
      _close = delay[1] ?? 0;
    }
    return { open: _open, close: _close };
  }, [delay]);

  if (!interactive && theme === 'next-tooltip' && trigger === 'mouseenter' && !popupClick) {
    return (
      <ITooltip
        flexCenter={false}
        placement={placement as Placement}
        delay={delayOption}
        offset={offset}
        popup={popup}
        className={cx('flex', className)}
        popupClassName={cx('break-words', popupClass)}
        maxWidth={equalWidth ? ref.current?.clientWidth : maxWidth}
        onClick={onClick}
        style={style}
        disabled={disable}
        {...(pick(rest, moveRestAttribute) as any)}
      >
        {children}
      </ITooltip>
    );
  }

  return (
    <Tippy
      onDestroy={() => {
        if (lazyLoad) {
          setShowContent(false);
        }
      }}
      onHidden={(instance) => {
        if (lazyLoad && !visible) {
          setShowContent(false);
        }
        onHidden?.(instance);
      }}
      onShow={(instance) => {
        if (lazyLoad) {
          setShowContent(true);
        }
        onShow?.(instance);
      }}
      onShown={(instance) => {
        if (lazyLoad) {
          setShowContent(true);
        }
        onShown?.(instance);
      }}
      interactive={interactive}
      arrow={arrow}
      delay={delay}
      duration={duration}
      disabled={disable}
      theme={theme}
      placement={placement}
      offset={offset}
      maxWidth={equalWidth ? ref.current?.clientWidth : maxWidth}
      appendTo={appendTo}
      animation={animation}
      content={
        showContent && (
          <div
            className={cx('text-t4-medium whitespace-pre-line', popupClass)}
            onClick={popupClick}
          >
            {showContent && popup}
          </div>
        )
      }
      {...omit(rest, moveRestAttribute)}
      {...visibleProps}
    >
      {content}
    </Tippy>
  );
}, deepEqual);
