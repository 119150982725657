import dayjs from 'dayjs';
import { DATE_FORMAT } from 'src/common/const';

const formatYear = (year: number) => {
  if (year < 10) {
    return Number('200'.concat(String(year)));
  }
  if (year < 100 && year >= 10) {
    return Number('20'.concat(String(year)));
  }
  return year;
};

export const formatDateString = (value: string, format = DATE_FORMAT): string | undefined => {
  if (format === DATE_FORMAT) {
    const newValue = value.replaceAll('-', '/');
    const dateArr = newValue.split('/').map((nv) => parseInt(nv, 10));
    const yearString = dateArr[0] as number;
    const monthString = dateArr[1] as number;
    const dayString = dateArr[2] && isNaN(dateArr[2]) ? 1 : dateArr[2];

    if (dayString || dayString === 0) {
      const newDate = new Date(formatYear(yearString), monthString - 1, dayString);
      const month = monthString - 1;

      if (isNaN(newDate.getTime())) return;
      if (newDate.getDate() !== dayString || newDate.getMonth() !== month) return;
      return dayjs(newDate).format(format);
    }
  }
  return value;
};

export const isValidTimeString = (value: string) => {
  return !isNaN(new Date(dayjs().format(DATE_FORMAT).concat(' ', value)).getTime());
};
/** 3:1会被修复为03:01 */
export const fixTime = (value: string) => {
  const dayTime = new Date(dayjs().format(DATE_FORMAT).concat(' ', value));
  const time = dayjs(dayTime).format('HH:mm');
  return time;
};
