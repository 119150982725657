import { BlockType } from '@next-space/fe-api-idl';
import { emptyConvertBlockTypes } from 'src/common/const';
import { getEditorModelByEditorKey } from 'src/editor/editor/uikit/editable-models';
import { makeEditorKey } from 'src/editor/editor/uikit/editable/helper';
import type { EditorKeyOption } from 'src/hooks/editor/use-focus-by-id';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { cache } from 'src/redux/store';
import type { NextBlock, NextWhere, SegmentType } from 'src/redux/types';
import { isMindMap } from 'src/utils/block-type-utils';
import { parseEditorKeyToBlockId } from 'src/utils/editor-key-to-id';
import {
  getEditorKey,
  getEditorKeyFromElement,
  getSyncIdFromElement,
} from 'src/utils/editor-utils';
import { focusEditableAtByEditorKey } from 'src/utils/focus-by-editor-key';
import { getDynamicPageId } from 'src/utils/getPageId';
import type { HandOption } from '../types';

export const common = (opt?: HandOption) => {
  const { ui } = cache;
  const { selectedBlocks } = ui;
  const pageId = opt?.pageBlock?.uuid || getDynamicPageId();
  const pageBlock = cache.blocks[pageId];
  const isInRight = pageId === getDynamicPageId('right');

  let posEditorKey = getEditorKeyFromElement(document.activeElement);

  let pos: string | undefined = parseEditorKeyToBlockId(posEditorKey ?? '');

  if (!pos && selectedBlocks.length === 1) {
    // 特殊逻辑：选中某个块之后立刻粘贴,此时document.activeElement并不会立刻拿到选中的元素，所以这里需要workaround
    pos = selectedBlocks[0]?.blockId ?? '';
  }
  let viewId = undefined;
  let syncId = getSyncIdFromElement(document.activeElement);
  if (selectedBlocks.length && cache.blocks[pos]) {
    // pos有可能是评论editor，因此需要判断cache.blocks[pos]存不存在
    const lastSelectedBlock = selectedBlocks[selectedBlocks.length - 1];
    pos = lastSelectedBlock?.blockId;
    viewId = lastSelectedBlock?.viewId;
    syncId = lastSelectedBlock?.syncId;
    posEditorKey = getEditorKey(pos, syncId, isInRight);
  }
  const focusEditableAt = (
    id: string | undefined,
    at: number,
    type?: SegmentType,
    option?: EditorKeyOption
  ) => {
    if (!id) return;
    const editorKey = makeEditorKey(id, undefined, option?.syncId ?? syncId);
    focusEditableAtByEditorKey(editorKey, at, type);
  };
  const where: NextWhere = { parentId: pageId };

  let posBlock: NextBlock | undefined;
  // 思维导图粘贴块是在选中块的子块下
  const isInMindMap = isMindMap(cache.blocks[pageId]?.type);
  if (pos) {
    posBlock = cache.blocks[pos];
    if (posBlock) {
      const { type } = posBlock;
      if (pos !== pageId) {
        if (type === BlockType.MARK || type === BlockType.QUOTE) {
          where.parentId = posBlock.uuid;
        } else {
          // NOTE:这个选中内嵌多维表下的记录粘贴块会走的逻辑，但其实只有粘贴页面块才能粘进去
          where.parentId = posBlock.parentId;
        }
      }
      // 思维导图粘贴块是在选中块的子块下
      if (isInMindMap) {
        where.parentId = pos;
        where.last = true;
      }
    }
  }

  const clearEmptyBlock = () => {
    // 思维导图不要清节点
    if (pos && posBlock && !isInMindMap) {
      // 在空块按粘贴时，删除该空块
      if (emptyConvertBlockTypes.includes(posBlock.type) && !posBlock.data.segments?.length) {
        archiveBlock(pos);
      }
    }
  };

  const insertInTitle = (text: string) => {
    if (pos === getDynamicPageId('left') || pos === getDynamicPageId('right')) {
      const model = posEditorKey && getEditorModelByEditorKey(posEditorKey);
      if (!model) return;

      model.performChange((ctx) => {
        ctx.delete();
        ctx.insert(text);
      });

      return true;
    }
  };

  const isInInlineEditable =
    document.getElementById('dropDownContent')?.querySelector('[contentEditable=true]') != null ||
    document.activeElement?.matches('.biz-inline-editable');

  return {
    syncId,
    posEditorKey,
    pos,
    where,
    pageId,
    posBlock,
    viewId,
    pageBlock,
    selectedBlocks,
    insertInTitle,
    clearEmptyBlock,
    isInInlineEditable,
    focusEditableAt,
  };
};
