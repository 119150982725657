import { deepEqual, fastEqual } from '@flowus/common';
import { SeoAnchorLink } from '@flowus/common/components/seo-anchor-link';
import { cx } from '@flowus/common/cx';
import { $searchParams } from '@flowus/common/flowus-utils/search-params';
import { BlockType } from '@next-space/fe-api-idl';
import type { ComponentProps, FC, RefObject } from 'react';
import { memo, useContext, useMemo, useRef } from 'react';
import { useTextAlignStyle } from 'src/hooks/block/use-align-style';
import { useBlockStyle } from 'src/hooks/block/use-block-style';
import { getAncestors } from 'src/hooks/block/use-get-ancestors';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { useHideSyncBlockChildBlockMenu } from 'src/hooks/block/use-hide-sync-block-child-block-menu';
import { useIsRecord } from 'src/hooks/block/use-is-record';
import { useIsCut } from 'src/hooks/page/use-copy-listener/on-cut-copy/cut-store';
import { cursorInRightPanelArea, isAsideArea, isInRightPanel } from 'src/hooks/page/use-dnd/helper';
import { useIsDragging } from 'src/hooks/page/use-dnd/hooks';
import type { Position } from 'src/hooks/page/use-dnd/types';
import { useIsSelected } from 'src/redux/managers/ui';
import { isLikeFoldBlock } from 'src/redux/managers/ui/use-fold';
import { cache } from 'src/redux/store';
import { $dropInfo } from 'src/services/app/hook/use-drop-info';
import { isCollection, isPageLike } from 'src/utils/block-type-utils';
import { getRefBlock, getRightLeaf, supportComments } from 'src/utils/block-utils';
import { getDynamicPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { BlockDiscussionsBadge } from 'src/views/comments/block-discussions-badge';
import { useHasUnresolvedComments } from '../../inline/comment';
import { NoBindIdContext } from '../../raw/no-bind-id-context';
import { useSyncId } from '../sync-block-context';
import { Indicator } from './indicator';

interface Props extends ComponentProps<'div'> {
  id: string;
  /** 这个虽然叫 */
  bindId?: boolean;
  hideHoverMenu?: boolean;
  /** 是否显示垂直（左右）的线 */
  horizontal?: boolean;
  fullWidth?: boolean;
  blockRef?: RefObject<HTMLDivElement>;
  dropInChild?: boolean;
  viewId?: string;
  groupValue?: string;
  subGroupValue?: string;
  disableColorStyle?: boolean;
  TagName?: 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'section';
  showAnchor?: boolean;
}

const usePosition = (
  id: string,
  elementRef: Props['blockRef'],
  dropInChild?: boolean,
  viewId?: string,
  groupValue?: string
) => {
  const block = usePickBlock(id, []);

  return $dropInfo((state) => {
    if (!state || isAsideArea(state.cursorArea)) {
      return;
    }
    const { dropId, position, cursorArea } = state;

    if (elementRef?.current) {
      if (cursorInRightPanelArea(cursorArea)) {
        const { left } = elementRef.current.getBoundingClientRect();
        const inRightPanel = isInRightPanel(left);
        if (!inRightPanel) return;
      }
    }

    if (
      position === 'bottom' &&
      dropId &&
      block &&
      ![
        BlockType.COLUMN_LIST,
        BlockType.QUOTE,
        BlockType.MARK,
        BlockType.COLLECTION_VIEW,
        BlockType.TEMPLATE,
      ].includes(block.type) &&
      getRightLeaf(cache.blocks, id).has(dropId) &&
      state.viewId === viewId &&
      state.groupValue === groupValue
    ) {
      return 'bottom';
    }

    if (
      dropId === id &&
      state.dropInChild === !!dropInChild &&
      state.viewId === viewId &&
      state.groupValue === groupValue
    ) {
      return position;
    }
  }, fastEqual);
};

export const BlockDrop: FC<Props> = memo((props) => {
  const { showAnchor, ...reset } = props;
  if (showAnchor && $searchParams.seoMode) {
    return <SeoAnchorLink href={props.id}>{<IBlockDrop {...reset} />}</SeoAnchorLink>;
  }
  return <IBlockDrop {...reset} />;
}, deepEqual);

const IBlockDrop: FC<Props> = ({
  id,
  children,
  bindId = true,
  hideHoverMenu = false,
  horizontal = false,
  fullWidth = true,
  className = '',
  dropInChild,
  blockRef,
  viewId,
  groupValue,
  subGroupValue,
  style,
  disableColorStyle,
  TagName = 'section',
  ...rest
}) => {
  // const { ref: elementRef } = useSetFlexibleInteraction();
  const blockStyle = useBlockStyle(id);
  const block = usePickBlock(id, ['discussions']);
  const elementRef = useRef<HTMLDivElement>(null);
  const ref = blockRef ?? elementRef;
  const contentGravityStyle = useTextAlignStyle(id);
  const position = usePosition(id, elementRef, dropInChild, viewId, groupValue);
  const isCut = useIsCut(id);

  // TODO 待优化，先禁用掉了
  // const isDragging = useIsDragging();

  const hideSyncBlockChildBlockMenu = useHideSyncBlockChildBlockMenu();
  const syncId = useSyncId();
  const canBindId = useContext(NoBindIdContext) && bindId;
  const commentsInColumn = useMemo(() => {
    let status = false;
    const ownerPage = getOwnerPage(id);

    [...getAncestors(id)].some((i) => {
      const block = cache.blocks[i];
      if (!block) return false;
      if (ownerPage === i) {
        return true;
      }
      if (block.type === BlockType.COLUMN) {
        status = true;
        return true;
      }
      return false;
    });

    return status;
  }, [id]);

  const hasUnresolvedComments = useHasUnresolvedComments();
  const showComment =
    !dropInChild && /* 子页面不显示 */ supportComments(block) && hasUnresolvedComments;

  return (
    <TagName
      ref={ref}
      className={cx(
        'relative cursor-text',
        fullWidth && 'w-full',
        showComment && commentsInColumn && 'pr-10',
        blockStyle.backgroundColor && 'rounded',
        // 拖拽时不触发 hover
        // isDragging ? className.replace('animate-hover', '') : className,
        className,
        hideSyncBlockChildBlockMenu && 'hide-sync-block-child',
        isCut && 'opacity-60'
      )}
      data-block-id={canBindId ? id : null}
      data-drop-in-child={dropInChild}
      data-horizontal={horizontal || null}
      data-hide-hover-menu={hideHoverMenu ? true : null}
      style={{ ...style, ...(disableColorStyle ? {} : blockStyle), ...contentGravityStyle }}
      data-sync-id={syncId}
      data-view-id={viewId}
      data-group-value={groupValue}
      data-sub-group-value={subGroupValue}
      {...rest}
    >
      {children}
      <Indicator
        zIndex={getDynamicPageId() === block?.parentId ? 0 : getAncestors(id).size}
        position={position}
        viewId={viewId}
      />
      {showComment && <Comments id={id} inColumn={commentsInColumn} />}
      <Cover
        id={id}
        bindId={canBindId}
        dropInChild={dropInChild}
        position={position}
        viewId={viewId}
        groupValue={groupValue}
        subGroupValue={subGroupValue}
      />
    </TagName>
  );
};

const Comments: FC<{ id: string; inColumn: boolean }> = memo(({ id, inColumn }) => {
  return (
    <div className={cx('absolute top-0 right-0 h-full z-10', inColumn && 'right-10')}>
      <BlockDiscussionsBadge className="absolute left-1 top-[5px]" blockId={id} />
    </div>
  );
});

const useShowBG = (id: string, isRecord: boolean, dropInChild?: boolean) => {
  return $dropInfo((state) => {
    const { dropId, position } = state || {};
    if (!dropId) return;
    const block = cache.blocks[id];
    if (!block || dropInChild) return;

    if (
      position === 'middle' &&
      (isPageLike(block.type) || isLikeFoldBlock(block.type)) &&
      !isRecord
    ) {
      return;
    }
    const parent = cache.blocks[block.parentId];
    const pageId = getDynamicPageId();
    if (
      !parent ||
      isCollection(parent.type) ||
      parent.type === BlockType.COLUMN_LIST ||
      parent.uuid === pageId
    ) {
      return;
    }

    return parent.uuid === id;
  });
};

const Cover: FC<Props & { position?: Position; groupValue?: string }> = memo((props) => {
  const { id, bindId, dropInChild, position, viewId, groupValue, subGroupValue } = props;

  const isSelected = useIsSelected(id, viewId, groupValue, subGroupValue);

  const isRecord = useIsRecord(id);
  const showBG = useShowBG(id, isRecord, dropInChild);

  // @TODO 待优化，数据量大会造成不必要的 rerender
  const isDragging = useIsDragging();

  const showCover = useMemo(() => {
    const block = cache.blocks[id];

    if (!block) return false;

    const type = getRefBlock(id)?.type ?? block.type;

    if (isDragging) {
      if (position === 'middle') {
        return isPageLike(type) || isLikeFoldBlock(type);
      }
      return showBG;
    }

    const isImageBlock =
      block.type === BlockType.FILE &&
      block.data.display === 'image' &&
      (block.data.link || block.data.ossName);

    return isSelected && bindId && !isImageBlock;
  }, [id, isDragging, isSelected, bindId, position, showBG]);

  return (
    <div
      className={cx(
        'absolute inset-0 pointer-events-none z-10 block-background animate__animated bg-active_color_10 rounded-sm transition-opacity',
        !showCover && 'opacity-0'
      )}
    ></div>
  );
}, deepEqual);
