import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { UNNAMED_USER } from 'src/common/const';
import { convertToLastEditTime } from 'src/common/utils/formatter';
import { usePageTotalCount } from 'src/hooks/page/use-page-word-count';
import { CollapseCount } from 'src/mind-map/component/collapse-count';
import { useGetPageId } from 'src/utils/getPageId';

export const UpdateUserInfo: FC<{
  className?: string;
  name?: string;
  updatedAt?: number;
  showWordCount?: boolean;
  showMindNodeCount?: boolean;
  isByAI?: boolean;
}> = ({ className, name, updatedAt, showWordCount, showMindNodeCount, isByAI }) => {
  const pageId = useGetPageId();
  const { wordCount, blockCount } = usePageTotalCount(pageId);

  return (
    <div className={cx('px-4 pt-1 text-t5 text-grey3', className)}>
      {showWordCount && <div>字数统计: {wordCount}</div>}
      <div>块数统计: {blockCount}</div>
      {showMindNodeCount && (
        <div>
          <span>节点统计: </span>
          <CollapseCount blockId={pageId} mindMapId={pageId} />
        </div>
      )}
      <div className={cx('text-ellipsis', { 'mt-3': showWordCount || showMindNodeCount })}>
        {'最后编辑人'} {name || UNNAMED_USER}
      </div>
      <div>
        {'最后编辑于'} {convertToLastEditTime(updatedAt)}
      </div>
      {/* {isByAI && <AiAlert />} */}
    </div>
  );
};

// export const AiAlert = () => {
//   if (__BUILD_IN__) return null;
//   return (
//     <div className="pt-3 flex items-center border-t mt-3">
//       <Icon name="IcNote" size="middle" className="mr-1" />
//       当前“块”可能含有 AI 生成内容
//     </div>
//   );
// };
