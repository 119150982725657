import { cx } from '@flowus/common/cx';
import { dropRight, takeRight } from 'lodash-es';
import type { FC, MouseEvent } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { ILLEGAL_TEXT } from 'src/common/const';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { HistoryMenu } from 'src/components/history-menu';
import { IconTrigger } from 'src/components/icon-trigger';
import { segmentsToText } from 'src/editor/utils/editor';
import { useGetBreadcrumb } from 'src/hooks/block/use-navs';
import { goBackInfo } from 'src/hooks/page/use-save-layout-info';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { getUntitledName } from 'src/utils/get-untitled-name';
import { useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { useIsInRight, useIsMaster } from 'src/utils/right-utils';
import { TocShowButton } from '../aside/toc-trigger';
import { SegmentPlainText } from '../aside/toc/helper';
import { useNormalizePath } from 'src/hooks/page';
import { SeoAnchorLink } from '@flowus/common/components/seo-anchor-link';

interface BreadcrumbProps {
  pageId?: string; // 默认为当前路由的页面
  ancestorId?: string; // 面包屑截止至ancestorId
  styleVariant?: 'default' | 'messages'; // 'messages' 指站内消息
  showHistoryButton?: boolean;
  hideIfPageIsRoot?: boolean;
}
/**
 *
 * 主页面/模板中心预览页面包屑
 */
export const Breadcrumb: FC<BreadcrumbProps> = (props) => {
  const routerPageId = useGetPageId();
  const pageId = props.pageId ?? routerPageId;
  const styleVariant = props.styleVariant ?? 'default';
  const getBreadcrumb = useGetBreadcrumb();
  const openModal = useOpenModal();
  const isMaster = useIsMaster();
  const isInRight = useIsInRight();

  const breadcrumb = getBreadcrumb(pageId, true, props.ancestorId);

  if (props.hideIfPageIsRoot && breadcrumb.length === 1) return null;

  const [head, ...rest] = breadcrumb;

  if (!head) return null;
  if (isInRight) return null;

  const tail = takeRight(rest, 2);
  const middle = dropRight(rest, 2);

  const expandCompact = (event: MouseEvent) => {
    openModal.dropdown({
      popcorn: event.currentTarget,
      placement: 'bottom',
      offset: [0, 8],
      content({ onCloseModal }) {
        return (
          <div className="py-2 next-modal w-60">
            {middle.map(({ uuid }) => (
              <BreadRow key={`bread-${uuid}`} uuid={uuid} onCloseModal={onCloseModal} />
            ))}
          </div>
        );
      },
    });
  };

  const compact =
    breadcrumb.length > 3 ? (
      <>
        <Tooltip
          popup="查看折叠的页面路径"
          className="w-5 h-5 px-1 mx-1 text-black rounded text-t2 animate-hover"
          onClick={expandCompact}
        >
          ...
        </Tooltip>
        <Slash />
      </>
    ) : null;

  return (
    <nav className="flex items-center overflow-x-auto">
      {isMaster && styleVariant !== 'messages' && props.showHistoryButton && (
        <>
          <TocShowButton />
          <HistoryMenu />
        </>
      )}
      <BreadItem key={`bread-${head.uuid}`} uuid={head.uuid} styleVariant={styleVariant} />
      {compact}
      {(styleVariant === 'messages' ? tail.slice(0, -1) : tail).map(({ uuid }, index) => (
        <BreadItem
          key={`bread-${uuid}`}
          uuid={uuid}
          last={index === tail.length - 1}
          styleVariant={styleVariant}
        />
      ))}
    </nav>
  );
};
interface Props {
  uuid: string;
  last?: boolean;
  styleVariant?: 'default' | 'messages'; // 'messages' 指站内消息
}

export const BreadRow: FC<Props & { onCloseModal: () => void }> = ({ uuid, onCloseModal }) => {
  const page = usePickBlock(uuid, ['data'], ['segments']);
  // 被风控了
  const { illegal } = usePermissions(uuid);
  const path = useNormalizePath(uuid);
  if (!page) return null;

  return (
    <NavLink
      to={path}
      onClick={onCloseModal}
      className={cx(
        'flex items-center px-4 py-2 cursor-pointer animate-hover',
        illegal && 'opacity-30'
      )}
    >
      <IconTrigger
        trigger={false}
        blockId={uuid}
        className="mr-1"
        defaultIcon={<BlockDefaultIcon uuid={uuid} />}
      />
      <span className="text-t2 text-ellipsis">
        {illegal ? ILLEGAL_TEXT : segmentsToText(page.data.segments) || getUntitledName(page.type)}
      </span>
    </NavLink>
  );
};

const BreadItem: FC<Props> = ({ uuid, last, styleVariant }) => {
  const page = usePickBlock(uuid, ['data'], ['segments', 'icon']);
  const history = useHistory();

  // 被风控了
  const { illegal } = usePermissions(uuid);
  const pathname = useNormalizePath(uuid);
  if (!page) return null;

  const title = illegal ? ILLEGAL_TEXT : <SegmentPlainText uuid={uuid} />;

  return (
    <>
      <SeoAnchorLink
        href={uuid}
        className={cx(
          'flex items-center rounded animate-hover',
          styleVariant === 'messages' ? 'text-grey3 text-t4 px-1' : 'mx-2 text-black text-t2 p-1',
          illegal && 'opacity-30'
        )}
        onPointerDown={() => {
          history.push(pathname);
          // 点击面包屑需要恢复位置
          goBackInfo.lastGoBackPath = pathname;
          goBackInfo.tmpDisable = true;
          setTimeout(() => {
            goBackInfo.tmpDisable = false;
          }, 500);
        }}
      >
        {page.data.icon?.value && styleVariant !== 'messages' && (
          <IconTrigger
            blockId={page.uuid}
            trigger={false}
            className="mr-1"
            iconSize={18}
            defaultIcon={<BlockDefaultIcon uuid={uuid} size="middle" />}
          />
        )}
        <Tooltip
          popup={title}
          className={cx(`block text-ellipsis`, last ? 'max-w-[238px]' : 'max-w-[166px]')}
        >
          {title}
        </Tooltip>
      </SeoAnchorLink>
      <Slash />
    </>
  );
};

const Slash = () => <span className="w-1 pointer-events-none text-grey4 last:hidden">/</span>;
