import { cx } from '@flowus/common/cx';
import type { FC } from 'react';
import { memo, useEffect, useRef } from 'react';
import { throttle } from 'lodash-es';
import { TableClassName } from 'src/bitable/const';
import { useBitable } from 'src/bitable/context';
import { useBitableLoadMoreContext } from 'src/bitable/hooks';

export const FloatContainer: FC = memo((props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { embed } = useBitable();
  const { pageNo } = useBitableLoadMoreContext();

  useEffect(() => {
    if (!embed) return;
    const containerNode = containerRef.current;
    const embedTable = containerNode?.parentElement;
    const nextSpacePage = containerNode?.closest('.next-space-page');
    if (!containerNode || !embedTable || !nextSpacePage) return;

    const embedTablePaddingBottom = parseInt(
      getComputedStyle(embedTable).getPropertyValue('padding-bottom'),
      10
    );

    const handleScroll = () => {
      const embedTableRect = embedTable.getBoundingClientRect();
      const nextSpacePageRect = nextSpacePage.getBoundingClientRect();

      if (embedTableRect.bottom > nextSpacePageRect.bottom) {
        let translateY = Math.abs(nextSpacePageRect.bottom - embedTableRect.bottom);
        const headerNode = embedTable.querySelector(`.${TableClassName.tableHeader}`);
        const headerRect = headerNode?.getBoundingClientRect();
        if (
          headerRect &&
          nextSpacePageRect.bottom - headerRect.bottom < containerNode.clientHeight
        ) {
          translateY =
            translateY -
            (containerNode.clientHeight - (nextSpacePageRect.bottom - headerRect.bottom));
        }

        translateY -= embedTablePaddingBottom;
        containerNode.style.transform = `translate3d(0,-${Math.round(translateY)}px,0)`;
        containerNode.style.borderTopWidth = '1px';
      } else {
        containerNode.style.transform = 'none';
        containerNode.style.borderTopWidth = '0px';
      }
    };

    const _handleScroll = throttle(handleScroll, 10);
    setTimeout(() => {
      _handleScroll();
    }, 100);
    nextSpacePage.addEventListener('scroll', _handleScroll);
    window.addEventListener('resize', _handleScroll);
    return () => {
      nextSpacePage.removeEventListener('scroll', _handleScroll);
      window.removeEventListener('resize', _handleScroll);
    };
  }, [embed, pageNo]);

  return (
    <div
      className={cx(
        'group sticky bottom-0 w-full min-w-max transition-none print:hidden bg-white1 z-[12]',
        TableClassName.tableFooter
      )}
      ref={containerRef}
    >
      {props.children}
    </div>
  );
});
