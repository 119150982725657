/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
/* eslint-disable react-hooks/rules-of-hooks */
import { useStateRef } from '@flowus/common/hooks/react-utils';
import { fastEqual } from '@flowus/common/utils/tools';
import { useDeepCompareMemo } from '@react-hookz/web';
import { useDebounceEffect, useDeepCompareEffect, useMemoizedFn } from 'ahooks';
import { debounce, throttle } from 'lodash-es';
import { useCallback, useMemo, useRef } from 'react';
import { useStore } from 'react-redux';
import type { RootState } from 'src/redux/types';
import { validate } from 'uuid';
import { useFuSelector } from '../store-context/use-fine-context-selector';
import { debugSelector, useIsEnableRedux } from './common';
import { useObsStoreContext } from './context';
import { observableStoreOp } from './map';
import {
  getIgnoreDeepKey,
  getIgnoreOtherData,
  getIsCutKey,
  getPermissionKey,
  getSelectedBlocksKey,
  getSelectedBlocksKeyByBlock,
  getSelectedBlocksKeyBySync,
  getSelectedBlocksKeyByView,
  getSelectedCellKey,
  isSelectPrefixId,
  isSimpleTableKey,
  prefixSimpleTable,
  RxPatchKey,
} from './patch';
import type { ObservableStoreProps, ObservableStoreSelector } from './types';
import { DEBOUNCE_WAIT_NUM, LEADING, THROTTLE_WAIT_NUM, TRAILING } from './types';

const useCreateProxyStore = (opt?: { log?: boolean }) => {
  const reduxStore = useStore();

  return useCallback(
    (
      storeKey: 'blocks' | 'discussions' | 'comments' | 'simpleTable' | 'collectionViews' | 'users',
      callback?: (key: string) => void
    ) => {
      return new Proxy((reduxStore.getState() as RootState)[storeKey], {
        get(target, p: string, receiver) {
          if (opt?.log) {
            // 输出log
          }
          callback?.(p);
          return Reflect.get(target, p, receiver);
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

/**
 * 用于订阅store中的数据，当数据发生变化时，会自动更新组件
 * @author kcsx
 * @param selector 获取想要的数据，参数中的`blocks`是一个proxy对象，会记录下来使用到的blockId，当block发生变化时会接收通知
 * @param dep 依赖项，如果传入的是blockId，会自动订阅block
 * @param opt 额外的选项
 */
export function useObservableStore<T>(
  selector: ObservableStoreSelector<T>,
  dep: ObservableStoreProps['dep'],
  opt?: ObservableStoreProps['opt']
): T {
  // #region 开关，使用原版 redux
  const isEnableRedux = useIsEnableRedux();
  if (!opt?.useRx && (isEnableRedux || opt?.useRedux)) {
    return useFuSelector((state) => {
      debugSelector(selector, { ...opt, dep });
      return selector(state);
    }, opt);
  }
  // #endregion

  // #region 声明
  const {
    obsSelectBlocks = [],
    obsSelectCell = [],
    wait,
    waitMode = 'throttle',
    obsSimpleTable,
    log,
    equal = fastEqual,
    leading = LEADING,
    trailing = TRAILING,
    enable = true,
    onlyPermissions,
    onlyCut,
    allUser,
    hasSelectBlock,
    allDiscussions,
    ignoreDeep,
    ignoreOtherData = true,
    enableProxyState = true,
  } = opt || {};

  const obsContext = useObsStoreContext();
  const enableAuditTime = opt?.enableAuditTime ?? obsContext.enableAuditTime ?? true;

  const reduxStore = useStore();
  const createProxyStore = useCreateProxyStore({ log });
  const idsRef = useRef(new Set<string>());
  const [ids, setIds] = useStateRef([...idsRef.current]);

  const memoDeps = [
    enable,
    dep,
    ids,
    obsSelectBlocks,
    obsSelectCell,
    obsSimpleTable,
    wait,
    waitMode,
  ];

  /** 把它加载内存泄露的 case */
  const isUnmounted = useRef(false);
  /** 从 0 开始，允许前两次重复 patch，避免些闪烁的问题 */
  const isInit = useRef(0);

  /** 保存 selector 得到的结果 */
  const selectorValueRef = useRef<T>();
  const depsMemo = useRef<any[]>([]);
  /** 触发组件 render */
  const [_, setState] = useStateRef(0);

  const patchState = useCallback(() => {
    if (isUnmounted.current) {
      return;
    }

    // 如果 use-memo 已经更新过了，就不再触发对比和计算
    // use-memo 先计算出最新的 selector value 了，ref 的就是旧的。所以这俩不相等
    if (isInit.current > 2 && !fastEqual(depsMemo.current, memoDeps)) {
      // 跳过这次之后，就得保持一致，避免阻碍正常 obs
      depsMemo.current = memoDeps;
      return;
    }

    const newState = runSelector(`patch`);

    if (equal(selectorValueRef.current, newState)) {
      return;
    }

    isInit.current++;
    selectorValueRef.current = newState;
    depsMemo.current = memoDeps;
    setState(isInit.current);
  }, []);

  const patchStateDebounce = useRef(
    debounce(patchState, wait ?? DEBOUNCE_WAIT_NUM, {
      leading,
      trailing,
    })
  );

  const patchStateThrottle = useRef(
    throttle(patchState, wait ?? THROTTLE_WAIT_NUM, {
      leading,
      trailing,
    })
  );

  const subscribeFn = useMemo(() => {
    const _subscribe = (id: string) => {
      if (!wait || waitMode === 'none') {
        patchStateDebounce.current.cancel();
        patchStateThrottle.current.cancel();
        patchState();
        return;
      }

      const isSelectId = isSelectPrefixId(id) || isSimpleTableKey(id);
      const isThrottle = !!isSelectId || waitMode === 'throttle';

      if (isThrottle) {
        patchStateThrottle.current();
        return;
      }

      patchStateThrottle.current();
    };
    return enableAuditTime ? debounce(_subscribe, 0, { leading: true }) : _subscribe;
    // return _subscribe;
  }, [enableAuditTime, patchState, wait, waitMode]);

  useDebounceEffect(
    () => {
      if (isUnmounted.current) return;
      setIds([...idsRef.current]);
    },
    [idsRef.current],
    { wait: 2000 }
  );

  const callbackByObj = useCallback((id: string) => {
    if (!idsRef.current.has(id) && validate(id)) {
      idsRef.current.add(id);
    }
  }, []);

  const runSelector = useMemoizedFn((type?: string): T => {
    debugSelector(selector);
    const store = reduxStore.getState() as RootState;

    const __proxy = new Proxy(store, {
      get(target, p: any, receiver) {
        if (
          typeof p === 'string' &&
          /users|blocks|simpleTable|discussions|comments|collectionViews|ui/.test(p)
        ) {
          // @ts-ignore type
          return createProxyStore(p, callbackByObj);
        }
        return Reflect.get(target, p, receiver);
      },
    });

    return selector(
      !enableProxyState
        ? store
        : ({
            ...__proxy,
            discussions: allDiscussions ? store.discussions : __proxy.discussions,
          } as RootState)
    );
  });
  // #endregion

  // 更新订阅，比 memo 慢一步
  useDeepCompareEffect(() => {
    isUnmounted.current = false;
    const compIds = new Set(ids);
    const obsMap: Map<string, Function> = new Map();

    const run = () => {
      // #region 额外订阅 id
      // 订阅deps中的 blockId
      dep.forEach((id) => {
        if (compIds.has(id) || !validate(id)) return;
        compIds.add(id);
      });

      // 订阅 simpleTable
      if (obsSimpleTable) {
        compIds.add(prefixSimpleTable);
      }

      // 选中block
      obsSelectBlocks.forEach((item) => {
        if (item.syncId && item.blockId && item.viewId) {
          const key = getSelectedBlocksKey(item);
          compIds.add(key);
        } else {
          // 只监听blockId
          if (item.blockId) {
            const key = getSelectedBlocksKeyByBlock(item.blockId);
            compIds.add(key);
          }

          // 监听viewId
          if (item.viewId) {
            const key = getSelectedBlocksKeyByView(item.viewId);
            compIds.add(key);
          }

          // 监听syncId
          if (item.syncId) {
            const key = getSelectedBlocksKeyBySync(item.syncId);
            compIds.add(key);
          }
        }
        if (item.all) {
          const key = getSelectedBlocksKey({ all: true });
          compIds.add(key);
        }
      });

      // 选中cell
      obsSelectCell.forEach((item) => {
        if (item.recordId && item.viewId && item.propertyId) {
          const key = getSelectedCellKey(item);
          compIds.add(key);
        } else {
          if (item.recordId) {
            const key = getSelectedCellKey({ recordId: item.recordId });
            compIds.add(key);
          }

          if (item.viewId) {
            const key = getSelectedCellKey({ viewId: item.viewId });
            compIds.add(key);
          }

          if (item.propertyId) {
            const key = getSelectedCellKey({ propertyId: item.propertyId });
            compIds.add(key);
          }
        }
        if (item.all) {
          const key = getSelectedCellKey({ all: true });
          compIds.add(key);
        }
      });

      if (allUser) {
        compIds.add(RxPatchKey.ALL_USERS);
      }

      if (allDiscussions) {
        compIds.add(RxPatchKey.ALL_DISCUSSIONS);
      }

      if (hasSelectBlock) {
        compIds.add(RxPatchKey.HAS_SELECT_BLOCK);
      }

      // 弥补一些额外的 case
      const store = reduxStore.getState() as RootState;
      compIds.forEach((id) => {
        // 监听 view 的同时应该也监听多维表
        const isView = store.collectionViews[id];
        if (isView?.parentId) {
          compIds.add(isView.parentId);
        }
      });
      // #endregion

      // #region 开始订阅
      compIds.forEach((id) => {
        if (!id || id === 'undefined') return null;

        if (validate(id)) {
          if (onlyCut) {
            // 只监听剪切的数据
            id = getIsCutKey(id);
          } else if (onlyPermissions) {
            // 只监听 permission 属性发生变化。目前仅供 use-permission 使用
            id = getPermissionKey(id);
          } else if (ignoreDeep) {
            // 是否只监听对象的一级变化，不观察二级数据
            id = getIgnoreDeepKey(id);
          } else if (ignoreOtherData) {
            // 不检测基础数据
            id = getIgnoreOtherData(id);
          }
        }

        if (obsMap.has(id)) return;

        // 使用新的订阅机制
        const unsubscribe = observableStoreOp.subscribe(id, subscribeFn);
        obsMap.set(id, unsubscribe);
      });
      // #endregion
    };

    if (enable) {
      run();
    }

    return () => {
      isUnmounted.current = true;
      obsMap.forEach((fn) => fn());
      patchStateThrottle.current.cancel();
      patchStateDebounce.current.cancel();
    };
  }, memoDeps);

  // memo props 依赖更新时会先执行 memo
  useDeepCompareMemo(() => {
    if (!enable) {
      return;
    }
    // if (log) {
    //   console.log('update-memo');
    // }
    if (isUnmounted.current) {
      return;
    }
    // memo 比 effect 快，所以 state + 1 ，避免 effect 再次执行
    selectorValueRef.current = runSelector('memo');
  }, memoDeps);

  return selectorValueRef.current as T;
}
