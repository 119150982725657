import { Role } from '@flowus/common';
import { setAiChatPages } from '@flowus/common/chat-ai/hooks/store';
import { useOpenLiteConversation } from '@flowus/common/chat-ai/hooks/use-lite-conversation-hook';
import { MindMapContext } from '@flowus/mind-map';
import type { BlockDTO } from '@next-space/fe-api-idl';
import { QASearchSource, PermissionRole } from '@next-space/fe-api-idl';
import { contentToString, sliceContent } from '@next-space/fe-inlined';
import type { MouseEvent } from 'react';
import { useContext, useMemo } from 'react';
import { map, throttleTime } from 'rxjs';
import { useObservable } from 'rxjs-hooks';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { createModel } from 'src/common/create-model';
import { ColorPicker } from 'src/components/color-picker';
import { INLINE_COLOR_PICKER_DATA_TEXT_COLOR } from 'src/components/color-picker/default-data';
import {
  AIBlockList,
  MindNodeTurnWhiteList,
  TurnBlockList,
} from 'src/editor/component/menu-list/const';
import { TransformBlockList } from 'src/editor/component/menu-list/transform-block-list';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { useShortcutCode } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-code';
import { useShortcutComment } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-comment';
import { useShortcutEquation } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-equation';
import { useShortcutFormat } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-format';
import { useShortcutInlinePage } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-inline-page';
import { useShortcutLink } from 'src/hooks/editor/toolbar-shortcut/use-shortcut-link';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useCurrentSpace } from 'src/hooks/space';
import { useUserToolbarShortcut } from 'src/hooks/user';
import { cache } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { useGetPageId } from 'src/utils/getPageId';
import { useSetDrawerOpenState } from 'src/views/main/drawer/shared';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { getBlockMenu } from '../../../component/menu-list/menu-item';
import { useSyncId } from '../../plugin/sync-block-context';
import { AIEditorFrom, AIEditorScene } from '../ai-editor/const';
import { useOpenAIEditor } from '../ai-editor/use-open-ai-editor';
import type { ToolbarContentProps, ToolbarFnType } from './type';
import { ToolbarBtnType, toolbarPrefixId } from './type';

const getMoreBtn = () => document.getElementById(toolbarPrefixId + ToolbarBtnType.More);

const useToolbarContext = (props: ToolbarContentProps) => {
  const { uuid, model, comments = false, segmentType, shortcutToolBar } = props;
  const pageScene = usePageScene();
  const { queryResult, setFakeFocus } = shortcutToolBar;
  const pageId = useGetPageId();
  const { role } = usePermissions(pageId);
  const { enableAI } = useEnableAI();
  const openModal = useOpenModal();
  const shortcutEquation = useShortcutEquation({ model });
  const shortcutFormat = useShortcutFormat();
  const shortcutCode = useShortcutCode({ model });
  const shortcutLink = useShortcutLink(uuid, { model, setFakeFocus });
  const shortcutInlinePage = useShortcutInlinePage(uuid, { model, setFakeFocus });
  const shortcutComment = useShortcutComment(uuid);
  const focusEditableAt = useFocusEditableByBlockId();
  const updateTask = useUpdateTask();
  const isInMindMap = !!useContext(MindMapContext);
  const openAIEditor = useOpenAIEditor();
  const openLiteConversation = useOpenLiteConversation();
  const setDrawerState = useSetDrawerOpenState();
  const currentSpace = useCurrentSpace();
  const syncId = useSyncId();
  const userToolbarShortcut = useUserToolbarShortcut();
  const { disableLink, inLink, disableEquation } = queryResult;

  const format = useObservable(
    () =>
      model.onFormatChange.pipe(
        throttleTime(20),
        map(() => model.format)
      ),
    model.format,
    [model]
  );

  const readonly = useObservable(
    () => model.onReadonlyChange.pipe(map(() => model.readonly)),
    model.readonly,
    [model]
  );

  const blockMenu = getBlockMenu(cache.blocks[uuid]?.type, {
    display: cache.blocks[uuid]?.data.display,
    level: cache.blocks[uuid]?.data.level,
  });

  const showCommentBtn = useMemo(
    () =>
      comments &&
      Role.contains(role, PermissionRole.COMMENTER) &&
      // 分享页也可以评论
      (pageScene === PageScene.MAIN || pageScene === PageScene.SHARE),
    [comments, pageScene, role]
  );

  const showAI = useMemo(
    () =>
      enableAI &&
      ((blockMenu?.type && AIBlockList.includes(blockMenu?.type)) ||
        segmentType === 'description') &&
      !queryResult.disableAI,
    [blockMenu?.type, enableAI, queryResult.disableAI, segmentType]
  );

  /** 功能禁止点击 */
  const disableClick: Record<`disable${ToolbarFnType}`, boolean> = {
    disableAI: !showAI,
    disableAiExplain: !showAI || !__BUILD_IN__,
    disableTurn:
      !blockMenu ||
      blockMenu.type === undefined ||
      !(isInMindMap ? MindNodeTurnWhiteList : TurnBlockList).includes(blockMenu.type),
    disableLinkPage: !!inLink || disableLink || segmentType === 'description',
    disableUrl: disableLink,
    disableEquation,
    disableComment: !showCommentBtn,
    disableBold: false,
    disableItalic: false,
    disableUnderline: false,
    disableDelete: false,
    disableCode: false,
    disableColor: false,
    disableMore: false,
  };

  /** 功能点击事件 */
  const clickFn: Record<`click${ToolbarFnType}`, (event: MouseEvent | Event) => void> &
    Record<`clickCustomPrompt`, (id: string, ev: MouseEvent) => void> = {
    clickColor: (event) => {
      if (disableClick.disableColor) {
        message.error('不可设置颜色');
        return;
      }
      const { selection } = model;
      if (selection === null) return;
      // 阻止pageDoc/index.ts里的handleClick
      event.stopPropagation();
      const target = event.target || getMoreBtn();
      bizTracker.event('rtf_choose', { rtf_name: 'color' });
      openModal.dropdown({
        popcorn: target as HTMLElement,
        offset: [0, 8],
        placement: 'left',
        closeBeforeCallBack: () => void model.requestFocus(),
        content: ({ onCloseModal }) => (
          <ColorPicker
            items={INLINE_COLOR_PICKER_DATA_TEXT_COLOR}
            onColorSelect={({ isBgColor, colorkey }) => {
              void model.requestFocus();
              model.performChange((ctx) => {
                ctx.select(selection.offset, selection.endOffset);
                if (isBgColor) {
                  ctx.applyFormat({
                    color: undefined,
                    backgroundColor: colorkey,
                  });
                } else {
                  ctx.applyFormat({
                    backgroundColor: undefined,
                    color: colorkey,
                  });
                }
              });
              onCloseModal();
            }}
          />
        ),
      });
    },
    clickUrl: (event) => {
      if (disableClick.disableUrl) {
        message.error('不可设置链接');
        return;
      }
      const { selection } = model;
      if (selection === null) return;
      // 阻止pageDoc/index.ts里的handleClick
      event.stopPropagation();
      event.preventDefault();
      bizTracker.event('rtf_choose', { rtf_name: '链接' });
      shortcutLink();

      // 积分任务
      void updateTask(ActivityIds.CREATE_LINK, ActivitiesListType.advancedList);
    },
    clickAI: (event) => {
      event.stopPropagation();
      event.preventDefault();
      if (disableClick.disableAI) {
        message.error('不可使用AI功能');
        return;
      }

      const popcorn = (model as any).editorElement;
      if (!popcorn) return;

      const { selection } = model;
      if (!selection) return;

      const { offset, endOffset } = selection;

      if (segmentType === 'description') {
        openAIEditor({
          popcorn,
          blockId: pageId,
          model,
          from: AIEditorFrom.RtfMenu,
          selection: { start: offset ?? 0, end: endOffset ?? 0 },
          editorScene: AIEditorScene.TextSelected,
          isDescription: true,
          isBitable: true,
        });
        return;
      }

      openAIEditor({
        blockId: uuid,
        popcorn,
        model,
        selection: { start: offset ?? 0, end: endOffset ?? 0 },
        editorScene: AIEditorScene.TextSelected,
        from: AIEditorFrom.RtfMenu,
      });
    },
    clickAiExplain: (event) => {
      event.stopPropagation();
      event.preventDefault();
      if (disableClick.disableAiExplain || !model.selection) {
        message.error('不可使用AI功能');
        return;
      }

      const selection = model.active ? model.selection : null;
      if (!selection) return;

      const left = selection.offset;
      const right = selection.endOffset;

      const text = contentToString(sliceContent(model.content, left, right));

      setAiChatPages({
        [pageId]: cache.blocks[pageId] as BlockDTO,
      });

      void openLiteConversation({
        keywords: `解释: ${text}`,
        parentId: pageId,
        spaceId: currentSpace.uuid,
        // searchBlocks: [
        //   {
        //     type: 'page',
        //     uuid: pageId,
        //   },
        // ],
        qaSearchSource: QASearchSource.AI_MODEL,
        onOpen: () => {
          setDrawerState('ai-chat');
        },
      });
    },
    clickBold: (event) => {
      bizTracker.event('rtf_choose', { rtf_name: 'bold' });
      event.stopPropagation();
      shortcutFormat(uuid, 'bold');
    },
    clickItalic: (event) => {
      bizTracker.event('rtf_choose', { rtf_name: 'italic' });
      event.stopPropagation();
      shortcutFormat(uuid, 'italic');
    },
    clickUnderline: (event) => {
      bizTracker.event('rtf_choose', { rtf_name: 'underline' });
      event.stopPropagation();
      shortcutFormat(uuid, 'underline');
    },
    clickDelete: (event) => {
      bizTracker.event('rtf_choose', { rtf_name: 'deleteline' });
      event.stopPropagation();
      shortcutFormat(uuid, 'lineThrough');
    },
    clickCode: (event) => {
      event.stopPropagation();
      bizTracker.event('rtf_choose', { rtf_name: 'code' });
      shortcutCode(uuid);
    },
    clickEquation: (event) => {
      if (disableClick.disableEquation) {
        message.error('不可插入公式');
        return;
      }
      const { selection } = model;
      if (selection === null) return;
      event.stopPropagation();
      bizTracker.event('rtf_choose', { rtf_name: 'equation' });
      shortcutEquation(uuid);
    },
    clickComment: (event) => {
      if (disableClick.disableComment) {
        message.error('不可评论');
        return;
      }
      event.stopPropagation();
      event.preventDefault();
      shortcutComment();
    },
    clickLinkPage: (event) => {
      if (disableClick.disableLinkPage) {
        message.error('不可链接页面');
        return;
      }
      const { selection } = model;
      if (selection === null) return;
      // 阻止pageDoc/index.ts里的handleClick
      event.stopPropagation();
      event.preventDefault();
      shortcutInlinePage();
    },
    clickTurn: (event) => {
      if (disableClick.disableTurn) {
        message.error('不可转换');
        return;
      }
      event.stopPropagation();
      const { selection } = model;
      if (selection === null) return;
      const target = event.target || getMoreBtn();
      openModal.dropdown({
        popcorn: target as HTMLElement,
        placement: 'left',
        offset: [0, 8],
        content: ({ onCloseModal }) => (
          <TransformBlockList
            syncId={syncId}
            focusEditableAt={focusEditableAt}
            onCloseModal={onCloseModal}
            blockId={uuid}
            selectItem={() => {
              focusEditableAt(uuid, selection.endOffset);
            }}
          />
        ),
      });
    },
    clickCustomPrompt: (_: string, __: MouseEvent) => {
      // 使用 id 拿到自定义的 prompt，再进行唤起 ai 弹窗
    },
    clickMore: () => {},
  };

  return {
    queryResult,
    clickFn,
    format,
    disableClick,
    blockMenu,
    readonly,
    userToolbarShortcut,
  };
};

export type ToolbarModelType = ReturnType<typeof useToolbarContext>;

export const ToolbarModel = createModel(useToolbarContext);
