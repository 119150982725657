import type { TableOfContentData } from '@tiptap-pro/extension-table-of-contents';
import { createContext, useContext } from 'react';
import { SIDE_BAR_WIDTH } from '../const';
import type { ConversationContextType, QuestionContextType } from './types';
import { ChatUIStyle, AI_PAGE_ID, SideBarType } from './types';

export interface ChatContextType {
  conversationId: string;
  questionId: string;
  chatId: string;
  toc: TableOfContentData;
  setTOC: (items: TableOfContentData) => void;
}

export const ChatContext = createContext<ChatContextType>({
  conversationId: '',
  questionId: '',
  chatId: '',
  toc: [],
  setTOC: () => {},
});

export const useChatContext = () => useContext(ChatContext);

export const defaultConversationContext: ConversationContextType = {
  conversationId: AI_PAGE_ID,
  sideBarType: SideBarType.OUTLINE,
  style: ChatUIStyle.DEFAULT,
  spaceId: '',
  sideBarWidth: SIDE_BAR_WIDTH,
  renderPageIcon: () => null,
  navigateToChat: () => {},
  updateContext: () => {},
  onSaveArticle: () => {},
  // insertParagraph: () => {},
  // openUpgrade: () => {},
  // onSummarize: () => {},
  // onRenderBlockPreview: () => null,
};

export const ConversationContext = createContext<ConversationContextType>(
  defaultConversationContext
);

export const useConversationContext = () => useContext(ConversationContext);

export const QuestionContext = createContext<QuestionContextType>({
  conversationId: AI_PAGE_ID,
  questionId: '',
  index: 0,
  totalIndex: 0,
  curChatIndex: 0,
  totalChats: 0,
  handlePrevious: () => {},
  handleNext: () => {},
});

export const useQuestionContext = () => useContext(QuestionContext);
