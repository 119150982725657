import { cx } from '@flowus/common/cx';
import { Dropdown } from '@flowus/common/next-modal/dropdown';
import type { PermissionDTO } from '@next-space/fe-api-idl';
import { DateFormat, DaySetting, PermissionType, TimeFormat } from '@next-space/fe-api-idl';
import dayjs from 'dayjs';
import isHotkey from 'is-hotkey';
import { omit } from 'lodash-es';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { DayModifiers, Modifier } from 'react-day-picker';
import DayPicker from 'react-day-picker';
import { Icon } from 'src/common/components/icon';
import { Switch } from 'src/common/components/switch';
import { Tooltip } from 'src/common/components/tooltip';
import { PRIORITY_PORTAL, globalListenerHelper } from 'src/common/utils/global-listener-helper';
import { OptionItem } from 'src/components/option-item';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useReadonly } from 'src/hooks/page';
import { useIsGuest } from 'src/hooks/share/use-permission-utils';
import { useCurrentSpaceDaySetting } from 'src/hooks/space';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { getSpaceGuests } from 'src/hooks/space/get-space-guests';
import { $currentUserCache } from 'src/services/user/current-user';
import { MONTHS, WEEKDAYS_LONG, WEEKDAYS_SHORT } from 'src/utils/date-utils';
import { DateInput } from './date-input';
import { findMatchReminderIndex, getValidDateFormat } from './find-match';
import { FormatSetting } from './format-setting';
import { RemindPerson } from './remind-person';
import './style.css';
import './ext.css';
import type { PickerDate } from './types';
import { remindDayOptions, remindTimeOptions } from './types';
import { TIME_FORMAT } from 'src/common/const';
import { DateTimePickerNew } from './date-time-picker-new';

const NavbarElement: FC<{
  onPreviousClick: (callback?: () => void) => void;
  onNextClick: (callback?: () => void) => void;
}> = ({ onPreviousClick, onNextClick }) => {
  return (
    <div className="absolute right-4 flex justify-between top-5">
      <Tooltip className="mr-2 rounded-sm animate-hover">
        <Icon name="IcArrowDateBack" size="middle" onClick={() => onPreviousClick()} />
      </Tooltip>
      <Tooltip className="rounded-sm animate-hover">
        <Icon name="IcArrowDateNext" size="middle" onClick={() => onNextClick()} />
      </Tooltip>
    </div>
  );
};

const CaptionElement: FC<{ date: Date }> = ({ date }) => (
  <div className="table-caption mt-2.5 h-10">
    <span className="flex items-center h-full text-black text-t2-bold">
      {dayjs(date).format('YYYY年MM月')}
    </span>
  </div>
);

interface Props {
  dateInfo: PickerDate;
  uiOption?: {
    /** 是否有时间点这个选项 default true*/
    hasSwitchTimeOption?: boolean;
    /** 是否有结束时间,这个暂未实现,可参考已实现的DateTimePickerExt组件逻辑 default false */
    hasEndTime?: boolean;
    /** 是否有时间格式设定选项 default true*/
    hasFormatSetting?: boolean;
    /** 去掉shadow default false*/
    noShadow?: boolean;
    /** 是否设置成提醒 default false*/
    hasRemind?: boolean;
    /** 置灰某些天数 */
    disabledDays?: Modifier | Modifier[];
    /** footer */
    footer?: React.ReactNode;
  };
  onChange: (date: PickerDate) => void;
  onClear?: () => void;
  closeDatePicker?: () => void;
}
export const DateTimePicker: FC<Props> = (props) => {
  return <DateTimePickerNew {...props} />;
};
