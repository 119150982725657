/* eslint-disable react-hooks/rules-of-hooks */
import { useObservableBlock } from 'src/services/rxjs-redux/use-obs-block';
import { pickBlock } from './pick';
import type { UsePickBlockType } from './type';
import memoize from 'micro-memoize';
import { fastEqual } from '@flowus/common';

// 使用 WeakMap 自动处理垃圾回收
const hookCache = new WeakMap();
const selectorCache = new WeakMap();

// Memoize selector creation
const createSelector = memoize(
  (blockFields: any[] = [], dataFields: any = undefined) => {
    const key = { blockFields, dataFields };
    if (!selectorCache.has(key)) {
      const selector = (block: any) => pickBlock(block, blockFields, dataFields);
      selectorCache.set(key, selector);
    }
    return selectorCache.get(key);
  },
  {
    maxSize: 1000,
    isEqual: fastEqual,
  }
);

// Memoize hook creation
const getOrCreateHook = memoize(
  (
    uuid: Parameters<UsePickBlockType>[0],
    blockFields: Parameters<UsePickBlockType>[1] = [],
    dataFields: Parameters<UsePickBlockType>[2] = undefined,
    opt: Parameters<UsePickBlockType>[3] = {}
  ) => {
    const key = { uuid, blockFields, dataFields, opt };

    if (!hookCache.has(key)) {
      const selector = createSelector(blockFields, dataFields);
      const hook = () =>
        useObservableBlock(uuid, selector, [uuid], {
          ignoreDeep: !blockFields?.length,
          ...opt,
        });
      hookCache.set(key, hook);
    }

    return hookCache.get(key);
  },
  {
    maxSize: 1000,
    isEqual: fastEqual,
  }
);

export const usePickBlock: UsePickBlockType = (uuid, blockFields = [], dataFields, opt = {}) => {
  const hook = getOrCreateHook(uuid, blockFields, dataFields, opt);
  return hook();
};
