import { type FC } from 'react';
import { usePublicSpace, useSpace } from 'src/hooks/space';
import { getIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { goBackInfo, useNormalizePath } from 'src/hooks/page';
import { Icon } from 'src/common/components/icon';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@flowus/common/components/tooltip';
import { SeoAnchorLink } from '@flowus/common/components/seo-anchor-link';

export const HomePage: FC = () => {
  const pageId = useGetPageId();
  const block = usePickBlock(pageId, []);
  const currentSpace = useSpace(block?.spaceId);
  const showPublicHomePage = usePublicSpace(block?.spaceId)?.showPublicHomePage;
  // const homePageBlock = usePickBlock(currentSpace.publicHomePage, []);
  // const fetchBlock = useFetchBlock();

  const isShare = judgeSharePage();
  const history = useHistory();
  const pathname = useNormalizePath(currentSpace.publicHomePage ?? '');

  // useEffect(() => {
  //   if (homePageBlock) {
  //     return;
  //   }
  //   void fetchBlock(currentSpace.publicHomePage);
  // }, [currentSpace.publicHomePage, fetchBlock, homePageBlock]);

  if (!block) return null;
  if (!currentSpace) return null;
  if (!isShare) return null;
  const isPro = getIsProSpace(block?.spaceId);
  if (!isPro) return null;

  if (!showPublicHomePage) return null;
  if (!currentSpace.domain) return null;
  if (!currentSpace.publicHomePage) return null;
  // if (!homePageBlock) return null;

  return (
    <Tooltip
      className="flex items-center"
      popup="空间公开主页"
      onClick={() => {
        history.push(pathname);
        // 点击首页需要恢复位置
        goBackInfo.lastGoBackPath = pathname;
        goBackInfo.tmpDisable = true;
        setTimeout(() => {
          goBackInfo.tmpDisable = false;
        }, 500);
      }}
    >
      <SeoAnchorLink href={pathname}>
        <Icon name={'IcHome'} size="middle" className="text-black" />
      </SeoAnchorLink>
    </Tooltip>
  );
};
