import { useEffect, useState, type FC } from 'react';
import { useSplash } from './hooks/utils/use-splash';
import { request } from './common/request';

export const PaySuccess: FC = () => {
  useSplash();
  const [status, setStatus] = useState('finish');
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const orderId = searchParams.get('order_id');
    if (!orderId) return;
    void request.infra.getOrderStatus.raw(orderId).then((res) => {
      if (res.code !== 200) {
        setStatus('');
        return;
      }
      if (res.data.status === 20) {
        setStatus('支付成功');
      }
    });
  }, []);
  return <div>{status}</div>;
};
