import type { QaBlockResource, QAQueryOptionDTO } from '@next-space/fe-api-idl';
import { QASearchSource } from '@next-space/fe-api-idl';
import { DefaultAIModel } from '../../ai-const';
import type { ChatStatus } from './types';

export interface ReferenceData {
  status?: ChatStatus;
  qaQueryOption: Required<QAQueryOptionDTO>;
}

export abstract class ReferenceTools {
  protected id: string;
  protected updateFn: (id: string, data: Record<string, any>) => void;

  constructor(id: string, updateFn: (id: string, data: Record<string, any>) => void) {
    this.id = id;
    this.updateFn = updateFn;
  }

  /** Internal method to get data - must be implemented by child classes */
  protected abstract getData(): ReferenceData;

  updateQaQueryOption(data: Partial<QAQueryOptionDTO>) {
    this.updateFn(this.id, {
      qaQueryOption: {
        ...this.getData().qaQueryOption,
        ...data,
      },
    });
  }

  /** 设置引用选项 */
  setQaSearchSource = (option: QASearchSource) => {
    this.updateQaQueryOption({
      ...this.getData().qaQueryOption,
      qaSearchSource: option,
      searchBlocks: [],
    });
  };

  /** 设置引用页面ID列表 */
  setReferencePageIds = (pages: QaBlockResource[]) => {
    if (pages.length > 0) {
      this.updateQaQueryOption({
        searchBlocks: pages,
        qaSearchSource: QASearchSource.NONE,
      });
    } else {
      this.updateQaQueryOption({
        searchBlocks: [],
        qaSearchSource: QASearchSource.ALL,
      });
    }
  };

  /** 添加引用页面 */
  addSearchBlocks = (page: QaBlockResource) => {
    const data = this.getData().qaQueryOption;
    const existingPages = data.searchBlocks;
    if (!existingPages.some((p: QaBlockResource) => p.uuid === page.uuid)) {
      this.setReferencePageIds([...existingPages, page]);
    }
  };

  /** 移除引用页面 */
  removeSearchBlocks = (pageId: string) => {
    const data = this.getData().qaQueryOption;
    this.setReferencePageIds(data.searchBlocks.filter((p: QaBlockResource) => p.uuid !== pageId));
  };

  /** 切换引用页面的选中状态 */
  toggleSearchBlocs = (page?: QaBlockResource) => {
    if (!page) return;
    const isExist = this.isSelectedSearchBlocks(page.uuid);
    if (isExist && page.uuid) {
      this.removeSearchBlocks(page.uuid);
    } else {
      this.addSearchBlocks(page);
    }
  };

  /** 检查页面ID是否被选中 */
  isSelectedSearchBlocks = (pageId?: string) => {
    if (!pageId) return false;
    const data = this.getData().qaQueryOption;
    return data.searchBlocks.some((p: QaBlockResource) => p.uuid === pageId);
  };

  /** 检查引用选项是否被选中 */
  isSelectedQaSearchSource = (option?: QASearchSource) => {
    if (!option) return false;
    const data = this.getData().qaQueryOption;
    return data.qaSearchSource === option;
  };

  /** 切换引用选项的选中状态 */
  toggleQaSearchSource = (option?: QASearchSource) => {
    if (!option) return;
    const isExist = this.isSelectedQaSearchSource(option);
    //已选中的不需要再次取消
    if (!isExist) {
      this.addQaSearchSource(option);
    }
  };

  /** 添加引用选项 */
  addQaSearchSource = (option?: QASearchSource) => {
    if (!option) return;
    this.setQaSearchSource(option);
  };

  /** 设置状态 */
  setStatus = (status: ChatStatus) => {
    this.updateFn(this.id, { status });
  };

  /** 获取状态 */
  getStatus = () => {
    return this.getData().status;
  };

  /** 设置网页搜索状态 */
  setNetworkSearch = (networkSearch: boolean) => {
    this.updateQaQueryOption({ networkSearch });
  };

  /** 切换网页搜索状态 */
  toggleNetworkSearch = () => {
    const data = this.getData();
    this.setNetworkSearch(!data.qaQueryOption.networkSearch);
  };

  /** 获取网页搜索状态 */
  getNetworkSearch = () => {
    return this.getData().qaQueryOption.networkSearch;
  };

  /** 设置 AI 模型 */
  setQaModel = (model: string) => {
    this.updateQaQueryOption({
      model,
    });
  };

  /** 获取当前 AI 模型 */
  getQaModel = () => {
    return this.getData().qaQueryOption.model;
  };
}

/** A self-contained ReferenceTools implementation with internal state management */
export class StateReferenceTools extends ReferenceTools {
  private state: ReferenceData;

  constructor(
    id: string,
    initialState: Partial<ReferenceData> = {},
    externalUpdateFn?: (data: ReferenceData) => void
  ) {
    const state: ReferenceData = {
      qaQueryOption: {
        searchBlocks: [],
        qaSearchSource: QASearchSource.ALL,
        networkSearch: true,
        model: DefaultAIModel.value,
        ...initialState.qaQueryOption,
      },
      ...initialState,
    };

    const updateFn = (id: string, data: Record<string, any>) => {
      this.state = {
        ...this.state,
        ...data,
      };
      externalUpdateFn?.(this.state);
    };

    super(id, updateFn);
    this.state = state;
  }

  protected getData(): ReferenceData {
    return this.state;
  }

  public getState(): ReferenceData {
    return this.getData();
  }

  /** Create a new instance with default state */
  static create(
    id: string,
    initialState?: Partial<ReferenceData>,
    externalUpdateFn?: (data: ReferenceData) => void
  ): StateReferenceTools {
    return new StateReferenceTools(id, initialState, externalUpdateFn);
  }
}
