import { SeoAnchorLink } from '@flowus/common/components/seo-anchor-link';
import { useAlreadyLayout, useMindMapEngine } from '@flowus/mind-map';
import { useUnmount } from 'ahooks';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { LoadingContainer } from 'src/common/components/loading-container';
import { useSizeCallback } from 'src/common/utils/use-size';
import { usePageScrollRef } from 'src/views/main/page-doc/context';
import { AsideToolbar } from './component/aside-toolbar';
import { MindMapContainer } from './component/mind-map-container';
import { DragRenderer } from './drag-renderer';
import { useMindMapStyle } from './hook/use-mind-map-style';
import { useSmoothScrollByEmbed } from './hook/use-smooth-scroll';
import { LineRenderer } from './line-renderer';
import { MindNodeRenderer } from './mind-node-renderer';
import { deleteMindMapEngine, putMindMapEngine } from './utils/mind-engine-utils';
import { usePageScene } from 'src/views/main/scene-context';
import { useLocationBlock } from 'src/hooks/block/use-location-block';
import { selectCount } from './component/collapse-count';
import { cache } from 'src/redux/store';
import { patchExpand } from 'src/redux/managers/ui/use-fold';

interface Props {
  id: string;
}
/** 只读，用于渲染脑图，无操作 */
export const MindMapRender: FC<Props> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const pageScrollRef = usePageScrollRef();
  const { mindMapStyle, mindMapLineStyle } = useMindMapStyle(props.id);
  const engine = useMindMapEngine();
  useSmoothScrollByEmbed(containerRef);
  const alreadyLayout = useAlreadyLayout();
  useSizeCallback(
    pageScrollRef.current,
    (ret) => {
      const [oldWidth, oldHeight] = engine.getSize();
      if (ret.width && ret.height) {
        engine.setSize(ret.width, ret.height);
        if (oldWidth && oldHeight) {
          const diffWidth = Math.floor(ret.width - oldWidth);
          const diffHeight = Math.floor(ret.height - oldHeight);
          // 宽高改变后需要移动对应的位置，保持ui看上去没变动
          pageScrollRef.current?.scrollBy({ left: diffWidth, top: diffHeight });
        }
      }
    },
    'all'
  );
  const locationBlock = useLocationBlock({ container: pageScrollRef });
  const pageScene = usePageScene();

  useEffect(() => {
    engine.setRootId(props.id);
    engine.setDirection(mindMapStyle, true);
    engine.setLineStyle(mindMapLineStyle);
  }, [engine, mindMapLineStyle, mindMapStyle, props.id]);

  useEffect(() => {
    if (!pageScrollRef.current) {
      return;
    }
    if (!containerRef.current) {
      return;
    }
    const rect = pageScrollRef.current?.getBoundingClientRect();
    if (rect) {
      engine.setSize(rect.width, rect.height);
    }
    engine.setContainer(containerRef.current);
    engine.setScroller(pageScrollRef.current);
  }, [engine, pageScrollRef]);

  useUnmount(() => {
    engine.destroy();
  });

  useEffect(() => {
    putMindMapEngine(props.id, engine);
    return () => {
      deleteMindMapEngine(props.id);
    };
  }, [engine, props.id]);

  useEffect(() => {
    const count = selectCount(cache, props.id, props.id);
    // 如果节点超过150就先默认全部折叠，后面再考虑优化
    if (count > 150) {
      const b = cache.blocks[props.id];
      if (b?.subNodes) {
        patchExpand(b.subNodes, false, false);
      }
      locationBlock();
    }
  }, [props.id, locationBlock, pageScene]);
  return (
    <SeoAnchorLink className="group" href={props.id}>
      <MindMapContainer ref={containerRef} id={props.id}>
        <LineRenderer />
        <MindNodeRenderer id={props.id} level={0} />
        <DragRenderer />
      </MindMapContainer>
      <div className="group-hover:opacity-100 opacity-0 transition-opacity">
        <AsideToolbar id={props.id} />
      </div>
      {!alreadyLayout && <LoadingContainer className="absolute left-0 top-0" />}
    </SeoAnchorLink>
  );
};
