import { cx } from '@flowus/common/cx';
import { PermissionType } from '@next-space/fe-api-idl';
import type { FC, MouseEvent } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { Tooltip } from 'src/common/components/tooltip';
import { request } from 'src/common/request';
import { useReadonly } from 'src/hooks/page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useCurrentSpace } from 'src/hooks/space';
import { getSpaceGuests } from 'src/hooks/space/get-space-guests';
import { usersActions } from 'src/redux/reducers/users';
import { dispatch } from 'src/redux/store';
import { bizTracker } from 'src/utils/biz-tracker';
import { emitter } from '@flowus/common/utils/emitter';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { bindDataTestId, TestIds } from 'src/utils/qa-utils';
import { getVirtualElement } from 'src/utils/virtualElement';
import { SharePanel } from './share-panel';
import { SharePanelModel } from './helper';
import { useIsGuest } from 'src/hooks/share/use-permission-utils';

interface Props {
  uuid?: string;
  color?: string;
  className?: string;
  pos?: 'fileItem';
  showType?: 'icon' | 'button';
}

export const Share: FC<Props> = ({ uuid, color, className, pos, showType = 'icon' }) => {
  const openModal = useOpenModal();
  const pageId = useGetPageId();
  const id = uuid || pageId;
  const { shared, illegal } = usePermissions(id, { wait: 200 });
  const readonly = useReadonly(id);
  const currentSpace = useCurrentSpace();
  const block = usePickBlock(id, ['permissions']);
  const isGuest = useIsGuest();
  // 协作者没有权限不显示
  if (readonly && isGuest) return null;

  if (!block) return null;

  const content = () => (
    <SharePanelModel uuid={id}>
      <SharePanel uuid={id} />
    </SharePanelModel>
  );

  const openMenu = async (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    bizTracker.event('share', { is_share: readonly });
    const virtual = getVirtualElement(event);

    if (currentSpace.permissions) {
      void getSpaceGuests(currentSpace.uuid);
    } else {
      const { permissions } = block;
      const requests = permissions
        .filter((p) => p.type === PermissionType.USER && p.isGuest && p.userId)
        .map((p) => ({ id: p.userId as string, table: 'user' as 'user' }));
      if (requests.length) {
        const { users } = await request.editor.queryRecords({
          requests,
        });
        if (users) {
          dispatch(usersActions.updateSpaceUsers({ users }));
        }
      }
    }

    openModal.dropdown({
      modalId: 'inviteConfirmModal',
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      popcorn: event.currentTarget || virtual,
      offset: [20, 15],
      closeBeforeCallBack: () => {
        emitter.emit('inviteConfirmModal');
      },
      content,
    });
  };

  if (showType === 'button') {
    return (
      <Button
        onClick={openMenu}
        {...bindDataTestId(TestIds.driveFileShare)}
        colorType={shared ? 'active' : 'secondary'}
        className={cx(
          'relative flex items-center justify-center h-8 rounded flex-shrink-0 whitespace-nowrap px-2 border-none text-t2-medium',
          className
        )}
      >
        {shared ? '分享中' : '分享'}
      </Button>
    );
  }

  return (
    <div
      {...bindDataTestId(TestIds.driveFileShare)}
      onClick={openMenu}
      className={cx(
        'relative flex items-center justify-center w-5 h-5 mr-5 rounded animate-hover',
        className
      )}
    >
      <Tooltip popup="分享">
        <Icon
          size="middle"
          name="IcTitleShare"
          className={shared ? 'text-active_color' : color || ''}
        />
      </Tooltip>

      {illegal && pos !== 'fileItem' && (
        <Icon name="IcBadgeDanger" size="tiny" className="absolute -right-1 -top-1" />
      )}
    </div>
  );
};
