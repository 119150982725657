import { cx } from '@flowus/common/cx';
import type { PermissionDTO } from '@next-space/fe-api-idl';
import { DateFormat, DaySetting, PermissionType, TimeFormat } from '@next-space/fe-api-idl';
import type { Instance } from '@popperjs/core';
import { createPopper } from '@popperjs/core';
import dayjs from 'dayjs';
import isHotkey from 'is-hotkey';
import type { FC, MutableRefObject } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { Modifier } from 'react-day-picker';
import DayPicker, { DateUtils } from 'react-day-picker';
import { Icon } from 'src/common/components/icon';
import { Input } from 'src/common/components/input';
import { Switch } from 'src/common/components/switch';
import { Tooltip } from 'src/common/components/tooltip';
import { DATE_FORMAT, DATE_TIME_FORMAT, TIME_FORMAT } from 'src/common/const';
import { useCurrentSpaceDaySetting } from 'src/hooks/space';
import { fixTime, formatDateString, isValidTimeString } from 'src/utils/date-time-check';
import { MONTHS, WEEKDAYS_SHORT, WEEKDAYS_LONG } from 'src/utils/date-utils';
import './style.css';
import './ext.css';
import type { PickerDate } from './types';
import { remindDayOptions, remindTimeOptions } from './types';
import { Dropdown } from '@flowus/common/next-modal/dropdown';
import { FormatSetting } from './format-setting';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { OptionItem } from 'src/components/option-item';
import { findMatchReminderIndex, getValidDateFormat } from './find-match';
import { $currentUserCache } from 'src/services/user/current-user';
import { RemindPerson } from './remind-person';
import { useIsGuest } from 'src/hooks/share/use-permission-utils';
import { useReadonly } from 'src/hooks/page';
import { omit } from 'lodash-es';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { getSpaceGuests } from 'src/hooks/space/get-space-guests';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { isDateStringValid } from '@flowus/common';
import { batch } from 'react-redux';
import { globalListenerHelper, PRIORITY_PORTAL } from '@flowus/common/hooks/global-listener-helper';

const NavbarElement: FC<{
  onPreviousClick: (callback?: () => void) => void;
  onNextClick: (callback?: () => void) => void;
}> = ({ onPreviousClick, onNextClick }) => {
  return (
    <div className="absolute right-4 flex justify-between top-5">
      <Tooltip className="mr-2 rounded-sm animate-hover">
        <Icon name="IcArrowDateBack" size="middle" onClick={() => onPreviousClick()} />
      </Tooltip>
      <Tooltip className="rounded-sm animate-hover">
        <Icon name="IcArrowDateNext" size="middle" onClick={() => onNextClick()} />
      </Tooltip>
    </div>
  );
};

const CaptionElement: FC<{ date: Date }> = ({ date }) => (
  <div className="table-caption mt-1.5 h-9">
    <span className="flex items-center h-full text-black text-t2-bold">
      {dayjs(date).format('YYYY年MM月')}
    </span>
  </div>
);

const getDateTimeString = (date: string, time: string) => date.concat(' ', time);
enum InputStatus {
  from,
  to,
}
/**
 *
 * 这个组件是基于DateTimePicker修改的，支持选择时间范围。
 * 理论上这个组件应该替换掉DateTimePicker,但实际上因为ui和逻辑有差异，且研发时间有限，没做单个时间选取的兼容测试
 * 建议：除非时间组件ui上有较大变动需要重构才考虑合并。
 * TODO:重构输入框相关的代码
 * 由于这个组件是在dropdown上使用的，没法做成受控组件，props的值都是一次性不会变更的,因而导致逻辑有点怪
 */
export const DateTimePickerNew: FC<{
  dateInfo: PickerDate;
  uiOption?: {
    /** 是否有时间点这个选项 default true*/
    hasSwitchTimeOption?: boolean;
    /** 是否有结束时间,这个暂未实现,可参考已实现的DateTimePickerExt组件逻辑 default false */
    hasEndTime?: boolean;
    /** 是否有时间格式设定选项 default true*/
    hasFormatSetting?: boolean;
    /** 去掉shadow default false*/
    noShadow?: boolean;
    /** 是否设置成提醒 default false*/
    hasRemind?: boolean;
    /** 置灰某些天数 */
    disabledDays?: Modifier | Modifier[];
    /** footer */
    footer?: React.ReactNode;
  };
  onChange: (date: PickerDate) => void;
  onClear?: () => void;
  closeDatePicker?: () => void;
}> = ({ closeDatePicker, dateInfo, uiOption = {}, ...props }) => {
  const { includeTime = true, reminder, includeDateRange = false } = dateInfo;
  const {
    hasSwitchTimeOption = true,
    hasEndTime = false,
    noShadow = false,
    hasFormatSetting = true,
    hasRemind = false,
  } = uiOption;
  const { timeFormat = TimeFormat.H_MM } = dateInfo;
  let { dateFormat = DateFormat.YYYY_MM_DD } = dateInfo;
  dateFormat = getValidDateFormat(dateFormat);
  const isGuest = useIsGuest();
  const readOnly = useReadonly();
  const daySetting = useCurrentSpaceDaySetting();

  const startDateInputRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const endDateInputRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const startDateErrorRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const endDateErrorRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const startTimeErrorRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const endTimeErrorRef = useRef<HTMLDivElement>() as MutableRefObject<HTMLDivElement>;
  const fromDateInputRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;
  const toDateInputRef = useRef<HTMLInputElement>() as MutableRefObject<HTMLInputElement>;

  const updateTask = useUpdateTask();
  const [inputStatus, setInputStatus] = useState<InputStatus | undefined>(
    includeDateRange ? InputStatus.from : undefined
  );

  const [fromInputDate, setFromInputDate] = useState({
    startDate: dayjs(dateInfo.from).format(DATE_FORMAT),
    startTime: dayjs(dateInfo.from).format(TIME_FORMAT),
    validStartDate: true,
    validStartTime: true,
  });
  const [toInputDate, setToInputDate] = useState({
    startDate: dateInfo.to ? dayjs(dateInfo.to).format(DATE_FORMAT) : '',
    startTime: dateInfo.to ? dayjs(dateInfo.to).format(TIME_FORMAT) : '',
    validStartDate: true,
    validStartTime: true,
  });
  const [formatDropDownVisible, setFormatDropDownVisible] = useState(false);
  const remindOptions = includeTime ? remindTimeOptions : remindDayOptions;
  const remindOptionIndex = useMemo(() => {
    return findMatchReminderIndex(reminder, includeTime);
  }, [includeTime, reminder]);
  // const [isIncludeTime, setIsIncludeTime] = useState(includeTime);

  const updateFromInputDate = useCallback((patch) => {
    setFromInputDate((preState) => ({ ...preState, ...patch }));
  }, []);
  const updateToInputDate = useCallback(
    (patch) => setToInputDate((preState) => ({ ...preState, ...patch })),
    []
  );

  useEffect(() => {
    const handleKeyDown = (event: globalThis.KeyboardEvent) => {
      if (isHotkey('enter')(event)) {
        closeDatePicker?.();
      }
    };

    globalListenerHelper.addEventListener('keydown', handleKeyDown, PRIORITY_PORTAL, true);
    return () => {
      globalListenerHelper.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [closeDatePicker]);

  const hasReminder = Boolean(reminder);
  useEffect(() => {
    if (hasReminder && !isGuest && !readOnly) {
      // 需要拉协作者
      void getSpaceGuests(getCurrentSpaceId());
    }
  }, [hasReminder, isGuest, readOnly]);

  const onChange = useCallback(
    (partial: Partial<PickerDate>) => {
      props.onChange({ ...dateInfo, ...partial });
    },
    [dateInfo, props]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      clearTimeout(timer);
      fromDateInputRef.current.focus();
    });
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: globalThis.KeyboardEvent) => {
      if (isHotkey('enter')(event)) {
        const startDate = formatDateString(fromInputDate.startDate);
        const isValid = isValidTimeString(fromInputDate.startTime);
        if (!!startDate && isValid) {
          closeDatePicker?.();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown, true);
    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, [fromInputDate.startDate, fromInputDate.startTime, closeDatePicker]);

  const handleTimeSwitch = (open: boolean) => {
    const from = dayjs(dateInfo.from);
    let fromDate: Date;
    const now = dayjs(Date.now());
    const time = now.format('HH:mm');
    const toDay = new Date();
    if (open) {
      fromDate = from.hour(toDay.getHours()).minute(toDay.getMinutes()).toDate();
    } else {
      fromDate = from.hour(0).minute(0).toDate();
    }
    let to: Date | undefined;
    if (includeDateRange) {
      const toDayjs = dayjs(dateInfo.to);
      if (open) {
        to = toDayjs.hour(toDay.getHours()).minute(toDay.getMinutes()).toDate();
      } else {
        to = toDayjs.hour(0).minute(0).toDate();
      }
    }
    onChange({
      from: fromDate,
      to,
      reminder: undefined,
      includeTime: open,
    });
    updateFromInputDate({
      startTime: time,
      validStartTime: true,
    });
    includeDateRange &&
      updateToInputDate({
        startTime: time,
        validStartTime: true,
      });
  };

  const handleDayClick = useCallback(
    (day: Date, m, e) => {
      e.stopPropagation();

      const keepTime = (newDate: Date, day: Date) => {
        const hour = day.getHours();
        const minutes = day.getMinutes();
        const newDayjs = dayjs(newDate).hour(hour).minute(minutes);
        return newDayjs;
      };
      if (!includeDateRange) {
        const hour = dateInfo.from.getHours();
        const minutes = dateInfo.from.getMinutes();
        // keep time
        const startDayjs = dayjs(day).hour(hour).minute(minutes);
        const from = startDayjs.toDate();
        const startDate = startDayjs.format(DATE_FORMAT);
        onChange({
          from,
        });
        updateFromInputDate({
          startDate,
          validStartDate: true,
        });

        if (!includeTime) {
          closeDatePicker?.();
        }
        return;
      }
      if (inputStatus === InputStatus.from && dateInfo.to) {
        const newdayjs = keepTime(day, dateInfo.from);
        const startDateStr = newdayjs.format(DATE_FORMAT);
        const needChangePosition = DateUtils.isDayAfter(day, dateInfo.to);
        batch(() => {
          setInputStatus(InputStatus.to);
          if (needChangePosition && dateInfo.to) {
            const newdayjs = keepTime(day, dateInfo.to);
            batch(() => {
              updateFromInputDate({
                ...omit(toInputDate, 'startTime'),
              });
              updateToInputDate({
                startDate: startDateStr,
                startTime: toInputDate.startTime,
                validStartDate: true,
                validStartTime: true,
              });
              onChange({
                from: dateInfo.to,
                to: newdayjs.toDate(),
              });
            });
          } else {
            updateFromInputDate({
              startDate: startDateStr,
              validStartDate: true,
              validStartTime: true,
            });
            onChange({
              from: newdayjs.toDate(),
            });
          }
        });
      } else {
        batch(() => {
          if (!dateInfo.to) return;
          const newDayjs = keepTime(day, dateInfo.to);
          const needChangePosition = DateUtils.isDayAfter(dateInfo.from, newDayjs.toDate());
          if (needChangePosition) {
            onChange({
              from: newDayjs.toDate(),
              to: keepTime(dateInfo.from, dateInfo.to).toDate(),
            });
          } else {
            onChange({
              from: dateInfo.from,
              to: newDayjs.toDate(),
            });
          }
          setInputStatus(InputStatus.from);
          const toDateStr = newDayjs.format(DATE_FORMAT);
          if (needChangePosition) {
            updateToInputDate({
              ...omit(fromInputDate, 'startTime'),
            });
            updateFromInputDate({
              startDate: toDateStr,
              startTime: fromInputDate.startTime,
              validStartDate: true,
              validStartTime: true,
            });
          } else {
            updateToInputDate({
              startDate: toDateStr,
              validStartDate: true,
              validStartTime: true,
            });
          }
        });
      }
    },
    [
      closeDatePicker,
      dateInfo.from,
      dateInfo.to,
      fromInputDate,
      includeDateRange,
      includeTime,
      inputStatus,
      onChange,
      toInputDate,
      updateFromInputDate,
      updateToInputDate,
    ]
  );

  useEffect(() => {
    const instances: Instance[] = [];
    if (!fromInputDate.validStartDate) {
      instances.push(
        createPopper(startDateInputRef.current, startDateErrorRef.current, {
          placement: 'left',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 6],
              },
            },
          ],
        })
      );
    }
    if (!toInputDate.validStartDate) {
      instances.push(
        createPopper(endDateInputRef.current, endDateErrorRef.current, {
          placement: 'left',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 6],
              },
            },
          ],
        })
      );
    }
    if (!fromInputDate.validStartTime) {
      instances.push(
        createPopper(startDateInputRef.current, startTimeErrorRef.current, {
          placement: 'right',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 6],
              },
            },
          ],
        })
      );
    }
    if (!toInputDate.validStartTime) {
      instances.push(
        createPopper(endDateInputRef.current, endTimeErrorRef.current, {
          placement: 'right',
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 6],
              },
            },
          ],
        })
      );
    }

    return () => {
      instances.forEach((poperInstance: Instance) => poperInstance.destroy());
    };
  }, [
    fromInputDate.validStartDate,
    fromInputDate.validStartTime,
    toInputDate.validStartDate,
    toInputDate.validStartTime,
  ]);

  const handleDateInputEnter = (isFromInput: boolean, focus: boolean) => {
    const startDate = formatDateString(
      isFromInput ? fromInputDate.startDate : toInputDate.startDate
    );
    if (focus) {
      isFromInput
        ? updateFromInputDate({ validStartDate: !!startDate })
        : updateToInputDate({ validStartDate: !!startDate });
    }
    if (startDate) {
      isFromInput
        ? updateFromInputDate({
            startDate,
          })
        : updateToInputDate({
            startDate,
          });
    }
  };
  const handleDateRangeSwicth = (open: boolean) => {
    if (!open) {
      onChange({
        to: undefined,
      });
      return;
    }
    const to = new Date(dateInfo.from);
    const startDate = dayjs(dateInfo.from).format(DATE_FORMAT);
    const startTime = dayjs(dateInfo.from).format(TIME_FORMAT);
    includeTime
      ? updateToInputDate({
          startDate,
          startTime,
          validStartDate: true,
          validStartTime: true,
        })
      : updateToInputDate({
          validStartDate: true,
          startDate,
        });
    onChange({
      to,
    });
  };

  const handleTimeInputEnter = (isFromInput: boolean, focus?: boolean, timeValue?: string) => {
    let time = timeValue ?? (isFromInput ? fromInputDate.startTime : toInputDate.startTime);
    const isValid = isValidTimeString(time);
    if (!focus) {
      isFromInput
        ? updateFromInputDate({ validStartTime: isValid })
        : updateToInputDate({ validStartTime: isValid });
    }
    if (!isValid) return;
    time = fixTime(time);
    if (isFromInput) {
      onChange({
        from: dayjs(getDateTimeString(fromInputDate.startDate, time), DATE_TIME_FORMAT).toDate(),
        reminder: hasReminder ? { ...reminder, time } : undefined,
      });
    } else {
      onChange({
        to: dayjs(getDateTimeString(toInputDate.startDate, time), DATE_TIME_FORMAT).toDate(),
      });
    }
  };

  const fromInputError = !fromInputDate.validStartDate || !fromInputDate.validStartTime;
  const toInputError = !toInputDate.validStartDate || !toInputDate.validStartTime;
  return (
    <div className={cx('w-[282px] bg-white2 pt-[10px] pb-[5px]', { 'next-modal': !noShadow })}>
      <div className="flex flex-col px-2.5">
        {/* 开始时间 */}
        <div
          ref={startDateInputRef}
          className={cx(`flex bg-grey9 rounded-sm py-1.5 h-8 border border-transparent`, {
            // 'border-red': fromInputError,
            'border-active_color dark:border-grey3':
              inputStatus === InputStatus.from && !fromInputError,
          })}
        >
          <Input
            inputRef={fromDateInputRef}
            showBorder={false}
            className={`border-none ${includeDateRange ? 'w-1/2' : 'w-full'}`}
            style={{ backgroundColor: 'transparent' }}
            onChange={(value) => {
              const startDate = formatDateString(value);
              const isDateValid = isDateStringValid(value, DATE_FORMAT);
              const date = dayjs(startDate).toDate();
              updateFromInputDate({ startDate: value, validStartDate: isDateValid });
              if (!isDateValid) {
                return;
              }

              onChange({
                from: date,
              });
            }}
            value={fromInputDate.startDate}
            onFocus={() => {
              setInputStatus(InputStatus.from);
            }}
            onEnter={() => {
              handleDateInputEnter(true, true);
            }}
            onBlur={() => {
              if (!includeDateRange) {
                setInputStatus(undefined);
              }
              handleDateInputEnter(true, false);
            }}
          />
          {includeTime && (
            <Input
              showBorder={false}
              className="w-1/2 px-3 rounded-none"
              style={{ borderLeft: '1px solid var(--grey6)', backgroundColor: 'transparent' }}
              onChange={(value) => {
                updateFromInputDate({ startTime: value });
                handleTimeInputEnter(true, false, value);
              }}
              onFocus={() => {
                setInputStatus(InputStatus.from);
              }}
              onEnter={() => {
                handleTimeInputEnter(true, true);
              }}
              onBlur={() => {
                if (!includeDateRange) {
                  setInputStatus(undefined);
                }
              }}
              value={fromInputDate.startTime}
            />
          )}
        </div>

        {!fromInputDate.validStartDate && (
          <div
            ref={startDateErrorRef}
            className="px-2 py-1 text-white1 bg-black/70 rounded-sm text-t4"
          >
            <span>无效日期</span>
          </div>
        )}
        {!fromInputDate.validStartTime && (
          <div
            ref={startTimeErrorRef}
            className="px-2 py-1 text-white1 bg-black/70 rounded-sm text-t4"
          >
            <span>无效时间</span>
          </div>
        )}
        {/* 结束时间 */}
        {includeDateRange && (
          <>
            <div
              ref={endDateInputRef}
              className={cx(`mt-1 flex bg-grey9 rounded-sm py-1.5 h-8 border border-transparent`, {
                // 'input-error': toInputError,
                'border-active_color dark:border-grey3':
                  inputStatus === InputStatus.to && !toInputError,
              })}
            >
              <Input
                inputRef={toDateInputRef}
                showBorder={false}
                className={`border-none ${includeDateRange ? 'w-1/2' : 'w-full'}`}
                style={{ backgroundColor: 'transparent' }}
                onChange={(value) => {
                  const toDate = formatDateString(value);
                  const isDateValid = isDateStringValid(value, DATE_FORMAT);
                  const date = dayjs(toDate).toDate();
                  updateToInputDate({ startDate: value, validStartDate: isDateValid });
                  if (!isDateValid) {
                    return;
                  }
                  onChange({
                    to: date,
                  });
                }}
                value={toInputDate.startDate}
                onFocus={() => {
                  setInputStatus(InputStatus.to);
                }}
                onBlur={() => {
                  if (!includeDateRange) {
                    setInputStatus(undefined);
                  }
                  handleDateInputEnter(false, false);
                }}
              />
              {includeTime && (
                <Input
                  showBorder={false}
                  className="w-1/2 px-3 border-none rounded-none border-grey6"
                  style={{ borderLeft: '1px solid var(--grey6)', backgroundColor: 'transparent' }}
                  onChange={(value) => {
                    updateToInputDate({ startTime: value });
                    handleTimeInputEnter(false, false, value);
                  }}
                  onFocus={() => {
                    setInputStatus(InputStatus.to);
                  }}
                  onEnter={() => {
                    // handleTimeInputEnter(false, true);
                  }}
                  onBlur={() => {
                    if (!includeDateRange) {
                      setInputStatus(undefined);
                    }
                  }}
                  value={toInputDate.startTime}
                />
              )}
            </div>

            {!toInputDate.validStartDate && (
              <div
                ref={endDateErrorRef}
                className="px-2 py-1 text-white1 bg-black/70 rounded-sm text-t4"
              >
                <span>无效日期</span>
              </div>
            )}
            {!toInputDate.validStartTime && (
              <div
                ref={endTimeErrorRef}
                className="px-2 py-1 text-white1 bg-black/70 rounded-sm text-t4"
              >
                <span>无效时间</span>
              </div>
            )}
          </>
        )}
      </div>

      <DayPicker
        className={cx('time-picker flex justify-center w-full', {
          'time-picker-range': includeDateRange,
          'time-picker-single': !includeDateRange,
        })}
        fixedWeeks
        disabledDays={uiOption?.disabledDays}
        selectedDays={
          includeDateRange
            ? [dateInfo.from, { from: dateInfo.from, to: dateInfo.to }]
            : dateInfo.from
        }
        modifiers={{
          // selected: dateInfo,
          start: dateInfo?.from,
          end: dateInfo?.to,
        }}
        firstDayOfWeek={daySetting === DaySetting.MON ? 1 : 0}
        navbarElement={NavbarElement}
        captionElement={CaptionElement}
        locale="zh-cn"
        month={dateInfo.from}
        months={MONTHS}
        onDayClick={handleDayClick}
        weekdaysShort={WEEKDAYS_SHORT}
        weekdaysLong={WEEKDAYS_LONG}
      />
      {hasEndTime && (
        <>
          <div className={`mx-4 h-px bg-grey6 `} />
          <div>
            <div className="flex items-center justify-between h-9 px-4">
              <span className="text-t2">结束日期</span>
              <Switch open={includeDateRange} onSwitch={handleDateRangeSwicth} />
            </div>
          </div>
        </>
      )}
      {hasSwitchTimeOption && (
        <>
          <div
            className={cx('w-[252px] h-px bg-grey6 mx-[15px]', {
              hidden: hasEndTime,
            })}
          />
          <div>
            <div className="flex items-center justify-between h-9 px-4">
              <span className="text-t2">时间点</span>
              <Switch open={includeTime} onSwitch={handleTimeSwitch} />
            </div>
          </div>
        </>
      )}
      {hasRemind && (
        <>
          <div className="mx-4 h-px bg-grey6" />
          <div>
            <div className="flex items-center justify-between h-9 px-4">
              <Icon name="IcMenuAlarm" size={'middle'} />
              <OptionItem
                name="提醒"
                className="w-full ml-2"
                selectedIndex={remindOptionIndex}
                options={remindOptions.map((v) => {
                  return { ...v, id: v.name };
                })}
                onSelect={(_, index) => {
                  if (index === 0) {
                    onChange({ reminder: undefined });
                    return;
                  }

                  // 积分任务
                  void updateTask(ActivityIds.CREATE_DATE_MENTION, ActivitiesListType.basicList, {
                    step2: true,
                  });

                  /**
                   * by wangzhen
                   * 补充一个时间提醒相关的需求策略：
                   * 当设置提醒用户为外部协作者时，开启提醒，默认提醒成员为该用户，且该用户不可修改；
                   * 已开始提醒时，该用户均无法修改提醒成员。相关研发及测试同事留意
                   */
                  const remindOption = remindOptions[index];
                  const me = [{ userId: $currentUserCache.uuid, type: PermissionType.USER }];
                  const userPermissions: PermissionDTO[] | undefined =
                    dateInfo.reminder?.userPermissions ?? me;

                  if (remindOption) {
                    if (includeTime) {
                      const time = dayjs(dateInfo.from).format(TIME_FORMAT);
                      onChange({
                        reminder: {
                          ...omit(remindOption, 'name'),
                          time,
                          userPermissions,
                        },
                      });
                    } else {
                      onChange({
                        reminder: {
                          ...omit(remindOption, 'name'),
                          userPermissions,
                        },
                      });
                    }
                  }
                }}
              />
            </div>
          </div>
        </>
      )}
      {hasRemind && remindOptionIndex > 0 && (
        <RemindPerson
          userPermissions={dateInfo.reminder?.userPermissions ?? []}
          onSelect={(permission) => {
            onChange({ reminder: { ...dateInfo.reminder, userPermissions: permission } });
          }}
        />
      )}
      {hasFormatSetting && (
        <>
          <Dropdown
            visible={formatDropDownVisible}
            placement="right"
            content={
              <FormatSetting
                disableTimeFormat={!includeTime}
                dateFormat={dateFormat}
                timeFormat={timeFormat}
                onSelectDateFormat={(format) => {
                  setFormatDropDownVisible(false);
                  onChange({
                    dateFormat: format,
                  });
                }}
                onSelectTimeFormat={(format) => {
                  setFormatDropDownVisible(false);
                  onChange({
                    timeFormat: format,
                  });
                }}
              />
            }
          >
            <div className="h-px bg-grey6 mx-4" />
            <div className="cursor-pointer">
              <div className="flex items-center h-9 px-4">
                <Icon name={'IcSettings'} size={'middle'} />
                <span className="text-t2 ml-2">日期格式</span>
              </div>
            </div>
          </Dropdown>
        </>
      )}
      {props.onClear && (
        <>
          <div className="h-px bg-grey6 mx-4" />
          <div
            className="flex items-center h-9 pl-4 cursor-pointer text-t2"
            onClick={props.onClear}
          >
            清除
          </div>
        </>
      )}
      {uiOption?.footer}
    </div>
  );
};
