import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getCurrentLanguage, Language, LanguageKey } from '../../hooks/use-language';
import en from './locales/en.json';
import zhHans from './locales/zh-hans.json';
import zhHant from './locales/zh-hant.json';
import ru from './locales/ru.json';
import { isBuildIn } from '../../build-in';

export const translationResources = {
  [Language.en]: {
    translation: en,
    ...en,
  },
  [Language.zhHans]: {
    translation: zhHans,
    ...zhHans,
  },
  [Language.zhHant]: {
    translation: zhHant,
    ...zhHant,
  },
  [Language.ru]: {
    translation: ru,
    ...ru,
  },
};

const i18n = i18next.createInstance(
  {
    lng: isBuildIn() ? getCurrentLanguage() : Language.zhHans,
    fallbackLng: isBuildIn() ? Language.en : Language.zhHans,
    resources: translationResources,
    returnObjects: true,
    detection: {
      order: ['cookie'],
      caches: ['cookie'],
      cookieOptions: {
        maxAge: 31536000000,
      },
      lookupCookie: LanguageKey,
    },
  },
  () => {}
);

void i18n.use(LanguageDetector).use(initReactI18next).init();

export default i18n;
