import { getFormatImageUrl } from '@flowus/common';
import { fixUrl } from '@flowus/common/embed-website';
import { FileRegex } from '@flowus/common/regex';
import { BlockType } from '@next-space/fe-api-idl';
import { useEffect, useRef, useState } from 'react';
import { batch, useStore } from 'react-redux';
import { segmentsToText } from 'src/editor/utils/editor';
import { cache } from 'src/redux/store';
import { $searchParams } from 'src/utils';
import { sequence, sleep } from 'src/utils/async-utils';
import { getFileNameInfo } from 'src/utils/file';
import { usePickBlock } from 'src/utils/pick-block';
import { urlFetcher } from 'src/utils/url-fetcher';
import { getPermissions, usePermissions } from '../share/use-permissions';

interface Option {
  disableLocalUrl?: boolean;
  isImage?: boolean;
  ossName?: string;
}
/**
 * 仅适用于fileBlock 和externalBlock
 * setUrl(undefined);是为了兼容undo情况
 */
export const useResource = (uuid: string, option?: Option) => {
  const store = useStore();
  const block = usePickBlock(uuid, ['data'], ['segments', 'caption', 'ossName', 'localUrl']);
  const isAnnotation = block?.type === BlockType.PDF_ANNOTATION;
  const ossName = option?.ossName ?? block?.data.ossName;
  const [loading, setLoading] = useState(Boolean(ossName));
  const [url, setUrl] = useState<string>();
  const [isOssNameUrl, setIsOssNameUrl] = useState(false);
  const ossFileName = ossName ?? segmentsToText(block?.data.segments);
  const { extName } = getFileNameInfo(ossFileName);
  const isImage = option?.isImage || FileRegex.image.test(extName);
  const { shared, accessFee, password } = usePermissions(uuid);
  const isShare = !accessFee && shared && !password;
  const timer = useRef<ReturnType<typeof setInterval>>();
  const isInit = useRef(false);

  useEffect(() => {
    // 如果ossName变了（替换图片），则url需要重置
    setUrl(undefined);
  }, [ossName]);

  useEffect(() => {
    if (url) return;
    const run = () => {
      const block = store.getState().blocks[uuid];
      if (!block) {
        setLoading(false);
        return;
      }

      if (block.type === BlockType.EXTERNAL_FILE) {
        batch(() => {
          setLoading(false);
          if (block.data.link) {
            setUrl(fixUrl(block.data.link));
          } else {
            setUrl(undefined);
          }
        });
        return;
      }

      if (isAnnotation) {
        setLoading(true);

        const fileName = segmentsToText(block?.data.segments) || ossFileName;
        void sequence(async () => {
          if (!block.data.pdfAnnotation?.ossName) return;
          const res = await urlFetcher.fetchDownloadUrl({
            blockId: uuid,
            ossName: block.data.pdfAnnotation?.ossName,
            fileName,
            opt: { isShare },
          });
          if (res) {
            setUrl(res);
          }
          setIsOssNameUrl(true);
          setLoading(false);
        }, 'oss');

        return;
      }

      if (!block?.data.localUrl) {
        setLoading(true);
      }
      // 用户有遇到过图片裂开的情况，但刷新或者切换页面后恢复，说明跟本地缓存关系不大，主要是这里的state没刷新
      // 为什么会有这种奇怪的情况：组件重新创建但网页本身没有reload，怀疑是chrome的tab切换机制或者长期在后台突然切换到前台引起的。
      // 为了定位问题，我们尝试每50分钟刷一把url(有缓存就会取缓存，不会有多余的请求)
      // 如果是内核本身切换前后台导致的，可能定时器也会受到影响，不确定能不能刷新state
      const loadUrl = async () => {
        if (!ossName) {
          setLoading(false);
          return;
        }
        // 因为新创建的块(特殊图片格式无法本地查看)立刻拉地址可能拉不到，报错的话需要sleep再拉一次
        try {
          for (let i = 0; i < 2; i++) {
            if ((!$searchParams.print && block?.data.localUrl) || i === 1) {
              // 第一次加载的时候，如果是本地图片，就先不拉取oss地址，用户反馈会体验不好，会闪
              if (!isInit.current && block?.data.localUrl) {
                isInit.current = true;
                break;
              }
              // 第二次可能就是需要刷新图片过期链接了，这时候再允许拉取oss地址
              await sleep(1000);
            }
            let res = '';
            if (isImage) {
              res = await urlFetcher.fetchImageUrl({
                blockId: block.uuid,
                ossName,
                opt: { isShare },
              });
            } else {
              const fileName = segmentsToText(block?.data.segments) || ossFileName;
              res = await urlFetcher.fetchDownloadUrl({
                blockId: block.uuid,
                ossName,
                fileName,
                opt: { isShare },
              });
            }
            if (res) {
              batch(() => {
                setUrl(res);
                setIsOssNameUrl(true);
              });
              break;
            }
          }
        } finally {
          setLoading(false);
        }
      };
      void loadUrl();
    };
    run();
    timer.current && clearInterval(timer.current);
    timer.current = setInterval(() => {
      run();
    }, 50 * 60 * 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnnotation, isImage, isShare, ossFileName, ossName, shared, url, uuid]);

  useEffect(() => {
    return () => {
      timer.current && clearInterval(timer.current);
    };
  }, []);

  return {
    imageUrl: url ? getFormatImageUrl(url, extName) : block?.data.localUrl ?? '',
    url: url || block?.data.localUrl || '', // 保证返回的至少是空串而不是undefined
    loading,
    isOssNameUrl,
  };
};

export const getDownloadUrl = async (uuid: string) => {
  const block = cache.blocks[uuid];
  if (!block || !block.data.ossName) return;
  const fileName = segmentsToText(block?.data.segments);

  const res = await urlFetcher.fetchDownloadUrl({
    blockId: block.uuid,
    ossName: block.data.ossName,
    fileName,
    opt: {
      isShare: getPermissions(uuid).shared,
    },
  });

  return res;
};
