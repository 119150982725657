import { getImageBlobByExternalUrl } from '@flowus/common/proxy-url';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { BlockType, TextType } from '@next-space/fe-api-idl';
import { chunk, last } from 'lodash-es';
import { getEditorModelByEditorKey } from 'src/editor/editor/uikit/editable-models';
import { textToSegments } from 'src/editor/utils/editor';
import { convertSegmentsToContent } from 'src/editor/utils/segments';
import { getCurrentSpaceId } from 'src/hooks/space/get-space';
import { getMediaInfo } from 'src/hooks/utils/use-get-media-info';
import { TRANSACTION_FIRE } from 'src/redux/actions';
import { addBlock } from 'src/redux/managers/block/add';
import { updateBlock } from 'src/redux/managers/block/update';
import { dispatch } from 'src/redux/store';
import type { NextBlock, NextWhere } from 'src/redux/types';
import { $currentUserCache } from 'src/services/user/current-user';
import { getEditorKeyFromElement } from 'src/utils/editor-utils';
import type { HandleDataType, HandOption, PasteHook } from '../../types';
import { common } from '../common';

export const handleBlocks = (props: PasteHook, handleData: HandleDataType, opt?: HandOption) => {
  const { transaction, selectedBlockHistory, uploadFile } = props;
  const { where, pos, pageId, clearEmptyBlock, isInInlineEditable, syncId } = common(opt);

  // 处理从 typora 复制的图片
  for (const block of Object.values(handleData.blocks)) {
    if (block.type === BlockType.TEXTAREA) {
      const { segments } = block.data;
      if (segments?.length === 1) {
        const [segment] = segments;
        if (segment) {
          const { type, text } = segment;
          if (type === TextType.TEXT) {
            const match = text.match(/^!\[(.*)\]\((.*)\)$/);
            if (match) {
              const url = match[2];
              // 如果图片下载不成功，那么图片大概率在外链上也是用不了的(会过期)
              // 所以不成功需要显示空块，要让用户知道缺了个图而不是临时的欺骗一下用户，导致隔天就来问缺图问题
              block.type = BlockType.FILE;
              block.data = {
                segments: textToSegments(match[1]),
                size: 0,
                extName: 'png',
                display: 'image',
                link: url,
              };
              handleData.images.push({
                parentId: block.parentId,
                blockId: block.uuid,
                url: url ?? '',
              });
            }
          }
        }
      }
    }
  }

  const posInfo = Object.entries(handleData.blockPos);
  const focusEditorKey = getEditorKeyFromElement(document.activeElement);
  const firstPosSub = (posInfo[0] ?? [undefined, ['']])[1];

  // 有 focus 的块时, 在多维表中粘贴 或 只粘贴了一行
  if (
    focusEditorKey &&
    (isInInlineEditable ||
      (posInfo.length === 1 &&
        firstPosSub.length === 1 &&
        // 公式块 | 文件 | 图片 除外
        ![BlockType.EQUATION, BlockType.FILE, BlockType.EXTERNAL_FILE].includes(
          handleData.blocks[firstPosSub[0] as string]?.type as BlockType
        )))
  ) {
    const editorModel = getEditorModelByEditorKey(focusEditorKey);
    if (editorModel) {
      const segments = firstPosSub.reduce((pre, id, i) => {
        if (i > 0) pre.push({ type: TextType.TEXT, text: '\n', enhancer: {} });
        const block = handleData.blocks[id];
        const _segments = block?.data.segments ?? [];
        if (i === 0 && block && block.type === BlockType.ORDER_LIST) {
          _segments.unshift({ type: TextType.TEXT, text: `${handleData.start}. `, enhancer: {} });
        }
        return pre.concat(_segments);
      }, [] as SegmentDTO[]);
      const content = convertSegmentsToContent(segments);
      editorModel.performChange((ctx) => {
        ctx.delete();
        ctx.insert(content);
      });
    }
  } else {
    const _addBlock = (parentId: string, pre?: null | string) => {
      const subNodes = handleData.blockPos[parentId];
      if (subNodes) {
        // 如果块过大就拆开再一组一组添加
        const chunkNodesArr = chunk(subNodes, 50);
        chunkNodesArr.reduce((p1, subNodes) => {
          const lastNode = last(subNodes);
          setTimeout(() => {
            transaction(() => {
              subNodes.reduce((p, uuid) => {
                const newWhere: NextWhere = { parentId };
                if (p === pageId) {
                  newWhere.first = true;
                } else if (p) {
                  newWhere.after = p;
                }

                if (handleData.blocks[uuid]) {
                  addBlock(handleData.blocks[uuid] as NextBlock, newWhere);
                }
                if (handleData.blockPos[uuid]) {
                  _addBlock(uuid);
                }
                return uuid;
              }, p1 || '');
            });
          }, 0);
          return lastNode || '';
        }, pre || '');
      }
    };

    if (pos === where.parentId) {
      // 特殊case
      _addBlock(where.parentId);
    } else {
      _addBlock(where.parentId, pos);
    }

    const selectedBlocks = handleData.blockPos[where.parentId]?.map((v) => {
      return { blockId: v, syncId };
    });

    // 确保比_addBlock晚一点进行
    setTimeout(() => {
      transaction(() => {
        selectedBlockHistory?.(selectedBlocks || []);
        clearEmptyBlock();
        handleData.files.forEach((o) => {
          void uploadFile?.({
            key: o.blockId,
            userId: $currentUserCache.uuid,
            spaceId: getCurrentSpaceId(),
            file: o.file,
            type: 'file',
            onComplete: async (ret) => {
              if (ret.success) {
                const mediaInfo = await getMediaInfo(o.file);
                const { ossName } = ret;
                updateBlock(o.blockId, { data: { ossName, ...mediaInfo } });
                dispatch(TRANSACTION_FIRE());
              }
            },
          });
        });

        const saveBlockImageFile = (
          o: typeof handleData.images[0],
          blobInfo: {
            fileName: string;
            blob: Blob;
          }
        ) => {
          const file = new File([blobInfo.blob], blobInfo.fileName);
          void uploadFile?.({
            inTransferList: true,
            blockId: o.blockId,
            key: o.blockId,
            userId: $currentUserCache.uuid,
            spaceId: getCurrentSpaceId(),
            file,
            type: 'file',
            onComplete: async (ret) => {
              if (ret.success) {
                const mediaInfo = await getMediaInfo(file);
                const { ossName } = ret;
                updateBlock(o.blockId, {
                  type: BlockType.FILE,
                  data: {
                    ossName,
                    ...mediaInfo,
                    segments: textToSegments(blobInfo.fileName),
                    size: blobInfo.blob.size,
                  },
                });
                dispatch(TRANSACTION_FIRE());
              }
            },
          });
        };
        void (async () => {
          for (const o of handleData.images) {
            await getImageBlobByExternalUrl(o.url, {
              callback: (fileInfo) => {
                saveBlockImageFile(o, fileInfo);
              },
            });
          }
        })();
      });
    }, 1);
  }
};
