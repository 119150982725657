import { cx } from '@flowus/common/cx';
import { BlockType } from '@next-space/fe-api-idl';
import { last } from 'lodash-es';
import type { FC } from 'react';
import { memo, useCallback } from 'react';
import { isBlockNoText, segmentsToText } from 'src/editor/utils/editor';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { useTransaction } from 'src/hooks/use-transaction';
import { addBlock } from 'src/redux/managers/block/add';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch, getState } from 'src/redux/store';
import { useIsOpenAiEditor } from 'src/services/modal';

export const useClickBlankAreaCreateBlock = () => {
  const transaction = useTransaction();
  const focusEditableAt = useFocusEditableByBlockId();

  const handleMouseDown = (uuid: string) => {
    const { blocks } = getState();
    const block = blocks[uuid];
    if (!block) {
      return;
    }
    const lastBlockId = last(block.subNodes);
    const lastBlock = lastBlockId ? blocks[lastBlockId] : undefined;

    if (cache.ui.selectedBlocks.length) {
      dispatch(uiActions.update({ selectedBlocks: [] }));
    }

    if (
      lastBlock?.type === BlockType.TEXTAREA &&
      isBlockNoText(lastBlock.data) &&
      lastBlock.subNodes.length === 0
    ) {
      focusEditableAt(lastBlockId, segmentsToText(lastBlock.data.segments).length);
      return;
    }

    transaction(() => {
      const newBlockId = addBlock({}, { parentId: uuid, last: true });
      focusEditableAt(newBlockId, 0);
    });
  };

  return useCallback(handleMouseDown, [focusEditableAt, transaction]);
};
/** 下半部分空白区域 */

export const BlankArea: FC<{ uuid: string; className?: string }> = memo(({ uuid, className }) => {
  const handleMouseDown = useClickBlankAreaCreateBlock();

  return (
    <div
      className={cx('page-footer min-h-[40vh] flex-1 cursor-text', className)}
      onClick={() => handleMouseDown(uuid)}
    />
  );
});

export const AiBlankArea: FC = () => {
  const isOpenAiEditor = useIsOpenAiEditor();
  return <div className={cx(isOpenAiEditor && 'h-[30vh]')}></div>;
};
