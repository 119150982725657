import type { SpaceDTO } from '@next-space/fe-api-idl';
import { BlockType, PermissionRole, PermissionType } from '@next-space/fe-api-idl';
import { omit } from 'lodash-es';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar } from 'src/common/components/avatar';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { message } from 'src/common/components/message';
import { useOpenModal } from 'src/common/components/next-modal';
import { request } from 'src/common/request';
import { getUrlWithoutDomain } from 'src/common/utils/url-utils';
import { useOpenMoveTo } from 'src/components/move-to';
import { useDeletePageCompletely, useFetchRootPages } from 'src/hooks/page';
import { fetchSpaces, getAllSpaces } from 'src/hooks/space';
import { CREATE_BLOCK, TRANSACTION_FIRE } from 'src/redux/actions';
import { addBlock } from 'src/redux/managers/block/add';
import { removeBlock } from 'src/redux/managers/block/remove';
import { setBlockPermission } from 'src/redux/managers/block/update-permission';
import { cache, dispatch } from 'src/redux/store';
import { $currentUserCache } from 'src/services/user/current-user';
import { SearchParams, ViewPath } from 'src/utils';
import { removeDuplicateWith } from 'src/utils/array-util';
import { sequence } from 'src/utils/async-utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { dataNeedForCopy } from 'src/utils/copy-utils';
import { splicePathSearchParams } from 'src/utils/history-utils';
import { setLocalStorage } from 'src/utils/local-storage';
import { qs } from 'src/utils/querystring';
import { ActivityIds } from '../activities/activity-ids';
import { useUpdateActivity } from '../activities/use-update-activity';
import { useBlock } from '../block/use-block';
import { useCheckCopySubNodes, useCopySubNodesApi } from '../block/use-copy-sub-nodes-api';
import { useFetchSpaceCapacity } from '../drive/use-fetch-space-capacity';
import { useGetQuery } from '../page/use-get-query';
import { useCurrentUser } from '../user';
import { getUserInfoByApi } from '../user/get-user';
import { useCheckLogin } from './use-check-login';
import { getPermissions } from './use-permissions';
import { Button } from '@flowus/common/components';
import { isMobile } from 'react-device-detect';

export const COPY_TO_SPACE_UUID = 'copy_to_space_uuid';

export const useCopyToSpace = () => {
  const openModal = useOpenModal();
  const checkLogin = useCheckLogin();
  const getQuery = useGetQuery(location.search);

  return useCallback(
    (uuid: string, opt?: { addCopyLimit?: boolean }) => {
      const isLogin = !!$currentUserCache.uuid;
      if (!isLogin) {
        const inviteCode = getQuery(SearchParams.inviteCode);
        const spaceId = getQuery(SearchParams.spaceId);

        if (inviteCode) {
          checkLogin([
            {
              key: SearchParams.inviteCode,
              value: inviteCode,
            },
            {
              key: SearchParams.spaceId,
              value: spaceId,
            },
          ]);
          return;
        }
        const page = cache.blocks[uuid];
        if (page?.createdBy) {
          // feat: 拷贝到空间，提示登录时，需携带创建人的邀请码
          void getUserInfoByApi(page.createdBy).then((user) => {
            if (user?.inviteCode) {
              checkLogin({
                key: SearchParams.inviteCode,
                value: user.inviteCode,
              });
            } else {
              checkLogin();
            }
          });
        } else {
          checkLogin();
        }

        window.sessionStorage.setItem(COPY_TO_SPACE_UUID, uuid);
        return;
      }

      const node =
        document.getElementById('page-header-more') || document.querySelector('.menu_more');

      if (node) {
        openModal.dropdown({
          popcorn: node,
          placement: 'bottom-end',
          offset: [0, 16],
          content: ({ onCloseModal }) => (
            <CopyToSpace
              addCopyLimit={opt?.addCopyLimit}
              uuid={uuid}
              onSelectSpace={() => {
                onCloseModal();
              }}
            />
          ),
        });
      }
    },
    [checkLogin, getQuery, openModal]
  );
};

export const canCopyToSpace = (uuid: string) => {
  const { allowDuplicate, illegal } = getPermissions(uuid);
  return allowDuplicate && !illegal;
};

export const CopyToSpace: FC<{
  uuid: string;
  onSelectSpace: () => void;
  addCopyLimit?: boolean;
}> = ({ uuid, onSelectSpace, addCopyLimit = false }) => {
  const openModal = useOpenModal();
  const block = useBlock(uuid);
  const moveTo = useOpenMoveTo();
  const fetchSpaceRootPages = useFetchRootPages();
  const loading = useRef('');
  const history = useHistory();
  const allSpaces = getAllSpaces();
  const currentUser = useCurrentUser();
  const updateActivity = useUpdateActivity();
  const copySubNodesApi = useCopySubNodesApi();
  const fetchSpaceCapacity = useFetchSpaceCapacity();
  const getQuery = useGetQuery();
  const checkCopySubNodes = useCheckCopySubNodes();

  useEffect(() => {
    void fetchSpaces().then(() => {
      if (getAllSpaces().length === 0) {
        message.warning('请先创建空间');
        window.sessionStorage.setItem(COPY_TO_SPACE_UUID, uuid);
        history.push(
          splicePathSearchParams(ViewPath.create, {
            key: SearchParams.callbackPath,
            value: location.pathname,
          })
        );
      } else {
        window.sessionStorage.removeItem(COPY_TO_SPACE_UUID);
      }
    });
  }, [history, uuid]);

  const items = useMemo(() => {
    const onClick = async (event: MouseEvent, space: SpaceDTO) => {
      if (!block || loading.current) return;
      onSelectSpace();
      const isCopyFile = block.type === BlockType.FILE;
      if (!isCopyFile) {
        // eslint-disable-next-line require-atomic-updates
        loading.current = openModal.loading({
          title: (
            <LoadingNode
              runBackground={() => {
                openModal.closeModal(loading.current);
              }}
            />
          ),
        }).modalId;
      }
      await checkCopySubNodes({
        ids: [block.uuid],
        spaceId: space.uuid,
        forceAwait: true,
        addCopyLimit,
      });

      // 存起来，模板中心的清空逻辑需要用到
      setLocalStorage(`${space.uuid}_template_id`, block.uuid);
      if (isCopyFile) {
        const popcorn = event.currentTarget as HTMLElement;
        void fetchSpaceRootPages(space.uuid).then(() => {
          moveTo({
            uuid,
            popcorn,
            copyToSpace: space.uuid,
            callback(id: string) {
              void sequence(() => {
                copySuccess(space.uuid, BlockType.FILE, id);
                void updateActivity(ActivityIds.LIMITED_TIME_SHARE_COPY, {
                  createdBy: block.createdBy,
                  ignorePresence: true,
                });
              });
            },
          });
        });
      } else {
        try {
          await fetchSpaceCapacity(space.uuid);
        } catch (err) {
          message.success('拷贝页面超时[query capacity timeout]');
          openModal.closeModal(loading.current);
          return;
        }
        // space 也看作 block, 这里用于骗过 createPage
        dispatch(
          CREATE_BLOCK({
            // @ts-ignore space
            block: { spaceId: space.uuid, type: 'SPACE', data: { icon: space.icon }, ...space },
            ignoreOp: true,
          })
        );
        // 复制页面不要把评论也给复制过来，否则可能因为权限问题无法删除
        const rest = omit(dataNeedForCopy(block), 'discussion');

        let pageType = rest.type;
        if (pageType === BlockType.COLLECTION_VIEW) {
          pageType = BlockType.COLLECTION_VIEW_PAGE;
        } else if (pageType === BlockType.REFERENCE_COLLECTION) {
          pageType = BlockType.REFERENCE_COLLECTION_PAGE;
        } else if (pageType === BlockType.MIND_MAPPING) {
          pageType = BlockType.MIND_MAPPING_PAGE;
        }

        const targetId = addBlock(
          {
            ...rest,
            data: { ...rest.data, pageFixedWidth: !!rest.data.pageFixedWidth },
            type: pageType,
          },
          { parentId: space.uuid, last: true },
          false,
          false
        );

        setBlockPermission(targetId, {
          type: PermissionType.USER,
          userId: currentUser.uuid,
          role: PermissionRole.EDITOR,
        });

        dispatch(TRANSACTION_FIRE());
        void sequence(async () => {
          try {
            const res = await copySubNodesApi({
              list: [{ uuid, targetId, isDescendant: true }],
              targetSpaceId: space.uuid,
              addCopyLimit,
            });
            if (res.code !== 200) return;
            copySuccess(space.uuid, rest.type, targetId, loading.current);
            void updateActivity(ActivityIds.LIMITED_TIME_SHARE_COPY, {
              createdBy: block.createdBy,
              ignorePresence: true,
            });
            if (res.code === 200) {
              copySuccess(space.uuid, rest.type, targetId, loading.current);
              const inviteCode = getQuery(SearchParams.inviteCode);
              const spaceId = getQuery(SearchParams.spaceId);
              if (inviteCode && spaceId) {
                await request.ai.postAiCopyReward({
                  spaceId,
                  inviteCode,
                  targetId,
                });
              }
              void updateActivity(ActivityIds.LIMITED_TIME_SHARE_COPY, {
                createdBy: block.createdBy,
                ignorePresence: true,
              });
              // @ts-ignore code
            } else if (res.code === 412) {
              // 如果复制失败就删掉新创建的那个页面
              removeBlock(targetId, false, true);
              dispatch(TRANSACTION_FIRE());
            }
          } finally {
            openModal.closeModal(loading.current);
            loading.current = '';
          }
        });
      }

      const query = qs.parse(location.search);
      const isCopyTemplateFromWebsite = query.from === 'website' && query.event === 'copy';
      if (isCopyTemplateFromWebsite) {
        bizTracker.event('copy_template', { is_from: 'home_template', template_id: block.uuid });
      } else {
        bizTracker.event('copy_from_share', {
          page_id: uuid,
        });
      }
    };

    const spaceItems = removeDuplicateWith(
      allSpaces.filter((space) => space.permissions),
      'uuid'
    );

    return [
      {
        type: ListItemType.BLOCK_TEXT,
        data: {
          title: '选择一个拷贝到的空间',
        },
      },
      ...spaceItems.map((space) => ({
        type: ListItemType.OPERATION,
        data: {
          onClick: (event: MouseEvent) => onClick(event, space),
          content: (
            <div className="flex items-center w-full">
              <Avatar
                className="flex-shrink-0 !text-t3-medium w-[26px] h-[26px]"
                name={space.title}
                icon={space.icon}
                color={space.backgroundColor}
              />
              <div className="ml-2 text-t2 text-ellipsis">{space.title}</div>
            </div>
          ),
        },
      })),
    ];
  }, [
    addCopyLimit,
    allSpaces,
    block,
    checkCopySubNodes,
    copySubNodesApi,
    currentUser.uuid,
    fetchSpaceCapacity,
    fetchSpaceRootPages,
    getQuery,
    moveTo,
    onSelectSpace,
    openModal,
    updateActivity,
    uuid,
  ]);

  return <ListView className="py-2 w-60 max-h-[50vh] overflow-y-auto next-modal" items={items} />;
};

const copySuccess = (spaceId: string, blockType: BlockType, targetId: string, key?: string) => {
  const mid = message.success({
    key,
    duration: 10000,
    content: (
      <div className="flex items-center justify-between w-52">
        <div className="text-t2">拷贝成功</div>
        {isMobile ? (
          <div
            onClick={() => {
              // 应用跳转协议参考 https://www.notion.so/H5-30f6d62235cc4e858f62df4d364c7cde#9a9cd341d13d4dbfbb5d0ca84d88240c
              const APP_LINK = `flowus://NoteActivity?uuid=${targetId}&type=${blockType}&spaceId=${spaceId}`;
              window.open(APP_LINK, '_blank');
              message.closeMessage(mid);
            }}
            className="text-t4 text-active_color animate-click"
          >
            App 中查看
          </div>
        ) : (
          <div
            onClick={() => {
              window.open(`${getUrlWithoutDomain()}/${targetId}`);
              message.closeMessage(mid);
            }}
            className="text-t4 text-active_color animate-click"
          >
            跳转查看
          </div>
        )}
      </div>
    ),
  });
};

const LoadingNode: FC<{ runBackground: () => void }> = (props) => {
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBtn(true);
    }, 15 * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, []);
  return (
    <div className="w-full text-center">
      <div>正在拷贝</div>
      {showBtn && (
        <Button
          className="w-full mt-2"
          onClick={() => {
            props.runBackground();
          }}
        >
          后台运行
        </Button>
      )}
    </div>
  );
};
