import type { FC } from 'react';
import { GlobalImages } from '../images';
import { isBuildIn } from '../build-in';

export const LogoSlogan: FC = () => {
  return (
    <a className="flex flex-col items-center justify-center text-t3" href="/">
      <img className="w-20 mb-2.5" src={GlobalImages.logoWide} />
      {!isBuildIn() && <span style={{ fontSize: '18px' }}>FlowUs</span>}
    </a>
  );
};
