import type { UploadParams, UploadResult } from '@flowus/upload';
import { fileUploader, UploadErrorCode, uploadInit } from '@flowus/upload';
import type { UploadFail, UploadSuccess } from '@flowus/upload/oss/types';
import type { BlockDataDTO, SpaceDTO } from '@next-space/fe-api-idl';
import { BlockType } from '@next-space/fe-api-idl';
import * as Sentry from '@sentry/browser';
import { useCallback } from 'react';
import { message } from 'src/common/components/message';
import { request } from 'src/common/request';
import type { LocalFolder } from 'src/common/utils/path-utils';
import { parseFiles } from 'src/common/utils/path-utils';
import { addPreviewUrl } from 'src/components/transfer-list/file-url';
import { addParams, removeParams } from 'src/components/transfer-list/retry-util';
import { textToSegments } from 'src/editor/utils/editor';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { TRANSACTION_FIRE } from 'src/redux/actions';
import { addBlock } from 'src/redux/managers/block/add';
import { updateBlock } from 'src/redux/managers/block/update';
import { cache, dispatch } from 'src/redux/store';
import type { NextWhere } from 'src/redux/types';
import { UploadStatus } from 'src/redux/types';
import { getSpaceCapacity, hasSpaceCapacity } from 'src/services/capacity';
import { useGetOtherSpacePublicDataAndPatch } from 'src/services/other-space/hook';
import { removeUploadInfoByKey, updateUploadInfo } from 'src/services/upload';
import { $currentUserCache } from 'src/services/user/current-user';
import { ONE_MINUTES } from 'src/utils/date-utils';
import { getDisplay, getFileNameInfo } from 'src/utils/file';
import { useGetPageId } from 'src/utils/getPageId';
import { urlFetcher } from 'src/utils/url-fetcher';
import { v4 } from 'uuid';
import { ActivitiesListType } from '../activities/use-activity';
import { useUpdateTask } from '../activities/use-update-task';
import { useFetchSpaceCapacity } from '../drive/use-fetch-space-capacity';
import type { DropInfo } from '../page/use-dnd/types';
import { useTransaction } from '../use-transaction';
import { useGetMediaInfo } from '../utils/use-get-media-info';
import { getCurrentSpaceId } from './get-space';
import { getCurrentSpace } from './use-current-space';
import { useOpenCapacityDialog, useUploadCheckCapacity } from './use-open-capacity-dialog';

uploadInit({
  request,
  ossCallbackHost: import.meta.env.VITE_OSS_CALLBACK_PREFIX || `http://${window.location.host}`,
  onError: (e) => {
    Sentry.captureException(e);
  },
});
/**
 * @key 通过key可以查到redux store ui的上传信息
 */
export type UploadParamsExt = Partial<Omit<UploadParams, 'file' | 'onComplete'>> &
  Pick<UploadParams, 'file' | 'onComplete' | 'type'> & {
    key?: string;
    handleFail?: (result: UploadResult) => boolean;
    isDatabase?: boolean;
  };
const updateUploadFail = (key: string | undefined) => {
  key &&
    updateUploadInfo({
      key,
      progress: -1,
      status: UploadStatus.failure,
    });
};

/** 上传结束后失败code统一操作 */
const useUploadOnError = () => {
  const openCapacityDialog = useOpenCapacityDialog();
  return useCallback(
    (
      ret: UploadFail,
      params: {
        singleFileMaxSize: number;
        key?: string;
        planType: SpaceDTO['planType'];
        maxCapacity: number;
        isNovice: boolean;
      }
    ) => {
      const { key, singleFileMaxSize, planType, maxCapacity, isNovice } = params;
      if (ret.errCode === UploadErrorCode.UPLOAD_SINGLE_FILE_CAPACITY_LIMIT) {
        openCapacityDialog({
          title: '上传失败',
          maxCapacity: singleFileMaxSize,
          singleFile: true,
          planType,
          isNovice,
        });
      } else if (ret.errCode === UploadErrorCode.UPLOAD_MAX_CAPACITY_LIMIT) {
        openCapacityDialog({
          title: '上传失败',
          maxCapacity,
          planType,
          isNovice,
        });
      } else if (ret.errCode === UploadErrorCode.UPLOAD_CANCEL) {
        message.success('已取消');
        key && removeUploadInfoByKey(key);
        // 取消了就不需要再执行后面的updateUploadFail
        return;
      } else {
        message.error(ret.errMsg);
      }
      updateUploadFail(key);
    },
    [openCapacityDialog]
  );
};

/** 通用上传（单文件） */
export const useUpload = () => {
  const updateTask = useUpdateTask();
  const fetchSpaceCapacity = useFetchSpaceCapacity();
  const getOtherSpacePublicDataAndPatch = useGetOtherSpacePublicDataAndPatch();
  const uploadCheckCapacity = useUploadCheckCapacity();
  const uploadOnError = useUploadOnError();
  const curPageId = useGetPageId();

  return useCallback(
    async (params: UploadParamsExt) => {
      const {
        onCapacitySuccess,
        onComplete,
        onProgress,
        handleFail,
        userId = $currentUserCache.uuid,
        spaceId = getCurrentSpaceId(),
        key,
        isDatabase,
        inTransferList,
        blockId,
        ...rest
      } = params;
      // 先更新下空间容量
      try {
        if (!hasSpaceCapacity(spaceId)) {
          await fetchSpaceCapacity(spaceId);
        }
      } catch {
        // 没网络也要回调
        updateUploadInfo({
          name: params.file.name,
          size: params.file.size,
          key: key ?? '',
          progress: -1,
          inTransferList,
          blockId,
          pageId: params.pageId ?? curPageId,
          status: UploadStatus.failure,
          uploadId: '',
        });
        onComplete?.({
          success: false,
          errCode: UploadErrorCode.UPLOAD_FAILED,
          errMsg: '',
        });
        return '';
      }

      let { planType } = getCurrentSpace();

      // 如果不是当前空间，再做判断获取空间的逻辑
      if (spaceId !== getCurrentSpace().uuid) {
        const spacesPublic = await getOtherSpacePublicDataAndPatch(spaceId);
        planType = spacesPublic?.planType;
      }

      const { singleFileMaxSize, maxCapacity, isNovice } = getSpaceCapacity(spaceId, {
        isDatabase,
      });

      const checkCapacity = await uploadCheckCapacity({
        fileName: params.file.name,
        fileSize: params.file.size,
        key,
        inTransferList,
        blockId,
        pageId: params.pageId ?? curPageId,
        onComplete,
        spaceId,
        isDatabase,
        type: rest.type,
      });

      if (!checkCapacity) {
        return '';
      }

      const uploadId = fileUploader.upload({
        ...rest,
        isDatabase,
        spaceId,
        userId,
        onCapacitySuccess,
        onProgress: (progress) => {
          onProgress?.(progress);
          key &&
            updateUploadInfo({
              key,
              progress,
              status: UploadStatus.uploading,
            });
        },
        onComplete: (ret) => {
          onComplete?.(ret);
          if (ret.success) {
            if (inTransferList) {
              ret.url &&
                addPreviewUrl(uploadId, {
                  expireTime: ret.expireTime ?? Date.now() + 1.5 * 60 * ONE_MINUTES,
                  url: ret.url,
                });
              removeParams(uploadId);
            }
            key &&
              updateUploadInfo({
                key,
                progress: 100,
                inTransferList,
                blockId,
                pageId: params.pageId ?? curPageId,
                status: UploadStatus.success,
                url: ret.url,
              });
            // setTimeout(() => {
            //   key && removeUploadInfoByKey(key);
            // }, 1000);
            // 积分任务
            void updateTask(ActivityIds.GUIDE_FIRST_TIME_UPLOAD, ActivitiesListType.basicList);
            void updateTask(
              ActivityIds.GUIDE_FIRST_TIME_CREATED_FOLDER,
              ActivitiesListType.basicList
            );
            return;
          }
          // 自己处理就不弹窗
          if (handleFail?.(ret)) return;
          uploadOnError(ret, {
            maxCapacity,
            planType,
            singleFileMaxSize,
            key,
            isNovice,
          });
        },
      });
      key &&
        updateUploadInfo({
          key,
          progress: 0,
          name: params.file.name,
          createdAt: Date.now(),
          uploadId,
          inTransferList,
          blockId,
          pageId: params.pageId ?? curPageId,
          size: params.file.size,
          status: UploadStatus.uploading,
        });
      if (inTransferList) {
        addParams(uploadId, params);
      }
      return uploadId;
    },
    [
      fetchSpaceCapacity,
      getOtherSpacePublicDataAndPatch,
      curPageId,
      updateTask,
      uploadCheckCapacity,
      uploadOnError,
    ]
  );
};

/** 收集表专用 */
export const useUploadWithoutLogin = () => {
  const getOtherSpacePublicDataAndPatch = useGetOtherSpacePublicDataAndPatch();
  const uploadCheckCapacity = useUploadCheckCapacity();
  const uploadOnError = useUploadOnError();
  const fetchSpaceCapacity = useFetchSpaceCapacity();

  return useCallback(
    async (params: UploadParamsExt & { viewId: string; spaceId: string }) => {
      const space = await getOtherSpacePublicDataAndPatch(params.spaceId);
      if (!space) return '';
      await fetchSpaceCapacity(params.spaceId);

      const { onCapacitySuccess, onComplete, onProgress, handleFail, key, ...rest } = params;
      const { planType } = space;
      const { singleFileMaxSize, maxCapacity, isNovice } = getSpaceCapacity(space.uuid, {
        isDatabase: params.isDatabase,
      });

      const checkCapacity = await uploadCheckCapacity({
        fileName: params.file.name,
        fileSize: params.file.size,
        key,
        inTransferList: params.inTransferList,
        onComplete,
        spaceId: params.spaceId,
        isDatabase: params.isDatabase,
        type: rest.type,
      });

      if (!checkCapacity) {
        return '';
      }

      const uploadId = fileUploader.uploadWithoutLogin({
        ...rest,
        singleFileMaxCapacity: singleFileMaxSize,
        onCapacitySuccess,
        onProgress: (progress) => {
          onProgress?.(progress);
          key &&
            updateUploadInfo({
              key,
              progress,
              status: UploadStatus.uploading,
            });
        },
        onComplete: (ret) => {
          onComplete?.(ret);
          if (ret.success) {
            if (ret.url && ret.expireTime) {
              urlFetcher.putCache(ret.ossName, ret.url, ret.expireTime);
            }
            key &&
              updateUploadInfo({
                key,
                progress: 100,
                status: UploadStatus.success,
                url: ret.url,
              });
            // setTimeout(() => {
            //   key && removeUploadInfoByKey(key);
            // }, 1000);
            return;
          }
          // 自己处理就不弹窗
          if (handleFail?.(ret)) return;
          uploadOnError(ret, {
            maxCapacity,
            planType,
            singleFileMaxSize,
            key,
            isNovice,
          });
        },
      });

      key &&
        updateUploadInfo({
          key,
          progress: 0,
          name: params.file.name,
          createdAt: Date.now(),
          uploadId,
          size: params.file.size,
          status: UploadStatus.uploading,
        });

      return uploadId;
    },
    [fetchSpaceCapacity, getOtherSpacePublicDataAndPatch, uploadCheckCapacity, uploadOnError]
  );
};

/** 上传多个文件 文件夹页面专用 */
export const useUploadDriveFiles = () => {
  const fetchSpaceCapacity = useFetchSpaceCapacity();
  const openCapacityDialog = useOpenCapacityDialog();
  const upload = useUpload();
  const transaction = useTransaction();
  const getMediaInfo = useGetMediaInfo();
  return useCallback(
    async (
      params: Omit<UploadParamsExt, 'key' | 'file'> & { ownerPageId: string; files: File[] },
      dropInfo?: DropInfo
    ) => {
      const { files, onComplete, blockId, ownerPageId, onCapacitySuccess, ...rest } = params;

      const getWhere = (defaultValue: NextWhere) => {
        if (!dropInfo) return defaultValue;

        const where: NextWhere = { parentId: defaultValue.parentId };

        if (dropInfo) {
          const { dropId, position } = dropInfo;
          if (dropId) {
            const dropBlock = cache.blocks[dropId];
            if (dropBlock) {
              const parentBlock = cache.blocks[dropBlock.parentId];
              if (parentBlock?.type === BlockType.COLUMN_LIST) {
                where.parentId = ownerPageId;
              } else {
                where.parentId = dropBlock.parentId;
              }
            }
          }

          if (position === 'bottom' || position === 'right') {
            where.after = dropId;
          } else if (position === 'top' || position === 'left') {
            where.before = dropId;
          }
        }

        return where;
      };

      // 如果是多个文件，只需要回调一次，多个文件下不需要回调多次
      let capacitySuccess = onCapacitySuccess;
      const uploadSingeFile = (parentId: string, file: File, where: NextWhere) => {
        const { extName } = getFileNameInfo(file.name);

        const data: BlockDataDTO & { localUrl?: string } = {
          segments: textToSegments(file.name),
          size: file.size,
          extName,
          display: getDisplay(extName),
        };

        if (dropInfo) {
          transaction(() => {
            const fileBlockId = addBlock(
              {
                type: BlockType.FILE,
                data,
                updatedAt: Date.now(),
              },
              where
            );

            void upload({
              ...rest,
              pageId: parentId,
              blockId: fileBlockId,
              key: `${parentId}_${fileBlockId}`,
              file,
              onCapacitySuccess: capacitySuccess,
              async onComplete(ret) {
                onComplete(ret);
                if (ret.success) {
                  if (ret.url && ret.expireTime) {
                    urlFetcher.putCache(ret.ossName, ret.url, ret.expireTime);
                  }
                  const mediaInfo = await getMediaInfo(file);
                  updateBlock(fileBlockId, {
                    data: { ossName: ret.ossName, ...mediaInfo },
                  });
                  dispatch(TRANSACTION_FIRE());
                }
              },
            });
          });
        } else {
          const fileBlockId = blockId ?? v4();
          void upload({
            ...rest,
            pageId: parentId,
            blockId: fileBlockId,
            key: `${parentId}_${fileBlockId}`,
            file,
            onCapacitySuccess: capacitySuccess,
            async onComplete(ret) {
              onComplete(ret);
              if (ret.success) {
                if (ret.url && ret.expireTime) {
                  urlFetcher.putCache(ret.ossName, ret.url, ret.expireTime);
                }
                const mediaInfo = await getMediaInfo(file);
                transaction(() => {
                  addBlock(
                    {
                      uuid: fileBlockId,
                      type: BlockType.FILE,
                      data: {
                        ...data,
                        ossName: ret.ossName,
                        ...mediaInfo,
                      },
                      updatedAt: Date.now(),
                    },
                    where
                  );
                });
              }
            },
          });
        }
      };

      if (files.length === 1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        uploadSingeFile(ownerPageId, files[0]!, getWhere({ parentId: ownerPageId, first: true }));
        return;
      }

      // 多个文件需要计算总容量后再上传
      const capacityInfo = await fetchSpaceCapacity();
      const totalSize = files.reduce((total, cur) => {
        return total + cur.size;
      }, 0);

      if (capacityInfo.currentCapacity + totalSize > capacityInfo.maxCapacity) {
        openCapacityDialog({
          title: '有文件上传失败',
          maxCapacity: capacityInfo.maxCapacity,
          isNovice: capacityInfo.isNovice,
        });
        return;
      }

      const rootFolder = parseFiles(files);
      if (!rootFolder) return;

      capacitySuccess = undefined;
      onCapacitySuccess?.();

      const uploadFolder = (
        parentId: string,
        folder: LocalFolder,
        where: NextWhere,
        isFirst = false
      ) => {
        if (folder.name) {
          transaction(
            () => {
              // 如果有名字说明有目录，名字为空说明用户上传的不是单目录
              parentId = addBlock(
                {
                  type: BlockType.FOLDER,
                  data: {
                    segments: textToSegments(folder.name),
                  },
                },
                where
              );

              folder.blockId = parentId;

              if (isFirst) {
                where.parentId = parentId;
              }
            },
            { noThrottle: true }
          );
        }

        folder.files.forEach((f) => {
          uploadSingeFile(parentId, f, isFirst ? where : { parentId, first: true });
        });

        for (const f of folder.folders) {
          uploadFolder(parentId, f, isFirst ? where : { parentId, last: true });
        }

        return folder.blockId;
      };

      const where = getWhere({ parentId: ownerPageId, last: true });

      return uploadFolder(where.parentId, rootFolder, where, true);
    },
    [fetchSpaceCapacity, getMediaInfo, openCapacityDialog, transaction, upload]
  );
};

/** 上传多个文件，非文件夹页面上传可使用 */
export const useUploadMultiFiles = () => {
  const fetchSpaceCapacity = useFetchSpaceCapacity();
  const openCapacityDialog = useOpenCapacityDialog();
  const upload = useUpload();

  return useCallback(
    async (
      params: Omit<UploadParamsExt, 'key' | 'file' | 'onComplete'> & {
        files: File[];
        blockIds: string[];
        keys: string[]; // 一般用对应的file blockId关联进度条，如果不是blockId的话需要自己维护关联信息
        onComplete: (result: (UploadSuccess & { key: string }) | UploadFail) => void;
      }
    ) => {
      const { files, onComplete, onCapacitySuccess, keys, blockIds, ...rest } = params;
      // 如果是多个文件，只需要回调一次，多个文件下不需要回调多次
      let capacitySuccess = onCapacitySuccess;

      const uploadSingeFile = (key: string, file: File, blockId?: string) => {
        void upload({
          ...rest,
          blockId,
          key,
          file,
          onCapacitySuccess: capacitySuccess,
          async onComplete(ret) {
            if (ret.success) {
              if (ret.url && ret.expireTime) {
                urlFetcher.putCache(ret.ossName, ret.url, ret.expireTime);
              }
              // 外面需要跟上传成功的某个文件关联上，通过key
              onComplete({ ...ret, key });
            } else {
              onComplete(ret);
            }
          },
        });
      };
      if (files.length === 1) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        uploadSingeFile(keys[0]!, files[0]!, blockIds[0]!);
        return;
      }
      // 多个文件需要计算总容量后再上传
      const capacityInfo = await fetchSpaceCapacity();
      const totalSize = files.reduce((total, cur) => {
        return total + cur.size;
      }, 0);
      if (capacityInfo.currentCapacity + totalSize > capacityInfo.maxCapacity) {
        openCapacityDialog({
          title: '有文件上传失败',
          maxCapacity: capacityInfo.maxCapacity,
          isNovice: capacityInfo.isNovice,
        });
        return;
      }
      if (files.length !== keys.length) {
        throw Error('files length is not equal fileBlockId length');
      }
      // 多文件上传至回调一次即可
      capacitySuccess = undefined;
      onCapacitySuccess?.();
      files.forEach((f, index) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        uploadSingeFile(keys[index]!, f, blockIds[index]);
      });
    },
    [fetchSpaceCapacity, openCapacityDialog, upload]
  );
};
