import { BlockType, CollectionSchemaType } from '@next-space/fe-api-idl';
import type { VirtualElement } from '@popperjs/core';
import type { FC } from 'react';
import { useOpenModal } from 'src/common/components/next-modal';
import type { SearchItem } from 'src/components/search';
import { SearchMenu } from 'src/components/search';
import { useUpdatePropertySchema } from 'src/hooks/block/use-update-property-schema';
import { getState } from 'src/redux/store';
import { getDefaultSearchList } from 'src/utils/block-utils';
import { v4 } from 'uuid';

import { SelectRelationType } from './select-relation-type';

export const SelectCollection: FC<{
  collectionId: string;
  propertyId: string;
  closeSelf: () => void;
  popcorn: VirtualElement;
}> = ({ collectionId, propertyId, closeSelf, popcorn }) => {
  const openModal = useOpenModal();
  const updatePropertySchema = useUpdatePropertySchema();
  const collection = getState().blocks[collectionId];
  if (!collection) return null;
  const defaultItems = getDefaultSearchList({
    ancestorId: collection.spaceId,
    findTypes: [BlockType.COLLECTION_VIEW, BlockType.COLLECTION_VIEW_PAGE],
  });

  const handleSelectTable = (target: SearchItem) => {
    if (target.uuid === collectionId) {
      openModal.dropdown({
        popcorn,
        placement: 'bottom',
        content: ({ onCloseModal }) => {
          return <SelectRelationType propertyId={propertyId} closeSelf={onCloseModal} />;
        },
      });
      closeSelf();
    } else {
      const pairProperty = v4();
      const { blocks } = getState();
      updatePropertySchema(collectionId, propertyId, {
        type: CollectionSchemaType.RELATION,
        collectionId: target.uuid,
        spaceId: blocks[target.uuid]?.spaceId,
        pairProperty,
        formula: {
          version: 2,
          text: '',
          refProps: {},
        },
      });
      closeSelf();
    }
  };
  return (
    <SearchMenu
      defaultValue={defaultItems}
      onSelect={handleSelectTable}
      placeholder="搜索多维表"
      searchType="collection"
      source="collectionSettingsSource"
      header={<p className="text-t2 mb-2 flex items-center pl-4 text-grey3">选择关联的多维表</p>}
    />
  );
};
