import { Input } from '@flowus/common/components/input';
import { message } from '@flowus/common/components/message';
import { useOpenModal } from '@flowus/common/next-modal';
import Fuse from 'fuse.js';
import moment from 'moment-timezone';
import type { FC, MouseEvent } from 'react';
import { useMemo, useState } from 'react';
import { ListItemType, ListView } from 'src/common/components/list-view';
import { listViewNormalClassName } from 'src/common/components/list-view/helper';
import { useCurrentUser, useUpdateUser } from 'src/hooks/user';
import { v4 } from 'uuid';
import { SettingModalCommon } from '../common';
import { Option } from '../option';
const modalId = v4();

// 获取所有时区的信息
const timezones = moment.tz.names().map((zoneName) => {
  const offset = moment.tz(zoneName).format('Z'); // 获取 +/-HH:mm 格式的偏移
  const gmtOffset = offset.replace(/^(-|\+)/, (match) => (match === '-' ? '-' : '+'));
  const numericOffset = parseInt(offset.replace(':', ''), 10) / 100;

  return {
    label: `(GMT${gmtOffset}) ${zoneName} (GMT${numericOffset > 0 ? '+' : ''}${numericOffset})`,
    value: zoneName, // 原始时区名称，用于保存到数据库
  };
});

export const TimezoneSetting: FC<{}> = () => {
  const openModal = useOpenModal();
  const currentUser = useCurrentUser();
  const timezone = currentUser.timeZone ?? moment.tz.guess();
  const label = timezones.find((i) => i.value === timezone)?.label;

  const openTimezoneMenu = (event: MouseEvent) => {
    openModal.dropdown({
      modalId,
      popcorn: event.currentTarget,
      content: () => <SelectList />,
    });
  };

  return (
    <Option
      showSplitLine
      title="时区"
      description="时区设置关乎到闹钟日期提醒、消息推送"
      rightPart={<SettingModalCommon.DropdownButton onClick={openTimezoneMenu} content={label} />}
    />
  );
};

const SelectList = () => {
  const currentUser = useCurrentUser();
  const timezone = currentUser.timeZone ?? moment.tz.guess();
  const updateUser = useUpdateUser();
  const openModal = useOpenModal();
  const [keywords, setKeywords] = useState('');

  const fuse = useMemo(() => {
    return new Fuse(timezones, {
      keys: ['title', 'value'],
      threshold: 0.3,
      minMatchCharLength: 1,
    });
  }, []);

  const searchResults = useMemo(() => {
    if (!keywords) return timezones;
    return fuse.search(keywords).map((result) => result.item);
  }, [keywords, fuse]);

  return (
    <ListView
      customHeader={
        <Input
          className="p-1 mx-2.5 my-1"
          autoFocus
          value={keywords}
          onChange={setKeywords}
          placeholder="搜索"
        />
      }
      virtualList
      className={listViewNormalClassName}
      items={searchResults.map((item) => ({
        type: ListItemType.OPERATION,
        data: {
          title: item.label,
          value: item.value,
          selected: item.value === timezone,
        },
      }))}
      onItemClick={async (item) => {
        await updateUser({ timeZone: item.data.value });
        message.success('设置成功');
        openModal.closeModal(modalId);
      }}
    />
  );
};
