import { cx } from '@flowus/common/cx';
import { useSetAtom } from 'jotai';
import type { FC } from 'react';
import { useState } from 'react';
import { publicLink } from 'src/common/const';
import type { TemplateCategory } from 'src/hooks/template/types';
import { Images } from 'src/image';
import { bizTracker } from 'src/utils/biz-tracker';

import { searchResultState } from './template-context';
import { isBuildIn } from 'src/env';

interface Props {
  officialTemplates: TemplateCategory[];
  currentCategory: string;
  setCurrentCategory: React.Dispatch<React.SetStateAction<string>>;
}

export const TemplateAside: FC<Props> = (props) => {
  const { officialTemplates, currentCategory, setCurrentCategory } = props;
  const setSearchResult = useSetAtom(searchResultState);

  return (
    <div className="w-[240px] bg-white2 border-r flex-shrink-0">
      <div className="w-full overflow-auto relative h-[calc(100%-50px)]">
        <div className="text-t2-medium py-[5px]">
          <div
            onClick={() => {
              setCurrentCategory('myTemplate');
              bizTracker.event('template_category', { category_id: '我的模板' });
              setSearchResult({});
            }}
            className={cx(
              'h-10 flex items-center cursor-pointer hover:bg-black_003 rounded pl-[30px] mx-2',
              currentCategory === 'myTemplate' && 'bg-black_006'
            )}
          >
            我的模板
          </div>
          {isBuildIn() && (
            <div
              onClick={() => {
                setCurrentCategory('purchased');
                setSearchResult({});
              }}
              className={cx(
                'h-10 flex items-center cursor-pointer hover:bg-black_003 rounded pl-[30px] mx-2',
                currentCategory === 'purchased' && 'bg-black_006'
              )}
            >
              已购买
            </div>
          )}
        </div>

        <div className="bg-grey6 h-px my-1 mx-2" />

        <div>
          {officialTemplates.map((first) => {
            return (
              <CategoryList
                key={first.name}
                first={first}
                changeCategory={(categoryPath) => {
                  setCurrentCategory(categoryPath);
                  setSearchResult({});
                }}
                currentCategory={currentCategory}
              />
            );
          })}
        </div>
      </div>

      <div
        className="hover:normal-bg w-full cursor-pointer rounded-bl"
        onClick={() => window.open(publicLink.templateContribution)}
      >
        <div className="h-px bg-grey6 w-full" />
        <div className="ml-4 flex items-center">
          <img src={Images.submit} className="w-5 h-5" />
          <div className="text-t2-medium h-[50px] flex items-center ml-2">投稿你的模板</div>
        </div>
      </div>
    </div>
  );
};

const THRESHOLD = 5;

const CategoryList: FC<{
  first: TemplateCategory;
  changeCategory: (path: string) => void;
  currentCategory?: string;
}> = ({ first, changeCategory, currentCategory }) => {
  const [showMore, setShowMore] = useState(false);

  const { name, subGroups: templateGroups } = first;
  if (!templateGroups) return null;

  return (
    <div key={name} className="flex flex-col">
      <h2 className="text-t2-medium h-[50px] flex items-center pl-[30px]">{name}</h2>

      {(showMore ? templateGroups : templateGroups.slice(0, THRESHOLD)).map((second) => {
        if (second.templates?.length === 0 && second.subGroups?.length === 0) {
          return null;
        }

        const categoryPath = `${name}/${second.name}`;
        return (
          <button
            key={categoryPath}
            onClick={() => {
              changeCategory(categoryPath);
              bizTracker.event('template_category', { category_id: second.name });
            }}
            className={cx(
              'h-10 flex items-center text-t2-medium text-grey2 mx-2 pl-[42px] cursor-pointer rounded hover:bg-black_003',
              currentCategory === categoryPath && 'bg-black_006'
            )}
          >
            {second.name}
          </button>
        );
      })}

      {templateGroups.length > THRESHOLD && (
        <button
          className="text-t2 text-grey3 mx-2 pl-[42px] animate-hover h-10 flex items-center"
          onClick={() => setShowMore(!showMore)}
        >
          {!showMore ? '显示更多' : '收起'}
        </button>
      )}
    </div>
  );
};
