/**
 * 引导程序
 */
// 需要 hack scheduler 所以放到前面
import { refreshSessionParams } from '@flowus/common/storage/activity';
import { initSceneCss } from '@flowus/common/style';
import { AUTH_PATH, isInWeapp, WECHAT_CODE, WECHAT_STATE } from '@flowus/login/utils';
import '@next-space/fe-inlined';
import Cookies from 'js-cookie';

import { render } from 'react-dom';
import * as App from './app';
import { USER_ID } from './common/const';
import { getDomain } from './common/utils/url-utils';
import { $searchParams, ViewPath } from './utils';
import { isFlowUsApp } from './utils/electron-util';
import { setLocalStorage } from './utils/local-storage';
import { getLocationOrigin, locationReplace } from './utils/location-utils';

initSceneCss();
const isPrint = $searchParams.print;

// 后台导出 pdf 会用到
if (isPrint && $searchParams.token && $searchParams.sig && $searchParams.userId) {
  Cookies.set('next_auth', $searchParams.token);
  Cookies.set('next_auth.sig', $searchParams.sig);
  setLocalStorage(USER_ID, $searchParams.userId);
}

// #region 样式增强

// #endregion

// #region 路由守卫
export const toPublicHome = () => {
  if (location.pathname === ViewPath.main && getDomain()) {
    return true;
  }
};

export const toProduct = async () => {
  if (location.pathname === ViewPath.main) {
    if (__PRIVATE__) {
      locationReplace(`${getLocationOrigin()}${ViewPath.login}`);
      return true;
    }
    // 未登录访问 '/' 跳转 product 页面
    if (isFlowUsApp.check || isInWeapp()) {
      return false;
    }
    locationReplace(`${getLocationOrigin()}${ViewPath.product}${location.search}${location.hash}`);
    return true;
  }
};

/**
 * 复制的分享链接，直接粘贴在浏览器需要处理一下
 */
export const copy2Share = () => {
  const { search, hash } = location;

  const prefix = ViewPath.share;
  const shareIndex = location.pathname.indexOf(prefix);
  if (shareIndex === -1) return;

  const blockId = location.pathname.slice(shareIndex + prefix.length);
  if (blockId.length > 36) {
    const path = location.pathname.slice(0, shareIndex + prefix.length + 36);
    locationReplace(`${path}${search}${hash}`);
    return true;
  }
};
// #endregion

// #region 挂载视图
/**
 * 挂载应用视图
 */
export const mountApp = () => {
  render(
    <App.AppProvider>
      <App.AppLayout>
        <App.AppContent />
        <App.AppVersion />
      </App.AppLayout>
    </App.AppProvider>,
    document.getElementById('root')
  );
};

// #endregion

// #region 异常捕获

const catchers: ((...args: any[]) => any)[] = [
  (msg: string) => {
    // fe-inlined
    if (msg === 'Set selection out of content range') {
      // Give develper a chance to open devtools. Only works for firefox.
      // alert(msg);
      // eslint-disable-next-line no-debugger
      return true;
    }
  },
];

// eslint-disable-next-line no-console
const ORIGINAL_WARN = console.warn;

export const catchWarnings = () => {
  if (__HOST_LOCAL__) {
    // eslint-disable-next-line no-console
    console.warn = (...args) => {
      for (const catcher of catchers) {
        if (catcher(...args)) {
          return;
        }
      }
      ORIGINAL_WARN.call(console, ...args);
    };
  }
};

// #endregion

// #region 初始化 inviteCode
export const initInviteCode = () => {
  refreshSessionParams();
};
// #endregion

/** 检测第三方登录callback */
export const checkAuthCallback = () => {
  if (!location.pathname.startsWith(AUTH_PATH)) return;
  const params = new URLSearchParams(location.search);
  const code = params.get(WECHAT_CODE);
  const state = params.get(WECHAT_STATE);
  if (code && state) {
    // iframe发给外面window
    window.parent.postMessage({
      code,
      state,
    });
    return true;
  }
};
