import {
  QASearchSource,
  type QaBlockResource,
  type QAQueryOptionDTO,
  type QASearchResultDTO,
} from '@next-space/fe-api-idl';
import { useCallback, useMemo } from 'react';
import { GlobalImages } from '../../images';
import { getEditor } from './cache';
import { useChatContext, useConversationContext, useQuestionContext } from './context';
import {
  chatStatusStore,
  conversationEditStore,
  conversationStore,
  getChatStatus,
  searchPageStore,
  setChatStatus,
} from './store';
import { ChatTools, ConversationTools, QuestionTools } from './store-tools';
import type { RefDataDTO } from './types';
import {
  getDefaultConversation,
  AI_PAGE_ID,
  ChatStatus,
  getDefaultChat,
  getDefaultConversationEditStore,
} from './types';
import { GLOBAL_CONSTANTS, useChatTranslation } from './use-i18n';
import { getPageTitleInfo } from './use-search';
import { ChatType } from '../types';
import { message } from '../../components/message';

export const useConversation = () => {
  const { conversationId } = useConversationContext();
  const conversation = conversationStore((state) => state[conversationId]);
  return conversation ?? getDefaultConversation();
};

export const useConversationEdit = () => {
  const { conversationId } = useConversationContext();
  const conversationEdit = conversationEditStore((state) => state[conversationId]);
  return conversationEdit || getDefaultConversationEditStore();
};

export const getQAQueryOption = (qaQueryOption?: QAQueryOptionDTO): Required<QAQueryOptionDTO> => {
  const defaultOption = getDefaultConversationEditStore()
    .qaQueryOption as Required<QAQueryOptionDTO>;

  const res = {
    searchBlocks: qaQueryOption?.searchBlocks ?? defaultOption.searchBlocks,
    qaSearchSource: qaQueryOption?.qaSearchSource ?? defaultOption.qaSearchSource,
    networkSearch: qaQueryOption?.networkSearch ?? defaultOption.networkSearch,
    model: qaQueryOption?.model ?? defaultOption.model,
  };

  return res;
};

export const useQAQueryOption = (): Required<QAQueryOptionDTO> => {
  const { qaQueryOption } = useConversationEdit();
  return getQAQueryOption(qaQueryOption);
};

/**
 * 保持不要有直接使用 store 监听，这里只负责提供 set 类方法
 * 如果需要监听，请单独写 hook 使用
 */
export const useConversationTools = () => {
  const { conversationId, navigateToChat, spaceId, parentId } = useConversationContext();
  const conversationTools = useMemo(() => new ConversationTools(conversationId), [conversationId]);
  const { setNetworkSearch, addQaSearchSource } = conversationTools;
  const { networkSearch, qaSearchSource } = useQAQueryOption();
  const { t } = useChatTranslation();
  let chatType = ChatType.SEARCH;
  //现在模式有3种，第一种是联网+大模型，第二种是空间(不联网)，第三种是大模型
  if (!networkSearch) {
    const isSelectOnlyAIModel = QASearchSource.AI_MODEL === qaSearchSource;
    if (isSelectOnlyAIModel) {
      chatType = ChatType.AI_MODAL;
    } else {
      chatType = ChatType.SPACE;
    }
  }
  const switchChatType = useCallback(
    (chatType: ChatType) => {
      switch (chatType) {
        case ChatType.SEARCH: {
          setNetworkSearch(true);
          addQaSearchSource(QASearchSource.AI_MODEL);
          break;
        }
        case ChatType.SPACE: {
          addQaSearchSource(QASearchSource.ALL);
          setNetworkSearch(false);
          break;
        }
        case ChatType.AI_MODAL: {
          addQaSearchSource(QASearchSource.AI_MODEL);
          setNetworkSearch(false);
          break;
        }
        default:
      }
    },
    [addQaSearchSource, setNetworkSearch]
  );

  return useMemo(
    () => ({
      chatType,
      ...conversationTools,
      switchChatType,
      summarize: () => {
        const editor = getEditor(conversationId);
        if (editor) {
          const content = editor.getText();
          const command = t('chatHomeSummarize');
          if (!content.includes(command)) {
            editor.commands.insertContent(`${command} @`);
          } else {
            editor.commands.insertContent(` @`);
          }
          editor.commands.focus();
        }
      },
      submitQuestion: async (params?: { _keywords?: string }) => {
        const { keywords: __keywords, qaQueryOption: _qaQueryOption } =
          conversationTools.getConversationEdit();

        const keywords = params?._keywords || __keywords;
        const status = getChatStatus(conversationId);

        if (keywords.length === 0) {
          return;
        }
        if (status !== ChatStatus.DEFAULT) {
          setChatStatus(conversationId, ChatStatus.DEFAULT);
          return;
        }

        const qaQueryOption = getQAQueryOption(_qaQueryOption);

        if (conversationId === AI_PAGE_ID) {
          const newChatId = await conversationTools.createConversation({
            parentId,
            spaceId,
            keywords,
            qaQueryOption,
          });
          newChatId && navigateToChat(newChatId);
          return;
        }

        void conversationTools.createQuestion({
          keywords,
          spaceId,
          qaQueryOption,
        });
      },
    }),
    [
      chatType,
      conversationId,
      conversationTools,
      navigateToChat,
      parentId,
      spaceId,
      switchChatType,
      t,
    ]
  );
};

export const useQuestionTools = (id?: string) => {
  const { conversationId, questionId } = useQuestionContext();
  const questionTools = useMemo(
    () => new QuestionTools(id ?? questionId, conversationId),
    [id, questionId, conversationId]
  );
  return questionTools;
};

export const useQuestions = () => {
  const { conversationId } = useConversationContext();
  const questions = conversationStore((state) => state[conversationId]?.questions);
  return questions ?? [];
};

export const useChatTools = () => {
  const { chatId, conversationId, questionId } = useChatContext();
  const chatTools = useMemo(
    () => new ChatTools(conversationId, questionId, chatId),
    [chatId, conversationId, questionId]
  );
  return chatTools;
};

export const useChat = () => {
  const { chatId, conversationId, questionId } = useChatContext();
  const chat = conversationStore((state) => {
    const question = state[conversationId]?.questions.find((item) => item.id === questionId);
    if (!question) return getDefaultChat();
    const cur = question.chatList.find((item) => item.uuid === chatId);
    return Object.assign(getDefaultChat(), cur);
  });
  return chat;
};

export const getRefData = (resultData?: QASearchResultDTO): RefDataDTO[] => {
  const res: RefDataDTO[] = [];

  if (!resultData) return res;

  (resultData?.vectorResult || []).forEach((item) => {
    if (!item.uuid) return;
    const cur = searchPageStore.getState().pages[item.uuid];
    if (!cur) return;

    const { title } = getPageTitleInfo(cur);

    res.push({
      id: item.uuid,
      type: 'block',
      title,
      url: `/${cur.uuid}`,
      content: item.content,
      engine: GLOBAL_CONSTANTS.PRODUCT_NAME,
      img_src: GlobalImages.favicon,
    });
  });

  (resultData?.engineResult || []).forEach((item) => {
    res.push({ ...item, type: 'web' });
  });

  return res;
};

export const useChatResultData = () => {
  const { resultData } = useChat();

  const refData = searchPageStore(() => getRefData(resultData));
  return refData;
};

export const useSearchPageStore = () => {
  return searchPageStore((state) => state);
};

export const useChatStatus = (id: string) => {
  return chatStatusStore((state) => state[id]) ?? ChatStatus.DEFAULT;
};

export const useLiteConversationIdByParentId = (parentId: string) => {
  return conversationStore((state) => {
    const conversation = Object.values(state).find((item) => item.parentId === parentId);
    if (!conversation) return;
    return conversation.id;
  });
};
