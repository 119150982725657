import { ossVideoSnapshotUrl } from '@flowus/common/url';
import { useState } from 'react';
import ReactPlayer from 'react-player';
import { Icon } from 'src/common/components/icon';
import { LoadingContainer } from 'src/common/components/loading-container';
import { urlFetcher } from 'src/utils/url-fetcher';
import type { VideoJSProps } from './_video';
import { judgeSharePage } from 'src/utils/getPageId';

export const VideoJS = (props: VideoJSProps) => {
  const light = ossVideoSnapshotUrl(props.videoUrl);
  // const showLight = __BUILD_IN__ ? false : light;
  const [showVideo, setShowVideo] = useState(true);
  const compressUrl = urlFetcher.getCompressVideoUrl(props.videoUrl);
  const [openCompressVideo, setOpenCompressVideo] = useState(!!compressUrl);
  const url = openCompressVideo ? compressUrl : props.videoUrl;

  return (
    <div
      className="relative group"
      onContextMenu={(e) => {
        if (judgeSharePage() && !props.allowDownload) {
          e.preventDefault();
        }
      }}
    >
      {showVideo ? (
        <ReactPlayer
          loading="lazy"
          width="100%"
          height="100%"
          controls
          url={url}
          stopOnUnmount
          style={props.style}
          fallback={<LoadingContainer />}
          config={{
            file: { attributes: { controlsList: 'nodownload' } },
          }}
        />
      ) : (
        <div
          onClick={() => setShowVideo(true)}
          className="relative flex items-center justify-center cursor-pointer"
        >
          <Icon className="absolute z-10 text-grey3" name="IcMenuNext" size="max" />
          <img src={light} />
        </div>
      )}
      <div
        className="hidden group-hover:block absolute p-1 right-1 top-1 bg-white1 hover:bg-white1/80 animate-hover text-t4"
        hidden={!compressUrl}
        onClick={() => setOpenCompressVideo(!openCompressVideo)}
      >
        {openCompressVideo ? '高清' : '原画'}
      </div>
    </div>
  );
};
