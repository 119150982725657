import { createSetState, createStore } from '@flowus/common/zustand/utils';
import { fastEqual } from '@flowus/common/utils/tools';
import type { CursorArea, Position, TocInfo } from 'src/hooks/page/use-dnd/types';
import { Aside, DRIVE } from 'src/hooks/page/use-dnd/types';
import { useColumnResizing, useIsEditingPage, useIsSelecting } from 'src/services/app';
import { useMemo } from 'react';

/** 拖拽时 hover 的 drop 块位置信息 */
export type DropInfoState =
  | {
      dropId?: string;
      position?: Position;
      dropInChild?: boolean;
      cursorArea?: CursorArea;
      tocInfo?: TocInfo;
      viewId?: string;
      groupValue?: string;
      subGroupValue?: string;
      syncId?: string;
      mindPageId?: string;
      calendarDate?: number;
      empty?: {
        viewId?: string;
        groupValue?: string;
        propertyId?: string;
        subGroupValue?: string;
        subGroupPropertyId?: string;
        collectionId?: string;
      };
      sourceRelationParentId?: string;
      targetRelationParentId?: string;
      addSubitem?: boolean;
    }
  | undefined;

export const $dropInfo = createStore<DropInfoState>(() => undefined);

const $setDropInfo = createSetState($dropInfo);

export const getDropInfo = (): Readonly<DropInfoState> => {
  return $dropInfo.getState();
};

export const reSetDropInfo = () => {
  $setDropInfo(() => undefined);
};

export const setDropInfo = (newState: DropInfoState) => {
  $setDropInfo(() => newState);
};

export const useDropInfo = () => {
  return $dropInfo((state) => state, fastEqual);
};

export const useIsDraggingBlock = () => {
  return $dropInfo((state) => !!state);
};

export const useDropInfoIsHovering = (id: string) => {
  return $dropInfo((state) => {
    if (state?.cursorArea !== DRIVE.ALL) return;
    return id === state?.dropId;
  });
};

export const useDropInfoNewPageShowLine = () => {
  return $dropInfo((state) => {
    if (!state) return;
    if (state.cursorArea !== Aside.BOTTOM) return;
    return true;
  });
};

export const useDropInfoPositionById = (id: string) => {
  return $dropInfo((state) => {
    if (state?.dropId === id) {
      return state.position;
    }
  }, fastEqual);
};

export const useCursorArea = () => {
  return $dropInfo((state) => state?.cursorArea, fastEqual);
};

export const useDisableInteractive = () => {
  const isSelecting = useIsSelecting();
  const isDragging = useIsDraggingBlock();
  const isEditingPage = useIsEditingPage();
  const isColumnResize = useColumnResizing();
  return useMemo(
    () => isSelecting || isDragging || isEditingPage || isColumnResize,
    [isColumnResize, isDragging, isEditingPage, isSelecting]
  );
};
