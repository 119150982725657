import type { CollectionViewGroupBy, UserDTO } from '@next-space/fe-api-idl';
import { CollectionSchemaType, GroupLevel, TextType } from '@next-space/fe-api-idl';
import { createSelector } from 'reselect';
import { getGroupLevel, getNumberGroupLevel } from 'src/bitable/bitable-manager/group-list/utils';
import { getDatePropertyFromBlock, readUrlFromSegments } from 'src/bitable/table-view/cell/helpers';
import { getRelationRecords } from 'src/bitable/table-view/cell/relation/get-relation-records';
import { segmentsToText } from 'src/editor/utils/editor';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import type { NextBlock } from 'src/redux/types';

import { EMPTY_GROUP_NAME } from './const';
import { getGroupNameFromDate, getNumberGroupName } from './date-number-groupname';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';
import { formula } from '@flowus/formula';

interface State {
  blocks: Record<string, NextBlock>;
  users: Record<string, UserDTO>;
  collectionId: string;
  recordId: string;
  groupProperty: string;
  groupBy: CollectionViewGroupBy;
}

export const selectBlockGroupNames = createSelector(
  (state: State) => state.blocks,
  (state: State) => state.users,
  (state: State) => state.collectionId,
  (state: State) => state.recordId,
  (state: State) => state.groupProperty,
  (state: State) => state.groupBy,
  (blocks, users, collectionId, recordId, groupProperty, groupBy) => {
    const block = blocks[recordId];
    if (!block) return [];

    const collection = blocks[collectionId];
    const schema = collection?.data.schema?.[groupProperty];
    if (!schema) return [];

    const schemaType = schema.type;
    let values: string[] = [];

    switch (schemaType) {
      case CollectionSchemaType.SELECT:
      case CollectionSchemaType.MULTI_SELECT: {
        values = segmentsToText(block.data.collectionProperties?.[groupProperty])
          .trim()
          .split(/\s*,\s*/g)
          .map((item) => schema.options?.find((option) => option.value === item)?.value)
          .filter((item): item is string => !!item);

        if (schemaType === CollectionSchemaType.SELECT) {
          values = values.slice(0, 1);
        }
        break;
      }

      case CollectionSchemaType.CHECKBOX: {
        const segments = block.data.collectionProperties?.[groupProperty];
        values = [formatCheckBoxValue(segmentsToText(segments)) ? 'YES' : 'NO'];
        break;
      }

      case CollectionSchemaType.CREATED_BY:
      case CollectionSchemaType.UPDATED_BY:
      case CollectionSchemaType.PERSON: {
        if (schemaType === CollectionSchemaType.PERSON) {
          const segments = block.data.collectionProperties?.[groupProperty] ?? [];
          values = segments
            .filter((item) => item.type === TextType.USER && users[item.uuid ?? ''])
            .map((item) => item.uuid)
            .filter((uuid): uuid is string => !!uuid);
        } else {
          const isCreateBy = schemaType === CollectionSchemaType.CREATED_BY;

          const userId = isCreateBy ? block.createdBy : block.updatedBy;

          if (userId && users[userId]) {
            values = [userId];
          }
        }
        break;
      }

      case CollectionSchemaType.RELATION: {
        const { relationRecords } = getRelationRecords(block.uuid, groupProperty, blocks);
        values = relationRecords;
        break;
      }

      case CollectionSchemaType.NUMBER: {
        const { start, end, size } = getNumberGroupLevel(groupBy);

        const value = segmentsToText(block.data.collectionProperties?.[groupProperty]);
        let num = parseFloat(value);
        if (Number.isNaN(num)) {
          num = parseFloat(value.match(/[+-]?\d+(?:\.?\d+)?/)?.[0] ?? '');
        }

        if (!Number.isNaN(num)) {
          values = [getNumberGroupName(num, start, end, size)];
        }
        break;
      }

      case CollectionSchemaType.CREATED_AT:
      case CollectionSchemaType.UPDATED_AT:
      case CollectionSchemaType.DATE: {
        let timestamp: number | undefined;

        if (schemaType === CollectionSchemaType.DATE) {
          const date = getDatePropertyFromBlock(block, groupProperty);
          timestamp = date?.timestamp;
        } else if (schemaType === CollectionSchemaType.CREATED_AT) {
          timestamp = block.createdAt;
        } else {
          timestamp = block.updatedAt;
        }

        if (!timestamp) {
          values = [];
          break;
        }

        const result = getGroupNameFromDate(timestamp, getGroupLevel('date', groupBy?.groupLevel));
        if (result) {
          values = [result];
        }

        break;
      }

      case CollectionSchemaType.TITLE:
      case CollectionSchemaType.TEXT:
      case CollectionSchemaType.PHONE:
      case CollectionSchemaType.URL:
      case CollectionSchemaType.EMAIL: {
        const groupLevel = getGroupLevel('text', groupBy?.groupLevel);

        let value = '';

        if (
          schemaType === CollectionSchemaType.PHONE ||
          schemaType === CollectionSchemaType.URL ||
          schemaType === CollectionSchemaType.EMAIL
        ) {
          const segments = block.data.collectionProperties?.[groupProperty];
          value = readUrlFromSegments(segments);
        } else if (schemaType === CollectionSchemaType.TITLE) {
          value = segmentsToText(block.data.segments);
        } else {
          value = segmentsToText(block.data.collectionProperties?.[groupProperty]);
        }

        value = value.trim();
        if (groupLevel === GroupLevel.ALPHABET_PREFIX) {
          value = value.charAt(0).toUpperCase();
        }

        if (value) {
          values = [value];
        }

        break;
      }

      case CollectionSchemaType.FORMULA: {
        const formulaTool = getFormulaTool(collectionId);

        const schemaType = formulaTool.getTypeAsCollectionSchemaType(groupProperty);

        const { start, end, size } = getNumberGroupLevel(groupBy);

        let { groupLevel } = groupBy;
        if (schemaType === 'text' || schemaType === 'date') {
          groupLevel = getGroupLevel(schemaType, groupLevel);
        }

        switch (schemaType) {
          case CollectionSchemaType.CHECKBOX: {
            const value = formulaTool.getValue(block.uuid, groupProperty);
            values = [value === true ? 'YES' : 'NO'];

            break;
          }
          case CollectionSchemaType.TEXT: {
            let value = formulaTool.getValueAsString(block.uuid, groupProperty);
            if (typeof value === 'string' && value.trim()) {
              value = value.trim();

              if (groupLevel === GroupLevel.ALPHABET_PREFIX) {
                value = value.charAt(0).toUpperCase();
              }

              values = [value];
            }
            break;
          }
          case CollectionSchemaType.NUMBER: {
            const value = formulaTool.getValue(block.uuid, groupProperty);
            if (typeof value === 'number' && !isNaN(value)) {
              values = [getNumberGroupName(value, start, end, size)];
            }
            break;
          }

          case CollectionSchemaType.DATE: {
            const value = formulaTool.getValue(block.uuid, groupProperty);
            if (value !== null && value instanceof Date) {
              const timestamp = value.getTime();
              const result = getGroupNameFromDate(timestamp, groupLevel);
              if (result) {
                values = [result];
              }
            }
            break;
          }

          default:
            break;
        }

        break;
      }

      default:
        break;
    }

    if (values.length === 0) {
      values = [EMPTY_GROUP_NAME];
    }

    return values;
  },
  {
    memoizeOptions: {
      maxSize: 200,
    },
  }
);
