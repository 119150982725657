import type { FC } from 'react';
import type { FormulaValueProps } from '../type';
import { formula } from '@flowus/formula';
import { cx } from '@flowus/common/cx';
import { Checkbox } from 'src/components/check-box';

export const FormulaBooleanValue: FC<FormulaValueProps> = (props) => {
  const checkeds = formula.ValueTool.asList(props.value).elements.map((v) => {
    return v as boolean;
  });
  return (
    <div className="flex items-center">
      {checkeds.map((checked) => {
        return (
          <button className={cx('block text-left')} style={{ width: 24, height: 24 }}>
            <Checkbox size="auto" checked={checked} />
          </button>
        );
      })}
    </div>
  );
};
export const FormulaSingleBooleanValue: FC<FormulaValueProps> = (props) => {
  const checked = formula.ValueTool.asBoolean(props.value);
  return (
    <div style={{ padding: 6 }}>
      <button className={cx('block text-left')} style={{ width: 24, height: 24 }}>
        <Checkbox size="auto" checked={checked} />
      </button>
    </div>
  );
};
