/** search 参数 */
export const $searchParams = new Proxy(
  {} as {
    from?: string;
    /** 打印模式 (导出 pdf) */
    print?: string;
    /** 用户 id (导出 pdf) */
    userId?: string;
    /** token (导出 pdf) */
    token?: string;
    sig?: string;
    /** 多维表范围 (导出 pdf) */
    dataBaseRange?: 'all' | 'current';
    /** 导出格式 (导出 pdf) */
    format?: 'a3' | 'a4';
    scale?: string;
    // 多维表属性id
    propertyId?: string;
    // 多维表列中文件的 ossName
    ossName?: string;
    // 是否是 walles 或者 buildin 的日志页面
    blog?: string;
    /** 嵌入模式 */
    embed?: string;
    subscribeCard?: string;
    seoMode?: string;
  },
  {
    get(_, key: string) {
      const _params = new URLSearchParams(location.search);
      return _params.get(key) ?? undefined;
    },
  }
);
