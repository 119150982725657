import { cx } from '@flowus/common/cx';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { CollectionSchemaType, TextType } from '@next-space/fe-api-idl';
import type { FC, ReactNode } from 'react';
import { useBitable } from 'src/bitable/context';
import { segmentsToText } from 'src/editor/utils/editor';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { useObservableStore } from 'src/services/rxjs-redux/use-obs-store';
import { Site, type CellViewProps } from './types';
import { TextValueView } from './text';
import { formula } from '@flowus/formula';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { PersonTag } from './person';
import { css } from 'otion';
import { LOCAL_LNG } from '@flowus/common/const';
import { formulaTextToSegemnts } from './formula/v2/utils';
import { InlinePage } from 'src/editor/editor/inline/inline-page';
import { Checkbox } from 'src/components/check-box';
import { generateFormatByDate } from '@flowus/common';
import dayjs from 'dayjs';
import { isBuildIn } from 'src/env';

interface Props {
  children: ReactNode;
  noWrap?: boolean;
  className?: string;
}

export const ArrayValueView: FC<Props> = ({ children, className, noWrap, ...rest }) => {
  return (
    <div {...rest} className={cx(className, noWrap ? 'whitespace-nowrap' : 'break-words')}>
      {children}
    </div>
  );
};

export const ArrayValue: FC<CellViewProps> = ({ className, recordId, propertyId }) => {
  const { collectionId } = useBitable();
  const text = useObservableStore(
    (state) => {
      const { blocks } = state;
      const schema = blocks[collectionId]?.data.schema?.[propertyId];
      if (schema?.type === CollectionSchemaType.FORMULA) {
        const formulaTool = getFormulaTool(collectionId, state);
        const ret = formulaTool.getValue(recordId, propertyId) as formula.ListValue;
        if (!ret) return '';
        return ret.elements.map((v) => v.toString()).join(', ');
      }
      const segments = blocks[recordId]?.data.collectionProperties?.[propertyId];
      return segmentsToText(segments);
    },
    [recordId, propertyId, collectionId]
  );

  if (!text) return null;

  return <TextValueView className={cx('p-2 leading-5', className)}>{text}</TextValueView>;
};
export const ArrayListValue: FC<CellViewProps> = ({ className, recordId, propertyId }) => {
  const { collectionId } = useBitable();
  const text = useObservableStore(
    (state) => {
      const { blocks } = state;
      const schema = blocks[collectionId]?.data.schema?.[propertyId];
      if (schema?.type === CollectionSchemaType.FORMULA) {
        const formulaTool = getFormulaTool(collectionId, state);
        const ret = formulaTool.getValue(recordId, propertyId) as formula.ListValue;
        if (!ret) return '';
        return ret.elements
          .map((v) => {
            return (v as formula.ListValue).elements
              .map((v) => {
                const value = formula.ValueTool.fromDynamic(v);
                return formula.ValueTool.toString(value);
              })
              .join(',');
          })
          .filter((v) => v)
          .join(',');
      }
      const segments = blocks[recordId]?.data.collectionProperties?.[propertyId];
      return segmentsToText(segments);
    },
    [recordId, propertyId, collectionId]
  );

  if (!text) return null;

  return <TextValueView className={cx('p-2 leading-5', className)}>{text}</TextValueView>;
};

export const ArrayOfUserValue: FC<CellViewProps> = ({ className, recordId, propertyId, site }) => {
  const { collectionId } = useBitable();
  const userIds = useObservableStore(
    (state) => {
      const formulaTool = getFormulaTool(collectionId, state);
      const ret = formulaTool.getValue(recordId, propertyId) as formula.ListValue;
      if (!ret) return [];
      if (!ret.elements?.length) return [];
      return ret.elements.map((v) => {
        return (v as formula.User).uuid;
      });
    },
    [recordId, propertyId, collectionId]
  );

  return (
    <div
      className={cx(
        'flex',
        site === Site.LIST || site === Site.CALENDAR ? '' : 'flex-wrap',
        site === Site.FIELD &&
          css({
            selectors: {
              ':empty:before': {
                content: `'${LOCAL_LNG.isEmpty}'`,
                display: 'block',
                fontSize: '14px',
                padding: '8px',
                color: 'var(--grey4)',
                lineHeight: '20px',
              },
            },
          }),
        className
      )}
    >
      {userIds.map((v) => {
        return <PersonTag key={v} userId={v ?? ''} site={site} />;
      })}
    </div>
  );
};
export const UserValue: FC<CellViewProps> = ({ className, recordId, propertyId, site }) => {
  const { collectionId } = useBitable();
  const userIds = useObservableStore(
    (state) => {
      const formulaTool = getFormulaTool(collectionId, state);
      const user = formulaTool.getValue(recordId, propertyId) as formula.User;
      if (!user) return [];
      return [user.uuid];
    },
    [recordId, propertyId, collectionId]
  );

  return (
    <div
      className={cx(
        'flex',
        site === Site.LIST || site === Site.CALENDAR ? '' : 'flex-wrap',
        site === Site.FIELD &&
          css({
            selectors: {
              ':empty:before': {
                content: `'${LOCAL_LNG.isEmpty}'`,
                display: 'block',
                fontSize: '14px',
                padding: '8px',
                color: 'var(--grey4)',
                lineHeight: '20px',
              },
            },
          }),
        className
      )}
    >
      {userIds.map((v) => {
        return <PersonTag key={v} userId={v ?? ''} site={site} />;
      })}
    </div>
  );
};
export const FormulaText: FC<CellViewProps> = ({ recordId, propertyId }) => {
  const { collectionId } = useBitable();
  const segments = useObservableStore(
    (state) => {
      const formulaTool = getFormulaTool(collectionId, state);
      const ret = formulaTool.getValue(recordId, propertyId) as formula.Text;
      if (!ret) return [];
      return formulaTextToSegemnts(ret);
    },
    [recordId, propertyId, collectionId]
  );
  return (
    <div className="p-2 w-full group-scope">
      <RichText className={cx('leading-[20px]')} segments={segments} interactable={true} />
    </div>
  );
};
export const ArrayOfFormulaText: FC<CellViewProps> = ({ recordId, propertyId }) => {
  const { collectionId } = useBitable();
  const segments = useObservableStore(
    (state) => {
      const formulaTool = getFormulaTool(collectionId, state);
      const list = formulaTool.getValue(recordId, propertyId) as formula.ListValue;
      if (list === null) return [];
      const all: SegmentDTO[] = [];
      list.elements.forEach((v, index) => {
        formulaTextToSegemnts(v as formula.Text).forEach((s) => {
          all.push(s);
        });
        if (index !== list.elements.length - 1) {
          all.push({ text: ',', type: TextType.TEXT, enhancer: {} });
        }
      });
      return all;
    },
    [recordId, propertyId, collectionId]
  );
  return (
    <div className="p-2 w-full group-scope">
      <RichText className={cx('leading-[20px]')} segments={segments} interactable={true} />
    </div>
  );
};

export const ArrayRelatioinValue: FC<CellViewProps> = ({
  className,
  recordId,
  propertyId,
  site,
}) => {
  const { collectionId } = useBitable();
  const pageIds = useObservableStore(
    (state) => {
      const formulaTool = getFormulaTool(collectionId, state);
      const ret = formulaTool.getValue(recordId, propertyId) as formula.ListValue;
      if (!ret) return [];
      if (!ret.elements?.length) return [];
      return ret.elements.map((v) => {
        return (v as formula.RelationPage).recordId;
      });
    },
    [recordId, propertyId, collectionId]
  );

  return (
    <div
      className={cx(
        (site === Site.CELL || site === Site.FIELD) && 'w-full h-full p-2 flex flex-wrap',
        (site === Site.CALENDAR || site === Site.LIST) && 'inline',
        site === Site.CARD && 'flex flex-wrap w-full overflow-hidden',
        className
      )}
    >
      {pageIds.map((v) => {
        return (
          <InlinePage
            uuid={v}
            key={v}
            autoIconSize={site !== Site.CELL && site !== Site.FIELD}
            className={cx(
              'leading-none p-0 mr-2 whitespace-nowrap text-ellipsis',
              site === Site.CARD && 'text-ellipsis'
            )}
          />
        );
      })}
    </div>
  );
};
export const RelatioinValue: FC<CellViewProps> = ({ className, recordId, propertyId, site }) => {
  const { collectionId } = useBitable();
  const relationPage = useObservableStore(
    (state) => {
      const formulaTool = getFormulaTool(collectionId, state);
      const ret = formulaTool.getValue(recordId, propertyId) as formula.RelationPage;
      if (!ret) return null;
      return ret;
    },
    [recordId, propertyId, collectionId]
  );
  if (!relationPage) return null;

  return (
    <div
      className={cx(
        (site === Site.CELL || site === Site.FIELD) && 'w-full h-full p-2 flex flex-wrap',
        (site === Site.CALENDAR || site === Site.LIST) && 'inline',
        site === Site.CARD && 'flex flex-wrap w-full overflow-hidden',
        className
      )}
    >
      <InlinePage
        uuid={relationPage.recordId}
        key={relationPage.recordId}
        autoIconSize={site !== Site.CELL && site !== Site.FIELD}
        className={cx(
          'leading-none p-0 mr-2 whitespace-nowrap text-ellipsis',
          site === Site.CARD && 'text-ellipsis'
        )}
      />
    </div>
  );
};
export const ArrayOfCheckValue: FC<CellViewProps> = ({ className, recordId, propertyId, site }) => {
  const { collectionId } = useBitable();
  const checkeds = useObservableStore(
    (state) => {
      const formulaTool = getFormulaTool(collectionId, state);
      const ret = formulaTool.getValue(recordId, propertyId) as formula.ListValue;
      if (!ret) return [];
      if (!ret.elements?.length) return [];
      return ret.elements.map((v) => {
        return !!v;
      });
    },
    [recordId, propertyId, collectionId]
  );

  return (
    <div className="flex items-center" style={{ padding: 6 }}>
      {checkeds.map((checked, index) => {
        return (
          <button
            key={index}
            className={cx('block text-left', className)}
            style={{ width: 24, height: 24 }}
          >
            <Checkbox size="auto" checked={checked} />
          </button>
        );
      })}
    </div>
  );
};
export const ArrayOfDateValue: FC<CellViewProps> = ({ className, recordId, propertyId, site }) => {
  const { collectionId } = useBitable();
  const allDates = useObservableStore(
    (state) => {
      const formulaTool = getFormulaTool(collectionId, state);
      const ret = formulaTool.getValue(recordId, propertyId) as formula.ListValue;
      if (!ret) return [];
      if (!ret.elements?.length) return [];
      return ret.elements.map((v) => {
        return v as Date | formula.DateRangeValue | null;
      });
    },
    [recordId, propertyId, collectionId]
  );

  return (
    <div style={{ padding: 6 }}>
      {allDates.map((date, index) => {
        const value = date;
        if (value == null) return null;
        if (value instanceof Date) {
          const dateFormat = generateFormatByDate(value);
          return (
            <TextValueView key={index} className={cx('p-2 leading-5', className)}>
              {dayjs(value)
                .locale(isBuildIn() ? 'en' : 'zh-cn')
                .format(dateFormat)}
            </TextValueView>
          );
        }
        const dateFormat = generateFormatByDate(value.start);
        return (
          <TextValueView key={index} className={cx('p-2 leading-5', className)}>
            {`${dayjs(value.start)
              .locale(isBuildIn() ? 'en' : 'zh-cn')
              .format(dateFormat)} → ${dayjs(value.end)
              .locale(isBuildIn() ? 'en' : 'zh-cn')
              .format(dateFormat)}`}
          </TextValueView>
        );
      })}
    </div>
  );
};
