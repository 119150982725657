import type { IEditorModel } from '@next-space/fe-inlined';
import { useCallback } from 'react';
import type { Placement } from '@popperjs/core';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { Modals } from 'src/modals';
import { useIsInRight } from 'src/utils/right-utils';
import { AIEditor } from '.';
import type { AIEditorScene, AIEditType } from './const';
import { useIsGuest } from 'src/hooks/share/use-permission-utils';
import { message } from '@flowus/common/components/message';

export const useOpenAIEditor = () => {
  const openModal = useOpenModal();
  const isInRight = useIsInRight();
  const { enableAI } = useEnableAI();
  const isGuest = useIsGuest();

  return useCallback(
    (options: {
      popcorn: ModalSchema.PopcornType;
      editorScene: AIEditorScene;
      blockId: string;
      model?: IEditorModel;
      offset?: number[];
      editType?: AIEditType;
      from: string;
      selection?: { start: number; end?: number };
      isBitable?: boolean;
      isMindMap?: boolean;
      isEmbedMindMap?: boolean; // 我也不想的
      isDescription?: boolean;
      showInput?: boolean;
      placement?: Placement;
      defaultCustomPrompt?: string;
    }) => {
      if (!enableAI) return;
      if (isGuest) {
        message.warning('只有空间成员可以使用AI功能');
        return;
      }
      const {
        popcorn,
        editorScene,
        blockId,
        model,
        offset,
        editType,
        selection,
        from,
        isBitable,
        isMindMap,
        isDescription,
        showInput = true,
        defaultCustomPrompt,
        placement = 'bottom-start',
      } = options;

      openModal.dropdown({
        modalId: Modals.AI_EDITOR,
        popcorn,
        offset: offset ?? [0, 8],
        placement,
        disableEsc: true,
        autoClose: false,
        blockMaskClick: false,
        content({ onCloseModal, popper }) {
          return (
            <AIEditor
              defaultCustomPrompt={defaultCustomPrompt}
              showInput={showInput}
              popcorn={popcorn}
              isInRight={isInRight}
              model={model}
              blockId={blockId}
              popper={popper}
              closeSelf={onCloseModal}
              editorScene={editorScene}
              editType={editType}
              from={from}
              selection={selection}
              isBitable={isBitable}
              isMindMap={isMindMap}
              isDescription={isDescription}
            />
          );
        },
      });
    },
    [enableAI, isGuest, isInRight, openModal]
  );
};
