import { checkTargetByHtml, isInAIChatPage } from '@flowus/common/dom';
import isHotkey from 'is-hotkey';
import { useCallback } from 'react';
import { AIEditorFrom, AIEditorScene } from 'src/editor/editor/uikit/ai-editor/const';
import { useOpenAIEditor } from 'src/editor/editor/uikit/ai-editor/use-open-ai-editor';
import { useGetOrInitEditorModel } from 'src/editor/editor/uikit/editable/hook';
import { useShortcutCode } from './toolbar-shortcut/use-shortcut-code';
import { useShortcutComment } from './toolbar-shortcut/use-shortcut-comment';
import { useShortcutEquation } from './toolbar-shortcut/use-shortcut-equation';
import { useShortcutFormat } from './toolbar-shortcut/use-shortcut-format';
import { useShortcutLink } from './toolbar-shortcut/use-shortcut-link';
import { useShortcutToolBar } from './toolbar-shortcut/use-shortcut-toolbar';

export const useShortcutKey = (uuid: string) => {
  const shortcutEquation = useShortcutEquation();
  const shortcutFormat = useShortcutFormat();
  const shortcutCode = useShortcutCode();
  const openAIEditor = useOpenAIEditor();
  const { queryResult, setFakeFocus } = useShortcutToolBar(uuid);
  const shortcutLink = useShortcutLink(uuid, { setFakeFocus });
  const shortcutComment = useShortcutComment(uuid);
  const { disableEquation, disableLink } = queryResult;

  const getEditorModel = useGetOrInitEditorModel();
  const shortcutKey = (event: globalThis.KeyboardEvent) => {
    const editorModel = getEditorModel(uuid);
    if (!editorModel) return;

    const { selection } = editorModel;
    if (!selection) return;

    if (checkTargetByHtml(event.target as HTMLElement, isInAIChatPage)) {
      return;
    }

    if (isHotkey('mod+a')(event)) {
      if (!(selection.offset === 0 && selection.length === editorModel.content.length)) {
        event.stopPropagation();
      }
      return;
    }

    if (isHotkey('mod+shift+m')(event)) {
      event.preventDefault();
      shortcutComment();
      return;
    }

    if (isHotkey('mod+b')(event)) {
      event.preventDefault();
      shortcutFormat(uuid, 'bold');
      return;
    }

    if (isHotkey('mod+i')(event)) {
      event.preventDefault();
      shortcutFormat(uuid, 'italic');
      return;
    }

    if (isHotkey('mod+u')(event)) {
      event.preventDefault();
      shortcutFormat(uuid, 'underline');
    }

    if (isHotkey('mod+e')(event)) {
      event.preventDefault();
      shortcutCode(uuid);
    }

    if (isHotkey('mod+shift+s')(event)) {
      event.preventDefault();
      shortcutFormat(uuid, 'lineThrough');
    }

    if (isHotkey('mod+shift+x')(event) && !disableEquation) {
      event.preventDefault();
      shortcutEquation(uuid);
    }

    if (isHotkey('mod+k')(event) && !disableLink) {
      event.preventDefault();
      event.stopPropagation();
      shortcutLink();
    }

    if (isHotkey('mod+j')(event)) {
      event.preventDefault();
      event.stopPropagation();
      const model = getEditorModel(uuid);
      if (!model) return;

      const popcorn = (model as any).editorElement;
      if (!popcorn) return;

      const { selection } = model;
      if (!selection) return;

      const { offset, endOffset } = selection;
      openAIEditor({
        blockId: uuid,
        popcorn,
        model,
        selection: { start: offset ?? 0, end: endOffset ?? 0 },
        editorScene: AIEditorScene.TextSelected,
        from: AIEditorFrom.RtfMenu,
      });
    }
  };

  return useCallback(shortcutKey, [
    getEditorModel,
    uuid,
    disableEquation,
    disableLink,
    shortcutComment,
    shortcutFormat,
    shortcutCode,
    shortcutEquation,
    shortcutLink,
    openAIEditor,
  ]);
};
