import type { NextBlock } from '@flowus/common/block/type';
import { BlockType } from '@next-space/fe-api-idl';
import { last } from 'lodash-es';
import { getOwnerPage } from 'src/hooks/block/use-get-owner-page';
import { supportAppendChild } from 'src/mind-map/utils/mind-engine-utils';
import { cache } from 'src/redux/store';
import { isMindMap } from 'src/utils/block-type-utils';

export interface AIMessageResult {
  content: string;
  reasoning_content: string;
}

export const parseAIMessage = (chunkValue: string): AIMessageResult => {
  const result: AIMessageResult = {
    content: '',
    reasoning_content: '',
  };

  chunkValue.split('data: ').forEach((str) => {
    str = str.trim();
    if (str) {
      try {
        const data = JSON.parse(str);
        result.content += data.content || '';
        if (data.reasoning_content) {
          result.reasoning_content += data.reasoning_content;
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('----parse error', err, chunkValue);
      }
    }
  });

  return result;
};

export const parsePDFAiMessage = (chunkValue: string) => {
  const allData: {
    type?: string;
    content?: string;
    pageNumber?: string;
    coords?: number[];
    code?: number;
    msg?: string;
  }[] = [];

  chunkValue.split('data: ').forEach((str) => {
    str = str.trim();
    if (str) {
      try {
        const data = JSON.parse(str);
        allData.push(data);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('----parse error', err, chunkValue);
      }
    }
  });

  return allData;
};
// AIx思维导图专用
export const getNodeType = (block: NextBlock) => {
  if (block.type === BlockType.HEADER) {
    return `${block.type}-${block.data.level ?? 0}`;
  }
  if (block.type === BlockType.ORDER_LIST) {
    return `${block.type}-${block.data.format?.orderListFormat ?? 'number'}`;
  }
  if (block.type === BlockType.LIST) {
    return `${block.type}-${block.data.format?.listFormat ?? 'disc'}`;
  }
  return `${block.type}-${block.data.level ?? 0}`;
};

export const getLastNodeId = (id: string): string => {
  if (supportAppendChild(id)) {
    const block = cache.blocks[id];
    if (block?.subNodes.length === 0) return id;
    const lastId = last(block?.subNodes);
    if (!lastId) return id;
    return getLastNodeId(lastId);
  }
  return id;
};
export const getAllNodeTypes = (id: string) => {
  const mindNodeTypes: string[] = [];
  let curId: string | undefined = id;
  while (curId) {
    const block: NextBlock | undefined = cache.blocks[curId];
    if (!block) break;
    if (!supportAppendChild(curId)) {
      break;
    }
    mindNodeTypes.push(getNodeType(block));
    curId = last(block.subNodes);
  }
  return mindNodeTypes;
};
export const checkMindMap = (id: string) => {
  const block = cache.blocks[id];
  if (isMindMap(block?.type)) {
    return { isMindMapPage: true, isInMindMap: false };
  }
  const pageId = getOwnerPage(id);
  if (pageId && isMindMap(cache.blocks[pageId]?.type)) {
    return { isMindMapPage: false, isInMindMap: true };
  }
  return { isMindMapPage: false, isInMindMap: false };
};
