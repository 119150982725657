// 将这些常量提取出来，避免因为需要引入常量而循环依赖
import { normalizeFormat, type IElementMeta } from '@next-space/fe-inlined';

export const CODE_TAG = 'code';
export const LINK_TAG = 'link';
export const SELECT_INLINE_TAG = 'select-inline';
export const INLINE_DATE_TAG = 'inline-date';
export const INLINE_PERSON_TAG = 'inline-person';
export const INLINE_LINK_PAGE_TAG = 'inline-link-page';
export const INLINE_MENTION_BLOCK_TAG = 'inline-mention-block';
export const INLINE_EQUATION_TAG = 'inline-equation';
export const INLINE_EMOJI_TAG = 'emoji';
export const INLINE_LINK_SUGGEST_TAG = 'inline-link-suggest';

export const LINK_META: IElementMeta = {
  tag: LINK_TAG,
  hasBoundary: false,
  hasContent: true,
  setFormat: (element, format) => {
    element.props.textFormat = normalizeFormat(format, element.props.textFormat as any);
  },
};

export const CODE_META: IElementMeta = {
  tag: CODE_TAG,
  hasBoundary: true,
  hasContent: true,
  allowContentElementTags: new Set([
    LINK_TAG,
    SELECT_INLINE_TAG,
    INLINE_LINK_PAGE_TAG,
    INLINE_DATE_TAG,
    INLINE_PERSON_TAG,
    INLINE_EQUATION_TAG,
    INLINE_EMOJI_TAG,
  ]),
};
