import type { FC } from 'react';
import { cx } from '../cx';

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children?: React.ReactNode;
}

export const SeoAnchorLink: FC<Props> = (props) => {
  const { children, className, href, ...reset } = props;
  return (
    <a
      {...reset}
      href={href?.startsWith('/') ? href : `/${href}`}
      className={cx(className || 'contents')}
      onClickCapture={(e) => {
        e.preventDefault();
      }}
    >
      {children}
    </a>
  );
};
