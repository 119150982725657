import { useMindMapEngine, useMindMapId } from '@flowus/mind-map';
import { BlockType } from '@next-space/fe-api-idl';
import isHotkey from 'is-hotkey';
import { debounce } from 'lodash-es';
import { useCallback, useMemo, useRef } from 'react';
import { message } from 'src/common/components/message';
import { useFocusEditableByBlockId } from 'src/hooks/editor/use-focus-by-id';
import { useReadonly } from 'src/hooks/page';
import { useChangeColorKey } from 'src/hooks/page/use-page-shortcut/use-change-color-key';
import { useLastColor } from 'src/hooks/page/use-page-shortcut/use-last-color';
import { useRichTextShortCut } from 'src/hooks/page/use-page-shortcut/use-rich-text-shortcut';
import { useTransaction } from 'src/hooks/use-transaction';
import { HISTORY_REDO, HISTORY_UNDO } from 'src/redux/actions/history';
import { moveBlock } from 'src/redux/managers/block/move';
import { uiActions } from 'src/redux/reducers/ui';
import { cache, dispatch } from 'src/redux/store';
import { $appUiStateCache, setAppUiState } from 'src/services/app';
import { bizTracker } from 'src/utils/biz-tracker';
import { supportCaption } from 'src/utils/block-type-utils';
import { getDynamicPageId, useGetPageId } from 'src/utils/getPageId';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import { isMindNodeFocused } from '../utils/mind-node-util';
import { useAddSubNode } from './use-add-sub-node';
import { useToggleAllNode, useToggleNode } from './use-toggle-node';

// 是否ignore掉全局的快捷键(usePageShortCut)
const ignoreGlobalHotKey = [
  'Enter',
  'Tab',
  'Shift+Tab',
  'Space',
  'mod+.',
  'Shift+mod+.',
  'ArrowUp',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'mod+=',
  'mod+-',
  'mod+0',
  'mod+z',
  'mod+shift+z',
  'mod+shift+y',
  'mod+shift+h',
];

const ignoreGlobalHotKeyWhenFocus = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Space'];

export const useMindMapShortcut = () => {
  const mindMapId = useMindMapId();
  const engine = useMindMapEngine();
  const readonly = useReadonly(mindMapId);
  const toggleNode = useToggleNode();
  const toggleAllNode = useToggleAllNode();
  const transaction = useTransaction();
  const addSubNode = useAddSubNode();
  const focusEditable = useFocusEditableByBlockId();
  const pageScene = usePageScene();
  const richTextShortcut = useRichTextShortCut();
  const pageId = useGetPageId();
  const lastColor = useLastColor();
  const messageRef = useRef('');
  const changeColorKey = useChangeColorKey();

  const spaceKey = useMemo(() => {
    return debounce(() => {
      const isFocused = isMindNodeFocused();
      if (!isFocused) {
        if (messageRef.current) {
          message.closeMessage(messageRef.current);
        }
        messageRef.current = message.success(
          $appUiStateCache.$pressedSpace ? '切换到编辑模式' : '切换到拖拽模式'
        );
        setAppUiState({ $pressedSpace: !$appUiStateCache.$pressedSpace });
      }
    }, 100);
  }, []);

  return useCallback(
    (e: KeyboardEvent) => {
      if (pageScene === PageScene.PAGE_HISTORY) return;

      const isFocused = isMindNodeFocused();
      if (pageId !== getDynamicPageId()) {
        return;
      }
      if (document.activeElement?.closest('.CodeMirror')) {
        return;
      }
      if (document.activeElement?.closest('.latex-input-panel')) {
        return;
      }
      const isComment = document.activeElement?.closest('[data-is-comment]');
      // 如果头评论弹窗则不响应快捷键
      if (isComment) return;

      // 特殊块处理
      if (handleForSpecialCase(e)) {
        return;
      }
      // 是否ignore掉全局的快捷键
      if (isFocused && isHotkey(ignoreGlobalHotKeyWhenFocus)(e)) {
        // 富文本focus时方向键不能吃掉，也不响应
        return;
      }
      if (isHotkey(ignoreGlobalHotKey)(e)) {
        e.stopPropagation();
        e.preventDefault();
      }

      if (isHotkey('Shift+mod+.')(e)) {
        toggleAllNode();
        bizTracker.event('node_fold', { from: 1 });
        return;
      } else if (isHotkey('mod+=')(e)) {
        engine.zoomin();
        return;
      } else if (isHotkey('mod+-')(e)) {
        engine.zoomout();
        return;
      } else if (isHotkey('mod+0')(e)) {
        engine.zoom(100);
        return;
      } else if (isHotkey('Enter')(e)) {
        if (isFocused) {
          (document.activeElement as HTMLDivElement).blur();
          return;
        }
      } else if (isHotkey('Space')(e) && cache.ui.selectedBlocks.length === 0) {
        spaceKey();
      }
      if (readonly) return;
      lastColor(e);
      changeColorKey(e);
      // 这里的快捷键处理是不分是否有选中块的情况
      switch (true) {
        case isHotkey('mod+z')(e): {
          dispatch(HISTORY_UNDO());
          break;
        }
        case isHotkey('mod+shift+z')(e): {
          dispatch(HISTORY_REDO());
          break;
        }
        case isHotkey('mod+y')(e): {
          dispatch(HISTORY_REDO());
          break;
        }
        default:
      }
      // 这里是专门处理单个块选中的情况
      const blockId = cache.ui.selectedBlocks[0]?.blockId ?? '';
      if (cache.ui.selectedBlocks.length === 1) {
        switch (true) {
          case isHotkey('Enter')(e): {
            if (blockId === mindMapId) return;
            // 插入同级节点
            transaction(() => {
              const newBlockId = addSubNode(
                { parentId: cache.blocks[blockId]?.parentId ?? '', after: blockId },
                mindMapId
              );
              newBlockId &&
                dispatch(
                  uiActions.updateSelectBlocks([
                    {
                      blockId: newBlockId,
                      mindMapId,
                    },
                  ])
                );
            });
            bizTracker.event('node_new', { from: 1 });
            break;
          }
          case isHotkey('Tab')(e): {
            const supportAddChild = engine.option.supportAppendChild(blockId);
            if (!supportAddChild) return;
            transaction(() => {
              const newBlockId = addSubNode({ parentId: blockId, last: true }, mindMapId);
              if (document.activeElement instanceof HTMLElement) {
                document.activeElement.blur();
              }
              if (!newBlockId) return;
              dispatch(
                uiActions.updateSelectBlocks([
                  {
                    blockId: newBlockId,
                    mindMapId,
                  },
                ])
              );
              toggleNode(blockId, true);
            });
            bizTracker.event('node_new', { from: 1 });
            break;
          }
          case isHotkey('Shift+Tab')(e): {
            if (blockId === mindMapId) return;
            if (document.activeElement instanceof HTMLElement) {
              document.activeElement.blur();
            }
            // 先加一个节点在当前节点同级的后面，再把当前节点移动到新节点subnode下
            transaction(() => {
              const newBlockId = addSubNode(
                {
                  parentId: cache.blocks[blockId]?.parentId ?? '',
                  after: blockId,
                },
                mindMapId
              );
              if (!newBlockId) return;
              moveBlock([blockId], {
                parentId: newBlockId,
                last: true,
              });
              dispatch(
                uiActions.updateSelectBlocks([
                  {
                    blockId: newBlockId,
                    mindMapId,
                  },
                ])
              );
            });
            bizTracker.event('node_new', { from: 1 });
            break;
          }
          case isHotkey('Space')(e): {
            const support = supportCaption(cache.blocks[blockId]);
            focusEditable(blockId, -1, support ? 'caption' : 'segments');
            break;
          }
          case isHotkey('mod+.')(e): {
            if (mindMapId === blockId) return;
            toggleNode(blockId);
            bizTracker.event('node_fold', { from: 1 });
            break;
          }
          default:
        }
      }

      if (richTextShortcut(e, true)) {
        e.stopPropagation();
        e.preventDefault();
      }
      switch (true) {
        case isHotkey('ArrowLeft')(e): {
          if (cache.ui.selectedBlocks.length === 0) return;
          const nextId = engine.getNextNodeIdByArrowKeyboard('left', blockId);
          if (nextId) {
            dispatch(
              uiActions.updateSelectBlocks([
                {
                  blockId: nextId,
                  mindMapId,
                },
              ])
            );
          }
          break;
        }
        case isHotkey('ArrowUp')(e): {
          if (cache.ui.selectedBlocks.length === 0) return;
          const nextId = engine.getNextNodeIdByArrowKeyboard('up', blockId);
          if (nextId) {
            dispatch(
              uiActions.updateSelectBlocks([
                {
                  blockId: nextId,
                  mindMapId,
                },
              ])
            );
          }
          break;
        }
        case isHotkey('ArrowRight')(e): {
          if (cache.ui.selectedBlocks.length === 0) return;
          const nextId = engine.getNextNodeIdByArrowKeyboard('right', blockId);
          if (nextId) {
            dispatch(
              uiActions.updateSelectBlocks([
                {
                  blockId: nextId,
                  mindMapId,
                },
              ])
            );
          }
          break;
        }
        case isHotkey('ArrowDown')(e): {
          if (cache.ui.selectedBlocks.length === 0) return;
          const nextId = engine.getNextNodeIdByArrowKeyboard('down', blockId);
          if (nextId) {
            dispatch(
              uiActions.updateSelectBlocks([
                {
                  blockId: nextId,
                  mindMapId,
                },
              ])
            );
          }
          break;
        }
        default:
      }
    },
    [
      addSubNode,
      changeColorKey,
      engine,
      focusEditable,
      lastColor,
      mindMapId,
      pageId,
      pageScene,
      readonly,
      richTextShortcut,
      spaceKey,
      toggleAllNode,
      toggleNode,
      transaction,
    ]
  );
};

/**
 * 对一些case特殊处理
 * 1.代码块内focus的时候不响应导图的enter,Tab,Tab+shift
 * 2.修改行内引用块文字
 */
const handleForSpecialCase = (e: KeyboardEvent) => {
  const isFocused = isMindNodeFocused();
  const blockId = cache.ui.selectedBlocks[0]?.blockId ?? '';
  if (isHotkey('Enter')(e) && isFocused) {
    if (cache.blocks[blockId]?.type === BlockType.CODE) {
      return true;
    }
  }
  if (isHotkey(['Tab', 'Tab+shift'])(e) && isFocused) {
    if (
      cache.blocks[blockId]?.type === BlockType.CODE ||
      cache.blocks[blockId]?.type === BlockType.TABLE
    ) {
      return true;
    }
  }

  if (isHotkey('Enter')(e)) {
    // 修改行内引用块文字
    if (
      !document.activeElement?.closest('.hidden-textarea') &&
      (document.activeElement?.tagName === 'INPUT' ||
        document.activeElement?.tagName === 'TEXTAREA')
    ) {
      return true;
    }
  }
  return false;
};
