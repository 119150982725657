import { cx } from '@flowus/common/cx';
import { ossImageSnapshotUrl } from '@flowus/common/url';
import { PermissionRole } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Avatar } from 'src/common/components/avatar';
import { message } from 'src/common/components/message';
import { useCloseModal, useOpenModal } from 'src/common/components/next-modal';
import { useVisible } from 'src/common/hooks/use-visible';
import { ActivityIds } from 'src/hooks/activities/activity-ids';
import { ActivitiesListType } from 'src/hooks/activities/use-activity';
import { useUpdateTask } from 'src/hooks/activities/use-update-task';
import { useFetchPage } from 'src/hooks/page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useIsCustomTemplate } from 'src/hooks/space/use-is-custom-template';
import type { Template } from 'src/hooks/template/types';
import { useHandleCopyTemplate } from 'src/hooks/template/use-copy-template';
import { useTransaction } from 'src/hooks/use-transaction';
import { useUserName } from 'src/hooks/user/use-remark-name';
import { useUser } from 'src/hooks/user/use-user';
import { Modals } from 'src/modals';
import { archiveBlock } from 'src/redux/managers/block/archive';
import { bizTracker } from 'src/utils/biz-tracker';
import { urlFetcher } from 'src/utils/url-fetcher';
import { SegmentPlainText } from '../aside/toc/helper';
import { Page } from '../page';
import { Cover } from './cover';
import { MoreMenu } from './more-menu';
import { useFrom } from './template-context';
import { getCurrencySymbols } from '@flowus/common';

interface ItemProps {
  template: Template;
  className?: string;
  onPreviewOpen: (uuid: string) => void;
  /** 是否直接开启preview，只有我的模板/别人分享给我的需要 */
  preview?: boolean;
  targetId?: string;
}

export const TemplateItem: FC<ItemProps> = ({
  template,
  onPreviewOpen,
  className,
  preview,
  targetId,
}) => {
  const history = useHistory();
  const fetchPage = useFetchPage();
  const updateTask = useUpdateTask();
  const from = useFrom();
  const closeModal = useCloseModal();
  const [imageUrl, setImageUrl] = useState('');
  const [sourceImageUrl, setSourceImageUrl] = useState('');
  const { role } = usePermissions(template.id);
  const isCustomTemplate = useIsCustomTemplate(template.id);
  const openModal = useOpenModal();
  const transaction = useTransaction();
  const userName = useUserName(template.userId ?? '');
  const [visible, ref] = useVisible<HTMLDivElement>();
  const handleCopyTemplate = useHandleCopyTemplate();

  const applyTemplate = async () => {
    if (template.accessFee && !template.purchased) {
      onPreviewOpen(template.id);
      return;
    }
    const pageId = await handleCopyTemplate(
      {
        ...template,
        targetId,
        // 自定义的模板或者已经购买了就不限制了
        addCopyLimit: template.isCustom || template.purchased ? false : true,
      },
      from
    );
    if (pageId) {
      bizTracker.event('copy_template', {
        is_from: template?.isCustom ? 'custom' : 'new_page',
        template_id: template.id,
      });
      // 积分任务
      void updateTask(ActivityIds.COPY_PAGE_FROM_TEMPLATE, ActivitiesListType.basicList);

      await fetchPage(pageId, { force: true });

      if (from !== 'record') {
        // 如果是从record来的话就不需要跳转
        history.push(`/${pageId}`);
      }
      message.closeMessage('templateLoading');
      closeModal(Modals.TEMPLATE_CENTER);
    }
  };

  useEffect(() => {
    if (!visible) return;
    if (imageUrl) return;
    if (!template.image) return;
    if (template.image.startsWith('http')) {
      setImageUrl(template.image);
      return;
    }
    void urlFetcher
      .fetchImageUrl({
        blockId: template.pageId,
        ossName: template.image,
      })
      .then((url) => {
        setImageUrl(
          ossImageSnapshotUrl(url, Math.floor(360 * 1.5 * Math.ceil(window.devicePixelRatio)))
        );
      });
  }, [visible, imageUrl, template.pageId, template.image, setImageUrl]);

  useEffect(() => {
    if (!visible) return;
    if (sourceImageUrl) return;
    if (!template.sourceImage) return;
    void urlFetcher
      .fetchImageUrl({
        blockId: template.pageId,
        ossName: template.sourceImage,
      })
      .then((url) =>
        setSourceImageUrl(
          ossImageSnapshotUrl(url, Math.floor(24 * 1.5 * Math.ceil(window.devicePixelRatio)))
        )
      );
  }, [visible, sourceImageUrl, template.pageId, template.sourceImage, setImageUrl]);

  const canEdit = role === PermissionRole.EDITOR || role === PermissionRole.WRITER;
  // 如果在模板中心删掉之后，isCustomTemplate会设为false
  if (!isCustomTemplate && template.isCustom) return null;

  const isTitleSearchFooter = from === 'titleSearchFooter';
  return (
    <div className={className} ref={ref}>
      <div
        className={cx('group relative bg-white1 rounded-[6px] overflow-hidden h-0 pb-[66%] w-full')}
        style={{
          border: '1px solid var(--black_006)',
          boxShadow: '0px 1px 3px var(--black_006)',
        }}
      >
        {!preview && imageUrl && (
          <Cover
            imageUrl={imageUrl}
            isCustomTemplate={template.isCustom}
            templateId={template.id}
            className={'absolute'}
            onClick={() => {
              if (isTitleSearchFooter) {
                void applyTemplate();
              }
              onPreviewOpen(template.id);
            }}
          />
        )}
        {preview && visible && (
          <div className={'zoom-03 w-[333%] overflow-hidden h-[800px] pointer-events-none'}>
            {/* 这个IframeLitePage一多，接口调用就多，太卡了，吃不消 */}
            {/* <IframeLitePage id={template.id} /> */}
            <Page uuid={template.id} />
          </div>
        )}

        <div className="opacity-0 group-hover:opacity-100 flex justify-end items-center absolute h-[56px] w-full top-0 rounded-b-1.5">
          {template.isCustom && canEdit && (
            <MoreMenu
              className="absolute z-20 w-[26px] h-[26px] group-hover:opacity-100 right-0 top-0 opacity-0 mt-2.5 mr-2.5 bg-black/75 rounded-sm"
              onEdit={() => {
                window.open(`/${template.id}`);
              }}
              onDelete={() => {
                openModal.warning({
                  title: '确认删除模板？',
                  content: '删除后，该模板将从模板库移除并进入回收站',
                  confirmText: '确认删除',
                  colorType: 'red',
                  confirm: () => {
                    transaction(() => {
                      archiveBlock(template.id);
                    });
                  },
                });
              }}
            />
          )}
        </div>

        {!isTitleSearchFooter && (
          <div className="opacity-0 group-hover:opacity-100 border-t flex justify-between items-center absolute h-[56px] w-full bottom-0 rounded-b-1.5 px-2.5 bg-white3">
            <p className="text-t3 text-grey3 line-clamp-2 overflow-ellipsis">{template.desc}</p>
            <div className="flex">
              <div
                className="flex justify-center items-center w-[56px] h-8 cursor-pointer border border-solid border-grey5 rounded text-black text-t2-medium  bg-white1 hover:bg-white1/90"
                onClick={() => onPreviewOpen(template.id)}
              >
                预览
              </div>
              <div
                className="flex justify-center items-center ml-2.5 w-[56px] h-8 cursor-pointer bg-active_color text-t2-medium text-white hover:bg-active_color/90 rounded"
                onClick={applyTemplate}
              >
                {template.accessFee && !template.purchased
                  ? `${getCurrencySymbols()}${template.accessFee}`
                  : '使用'}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="relative flex justify-between items-center mt-4 mb-[30px] overflow-hidden w-full">
        <div className="flex-1 overflow-hidden">
          <div className="text-t2-medium text-ellipsis">
            {template.isCustom ? <SegmentPlainText uuid={template.id} /> : template.name}
          </div>
          <div className="text-t2 text-grey3 mt-[2px] text-ellipsis">
            {template.source || userName}
          </div>
        </div>

        {sourceImageUrl && (
          <img className="w-9 h-9 md:w-5 md:h-5 rounded-full mr-1" src={sourceImageUrl} />
        )}

        {template.isCustom && <UserAvatar userId={template.userId ?? ''} />}
      </div>
    </div>
  );
};

const UserAvatar: FC<{ userId: string }> = (props) => {
  const user = useUser(props.userId);
  const userName = useUserName(props.userId);
  if (!user) return null;
  return (
    <Avatar
      className="rounded-full text-white w-9 h-9 mr-1"
      name={userName || '客'}
      color={user?.backgroundColor || undefined}
      icon={user?.avatar ? { type: 'upload', value: user.avatar } : undefined}
      imgClassName="rounded-full"
    />
  );
};
