import { URL_SPLIT_TEXT } from '@flowus/common/const';
import { cx } from '@flowus/common/cx';
import { FormPermission } from '@next-space/fe-api-idl';
import type { Placement } from '@popperjs/core';
import type { FC, MouseEvent } from 'react';
import { useCallback, useEffect } from 'react';
import { Divider } from 'src/common/components/divider';
import { Icon } from 'src/common/components/icon';
import { ListItemType, ListView } from 'src/common/components/list-view';
import type { ModalSchema } from 'src/common/components/next-modal';
import { useOpenModal } from 'src/common/components/next-modal';
import { Switch } from 'src/common/components/switch';
import type { TabPanelProps } from 'src/common/components/tab-panel';
import { TabPanel } from 'src/common/components/tab-panel';
import { UNTITLED_FORM } from 'src/common/const';
import { request } from 'src/common/request';
import { HelperIconBox, helperLink } from 'src/components/helper-icon';
import { SearchBar } from 'src/components/share/components/search-bar';
import { SearchUser } from 'src/components/share/search-user';
import { ShareButtons } from 'src/components/share/share-buttons';
import { FormShareUserPermissionList } from 'src/components/share/share-user-permission-list';
import { useBlockLocked } from 'src/hooks/block/use-block-locked';
import { updateCollectionView, updateViewFormat } from 'src/hooks/block/use-update-collection-view';
import { useCollectionView, useViewParentId } from 'src/hooks/collection-view/use-collection-view';
import { getCurrentSpace } from 'src/hooks/space';
import { getSpaceGuests } from 'src/hooks/space/get-space-guests';
import { useUser } from 'src/hooks/user/use-user';
import { getState } from 'src/redux/store';
import { SearchParams, ViewPath } from 'src/utils';
import { bizTracker } from 'src/utils/biz-tracker';
import { writeTextInClipboard } from 'src/utils/clipboard';
import { getLocationOrigin } from 'src/utils/location-utils';
import { v4 as uuid4 } from 'uuid';

enum TabsId {
  FormUserList = 'FormUserList',
  SelectUsers = 'SelectUsers',
}
export const useOpenShareFormSetting = () => {
  const openModal = useOpenModal();

  return useCallback(
    (props: {
      popcorn: ModalSchema.PopcornType;
      placement?: Placement;
      viewId: string;
      collectionId: string;
    }) => {
      const { popcorn, viewId, placement, collectionId } = props;

      const tabs: TabPanelProps['tabs'] = [
        {
          id: TabsId.FormUserList,
          content: (back) => <FormShareUserPermissionList uuid={viewId} backToMainPanel={back} />,
        },
        {
          id: TabsId.SelectUsers,
          content: (back) => <SearchUser searchType="form" uuid={viewId} backToMainPanel={back} />,
        },
      ];

      openModal.dropdown({
        popcorn,
        placement,
        content: ({ onCloseModal }) => (
          <TabPanel
            main={(onChangeTab) => (
              <ShareFormSettingMain
                onChangeTab={onChangeTab}
                viewId={viewId}
                onCloseModal={onCloseModal}
                collectionId={collectionId}
              />
            )}
            tabs={tabs}
          />
        ),
      });
    },
    [openModal]
  );
};

const ShareFormSettingMain: FC<{
  onCloseModal: ModalSchema.CloseModalType;
  viewId: string;
  collectionId: string;
  onChangeTab: (id: string) => void;
}> = ({ viewId, onChangeTab, collectionId }) => {
  const maxUserCount = 5;
  const viewParentId = useViewParentId(viewId);
  const isLocked = useBlockLocked(viewParentId);
  const shareFormSettingType = {
    [FormPermission.LOGIN]: '任何获得此链接的人，登录后可填写',
    [FormPermission.NO_LOGIN]: '任何获得此链接的人，无需登录即可填写',
    [FormPermission.WORKSPACE]: '仅当前空间成员可填写',
    [FormPermission.INVITE]: '仅邀请的人可填写',
  };

  const openModal = useOpenModal();
  const collectionView = useCollectionView(viewId);
  const createViewUser = useUser(collectionView?.createdBy);

  const { formPermission = FormPermission.LOGIN, formInviteUserPermissions = [] } =
    collectionView?.format || {};
  const isShared = !!collectionView?.format.formShared;
  const onlyInvitationUsers = formPermission === FormPermission.INVITE;
  const isNoLoginForm = formPermission === FormPermission.NO_LOGIN;
  const shareLink = `${getLocationOrigin()}${ViewPath.form}${collectionView?.shareId}${
    createViewUser?.inviteCode ? `?${SearchParams.inviteCode}=${createViewUser.inviteCode}` : ''
  }`;

  useEffect(() => {
    void getSpaceGuests(getCurrentSpace().uuid);
  }, []);

  /** 切换分享状态 */
  const switchOpenShare = async (status = !isShared) => {
    if (isLocked) return;
    bizTracker.event('form_share_switch', { is_open: status });
    // 开启分享，并且没有shareID，防止数据没同步过来的时候，重置id
    if (status && !collectionView?.shareId) {
      const res = await request.editor.getCollectionView(viewId);
      const shareId = res.shareId || uuid4();
      updateCollectionView(viewId, { shareId });
    }
    // ----------------------------------------------------------------
    const view = getState().collectionViews?.[viewId];
    if (!view) return;
    updateViewFormat(viewId, {
      formShared: status,
      formPermission: view.format.formPermission ?? FormPermission.LOGIN,
      formOnlyOneSubmit:
        view.format.formPermission === FormPermission.NO_LOGIN
          ? false
          : view.format.formOnlyOneSubmit,
    });
  };

  /** 切换是否可以多次提交 */
  const switchOnlyOneSubmit = (status = !collectionView?.format.formOnlyOneSubmit) => {
    if (isLocked) return;
    updateViewFormat(viewId, { formOnlyOneSubmit: status });
  };

  /** 选择收集表权限 */
  const openFormShareType = (event: MouseEvent) => {
    if (isLocked) return;

    openModal.dropdown({
      popcorn: event.currentTarget,
      content: (_) => (
        <ListView
          className="next-modal py-2.5 w-80"
          onItemClick={() => _.onCloseModal()}
          items={Object.keys(shareFormSettingType).map((item: unknown) => ({
            type: ListItemType.OPERATION,
            data: {
              title: shareFormSettingType[item as FormPermission],
              selected: formPermission === Number(item),
              onClick: () => {
                // 任何人登录后填写（Anyone_login），无需登录(Anyone)，当前空间成员（Space_member），邀请的人（people_invite）
                const bizFormPermission = {
                  [FormPermission.LOGIN]: 'Anyone_login',
                  [FormPermission.NO_LOGIN]: 'Anyone',
                  [FormPermission.WORKSPACE]: 'Space_member',
                  [FormPermission.INVITE]: 'people_invite',
                };
                bizTracker.event('form_authority', {
                  authority_is: bizFormPermission[item as FormPermission],
                });
                const view = getState().collectionViews?.[viewId];
                if (!view) return;
                updateViewFormat(viewId, {
                  formPermission: Number(item),
                  formOnlyOneSubmit:
                    view.format.formPermission === FormPermission.NO_LOGIN
                      ? false
                      : view.format.formOnlyOneSubmit,
                });
              },
            },
          }))}
        />
      ),
    });
  };

  const copyLink = async () => {
    let link = shareLink;
    // 偶尔会有用户复制下来的collectionView.shareId为空，因为没法复现，不知道哪里导致的(比如网络不好多次开关分享/store更新问题)
    // 我们需要做二次校验
    if (!collectionView?.shareId) {
      const res = await request.editor.getCollectionView(viewId);
      const shareId = res.shareId || uuid4();
      updateCollectionView(viewId, { shareId });
      link = `${getLocationOrigin()}${ViewPath.form}${shareId}${
        createViewUser?.inviteCode ? `?${SearchParams.inviteCode}=${createViewUser.inviteCode}` : ''
      }`;
    }
    void writeTextInClipboard(
      `${link}\n${URL_SPLIT_TEXT}${collectionView?.title ?? UNTITLED_FORM}`,
      { message: '已复制链接' }
    );
  };

  return (
    <div className={cx('pt-2.5', !isShared && 'pb-2.5')}>
      <div
        className="animate-hover rounded-none cursor-pointer py-2.5 px-4 flex items-center justify-between"
        onClick={() => switchOpenShare()}
      >
        <div>
          {__BUILD_IN__ ? (
            <div className="text-t2 mb-px">分享表单</div>
          ) : (
            <HelperIconBox
              popup="了解收集表分享"
              className="text-t2 mb-px"
              link={helperLink.shareFormLink}
            >
              分享表单
            </HelperIconBox>
          )}
          <div className="text-t4 text-grey3">开启后可进行分享设置</div>
        </div>
        <Switch open={isShared} onSwitch={switchOpenShare} disabled={isLocked} />
      </div>
      {isShared && (
        <>
          <Divider className="mx-4 my-1" />
          <div
            className={cx(
              'animate-hover rounded-none cursor-pointer py-2.5 px-4 flex items-center justify-between',
              isNoLoginForm && 'pointer-events-none opacity-30'
            )}
            onClick={() => switchOnlyOneSubmit()}
          >
            <div className="text-t2 mb-px">允许填写人提交多次</div>
            <Switch
              open={!collectionView.format.formOnlyOneSubmit}
              onSwitch={() => switchOnlyOneSubmit()}
              disabled={isLocked}
            />
          </div>
          <Divider className="mx-4 my-1" />
          <div
            className={cx(
              'animate-hover rounded-none cursor-pointer py-2.5 px-4 flex items-center justify-between mb-1',
              isLocked && 'cursor-not-allowed'
            )}
            onClick={(event: MouseEvent) => openFormShareType(event)}
          >
            <div className="text-t2 mb-px flex items-center justify-between w-full">
              <span className="flex items-center">
                <Icon name="IcLink" className="mr-2" size="middle" />
                {shareFormSettingType[formPermission]}
              </span>
              <Icon name="IcArrowDown01" className="text-grey3 ml-2" size="xxxsmall" />
            </div>
          </div>
          {onlyInvitationUsers && (
            <>
              <Divider className="mx-4 my-1" />
              <SearchBar
                className="px-4 py-2.5"
                uuid={collectionId}
                onClick={() => onChangeTab(TabsId.SelectUsers)}
              />
              {formInviteUserPermissions.length > 0 && (
                <div className="px-4 py-2.5">
                  <FormShareUserPermissionList uuid={viewId} num={maxUserCount} onlyUserList />
                </div>
              )}
              {maxUserCount < formInviteUserPermissions.length && (
                <div
                  onClick={() => onChangeTab(TabsId.FormUserList)}
                  className="flex items-center animate-click animate-hover rounded-none px-4 py-2.5"
                >
                  <Icon size="middle" className="text-grey4" name="IcShareAnyone" />
                  <div className="text-t3 text-grey4 pl-4">
                    查看所有填写者（{formInviteUserPermissions.length} 个）
                  </div>
                </div>
              )}
            </>
          )}
          <ShareButtons uuid={viewId} url={shareLink} onCopyLink={copyLink} />
        </>
      )}
    </div>
  );
};
