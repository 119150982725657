import type { FC } from 'react';
import type { FormulaValueProps } from '../type';
import { formula } from '@flowus/formula';
import { PersonTag } from '../../../person';

export const FormulaPersonValue: FC<FormulaValueProps> = (props) => {
  const users = formula.ValueTool.asList(props.value).elements.map((v) => {
    return v as formula.User;
  });
  return (
    <div className="flex items-center">
      {users.map((v, index) => {
        return <PersonTag key={index} userId={v.uuid} />;
      })}
    </div>
  );
};
export const FormulaSinglePersonValue: FC<FormulaValueProps> = (props) => {
  const user = formula.ValueTool.asUser(props.value);
  return <PersonTag userId={user.uuid} />;
};
