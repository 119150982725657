import { FoldMenu } from 'src/editor/component/fold-menu';
import { useExpand } from 'src/redux/managers/ui/use-fold';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { $searchParams } from 'src/utils';
import type { BlockElement } from '../core/type';
import { BlockChildren } from '../uikit/block-children';
import { Editable } from '../uikit/editable';
import { BlockDrop } from './dnd/block-drop';
import { getOrderListNumber } from './order-list-helper';

export const OrderListBlockElement: BlockElement = ({ id, root, children }) => {
  const [expand] = useExpand(id, { defaultOpen: true });

  const listNo = useObservableStore(
    (state) => {
      const { blocks } = state;
      return getOrderListNumber(id, blocks);
    },
    [id]
  );

  const isExpand = $searchParams.print || expand;

  return (
    <div className="relative">
      <FoldMenu id={id} />
      <BlockDrop id={id} className="relative my-px" horizontal={root}>
        <div className="block-has-icon-container flex-1">
          <div className="min-w-[24px] mr-1 text-center inline-block dot">{listNo}</div>
          <Editable uuid={id} className="self-start flex-1" placeholder="编号列表" />
        </div>
        {children && isExpand && <BlockChildren blockId={id}>{children}</BlockChildren>}
      </BlockDrop>
    </div>
  );
};
