import type { SearchEngineResultDTO } from '@next-space/fe-api-idl';
import { isBuildIn } from '../../build-in';
import { isInWeapp, splicePathSearchParams } from '../../utils';
import { message } from '../../components/message';
import { ViewPath } from '../../static-const';
import { SearchParams } from '@flowus/const-config';

export const formatReferenceTextToHTML = (text = '', refData = [] as SearchEngineResultDTO[]) => {
  return text?.replace(/\{\{ref:([0-9,]+)\}\}/g, (_, text: string) => {
    return text
      .split(',')
      .map((index) => {
        const idx = parseInt(index, 10) - 1;
        if (!refData[idx]) {
          return '';
        }
        return `<span data-type="reference" data-index="${idx}"></span>`;
      })
      .join('');
  });
};

export const formatReferenceTextToMd = (text = '', refData = [] as SearchEngineResultDTO[]) => {
  text = text.replaceAll('\\(', '$');
  text = text.replaceAll('\\)', '$');
  text = text.replaceAll('\\[', '$$');
  text = text.replaceAll('\\]', '$$');
  return text?.replace(/\{\{ref:([0-9,]+)\}\}/g, (_, text: string) => {
    return text
      .split(',')
      .map((index) => {
        const idx = parseInt(index, 10) - 1;
        const url = refData[idx]?.url;
        if (!refData[idx] || !url) {
          return '';
        }
        return `[[${idx + 1}]](${
          url.startsWith('http') ? url : `${window.location.origin}${url}`
        })`;
      })
      .join('');
  });
};

export const getSourceDisplayText = (result: { url?: string; engine?: string }) => {
  if (!result.url) return result.engine;
  try {
    const url = new URL(result.url);
    if (url.protocol.startsWith('http')) {
      return url.hostname;
    }
  } catch {
    // If URL parsing fails, fall back to engine
  }
  return result.engine;
};

export const getUrlFavicon = (url: string) => {
  if (isBuildIn()) {
    return `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${url}&size=256`;
  }
  return `https://icon.1st.name/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&size=256&url=${url}`;
};

export const downloadImage = (url: string, fileName: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export function isTextTruncatedByRange(element: HTMLElement): boolean {
  // 确保元素包含文本节点
  if (!element.firstChild || element.firstChild.nodeType !== Node.TEXT_NODE) {
    return false;
  }

  // 明确将 firstChild 断言为 Text 类型
  const textNode = element.firstChild as Text;

  // 创建 Range 对象并测量文本宽度
  const range = document.createRange();
  range.setStart(textNode, 0);
  range.setEnd(textNode, textNode.length);

  // 获取文本实际宽度和容器可视宽度
  const textWidth = range.getBoundingClientRect().width;
  const containerWidth = element.getBoundingClientRect().width;

  // 考虑可能的 1px 精度误差（添加容错阈值）
  const precisionThreshold = 1;
  return textWidth > containerWidth + precisionThreshold;
}

export const gotoUpgradePage = (spaceId: string) => {
  if (isInWeapp()) {
    message.warning('请前往 App 或网页端升级');
    return;
  }
  const path = splicePathSearchParams(ViewPath.upgrade, [
    {
      key: SearchParams.callbackPath,
      value: location.pathname,
    },
    {
      key: SearchParams.spaceId,
      value: spaceId,
    },
  ]);
  window.location.href = `${getUrlWithoutDomain()}${path}`;
};

export const getUrlWithoutDomain = () => {
  const { hostname, protocol, port } = window.location;

  const domains = hostname.split('.');
  if (domains.length === 3) {
    domains.shift();
  }

  return `${protocol}//${domains.join('.')}${port ? `:${port}` : ''}`;
};
