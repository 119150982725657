import { useMemo, type FC } from 'react';
import type { FormulaValueProps } from '../type';
import { formula } from '@flowus/formula';
import { cx } from '@flowus/common/cx';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { formulaTextToSegemnts } from '../utils';
import { TextType, type SegmentDTO } from '@next-space/fe-api-idl';

export const FormulaRichTextValue: FC<FormulaValueProps> = (props) => {
  const segments = useMemo(() => {
    const text = formula.ValueTool.asText(props.value);
    return formulaTextToSegemnts(text);
  }, [props.value]);

  return <RichText className={cx('leading-[20px]')} segments={segments} interactable={true} />;
};

export const FormulaListTextValue: FC<FormulaValueProps> = (props) => {
  const list = formula.ValueTool.asList(props.value);
  const segments: SegmentDTO[] = [];
  list.elements.forEach((v, index) => {
    formulaTextToSegemnts(v as formula.Text).forEach((s) => {
      segments.push(s);
    });
    if (index !== list.elements.length - 1) {
      segments.push({ text: ',', type: TextType.TEXT, enhancer: {} });
    }
  });

  return <RichText className={cx('leading-[20px]')} segments={segments} interactable={true} />;
};
