import { BlockType } from '@next-space/fe-api-idl';
import { FoldMenu } from 'src/editor/component/fold-menu';
import { useExpand } from 'src/redux/managers/ui/use-fold';
import type { NextBlock } from 'src/redux/types';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import { $searchParams } from 'src/utils';
import { calculateListFormat } from 'src/utils/block-utils';
import type { BlockElement } from '../core/type';
import { BlockBefore } from '../uikit/block-before';
import { BlockChildren } from '../uikit/block-children';
import { Editable } from '../uikit/editable';
import { BlockDrop } from './dnd/block-drop';

const formatMap = {
  disc: '•',
  circle: '◦',
  square: '▪',
};
export const BulletListBlockElement: BlockElement = ({ id, root, children }) => {
  const listFormat = useObservableStore(({ blocks }) => getListFormat(id, blocks), [id]);
  const [expand] = useExpand(id, { defaultOpen: true });
  const isExpand = $searchParams.print || expand;

  return (
    <div className="relative">
      <FoldMenu id={id} />
      <BlockDrop id={id} className="my-px" horizontal={root}>
        <div className="block-has-icon-container flex-1">
          <BlockBefore className="h-7">
            <div
              className="pseudoBefore"
              style={{ '--pseudoBefore--content': `"${listFormat}"` } as any}
            />
          </BlockBefore>
          <Editable placeholder="项目列表" className="self-start flex-1" uuid={id} />
        </div>
        {children && isExpand && <BlockChildren blockId={id}>{children}</BlockChildren>}
      </BlockDrop>
    </div>
  );
};

export function getListFormat(uuid: string, blocks: Record<string, NextBlock>) {
  const formatInfo = calculateListFormat(uuid, blocks, BlockType.LIST);
  const format = formatInfo?.format || formatInfo?.defaultFormat;
  // @ts-ignore ignore
  if (format) return formatMap[format] ?? formatMap.disc;
  return formatMap.disc;
}
