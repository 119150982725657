import { AIModeList, DefaultAIModel, ThinkModel } from '@flowus/common/ai-const';
import { sleep } from '@flowus/common/async';
import type { NextBlock } from '@flowus/common/block/type';
import { defaultConversationContext } from '@flowus/common/chat-ai/hooks/context';
import type {
  ConversationContextType,
  SaveArticleParams,
} from '@flowus/common/chat-ai/hooks/types';
import { AI_PAGE_ID } from '@flowus/common/chat-ai/hooks/types';
import { message } from '@flowus/common/components/message';
import { useOnceAnyDay } from '@flowus/common/hooks/use-once-any-day';
import { ViewPath } from '@flowus/common/static-const';
import { BlockType, type BlockDTO, type ConversationDetailDTO } from '@next-space/fe-api-idl';
import { last } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { PagePreview } from 'src/components/page-preview';
import { useOpenUpgradeAiModal } from 'src/components/select-upgrade-plan/upgrade-ai';
import { textToSegments } from 'src/editor/utils/editor';
import { blocksActions } from 'src/redux/reducers/blocks';
import { cache, dispatch } from 'src/redux/store';
import { useGetPageId } from 'src/utils/getPageId';
import { TocType } from 'src/views/main/aside/toc/types';
import { useJumpTop } from 'src/views/main/page-doc/use-jump-top';
import { useCreateDocPage } from '../page';
import { pastePlain } from '../page/use-copy-listener/on-paste/paste-plain';
import { useCurrentSpace } from '../space';
import { useTransaction } from '../use-transaction';
import { switchSpaceViewIdBySpaceId } from '../user/switch-space-view-id';
import { useCurrentUser } from '../user/use-current-user';
import { getIsApp } from '@flowus/common/utils/get-is-app';
import { callNativeAsync } from '@flowus/common/utils/ds-bridge';
import { AppIpc } from '@flowus/shared';
import { AIChatIpc } from '@flowus/shared/ai-chat-ipc';
import { Transformer } from 'markmap-lib';
import type { IPureNode } from 'markmap-common';
import { fromHtml2Mdast } from 'src/utils/ast-util';
import { mdast2Block } from '../page/use-copy-listener/on-paste/helper/mdast-block';

export const useAiChat = () => {
  const id = useGetPageId();
  const history = useHistory();
  const currentSpace = useCurrentSpace();
  const transaction = useTransaction();
  const createPage = useCreateDocPage();
  const openUpgradeAiModal = useOpenUpgradeAiModal();
  const jumpTop = useJumpTop();
  const [context, setContext] = useState<
    Omit<ConversationContextType, 'updateContext' | 'navigateToChat'>
  >({
    ...defaultConversationContext,
    conversationId: id || defaultConversationContext.conversationId,
    spaceId: currentSpace.uuid,
  });

  const toPage = useCallback(
    (id: string) => {
      if (getIsApp()) {
        callNativeAsync({
          event: AppIpc.OPEN_PAGE,
          param: {
            type: AIChatIpc.OPEN_BLOCK_PAGE,
            data: { uuid: id },
          },
        });
        return;
      }
      history.push(`/${id}`);
    },
    [history]
  );

  const navigateToChat = useCallback(
    (id: string) => {
      history.push(`${ViewPath.chat}/${id}`);
    },
    [history]
  );

  const updateContext = useCallback((context: Partial<ConversationContextType>) => {
    setContext((prev) => ({ ...prev, ...context }));
  }, []);

  const onSaveArticle = useCallback(
    async (res: SaveArticleParams) => {
      transaction(() => {
        const _pageId = createPage(
          res.saveType,
          { parentId: currentSpace.uuid },
          TocType.PRIVATE,
          textToSegments(res.user)
        );

        if (!_pageId) return;

        void sleep(200).then(() => {
          if (res.saveType === BlockType.MIND_MAPPING_PAGE) {
            const transformer = new Transformer();
            const { root } = transformer.transform(res.ai);
            transaction(() => {
              const loop = (node: IPureNode, parentId: string) => {
                let blockId = parentId;
                if (node.content) {
                  const mdast = fromHtml2Mdast(node.content);
                  if (!mdast) return;
                  setTimeout(() => {
                    // mdast2Block里面有setTimeout，为了逻辑能走得通，外层也需要setTimeout一下
                    const blocks = mdast2Block({ transaction }, mdast, {
                      pageBlock: cache.blocks[parentId],
                      combine: true,
                    });
                    if (blocks) {
                      blockId = Object.values(blocks.blocks)[0]?.uuid ?? '';
                    }
                    node.children.forEach((v) => {
                      loop(v, blockId);
                    });
                  }, 1);
                } else {
                  node.children.forEach((v) => {
                    loop(v, blockId);
                  });
                }
              };
              loop(root, _pageId);
            });

            // 思维导图需要另外算。
          } else {
            pastePlain({ transaction }, res.ai, { pageBlock: cache.blocks[_pageId] });
          }

          message.success({
            content: (
              <div className="flex items-center space-x-1">
                <span>笔记保存成功</span>
                <span className="text-active_color animate-click" onClick={() => toPage(_pageId)}>
                  立即查看
                </span>
              </div>
            ),
          });
        });
      });
    },
    [createPage, currentSpace.uuid, toPage, transaction]
  );

  const insertParagraph = useCallback(
    async (res: Parameters<NonNullable<ConversationContextType['insertParagraph']>>[0]) => {
      transaction(() => {
        pastePlain(
          {
            transaction,
            readonly: false,
          },
          res.ai
        );
      });
      await sleep(0);
      const lastBlockId = last(cache.blocks[id]?.subNodes);
      if (lastBlockId) {
        void jumpTop(lastBlockId);
      }
    },
    [id, jumpTop, transaction]
  );

  const renderPageIcon = useCallback((block: BlockDTO) => {
    if (!cache.blocks[block.uuid]) {
      dispatch(blocksActions.update({ blocks: { [block.uuid]: block as NextBlock } }));
    }

    return (
      <IconTrigger
        blockId={block.uuid}
        trigger={false}
        iconSize={16}
        defaultIcon={<BlockDefaultIcon uuid={block.uuid} size="middle" />}
      />
    );
  }, []);

  const onRenderBlockPreview = useCallback((id: string) => {
    return <PagePreview className="max-w-full max-h-full" onlyPreview pageId={id} />;
  }, []);

  const onLoadConversation = useCallback(
    (conversation: ConversationDetailDTO) => {
      if (conversation.spaceId !== currentSpace.uuid) {
        switchSpaceViewIdBySpaceId(conversation.spaceId);
      }
    },
    [currentSpace.uuid]
  );

  useEffect(() => {
    const conversationId = id || AI_PAGE_ID;
    if (conversationId !== context.conversationId) {
      updateContext({ conversationId });
    }
    const spaceId = currentSpace.uuid;
    if (spaceId !== context.spaceId) {
      updateContext({ spaceId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, currentSpace.uuid]);

  return {
    onRenderBlockPreview,
    context,
    updateContext,
    navigateToChat,
    onSaveArticle,
    insertParagraph,
    renderPageIcon,
    openUpgrade: openUpgradeAiModal,
    onLoadConversation,
  };
};

export const useAutoShowAiPage = () => {
  return useOnceAnyDay('init-ai-chat', {
    endDay: -1,
    enable: __BUILD_IN__,
  });
};

export const useAIModel = () => {
  const currentUser = useCurrentUser();
  const aiModel = currentUser.setting?.aiModel ?? DefaultAIModel.value;
  const selectedAIMode = AIModeList.find((i) => i.value === aiModel) ?? DefaultAIModel;
  return selectedAIMode;
};

export const getIsThinkModel = (model: string) => {
  return ThinkModel.includes(model);
};
