import type { formula } from '@flowus/formula';
import { CollectionSchemaType, TextType, type SegmentDTO } from '@next-space/fe-api-idl';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { getState } from 'src/redux/store';

export const formulaTextToSegemnts = (text: formula.Text) => {
  if (!text) return [];
  if (!text.segments) return [];
  return text.segments.map((v) => {
    const segment: SegmentDTO = {
      type: TextType.TEXT,
      text: v.text,
      enhancer: { ...v.enhancer },
    };
    if (v.enhancer.link) {
      segment.type = TextType.URL;
    }
    return segment;
  });
};
/** 尝试转换为公式2.0的文本 */
export const tryNewFormula = (formulaSchema: formula.ServerFormula, collectionId: string) => {
  const collection = getState().blocks[collectionId];
  if (formulaSchema.version === 2 || isNewFormulaInTime(collection?.createdAt)) {
    return formulaSchema;
  }
  let { text } = formulaSchema;
  // 检测是不是relation或者多选，如果是就加个.format()
  const schema = collection?.data.schema ?? {};
  // const re = /prop\("([^"]+)"\)/g;
  const re = /prop\("([^"]+)"\)(?!\.format\(\))/g;
  let match;
  while ((match = re.exec(text))) {
    const propText = match[0] ?? '';
    const propId = match[1] ?? '';
    const s = schema[propId];
    if (
      s?.type === CollectionSchemaType.MULTI_SELECT ||
      s?.type === CollectionSchemaType.RELATION
    ) {
      text = text.replaceAll(propText, `${propText}.format()`);
    }
  }
  return { ...formulaSchema, text, version: 2 } as formula.ServerFormula;
};
/** 判断是否是2.0的一个依据,2025-2-28之后创建的多维表一律当2.0处理 */
export const isNewFormulaInTime = (createdAt: number | undefined) => {
  return (createdAt ?? 0) > 1740672000000;
};
