import { cx } from '@flowus/common/cx';
import { CollectionSchemaType, CollectionViewType } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { memo, useEffect, useRef } from 'react';
import { shallowEqual } from 'react-redux';
import { useBitable } from 'src/bitable/context';
import { useProperties } from 'src/hooks/collection-view/use-properties';

import { DEFAULT_ROW_HEIGHT, TableClassName, getPropertyWidth } from '../../const';
import { FooterItem } from './footer-item';
import { HIDDEN_CHECK_BOX_WIDTH } from '../body/hidden-checkbox';
import {
  useFreezeColumnIndex,
  useLoadingMode,
} from 'src/hooks/collection-view/use-collection-view';
import { Pagination } from '../pagination';
import { throttle } from 'lodash-es';
import { useBitableLoadMoreContext } from 'src/bitable/hooks';

interface FooterProps {
  recordIds: string[];
  showPagination?: boolean;
}

export const Footer: FC<FooterProps> = memo(({ recordIds, showPagination }) => {
  const { viewId, embed, viewType, readonly } = useBitable();
  const isTimeline = viewType === CollectionViewType.TIMELINE;
  const [properties = [], timelineTableProperties = []] = useProperties(viewId, {
    visible: true,
    includeIdNumber: true,
  });
  const { pageNo } = useBitableLoadMoreContext();
  const loadMode = useLoadingMode(viewId);
  let paginationheight = 0;
  if (showPagination && loadMode === 'pagination') {
    paginationheight = 40;
  }

  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!embed) return;

    const footerNode = footerRef.current;
    const embedTable = footerNode?.parentElement;
    const nextSpacePage = footerNode?.closest('.next-space-page');
    if (!footerNode || !embedTable || !nextSpacePage) return;
    const embedTablePaddingBottom = parseInt(
      getComputedStyle(embedTable).getPropertyValue('padding-bottom'),
      10
    );
    const handleScroll = () => {
      const embedTableRect = embedTable.getBoundingClientRect();
      const nextSpacePageRect = nextSpacePage.getBoundingClientRect();

      if (embedTableRect.bottom > nextSpacePageRect.bottom) {
        let translateY = Math.abs(nextSpacePageRect.bottom - embedTableRect.bottom);

        const headerNode = embedTable.querySelector(`.${TableClassName.tableHeader}`);
        const headerRect = headerNode?.getBoundingClientRect();
        if (headerRect && nextSpacePageRect.bottom - headerRect.bottom < footerNode.clientHeight) {
          translateY =
            translateY - (footerNode.clientHeight - (nextSpacePageRect.bottom - headerRect.bottom));
        }

        translateY -= embedTablePaddingBottom;
        footerNode.style.transform = `translate3d(0,-${Math.round(translateY)}px,0)`;
        footerNode.style.borderTopWidth = '1px';
      } else {
        footerNode.style.transform = 'none';
        footerNode.style.borderTopWidth = '0px';
      }
    };
    const _handleScroll = throttle(handleScroll, 10);
    // 切换页面之后要刷一下位置
    setTimeout(() => {
      _handleScroll();
    }, 100);

    nextSpacePage.addEventListener('scroll', _handleScroll);
    window.addEventListener('resize', _handleScroll);
    return () => {
      nextSpacePage.removeEventListener('scroll', _handleScroll);
      window.removeEventListener('resize', _handleScroll);
    };
  }, [embed, pageNo]);
  const freezeColumnIndex = useFreezeColumnIndex(viewId, properties);
  const freezeProperties = properties.slice(0, freezeColumnIndex + 1);
  const restProperties = properties.slice(freezeColumnIndex + 1);
  return (
    <div
      className={cx(
        'group sticky bottom-0 w-full min-w-max transition-none print:hidden bg-white1 z-[12]',
        TableClassName.tableFooter
      )}
      style={{ height: DEFAULT_ROW_HEIGHT + paginationheight }}
      ref={footerRef}
    >
      <div className="flex h-9">
        {!isTimeline && !readonly && freezeProperties.length === 0 && (
          <div
            style={{
              width: HIDDEN_CHECK_BOX_WIDTH,
            }}
          />
        )}
        {freezeProperties.length > 0 && (
          <div className="flex sticky left-0 z-[10] bg-white1">
            {!isTimeline && !readonly && (
              <div
                style={{
                  width: HIDDEN_CHECK_BOX_WIDTH,
                }}
              />
            )}
            {(isTimeline ? timelineTableProperties : freezeProperties).map((property, index) => {
              if (property.type === CollectionSchemaType.ID_NUMBER) {
                // 占位
                return (
                  <div
                    key={index}
                    style={{
                      width: getPropertyWidth(property),
                    }}
                  />
                );
              }
              return (
                <FooterItem
                  recordIds={recordIds}
                  index={index}
                  propertyId={property.property}
                  width={getPropertyWidth(property)}
                  key={property.property}
                />
              );
            })}
          </div>
        )}
        {(isTimeline ? timelineTableProperties : restProperties).map((property, index) => {
          if (property.type === CollectionSchemaType.ID_NUMBER) {
            // 占位
            return (
              <div
                key={index}
                style={{
                  width: getPropertyWidth(property),
                }}
              />
            );
          }
          return (
            <FooterItem
              recordIds={recordIds}
              index={index}
              propertyId={property.property}
              width={getPropertyWidth(property)}
              key={property.property}
            />
          );
        })}
        {!isTimeline && <div className="w-12 flex-1" />}
      </div>
      {showPagination && <Pagination />}
    </div>
  );
}, shallowEqual);
