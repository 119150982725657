import { PDF_HISTORY_SIDEBAR, TOC_COLLAPSED } from 'src/common/const';
import { getLocalFolderViewTypeList } from 'src/hooks/drive/get-local-folder-view-type-list';
import { getLocalStorageThemeMode } from 'src/hooks/public/use-theme/get-local-storage-theme-mode';
import { foldListExpandInfo } from 'src/redux/managers/ui/fold-list-expand-info';
import { getDateTimeStamp } from 'src/utils/date-utils';
import { createWithEqualityFn } from 'zustand/traditional';
import type { AppUiStateType } from './type';

const initState = {
  $currentSpaceId: '',
  $isDarkMode: document.body.className.includes('dark'),
  $firstLoadPage: true,
  $isMobileSize: false,
  $isEditingPage: false,
  $today: getDateTimeStamp(Date.now()),
  $pageRootLoaded: false,
  $createBlockMenuListId: '',
  $blockMenuListId: '',
  $theme: getLocalStorageThemeMode(),
  $isCollapsed: localStorage.getItem(TOC_COLLAPSED) === 'true',
  $columnResizing: false,
  $menuListKeywords: '',
  $isFocusInRightPanel: false,
  $textareaPlaceHolder: undefined,
  $slashSelection: {},
  $ignoreSlash: false,
  $focusedInSyncBlockId: '',
  $serviceCenterRedBadge: false,
  $pressedSpace: false,
  $editingBlockId: undefined,
  $focusBlockId: undefined,
  $fatalError: undefined,
  $publicHomePage: undefined,
  $isSelecting: false,
  $expandPageRecord: {},
  $expandFoldRecord: foldListExpandInfo.getSortItems().reduce((p, c) => {
    p[c.key] = c.value;
    return p;
  }, {} as Record<string, boolean>),
  $fileIllegal: false,
  $dirty: false,
  $syncUpFault: {
    backup: false,
    noPermission: false,
  },
  $membersStates: {},
  $collectionSearch: {},
  $folderListViewTypeList: getLocalFolderViewTypeList(),
  $sharedPages: [],
  $subscriptionData: {},
  $notificationsMagic: {},
  $guestsList: [],
  $toolbarInfo: undefined,
  $tableShowHiddenGroup: {},
  $tableGroupFoldStatus: {},
  $newTableProperty: undefined,

  $allowEmojiPanel: true,
  $bodyWidth: document.body.offsetWidth,
  $bodyHeight: document.body.offsetHeight,
  $isOpenImagePreview: false,
  $readyUpdate: false,
  $isCtrlKeyDown: false,
  $showTransferList: false,
  $aiCommands: [],
  $aiMembers: [],
  $pdfQuotes: [],
  $pdfAIContents: [],
  $showPDFSidebar: false,
  $pdfHistorySidebar: localStorage.getItem(PDF_HISTORY_SIDEBAR) === 'true',
  $pdfAIHistory: [],
  $lastRefreshTime: Date.now(),
  $subscribePages: undefined,
  $exChangeCodeRedBadge: false,
  $showAiHistory: false,
} as AppUiStateType;

export const $appUiState = createWithEqualityFn<AppUiStateType>(() => initState);
