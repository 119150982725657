import { SeoAnchorLink } from '@flowus/common/components/seo-anchor-link';
import { cx } from '@flowus/common/cx';
import { Tooltip } from '@flowus/common/tooltip';
import type { CardFormat } from '@next-space/fe-api-idl';
import { PermissionRole } from '@next-space/fe-api-idl/lib/models/PermissionRole';
import type { FC } from 'react';
import React, { memo, useMemo, useRef } from 'react';
import { Tooltip as NoBugTooltip } from 'src/common/components/tooltip';
import { NoAccess } from 'src/components/no-access';
import { PagePreview, set_mention_block_menu } from 'src/components/page-preview';
import { useBlockStyle } from 'src/hooks/block/use-block-style';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useDisableInteractive } from 'src/services/app/hook/use-drop-info';
import { useObservableBlock } from 'src/services/rxjs-redux/hook';
import { StoreContextProvider } from 'src/services/store-context/provider';
import { isInColumnList } from 'src/utils/block-utils';
import { judgeSharePage, useGetPageId } from 'src/utils/getPageId';
import { usePickBlock } from 'src/utils/pick-block';
import { SegmentPlainText } from 'src/views/main/aside/toc/helper';
import { PageScene, usePageScene } from 'src/views/main/scene-context';
import type { Instance, Props } from 'tippy.js';
import { MentionBlockMenu } from '../inline/inline-page';
import { DefaultPage } from './default-page';
import { LargeDefaultCard } from './large-default-card';
import { LargeFillBackgroundCard } from './large-fill-background-card';
import { LargeThumbImagesCard } from './large-thumb-images-card';
import { LargeThumbPageCard } from './large-thumb-page-card';
import { PageToolbar } from './page-tool-bar';
import { SmallDefaultCard } from './small-default-card';
import { SmallFillBackgroundCard } from './small-fill-background-card';
import { SmallThumbImagesCard } from './small-thumb-images-card';
import { SmallThumbPageCard } from './small-thumb-page-card';
import type { BlockElementProps } from './types';

try {
  set_mention_block_menu(MentionBlockMenu);
} catch {
  // 本地开发偶尔编译会出问题
}

export const CardStyleCompat = {
  regular: 'regular',
  fill: 'fill',
  thumbnailPage: 'thumbnailPage',
  thumbnailImage: 'thumbnailImage',
  // horizontalRectangle,smallVerticalRectangle是老数据，指向regular
  horizontalRectangle: 'regular',
  smallVerticalRectangle: 'regular',
};

export const RawPage: FC<BlockElementProps> = memo(({ id, ownerBlockId }) => {
  const block = usePickBlock(id, ['data'], ['segments']);
  const cardFormat = useObservableBlock(
    ownerBlockId ?? id,
    (block) => block?.data.format?.cardFormat
  );

  // 引用block是否存在,不存在有2种情况, 1.被删除 2.引用的是别人的页面，别人没开启分享
  const isLinkPageNotExist = ownerBlockId && !block;
  // 被风控了
  const { illegal, role } = usePermissions(id);
  const openPage = useOpenPage();
  const tippyInstance = useRef<Instance<Props>>();
  const blockStyle = useBlockStyle(id);
  const cover = useObservableBlock(id, (block) => block?.data.cover);
  const pageId = useGetPageId();
  const disableInteractive = useDisableInteractive();
  const pageScene = usePageScene();
  const isInColumn = useMemo(
    () => isInColumnList(ownerBlockId ?? id, pageId),
    [ownerBlockId, id, pageId]
  );

  // 这里不能加页面已被删除，因为有可能是引用别人的未开启的分享的页面
  const title = isLinkPageNotExist ? '无法访问' : <SegmentPlainText uuid={block?.uuid ?? ''} />;
  const showIcon = cardFormat?.isShowIcon ?? true;
  const showCover = Boolean(cover) && (cardFormat?.isShowCover ?? true);

  const handlePageClick = (event: React.MouseEvent) => {
    openPage(id, {
      forceOpenInRight: event.altKey,
      forceOpenNewTab: event.ctrlKey || event.metaKey,
    });
  };

  const PageCardElement = useMemo(() => {
    const showDefault =
      cardFormat?.showType === 'default' ||
      cardFormat?.showType === undefined ||
      pageScene === PageScene.PAGE_LITE_PREVIEW;
    const cardStyle = (
      cardFormat?.cardStyle ? CardStyleCompat[cardFormat.cardStyle] : 'regular'
    ) as CardFormat['cardStyle'];
    const isLarge = cardFormat?.showType === 'large';
    let _PageCardElement;
    // 如果已经在预览页面，则不要再显示预览图，否则会有互相引用，死循环的后果
    if (showDefault) {
      _PageCardElement = DefaultPage;
    } else {
      switch (cardStyle) {
        case 'regular':
          _PageCardElement = isLarge ? LargeDefaultCard : SmallDefaultCard;
          break;
        case 'fill':
          _PageCardElement = isLarge ? LargeFillBackgroundCard : SmallFillBackgroundCard;
          break;
        case 'thumbnailPage':
          _PageCardElement = isLarge ? LargeThumbPageCard : SmallThumbPageCard;
          break;
        case 'thumbnailImage':
          _PageCardElement = isLarge ? LargeThumbImagesCard : SmallThumbImagesCard;
          break;
        default:
          _PageCardElement = DefaultPage;
      }
    }
    return _PageCardElement;
  }, [cardFormat?.cardStyle, cardFormat?.showType, pageScene]);

  if (!block && !isLinkPageNotExist) return null;

  if (role === PermissionRole.NONE) {
    return <NoAccess id={id} />;
  }

  return (
    <StoreContextProvider extendsStore wait={1000} waitMode="debounce">
      <Tooltip
        popupClassName="bg-white2"
        placement="top-end"
        className={cx('w-full', {
          'overflow-hidden max-w-full': cardFormat?.showType === 'large',
          'w-fit': cardFormat?.showType === 'large' && !isInColumn,
        })}
        popup={!judgeSharePage() && <PageToolbar blockId={ownerBlockId ?? id} illegal={illegal} />}
        style={{
          lineHeight: '36px', // hover menu需求这个
        }}
      >
        {/* floating-ui实现的tooltip鼠标快速移动到上面可能会close掉,需要用老的那个 */}
        <NoBugTooltip
          interactive={true}
          placement={'top'}
          lazyLoad={true}
          theme="next-modal"
          maxWidth={10000}
          delay={1000}
          className="w-full"
          disabled={disableInteractive}
          popup={
            <PagePreview
              pageId={id}
              isLink={!!ownerBlockId}
              onCloseOutsideModel={() => {
                tippyInstance.current?.hide();
              }}
            />
          }
        >
          <SeoAnchorLink href={id}>
            <PageCardElement
              id={id}
              showCover={showCover}
              showIcon={showIcon}
              title={title}
              isInColumn={isInColumn}
              ownerBlockId={ownerBlockId}
              onClick={handlePageClick}
              illegal={illegal}
              desc={cardFormat?.cardCaption}
              descColor={blockStyle.color}
            />
          </SeoAnchorLink>
        </NoBugTooltip>
      </Tooltip>
    </StoreContextProvider>
  );
});
