import { fromMarkdown } from 'src/utils/ast-util';
import type { HandOption, PasteHook } from '../types';
import { common } from './common';
import { mdast2Block } from './helper/mdast-block';

export const pastePlain = (props: PasteHook, plain: string, opt?: HandOption) => {
  const { insertInTitle } = common();

  if (insertInTitle(plain)) return;

  const mdast = fromMarkdown(plain);

  mdast2Block(props, mdast, opt);
};
