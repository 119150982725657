import { cx } from '@flowus/common/cx';
import { deepEqual } from '@flowus/common/utils/tools';
import { debounce, last, sum, throttle } from 'lodash-es';
import type { FC } from 'react';
import { memo, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { useBitable } from 'src/bitable/context';
import { IS_IPHONE } from 'src/common/utils/environment';
import { HoverMenu } from 'src/editor/editor/uikit/hover-menu';
import { Selector } from 'src/editor/editor/uikit/selector';
import { useOpenSubItem } from 'src/hooks/block/use-open-subitem';
import { useBiTableGroups } from 'src/hooks/collection-view/table-groups';
import type { TableGroupData } from 'src/hooks/collection-view/table-groups/select-collection-groups';
import { useShowTimelineTable, useZoomLevel } from 'src/hooks/collection-view/use-collection-view';
import { useProperties } from 'src/hooks/collection-view/use-properties';
import { useCtrlDragScroll } from 'src/hooks/utils/use-ctrl-drag-scroll';
import { useIsMobileSize, useTableGroupIsUnFolded } from 'src/services/app/hook';
import { ScrollRefContext, usePageScrollRef } from 'src/views/main/page-doc/context';
import { AddGroup } from '../add-group';
import { getPropertyWidth, isEnableVirtuoso, OVERSCAN } from '../const';
import { GroupHeader } from '../group-header';
import { BitableLoadMoreContext } from '../hooks';
import { TableGroup, useInitGroupFoldStatus } from '../table-view';
import { Body } from '../table-view/body';
import { Footer } from '../table-view/footer';
import { Header } from '../table-view/header';
import { ToggleHiddenGroup } from '../toggle-hidden-group';
import { TimelineDates } from './body/dates';
import { TimelineMonths } from './body/months';
import { TimelineWeekend } from './body/week-end';
import {
  DateDir,
  EMBED_PAGE_MANAGER_HEIGHT,
  NewCardPlaceholderId,
  PAGE_MANAGER_HEIGHT,
  ScrollBufferLength,
  StepLengthMap,
  UnitWidthMap,
} from './const';
import { TimelineContext, useTimeline } from './context';
import { TimelineHeader } from './header';
import { RecordLine } from './record-line';
import {
  getContainerWidth,
  getDateRange,
  getTimelineDateRange,
  getUnitLength,
} from './utils/get-timeline-dates';

export interface RecordData {
  id: string;
  startTime?: number;
  endTime?: number;
}

interface TimeLineProps {
  recordIds: string[];
}

export const TimeLineView: FC<TimeLineProps> = ({ recordIds }) => {
  const { viewId } = useBitable();
  const zoomLevel = useZoomLevel(viewId);
  const stepLength = useRef<number>(StepLengthMap[zoomLevel]);
  const dates = getTimelineDateRange({ zoomLevel, length: stepLength.current });
  const [timelineDates, setTimelineDates] = useState<number[]>(dates);
  const timelineDatesRef = useRef<number[]>(timelineDates);
  const container = useRef<HTMLDivElement | null>(null);
  const datesContainer = useRef<HTMLDivElement>(null);
  const scrollLeft = useRef<number | undefined>();
  const draggingRecordId = useRef<string | undefined>();

  timelineDatesRef.current = timelineDates;

  useEffect(() => {
    stepLength.current = StepLengthMap[zoomLevel];
    scrollLeft.current = undefined;
    draggingRecordId.current = undefined;
    const dates = getTimelineDateRange({ zoomLevel, length: stepLength.current });
    timelineDatesRef.current = dates;
    setTimelineDates(dates);
  }, [zoomLevel]);

  const context = useMemo(() => {
    return {
      containerWidth: getContainerWidth(zoomLevel, timelineDates),
      zoomLevel,
      timelineDatesRef,
      stepLength,
      timelineDates,
      setTimelineDates,
      container,
      scrollLeft,
      datesContainer,
      draggingRecordId,
    };
  }, [zoomLevel, timelineDates]);

  return (
    <TimelineContext.Provider value={context}>
      <TimeLine recordIds={recordIds} />
    </TimelineContext.Provider>
  );
};

export const TimeLine: FC<{ recordIds: string[] }> = memo(({ recordIds }) => {
  const { embed, readonly, viewId } = useBitable();
  const {
    timelineDates,
    setTimelineDates,
    container,
    datesContainer,
    scrollLeft,
    stepLength,
    zoomLevel,
    timelineDatesRef,
  } = useTimeline();
  const timelineHeaderRef = useRef<HTMLDivElement>(null);
  const monthContainerRef = useRef<HTMLDivElement>(null);
  const isMobileSize = useIsMobileSize();
  const isShowTable = useShowTimelineTable(viewId);
  const { tableGroups } = useBiTableGroups(viewId) ?? {};
  const { withoutValidGroup, visibleGroups = [], hiddenGroups = [] } = tableGroups ?? {};
  const scrollRef = useRef<HTMLDivElement>(null);
  const [_, timelineTableProperties = []] = useProperties(viewId, { visible: true });
  const unitWidth = UnitWidthMap[zoomLevel];
  useCtrlDragScroll(container);

  useLayoutEffect(() => {
    if (!container.current) return;
    container.current.scrollLeft =
      getUnitLength(Date.now(), timelineDates[0] as number, zoomLevel) * unitWidth -
      container.current.clientWidth / 2;
  }, [container, timelineDates, unitWidth, zoomLevel]);

  useLayoutEffect(() => {
    if (!container.current || !scrollLeft.current) return;

    container.current.scrollLeft = scrollLeft.current;
    scrollLeft.current = undefined;
  }, [timelineDates, container, scrollLeft]);

  useEffect(() => {
    if (!container.current) return;

    // 处理当前屏幕超宽导致 range 的展示长度不够,需要修改步进长度
    if (container.current.scrollWidth < container.current.clientWidth * 2) {
      stepLength.current = Math.ceil(stepLength.current * 1.5);
      const newDates = getTimelineDateRange({
        zoomLevel,
        length: stepLength.current,
      });

      setTimelineDates(newDates);
      scrollLeft.current =
        getUnitLength(Date.now(), newDates[0] as number, zoomLevel) * unitWidth -
        container.current.clientWidth / 2;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [container.current?.clientWidth, unitWidth, zoomLevel]);

  useEffect(() => {
    if (!container.current || !datesContainer.current) return;
    const containerNode = container.current;

    const handleScroll = () => {
      if (typeof scrollLeft.current !== 'undefined' || !container.current) return;

      if (
        containerNode.scrollWidth - containerNode.scrollLeft - containerNode.clientWidth <
        ScrollBufferLength
      ) {
        const oldDates = timelineDatesRef.current;
        const deleteLength =
          getUnitLength(oldDates[stepLength.current] as number, oldDates[0] as number, zoomLevel) *
          unitWidth;
        scrollLeft.current = container.current.scrollLeft - deleteLength;

        setTimelineDates((dates) => {
          const lastDay = last(dates) as number;
          const appendDates = getDateRange({
            zoomLevel,
            base: lastDay,
            length: stepLength.current,
            dir: DateDir.NEXT,
          });
          const newDates = [...dates.slice(stepLength.current), ...appendDates];
          return newDates;
        });
      }

      if (containerNode.scrollLeft < ScrollBufferLength) {
        const oldDates = timelineDatesRef.current;
        const deleteLength =
          getUnitLength(
            oldDates[oldDates.length - 1] as number,
            oldDates[stepLength.current - 1] as number,
            zoomLevel
          ) * unitWidth;
        scrollLeft.current = container.current.scrollLeft + deleteLength;

        setTimelineDates((dates) => {
          const firstDay = dates[0] as number;
          const appendDates = getDateRange({
            zoomLevel,
            base: firstDay,
            length: stepLength.current,
            dir: DateDir.PREV,
          });
          const newDates = [...appendDates, ...dates.slice(0, dates.length - stepLength.current)];
          return newDates;
        });
      }
    };

    const scrollFn = IS_IPHONE ? debounce(handleScroll) : throttle(handleScroll, 60);
    containerNode.addEventListener('scroll', scrollFn);
    return () => containerNode.removeEventListener('scroll', scrollFn);
  }, [
    container,
    scrollLeft,
    stepLength,
    datesContainer,
    setTimelineDates,
    timelineDatesRef,
    zoomLevel,
    unitWidth,
  ]);

  useEffect(() => {
    const containerNode = container.current;
    const datesContainerNode = datesContainer.current;
    const timelineHeaderNode = timelineHeaderRef.current;
    const monthContainerNode = monthContainerRef.current;
    const nextSpacePage = datesContainerNode?.closest('.next-space-page');
    if (
      !containerNode ||
      !datesContainerNode ||
      !timelineHeaderNode ||
      !monthContainerNode ||
      !nextSpacePage
    ) {
      return;
    }

    const offset = embed ? EMBED_PAGE_MANAGER_HEIGHT : PAGE_MANAGER_HEIGHT;

    const handleScroll = () => {
      const containerRect = containerNode.getBoundingClientRect();
      const nextSpacePageRect = nextSpacePage.getBoundingClientRect();

      if (containerRect.top < nextSpacePageRect.top) {
        let translateY = Math.abs(nextSpacePageRect.top - containerRect.top);
        if (containerRect.bottom < nextSpacePageRect.top + 111) {
          // 111 是根据左边table算出来的。大致等于 header + footer + insertblock 部分的高度，基于此微调
          translateY -= nextSpacePageRect.top + 111 - containerRect.bottom;
        }

        timelineHeaderNode.style.transform = `translate3d(0,${Math.round(
          translateY + offset
        )}px,0)`;
        monthContainerNode.style.transform = `translate3d(0,${Math.round(
          translateY + offset
        )}px,0)`;
        datesContainerNode.style.transform = `translate3d(0,${Math.round(
          translateY + offset
        )}px,0)`;
      } else {
        timelineHeaderNode.style.transform = 'none';
        monthContainerNode.style.transform = 'none';
        datesContainerNode.style.transform = 'none';
      }
    };

    nextSpacePage.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {
      nextSpacePage.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [container, datesContainer, embed]);

  const pageRef = usePageScrollRef();
  const [pageContainer, setContainer] = useState(pageRef?.current);
  useEffect(() => {
    setContainer(pageRef?.current);
  }, [pageRef]);
  const width: number | undefined = sum(
    timelineTableProperties.map((item) => getPropertyWidth(item))
  );

  const renderTable = () => {
    if (withoutValidGroup) {
      return (
        <div className="relative border-r">
          <Header isGroup={false} properties={timelineTableProperties} />
          <BitableLoadMoreContext recordIds={recordIds} disable>
            <Body embed={embed} properties={timelineTableProperties} />
            <Footer recordIds={recordIds} />
          </BitableLoadMoreContext>
          {!readonly && !embed && <Selector type="collection" />}
          {!embed && <HoverMenu readonly={readonly} />}
        </div>
      );
    }

    return (
      <ScrollRefContext.Provider value={scrollRef}>
        <div className={'relative border-r'}>
          {pageContainer && (
            <>
              {isEnableVirtuoso(visibleGroups.length) ? (
                <Virtuoso
                  style={{ width }}
                  customScrollParent={pageContainer}
                  overscan={OVERSCAN}
                  increaseViewportBy={OVERSCAN}
                  computeItemKey={(index) => index}
                  totalCount={visibleGroups.length}
                  itemContent={(index) => {
                    const tableGroup = visibleGroups[index];
                    if (!tableGroup) return null;
                    return (
                      <TableGroup
                        key={tableGroup.value}
                        tableGroup={tableGroup}
                        withoutValidGroup={withoutValidGroup}
                        embed={embed}
                        properties={timelineTableProperties}
                        index={index}
                      />
                    );
                  }}
                />
              ) : (
                <>
                  {visibleGroups.map((tableGroup, index) => (
                    <TableGroup
                      key={tableGroup.value}
                      tableGroup={tableGroup}
                      withoutValidGroup={withoutValidGroup}
                      embed={embed}
                      properties={timelineTableProperties}
                      index={index}
                    />
                  ))}
                </>
              )}

              <ToggleHiddenGroup
                hiddenGroupsLength={hiddenGroups.length}
                isTimelineTable
                hiddenGroups={
                  isEnableVirtuoso(hiddenGroups.length) ? (
                    <Virtuoso
                      style={{ width }}
                      customScrollParent={pageContainer}
                      overscan={OVERSCAN}
                      increaseViewportBy={OVERSCAN}
                      computeItemKey={(index) => index}
                      totalCount={hiddenGroups.length}
                      itemContent={(index) => {
                        const tableGroup = hiddenGroups[index];
                        if (!tableGroup) return null;
                        return (
                          <TableGroup
                            key={tableGroup.value}
                            tableGroup={tableGroup}
                            withoutValidGroup={withoutValidGroup}
                            embed={embed}
                            properties={timelineTableProperties}
                          />
                        );
                      }}
                    />
                  ) : (
                    <>
                      {hiddenGroups.map((tableGroup) => (
                        <TableGroup
                          key={tableGroup.value}
                          tableGroup={tableGroup}
                          withoutValidGroup={withoutValidGroup}
                          embed={embed}
                          properties={timelineTableProperties}
                        />
                      ))}
                    </>
                  )
                }
              />
            </>
          )}

          <AddGroup />
          {!readonly && !embed && <Selector type="collection" />}
          {!embed && <HoverMenu readonly={readonly} />}
        </div>
      </ScrollRefContext.Provider>
    );
  };

  return (
    <div
      ref={scrollRef}
      className={cx(
        'flex',
        !embed && (isMobileSize ? '-mx-10 sm:-mx-10' : '-mr-24'),
        !embed && !isShowTable && '-ml-24'
      )}
    >
      {!isMobileSize && isShowTable && renderTable()}

      <div className="relative flex-1 overflow-hidden">
        <TimelineHeader ref={timelineHeaderRef} />

        <div ref={container} className={'relative h-full w-full overflow-auto pb-[40px]'}>
          <TimelineMonths ref={monthContainerRef} />
          <TimelineDates />
          <TimelineWeekend />

          <RecordLines recordIds={recordIds} />
        </div>
      </div>
    </div>
  );
});

const RecordLines: FC<{ recordIds: string[] }> = ({ recordIds }) => {
  const { collectionId, viewId, readonly, managerReadonly } = useBitable();
  const recordList = useRef<HTMLDivElement>(null);
  const isShowTable = useShowTimelineTable(viewId);
  const { containerWidth } = useTimeline();
  const { tableGroups } = useBiTableGroups(viewId) ?? {};
  const { withoutValidGroup, visibleGroups = [], hiddenGroups = [] } = tableGroups ?? {};
  const openSubitem = useOpenSubItem(collectionId);
  useInitGroupFoldStatus();

  const pageRef = usePageScrollRef();
  if (!pageRef.current) return null;

  return (
    <div
      ref={recordList}
      className={cx(
        'record-line-container relative',
        isShowTable && !withoutValidGroup && '-translate-y-[75px]'
      )}
      style={{
        width: containerWidth,
      }}
    >
      {withoutValidGroup && (
        <>
          <Virtuoso
            customScrollParent={pageRef.current}
            overscan={OVERSCAN}
            increaseViewportBy={OVERSCAN}
            computeItemKey={(index) => index}
            // fixedItemHeight={37}
            totalCount={recordIds.length}
            itemContent={(index) => {
              const recordId = recordIds[index];
              if (!recordId) return null;
              return (
                <RecordLine
                  key={recordId}
                  recordId={recordId}
                  level={openSubitem ? 0 : undefined}
                />
              );
            }}
          />

          {isShowTable && recordIds.length === 0 && <div className="h-[37px]" />}

          {readonly || managerReadonly ? null : (
            <RecordLine
              recordId={NewCardPlaceholderId}
              lastRecordId={recordIds[recordIds.length - 1]}
              level={openSubitem ? 0 : undefined}
            />
          )}
        </>
      )}

      {!withoutValidGroup && (
        <>
          {isEnableVirtuoso(visibleGroups.length) ? (
            <Virtuoso
              customScrollParent={pageRef.current}
              overscan={OVERSCAN}
              increaseViewportBy={OVERSCAN}
              computeItemKey={(index) => index}
              totalCount={visibleGroups.length}
              itemContent={(index) => {
                const tableGroup = visibleGroups[index];
                if (!tableGroup) return null;
                return <TimelineGroup key={tableGroup.value} tableGroup={tableGroup} />;
              }}
            />
          ) : (
            <>
              {visibleGroups.map((tableGroup) => (
                <TimelineGroup key={tableGroup.value} tableGroup={tableGroup} />
              ))}
            </>
          )}

          <ToggleHiddenGroup
            hiddenGroupsLength={hiddenGroups.length}
            hiddenGroups={
              isEnableVirtuoso(hiddenGroups.length) ? (
                <Virtuoso
                  customScrollParent={pageRef.current}
                  overscan={OVERSCAN}
                  increaseViewportBy={OVERSCAN}
                  computeItemKey={(index) => index}
                  totalCount={hiddenGroups.length}
                  itemContent={(index) => {
                    const tableGroup = hiddenGroups[index];
                    if (!tableGroup) return null;
                    return <TimelineGroup key={tableGroup.value} tableGroup={tableGroup} />;
                  }}
                />
              ) : (
                <>
                  {hiddenGroups.map((tableGroup) => (
                    <TimelineGroup key={tableGroup.value} tableGroup={tableGroup} />
                  ))}
                </>
              )
            }
          />

          {!isShowTable && <AddGroup />}
        </>
      )}
    </div>
  );
};

interface TimelineGroupProps {
  tableGroup: TableGroupData;
  className?: string;
}

const TimelineGroup: FC<TimelineGroupProps> = memo(({ tableGroup, className }) => {
  const { collectionId, viewId, readonly, managerReadonly } = useBitable();
  const { recordIds, value } = tableGroup;
  const isShowTable = useShowTimelineTable(viewId);
  const isUnFolded = useTableGroupIsUnFolded(viewId, value);
  const openSubitem = useOpenSubItem(collectionId);
  const pageRef = usePageScrollRef();
  if (!pageRef.current) return null;

  if (isShowTable && !isUnFolded) {
    return <div className="h-[57px]" />;
  }

  return (
    <div className={cx(className, isShowTable ? 'pt-[75px] pb-[57px]' : 'pb-5')}>
      {!isShowTable && <GroupHeader tableGroup={tableGroup} />}

      {isUnFolded && (
        <>
          <Virtuoso
            customScrollParent={pageRef.current}
            overscan={OVERSCAN}
            increaseViewportBy={OVERSCAN}
            computeItemKey={(index) => index}
            fixedItemHeight={37}
            totalCount={recordIds.length}
            itemContent={(index) => {
              const recordId = recordIds[index];
              if (!recordId) return null;
              return (
                <RecordLine
                  key={recordId}
                  recordId={recordId}
                  level={openSubitem ? 0 : undefined}
                />
              );
            }}
          />

          {isShowTable && recordIds.length === 0 && <div className="h-[37px]" />}

          {readonly || managerReadonly ? null : (
            <RecordLine
              recordId={NewCardPlaceholderId}
              lastRecordId={recordIds[recordIds.length - 1]}
              groupName={value}
              level={openSubitem ? 0 : undefined}
            />
          )}
        </>
      )}
    </div>
  );
}, deepEqual);
