import dayjs from 'dayjs';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { DATE_TIME_FORMAT } from 'src/common/const';
import { useOpenUpgradeAiModal } from 'src/components/select-upgrade-plan/upgrade-ai';
import { PRODUCT_AI_PAY_TITLE, PRODUCT_AI_TITLE, PRODUCT_AI_TITLE_BUILDIN } from 'src/const/title';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { useIsProSpace } from 'src/hooks/space/use-is-pro-space';
import { Modals } from 'src/modals';
import { useIsAiPlan, useSpaceCapacity } from 'src/services/capacity';
import { OrderListSupport, useSetting } from '../common';
import { Option } from '../option';
import { FlowusAIHistory } from './ai-history';

export const SpaceInfoAI: FC = () => {
  const spaceCapacity = useSpaceCapacity();
  const openModal = useOpenModal();
  const isAiPlan = useIsAiPlan();
  const openUpgradeAiModal = useOpenUpgradeAiModal();
  const { enableAI } = useEnableAI();
  const isProSpace = useIsProSpace();
  const { toUpgradeAi } = useSetting();

  const openAiOrderList = useCallback(() => {
    openModal.modal({
      modalId: Modals.ORDER_LIST_SUPPORT,
      content: () => (
        <OrderListSupport
          title={PRODUCT_AI_PAY_TITLE}
          subTitle={`购买${PRODUCT_AI_PAY_TITLE}`}
          orderType="aiUsage"
          openUpgradeModal={openUpgradeAiModal}
        />
      ),
    });
  }, [openModal, openUpgradeAiModal]);

  const openHistory = () => {
    openModal.modal({
      content: () => <FlowusAIHistory />,
    });
  };

  const aiPlanButton = useMemo(() => {
    if (spaceCapacity.monthlyEndDate) {
      return (
        <span className="animate-click" onClick={() => openAiOrderList()}>
          失效日期 {dayjs(spaceCapacity.monthlyEndDate).format(DATE_TIME_FORMAT)}
        </span>
      );
    }
    return null;
  }, [openAiOrderList, spaceCapacity.monthlyEndDate]);

  if (__BUILD_IN__) {
    const { baseModel, pdf, additional } = spaceCapacity;

    const monthUsed = baseModel.monthUsed + pdf.monthUsed;

    const baseModelRest = isAiPlan
      ? baseModel.monthLimit - baseModel.monthUsed
      : baseModel.dayLimit - baseModel.todayUsed;

    const pdfRest = isAiPlan ? pdf.dayLimit - pdf.todayUsed : pdf.monthLimit - pdf.monthUsed;
    return (
      <>
        <Option title={PRODUCT_AI_TITLE_BUILDIN} rightPart={isProSpace ? '无限次' : ''} />
        {!isProSpace && (
          <div className="flex items-center text-t3 h-9">
            <span className="mr-4">{`已使用 ${monthUsed} 次/ ${pdfRest + baseModelRest} 次`}</span>
            <button className="ml-auto mr-[5px] flex items-center" onClick={openHistory}>
              <span className="text-t3 text-grey3">查看详情</span>
              <Icon name="IcArrowUnfold" size="xxxsmall" className="text-grey3" />
            </button>
          </div>
        )}

        <div className="bg-grey9 rounded py-2 px-4 text-t2 text-grey3">
          <div className="h-7 flex justify-between items-center">
            <span className="">
              GPT-4o mini 基础请求次数：{isAiPlan ? '每月' : '每日'}
              {isAiPlan
                ? `${baseModel.monthUsed}/${baseModel.monthLimit}`
                : `${baseModel.todayUsed}/${baseModel.dayLimit}`}
            </span>
          </div>
          <div className="h-7 flex justify-between items-center">
            <span>GPT-4o mini 补充请求次数：总共 {additional}</span>
          </div>
          <div className="h-7 flex justify-between items-center">
            <span>
              阅读 PDF：{isAiPlan ? '每日' : '每月'}
              {isAiPlan ? `${pdf.todayUsed}/${pdf.dayLimit}` : `${pdf.monthUsed}/${pdf.monthLimit}`}
            </span>
          </div>
        </div>

        <div className="bg-grey9 rounded py-2 px-4 text-t2 text-grey3 mt-2.5 flex justify-between">
          <span>AI 补充权益</span>
          <a
            className="underline ml-2 text-t3 text-active_color cursor-pointer"
            target="_blank"
            onClick={() => toUpgradeAi()}
          >
            购买查询额度
          </a>
        </div>
      </>
    );
  }

  return (
    <>
      <Option title={PRODUCT_AI_TITLE} rightPart={isProSpace ? '无限次' : ''} />
      {!isProSpace && (
        <div className="flex items-center text-t3 h-9">
          <span className="mr-4">
            已使用 {spaceCapacity.usage}次/{isProSpace ? '无限' : spaceCapacity.aiRestUsage}次
          </span>
          <button
            hidden={!enableAI}
            className="ml-auto mr-[5px] flex items-center"
            onClick={openHistory}
          >
            <span className="text-t3 text-grey3">查看详情</span>
            <Icon name="IcArrowUnfold" size="xxxsmall" className="text-grey3" />
          </button>
        </div>
      )}

      {!isProSpace && (
        <div className="bg-grey9 rounded py-2 px-4 text-t2 text-grey3 mt-2.5 flex justify-between">
          <div className="space-x-1">
            <span>{PRODUCT_AI_PAY_TITLE}</span>
            <span
              className="text-active_color underline underline-offset-2 animate-click"
              onClick={() => openUpgradeAiModal()}
            >
              购买查询额度
            </span>
          </div>
          {aiPlanButton}
        </div>
      )}
    </>
  );
};
