import { BlockStatus } from '@next-space/fe-api-idl';
import type { BlockDTO } from '@next-space/fe-api-idl';
import { debounce, keys, pickBy } from 'lodash-es';
import { API } from '../../api';
import { segmentsToText } from '../../subscribe/util';
import i18n from '../i18n/i18n';
import { searchPageStore, setAiChatPages, setSearchStatus } from './store';
import type { MentionItem } from './types';
import { SearchPageStatus } from './types';

interface SearchParams {
  query: string;
  spaceId: string;
  pageSize?: number;
  page?: number;
}

export interface PageTitleInfo {
  id: string;
  title: string;
}

export const getPageTitleInfo = (page?: BlockDTO): PageTitleInfo => {
  return {
    id: page?.uuid || '',
    title: segmentsToText(page?.data?.segments) || i18n.t('untitled'),
  };
};

export const debouncedSearch = debounce(async (params: SearchParams): Promise<MentionItem[]> => {
  try {
    setSearchStatus(SearchPageStatus.LOADING);

    const blocks: Record<string, BlockDTO> = {};

    if (!params.query && keys(searchPageStore.getState().pages).length === 0) {
      const res = await API.editor.querySpaceVisitsHistory(params.spaceId, 0);
      Object.assign(blocks, res.recordMap?.blocks);
    } else {
      const response = await API.editor.searchDocs(
        params.spaceId,
        params.page || 1,
        params.pageSize || 20,
        params.query
      );
      Object.assign(blocks, response.recordMap?.blocks);
    }

    const normalBlocks = pickBy(blocks, (block) => block.status === BlockStatus.NORMAL);
    setAiChatPages(normalBlocks);

    return Object.values(normalBlocks).map((page) => ({
      id: page.uuid,
      label: getPageTitleInfo(page).title,
    }));
  } finally {
    setSearchStatus(SearchPageStatus.DEFAULT);
  }
}, 300);

export const searchPages = async (params: SearchParams): Promise<MentionItem[]> => {
  const results = await debouncedSearch(params);
  return results || [];
};
