/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { formula } from '@flowus/formula';
import { AggregationAction, CollectionSchemaType } from '@next-space/fe-api-idl';
import BigNumber from 'bignumber.js';
import dayjs from 'dayjs';
import { divide, last, sortBy } from 'lodash-es';
import { DATE_TIME_FORMAT } from 'src/common/const';
import type { FormulaTool } from 'src/hooks/block/use-formula-tool';
import { getFormulaTool } from 'src/hooks/block/use-formula-tool';
import { getPropertySchema } from 'src/hooks/block/use-property-schema';
import type { NextBlock } from 'src/redux/types';
import { numberToPercent, percentToNumber } from 'src/utils/number';

import { getDatePropertyFromBlock } from '../cell/helpers';
import type { getRollupValue as getRollupValue0 } from '../cell/rollup/get-rollup-value';
import { getColCellValues } from './get-col-values';
import { isDateAggregation, isPercentAggregation, isShowOriginalValue } from './helper';
import { formatCheckBoxValue } from '@flowus/common/block/checkbox-value';
import type { DateSegmentInfo } from '../cell/types';

export let _getRollupValue: typeof getRollupValue0;

export const antiCycleSet_getRollupValue_1 = (getRollupValue: typeof _getRollupValue) => {
  _getRollupValue = getRollupValue;
};

const getRollupValue: typeof _getRollupValue = (...args) => _getRollupValue(...args);

export const InvalidAggregationValue = undefined;

export const calculateAggregationValue = (
  records: (NextBlock | undefined)[],
  propertyId: string,
  action: AggregationAction,
  collectionId: string,
  formulaTool: FormulaTool
) => {
  const { propertySchema, targetPropertySchema } = getPropertySchema(collectionId, propertyId);
  if (!propertySchema) return undefined;

  const { type: schemaType, aggregation } = propertySchema;
  const targetPropertyType = targetPropertySchema?.type;

  let result: number | string | undefined = undefined;
  let dateStartTimestamp: string | undefined;
  let dateEndTimestamp: string | undefined;

  // @hexiang TODO 类型太复杂!!
  let allColCellValues:
    | (string | undefined | string[])[]
    | (string | (string | string[] | undefined)[] | undefined)[] = [];
  let notEmptyValue: (string | (string | string[] | undefined)[] | undefined)[] = [];

  const isRollup = schemaType === CollectionSchemaType.ROLLUP;

  if (!isDateAggregation(action) && !isRollup) {
    const result = getColCellValues({
      blocks: records,
      propertyId,
      schema: propertySchema,
      formulaTool,
    });

    allColCellValues = result.allCellValues;
    notEmptyValue = result.notEmptyCellValues;
  }

  if (isRollup) {
    allColCellValues = records.map((block) => {
      if (!block) return undefined;
      const {
        originValues,
        aggregationValue,
        relationRecords = [],
      } = getRollupValue(block.uuid, propertyId) ?? {};

      if (isShowOriginalValue(aggregation)) {
        if (targetPropertyType === CollectionSchemaType.TITLE) return relationRecords;
        return originValues;
      }

      if (isPercentAggregation(aggregation)) {
        if (aggregationValue) {
          return String(percentToNumber(aggregationValue));
        }
        return undefined;
      }

      return aggregationValue;
    });

    notEmptyValue = allColCellValues.filter(
      (value): value is string | string[] =>
        (typeof value === 'string' && value !== '') || (Array.isArray(value) && value.length > 0)
    );
  }

  switch (action) {
    /** common */
    case AggregationAction.NONE:
      break;
    case AggregationAction.COUNT_ALL:
    case AggregationAction.COUNT_UNIQUE_VALUE:
    case AggregationAction.COUNT_VALUE:
    case AggregationAction.COUNT_EMPTY:
    case AggregationAction.COUNT_NOT_EMPTY:
    case AggregationAction.PERCENT_EMPTY:
    case AggregationAction.PERCENT_NOT_EMPTY: {
      switch (action) {
        case AggregationAction.COUNT_ALL:
          result = records.length;
          break;
        case AggregationAction.COUNT_VALUE: {
          if (isRollup) {
            result = notEmptyValue.flat(Infinity).filter((item) => !!item).length; // 有些记录的值可能不存在
          } else {
            result = notEmptyValue.flat(Infinity).length;
          }
          break;
        }
        case AggregationAction.COUNT_UNIQUE_VALUE:
          result = new Set(notEmptyValue.flat(Infinity)).size;
          break;
        case AggregationAction.COUNT_EMPTY: {
          result = records.length - notEmptyValue.length;
          break;
        }
        case AggregationAction.COUNT_NOT_EMPTY:
          result = notEmptyValue.length;
          break;
        case AggregationAction.PERCENT_EMPTY:
          result =
            records.length === 0
              ? InvalidAggregationValue
              : numberToPercent(divide(records.length - notEmptyValue.length, records.length));
          break;
        case AggregationAction.PERCENT_NOT_EMPTY:
          result =
            records.length === 0
              ? InvalidAggregationValue
              : numberToPercent(divide(notEmptyValue.length, records.length));
          break;
        default:
          break;
      }

      break;
    }

    /** number */
    case AggregationAction.SUM:
    case AggregationAction.AVERAGE:
    case AggregationAction.MEDIAN:
    case AggregationAction.MIN:
    case AggregationAction.MAX:
    case AggregationAction.RANGE: {
      const values = notEmptyValue.map(Number).filter((num) => !isNaN(num));

      if (values.length === 0) {
        result = 0;
        break;
      }

      switch (action) {
        case AggregationAction.SUM:
          if (values.includes(Infinity) && values.includes(-Infinity)) {
            result = InvalidAggregationValue;
            break;
          }
          result = BigNumber.sum(...values).toNumber();
          break;
        case AggregationAction.AVERAGE:
          if (values.includes(Infinity) && values.includes(-Infinity)) {
            result = InvalidAggregationValue;
            break;
          }
          result = BigNumber.sum(...values)
            .dividedBy(values.length)
            .toNumber();
          break;
        case AggregationAction.MIN:
          result = BigNumber.minimum(...values).toNumber();
          break;
        case AggregationAction.MAX:
          result = BigNumber.maximum(...values).toNumber();
          break;
        case AggregationAction.RANGE: {
          result = new BigNumber(Math.max(...values)).minus(Math.min(...values)).toNumber();
          break;
        }
        case AggregationAction.MEDIAN: {
          const newValues = values.sort((a, b) => a - b);
          if (newValues.length % 2 === 0) {
            const number1 = newValues[newValues.length / 2];
            const number2 = newValues[newValues.length / 2 - 1];
            if (!number1 || !number2) {
              result = InvalidAggregationValue;
              break;
            }
            result = BigNumber.sum(number1, number2).dividedBy(2).toNumber();
          } else {
            result = newValues[(newValues.length - 1) / 2] ?? 0;
          }
          break;
        }
        default:
          break;
      }

      break;
    }

    /** checkbox */
    case AggregationAction.CHECKED:
    case AggregationAction.UNCHECKED:
    case AggregationAction.PERCENT_CHECKED:
    case AggregationAction.PERCENT_UNCHECKED: {
      const checkedCount = notEmptyValue.filter(
        (text) => typeof text === 'string' && formatCheckBoxValue(text)
      ).length;

      switch (action) {
        case AggregationAction.CHECKED:
          result = checkedCount;
          break;
        case AggregationAction.UNCHECKED:
          result = records.length - checkedCount;
          break;
        case AggregationAction.PERCENT_CHECKED:
          result =
            records.length === 0
              ? InvalidAggregationValue
              : numberToPercent(divide(checkedCount, records.length));
          break;
        case AggregationAction.PERCENT_UNCHECKED:
          result =
            records.length === 0
              ? InvalidAggregationValue
              : numberToPercent(divide(records.length - checkedCount, records.length));
          break;
        default:
          break;
      }
      break;
    }

    /** date, create_tine, update_time */
    case AggregationAction.EARLIEST_DATE:
    case AggregationAction.LATEST_DATE:
    case AggregationAction.DATE_RANGE: {
      let dates: number[] = [];

      if (
        schemaType === CollectionSchemaType.CREATED_AT ||
        schemaType === CollectionSchemaType.UPDATED_AT ||
        schemaType === CollectionSchemaType.FORMULA
      ) {
        if (schemaType === CollectionSchemaType.CREATED_AT) {
          dates = records.map((block) => block?.createdAt ?? 0).sort();
        } else if (schemaType === CollectionSchemaType.UPDATED_AT) {
          dates = records.map((block) => block?.updatedAt ?? 0).sort();
        } else if (schemaType === CollectionSchemaType.FORMULA) {
          const formulaTool = getFormulaTool(collectionId);
          dates = records
            .filter((it) => it != null)
            .map((block) => {
              const value = formulaTool.getBoxedValue(block!.uuid, propertyId);
              if (value == null) return 0;
              if (formula.ValueTool.typeOf(value) === formula.ValueTool.dateType) {
                return formula.ValueTool.asDate(value).getTime();
              }
              return 0;
            })
            .sort();
        }

        const firstDate = dates[0];
        const lastDate = last(dates);
        if (!firstDate || !lastDate) {
          result = InvalidAggregationValue;
          break;
        }

        switch (action) {
          case AggregationAction.EARLIEST_DATE: {
            result = dayjs(firstDate).format(DATE_TIME_FORMAT);
            dateStartTimestamp = result;
            break;
          }

          case AggregationAction.LATEST_DATE: {
            result = dayjs(lastDate).format(DATE_TIME_FORMAT);
            dateStartTimestamp = result;
            break;
          }

          case AggregationAction.DATE_RANGE: {
            if (firstDate && lastDate) {
              dateStartTimestamp = dayjs(firstDate).format(DATE_TIME_FORMAT);
              dateEndTimestamp = dayjs(lastDate).format(DATE_TIME_FORMAT);
              result = dayjs(firstDate)
                .format(DATE_TIME_FORMAT)
                .concat(' - ')
                .concat(dayjs(lastDate).format(DATE_TIME_FORMAT));
            }
            break;
          }

          default:
            break;
        }
        break;
      }

      if (schemaType === CollectionSchemaType.DATE || schemaType) {
        let allDates = records
          .map((block) => getDatePropertyFromBlock(block, propertyId))
          .filter((item): item is DateSegmentInfo => item !== undefined);

        allDates = sortBy(allDates, (date) => date.timestamp);

        const firstDate = allDates[0];
        const lastDate = last(allDates);

        if (!firstDate || !lastDate) {
          result = InvalidAggregationValue;
          break;
        }

        switch (action) {
          case AggregationAction.EARLIEST_DATE: {
            result = firstDate.dateString;
            dateStartTimestamp = result;
            break;
          }

          case AggregationAction.LATEST_DATE: {
            result = lastDate.dateString;
            dateStartTimestamp = result;
            break;
          }

          case AggregationAction.DATE_RANGE: {
            dateStartTimestamp = firstDate.dateString;
            dateEndTimestamp = lastDate.dateString;
            result = firstDate.dateString.concat(' - ', lastDate.dateString);
            break;
          }

          default:
            break;
        }
      }

      if (isRollup) {
        let allDates: string[] = [];
        if (aggregation === AggregationAction.DATE_RANGE) {
          notEmptyValue.forEach((item) => {
            if (typeof item === 'string') {
              item.split('-').forEach((str) => allDates.push(str.trim()));
            }
          });
        } else {
          allDates = notEmptyValue as string[];
        }
        allDates = allDates.sort() as string[];

        const firstDate = allDates[0];
        const lastDate = last(allDates);

        if (!firstDate || !lastDate) {
          result = InvalidAggregationValue;
          break;
        }

        switch (action) {
          case AggregationAction.EARLIEST_DATE: {
            dateStartTimestamp = firstDate;
            result = firstDate;
            break;
          }

          case AggregationAction.LATEST_DATE: {
            dateStartTimestamp = lastDate;
            result = lastDate;
            break;
          }

          case AggregationAction.DATE_RANGE: {
            dateStartTimestamp = firstDate;
            dateEndTimestamp = lastDate;
            result = firstDate.concat(' - ', lastDate);
            break;
          }

          default:
            break;
        }
      }
      break;
    }

    default:
      break;
  }
  return {
    result,
    dateStartTimestamp,
    dateEndTimestamp,
  };
};
