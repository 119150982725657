import { BlockStatus, BlockType } from '@next-space/fe-api-idl';
import type { SegmentType } from 'src/redux/types';
import { showCaption } from 'src/utils/block-type-utils';
import { LIST_AFTER_BLOCK, LIST_REMOVE_BLOCK, UPDATE_BLOCK } from '../../actions';
import { dispatch, getState } from '../../store';
import { cleanupColumn } from './cleanup-column';

/**
 * 合并块
 * @returns 返回与其合并块的 id
 */
export const mergeBlock = (fromId: string, toId: string) => {
  const { blocks } = getState();

  const curr = blocks[fromId];
  // 只能转换 textarea 块,caption也可以
  if (!curr) return;
  const showingCaption = showCaption(curr);
  if (curr.type !== BlockType.TEXTAREA && !showingCaption) return;
  const prev = blocks[toId];
  if (!prev) return;
  const preSegmentType: SegmentType = showCaption(prev) ? 'caption' : 'segments';
  const currSegmentType: SegmentType = showingCaption ? 'caption' : 'segments';
  const preSegments = prev.data[preSegmentType] ?? [];
  const segments = preSegments.concat(curr.data[currSegmentType] ?? []);

  // 移动原 block 的 children 到当前 block 后面
  curr.subNodes.reduce((after, item) => {
    dispatch(LIST_REMOVE_BLOCK({ uuid: item }));
    dispatch(
      LIST_AFTER_BLOCK({
        uuid: item,
        parentId: curr.parentId,
        after,
      })
    );
    return item;
  }, curr.uuid);

  if (currSegmentType === 'segments') {
    dispatch(UPDATE_BLOCK({ uuid: fromId, patch: { status: BlockStatus.DELETED } }));
    // 从原来的父容器内移除
    dispatch(LIST_REMOVE_BLOCK({ uuid: fromId }));
  } else {
    // 需要把caption清空,不需要删除自己
    dispatch(
      UPDATE_BLOCK({ uuid: fromId, patch: { data: { caption: [], showEmptyCaption: false } } })
    );
  }
  dispatch(
    UPDATE_BLOCK({
      uuid: toId,
      patch: { data: { [preSegmentType]: segments } },
    })
  );

  cleanupColumn(curr.uuid, curr.parentId);
};
