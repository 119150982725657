import { IpcMessage } from '@flowus/shared';
import { Button } from './common/components/button';
import { useLogout } from './hooks/user';
import { ipcSendMessage, isFlowUsApp } from './utils/electron-util';
import { setLocalStorage } from './utils/local-storage';
import { locationToUrl } from './utils/location-utils';
import { useTryPage } from './views/main/aside/toc/use-try-page';
import { useGetPageId } from './utils/getPageId';
import { useEffect } from 'react';
import { bizTracker } from './utils/biz-tracker';

/** 页面白屏错误提示 */
export const ErrorTip = ({ errorMessage }: { errorMessage: string }) => {
  const tryPageId = useTryPage();
  const logout = useLogout();
  const pageId = useGetPageId();

  const handleClick = () => {
    setLocalStorage('tryPageId', tryPageId);
    if (isFlowUsApp.check) {
      ipcSendMessage(IpcMessage.Reload);
    } else {
      locationToUrl(`/${tryPageId}`);
    }
  };
  useEffect(() => {
    if (!pageId) return;
    bizTracker.event('crash', {
      id: pageId,
    });
  }, [pageId]);

  const cleanup = () => {
    void logout();
  };

  return (
    <div className="fixed flex-col left-0 top-0 w-full h-full flex items-center justify-center z-50 bg-white1">
      <p hidden={isFlowUsApp.check}>
        {`出现意外错误。请确保浏览器没有开启广告插件，或已将{PRODUCT_NAME}加入至广告插件白名单内\n[${pageId}]`}
      </p>
      {errorMessage && (
        <p className="px-20 my-4 select-text max-h-[20vh] overflow-auto">{errorMessage}</p>
      )}
      <Button colorType="primary" className="mb-5 w-52" onClick={handleClick}>
        重新加载
      </Button>
      <Button colorType="black" className="w-52" onClick={cleanup}>
        清理缓存
      </Button>
    </div>
  );
};
