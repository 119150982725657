import { Deferred } from '@flowus/common';
import { TransferModal } from '@flowus/common/components/transfer-modal';
import { useOpenModal } from '@flowus/common/next-modal';
import { useCallback } from 'react';
import { request } from 'src/common/request';

export const useOpenTransfer = () => {
  const openModal = useOpenModal();
  return useCallback(
    (options: { type: 'space' | 'user'; spaceId: string }) => {
      const { type } = options;
      const defer = new Deferred();

      const spaceApi = (params: Parameters<typeof request.infra.withdrawFromWallet>[number]) => {
        return request.infra.withdrawFromWallet({
          ...params,
          spaceId: options.spaceId,
        });
      };

      const userApi = request.infra.withdrawMoneyForVipFromWallet;

      const api = type === 'space' ? spaceApi : userApi;

      openModal.modal({
        content: () => (
          <TransferModal
            payApi={api}
            onSuccess={() => {
              defer.resolve();
            }}
          />
        ),
      });
      return defer.promise;
    },
    [openModal]
  );
};
