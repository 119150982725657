import dayjs from 'dayjs';
import produce from 'immer';
import { useCallback, useMemo } from 'react';
import { createPersistStore, createSetState } from '../zustand/utils';

const $onceAnyDay = createPersistStore<Record<string, string>>('$onceAnyDay', () => {
  return {};
});
const $setOnceAnyDay = createSetState($onceAnyDay);

export const useOnceAnyDay = (
  key: string,
  opt: {
    endDay: number;
    enable?: boolean;
  }
) => {
  const { endDay, enable = true } = opt;
  const lastCloseTime = $onceAnyDay((state) => state[key]);

  const setLastCloseTime = useCallback(
    (time: string) => {
      $setOnceAnyDay(
        produce((state) => {
          state[key] = time;
        })
      );
    },
    [key]
  );

  const toDay = useMemo(() => {
    return dayjs().startOf('day').valueOf();
  }, []);

  const day = useMemo(() => dayjs(toDay).diff(dayjs(lastCloseTime), 'day'), [lastCloseTime, toDay]);

  const showOnce = useMemo(() => {
    if (!enable) return false;
    return endDay === -1 ? !lastCloseTime : !lastCloseTime || day >= endDay;
  }, [day, enable, endDay, lastCloseTime]);

  const close = useCallback(() => {
    setLastCloseTime(dayjs(toDay).format('YYYY-MM-DD HH:mm'));
  }, [setLastCloseTime, toDay]);

  const clear = useCallback(() => {
    setLastCloseTime('');
  }, [setLastCloseTime]);

  return useMemo(
    () => ({
      lastCloseTime,
      setLastCloseTime,
      showOnce,
      close,
      clear,
    }),
    [clear, close, lastCloseTime, setLastCloseTime, showOnce]
  );
};

export const onceAnyDayByDay = (
  key: string,
  opt: {
    endDay: number;
  }
) => {
  const { endDay } = opt;
  const lastCloseTime = $onceAnyDay.getState()[key];

  const setLastCloseTime = (time: string) => {
    $setOnceAnyDay(
      produce((state) => {
        state[key] = time;
      })
    );
  };
  const toDay = dayjs().startOf('day').valueOf();

  const day = dayjs(toDay).diff(dayjs(lastCloseTime), 'day');

  const showOnce = !lastCloseTime || day >= endDay;

  const close = () => {
    setLastCloseTime(dayjs(toDay).format('YYYY-MM-DD HH:mm'));
  };
  return { showOnce, close, lastCloseTime, setLastCloseTime };
};
