import { sleep } from '@flowus/common/async';
import { cx } from '@flowus/common/cx';
import Tippy from '@tippyjs/react';
import { useDebounceEffect } from 'ahooks';
import type { FC, MutableRefObject, ReactNode } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { useIsMobileSize } from 'src/services/app/hook';
import type { Placement } from 'tippy.js';
import type { IconName } from '../../icon';
import { Icon } from '../../icon';
import { Tooltip } from '../../tooltip';
import { listViewItemDefaultClassName } from '../helper';
import type { ElementItemProps } from '../types';

/**
 * 操作菜单item
 */
interface OperationDataScheme {
  title: JSX.Element;
  label?: ReactNode;
  disable?: boolean;
  icon: IconName;
  iconClassName?: string;
  hasArrow?: boolean;
  arrow?: JSX.Element;
  renderSubMenu?: () => JSX.Element;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  offset?: [0, 0];
  content?: any;
  rightText?: any;
  textClassName?: string;
  selected?: boolean;
  selectColor?: string;
  tip?: string;
  className?: string;
  placement?: Placement;
}

const TAG: React.FC<{
  dataScheme: OperationDataScheme;
  divRef: React.RefObject<HTMLDivElement>;
  children: React.ReactElement;
  isSelected?: boolean;
}> = (tagProps) => {
  const { dataScheme, isSelected } = tagProps;
  const isMobileSize = useIsMobileSize();
  const ref = useRef<Element>(null);
  const [show, setShow] = useState(false);
  const [renderContent, setRenderContent] = useState(false);

  useEffect(() => {
    if (!ref.current) return;
    const curRef = ref.current;

    const onShow = () => setShow(true);
    const onHidden = () => setShow(false);

    curRef.addEventListener('mouseenter', onShow);
    curRef.addEventListener('focus', onShow);
    curRef.addEventListener('blur', onHidden);

    const clear = () => {
      curRef.removeEventListener('blur', onHidden);
      curRef.removeEventListener('mouseenter', onShow);
      curRef.removeEventListener('focus', onShow);
    };

    if (!curRef) {
      clear();
    }

    return clear;
  }, []);

  useEffect(() => {
    if (!isSelected) {
      setShow(false);
    }
  }, [isSelected]);

  useDebounceEffect(
    () => {
      if (show) {
        setRenderContent(show);
      } else {
        void sleep(50).then(() => {
          setRenderContent(show);
        });
      }
    },
    [show],
    {
      leading: true,
      trailing: true,
      wait: 50,
    }
  );

  return (
    <div>
      {dataScheme.renderSubMenu && !dataScheme.disable ? (
        <Tippy
          ref={ref}
          visible={show}
          delay={[200, 0]}
          duration={[150, 50]}
          offset={isMobileSize ? [0, 0] : dataScheme.offset || [0, -8]}
          placement={isMobileSize ? 'bottom' : 'right'}
          theme="none"
          interactive
          interactiveDebounce={0}
          arrow={false}
          animation={'shift-away'}
          appendTo={() => document.body}
          content={renderContent && dataScheme.renderSubMenu()}
        >
          {/* trigger need a html tag in child */}
          <div>{tagProps.children}</div>
        </Tippy>
      ) : (
        tagProps.children
      )}
    </div>
  );
};

export const OperationItemHeight = 40;
export const OperationItem: FC<ElementItemProps<OperationDataScheme>> = (props) => {
  const divRef = useRef() as MutableRefObject<HTMLDivElement>;
  const dataScheme = props.listItem.data;
  if (!dataScheme) return null;

  return (
    <TAG dataScheme={dataScheme} divRef={divRef} isSelected={props.selected}>
      <Tooltip popup={dataScheme.tip} placement={dataScheme.placement ?? 'left'}>
        <div
          {...props.attribute}
          ref={divRef}
          onClick={(e) => {
            if (dataScheme.disable) return;
            props.onItemClick?.(props.listItem, props.index, e);
            dataScheme.onClick?.(e);
          }}
          className={cx(
            listViewItemDefaultClassName,
            'relative flex items-center w-full justify-between rounded cursor-pointer active-bg animate-hover text-ellipsis',
            { 'normal-bg': props.selected, 'opacity-30 cursor-not-allowed': dataScheme.disable },
            dataScheme.className
          )}
        >
          <div className="flex min-w-0 items-center">
            {dataScheme.content ? (
              dataScheme.content
            ) : (
              <>
                {typeof dataScheme.icon === 'string' ? (
                  <Icon
                    name={dataScheme.icon as IconName}
                    size="small"
                    className={dataScheme.iconClassName}
                  />
                ) : (
                  dataScheme.icon
                )}
                <span
                  className={cx(
                    'flex-1 min-w-0 overflow-hidden text-black whitespace-nowrap overflow-ellipsis text-t2',
                    dataScheme.icon && 'ml-2'
                  )}
                >
                  {dataScheme.title}
                </span>
                {dataScheme.label}
              </>
            )}
          </div>
          <>
            {dataScheme.rightText && (
              <span className={cx('text-t4 text-grey3 mr-1', dataScheme.textClassName)}>
                {dataScheme.rightText}
              </span>
            )}
            {dataScheme.hasArrow &&
              (dataScheme.arrow ?? <Icon name="IcMenuNext" size="small" className="text-grey4" />)}
          </>
          {dataScheme.selected && (
            <Icon name="IcCheck02" size="small" style={{ color: dataScheme.selectColor }} />
          )}
        </div>
      </Tooltip>
    </TAG>
  );
};
