import { cx } from '@flowus/common/cx';
import type { BlockDTO } from '@next-space/fe-api-idl';
import type { FC } from 'react';
import { Icon } from 'src/common/components/icon';

import { IconSubject } from '../subject';

export const PageIconChange: FC<{
  className?: string;
  from: BlockDTO;
  to: BlockDTO;
  onClick?: () => void;
}> = ({ className, from, to, onClick }) => {
  return (
    <div className="mt-1 px-1 animate-hover" onClick={onClick}>
      <div>
        <span
          className={cx(
            'flex items-center text-t4 text-grey3',
            onClick && 'animate-hover',
            className
          )}
        >
          <Icon name={'IcTitleEmoji'} size="middle" className="align-middle relative" />
          <span>页面图标</span>
        </span>
      </div>
      <div className="flex items-center mt-2">
        <IconSubject block={from} />
        <Icon name="IcDateEnd" size="middle" className="mx-2 text-grey3" />
        <IconSubject block={to} />
      </div>
    </div>
  );
};
