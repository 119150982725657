import { restrictToParentElement } from '@dnd-kit/modifiers';
import { css, cx } from '@emotion/css';
import {
  getBlockBackgroundColor,
  getBlockTextColor,
} from '@flowus/common/block/color/get-block-color';
import { cloneDeep } from 'lodash-es';
import type { FC, MouseEvent } from 'react';
import { useMemo, useState } from 'react';
import { Button } from 'src/common/components/button';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { SortableList } from 'src/common/components/sortable-list';
import { Tooltip } from 'src/common/components/tooltip';
import { useModel } from 'src/common/create-model';
import { IconUiSizeBox } from 'src/components/icon-ui-size-box';
import { MenuCategory } from 'src/editor/component/menu-list/types';
import { useEnableAI } from 'src/hooks/block/use-enable-AI';
import { useUpdateUser, useUserToolbarShortcut } from 'src/hooks/user';
import { Modals } from 'src/modals';
import { ToolbarButton, tooltipShortcut } from './button';
import { ToolbarModel } from './context';
import type { RenderProps } from './type';
import { ToolbarBtnType, toolbarPrefixId } from './type';
import { pickToolbarShortcutMap, toolbarShortcutToArray, toolbarShortcutToMap } from './utils';

/** AI */
const AI = {
  info: {
    id: ToolbarBtnType.AI,
    icon: 'IcAiFill',
    title: MenuCategory.aiAssistantWithEmptyDoc,
    border: true,
  },
  Render: (props: RenderProps) => {
    const { showBorder = true } = props;
    const { clickFn, disableClick } = useModel(ToolbarModel);

    if (disableClick.disableAI) {
      return null;
    }

    return (
      <ToolbarButton
        disabled={disableClick.disableAI}
        id={toolbarPrefixId + AI.info.id}
        title={AI.info.title}
        label={AI.info.title}
        icon={AI.info.icon}
        iconClass="mr-1 text-ai_color"
        // className="text-ai_color"
        boxClassName={showBorder ? 'border-r border-grey6' : ''}
        onClick={clickFn.clickAI}
      />
    );
  },
} as const;

const AiExplain = {
  info: {
    id: ToolbarBtnType.AiExplain,
    icon: 'IcQuestion',
    title: 'AI 解释',
    border: true,
  },
  Render: () => {
    const { clickFn, disableClick } = useModel(ToolbarModel);

    if (disableClick.disableAiExplain) {
      return null;
    }

    return (
      <ToolbarButton
        disabled={disableClick.disableAI}
        id={toolbarPrefixId + AiExplain.info.id}
        title={AiExplain.info.title}
        icon={AiExplain.info.icon}
        label={AiExplain.info.title}
        onClick={clickFn.clickAiExplain}
        iconClass="text-ai_color mr-1"
      />
    );
  },
} as const;

/** 转换为 */
const Turn = {
  info: {
    id: ToolbarBtnType.Turn,
    icon: 'IcTurnInto',
    title: '转换为',
    border: true,
  },
  Render: (props: RenderProps) => {
    const { showBorder = true } = props;
    const { blockMenu, clickFn, disableClick } = useModel(ToolbarModel);

    return (
      <ToolbarButton
        arrow
        disabled={disableClick.disableTurn}
        id={toolbarPrefixId + Turn.info.id}
        title={blockMenu?.title}
        label={blockMenu?.title}
        boxClassName={showBorder ? 'border-r border-grey6' : ''}
        onClick={clickFn.clickTurn}
      />
    );
  },
} as const;

/** 链接页面 */
const LinkPage = {
  info: {
    id: ToolbarBtnType.LinkPage,
    icon: 'IcMenuSubpage',
    title: '创建或引用页面',
    border: true,
  },
  Render: (props: RenderProps) => {
    const { showBorder } = props;
    const { disableClick, clickFn } = useModel(ToolbarModel);

    return (
      <ToolbarButton
        id={toolbarPrefixId + LinkPage.info.id}
        title={LinkPage.info.title}
        icon="IcMenuSubpage"
        disabled={disableClick.disableLinkPage}
        boxClassName={showBorder ? 'border-r border-grey6' : ''}
        onClick={clickFn.clickLinkPage}
      />
    );
  },
} as const;

/** 评论 */
const Comment = {
  info: {
    id: ToolbarBtnType.Comment,
    icon: 'IcComment',
    title: '评论',
    border: true,
  },
  Render: (props: RenderProps) => {
    const { showBorder } = props;
    const { clickFn, disableClick, readonly } = useModel(ToolbarModel);

    return (
      <ToolbarButton
        id={toolbarPrefixId + Comment.info.id}
        disabled={disableClick.disableComment}
        title={tooltipShortcut(Comment.info.title, `Shift+M`)}
        boxClassName={readonly ? '' : showBorder ? 'border-r border-grey6' : ''}
        icon={Comment.info.icon}
        onClick={clickFn.clickComment}
      />
    );
  },
} as const;

/** 加粗 */
const Bold = {
  info: {
    id: ToolbarBtnType.Bold,
    icon: 'IcBold',
    title: '加粗',
    border: false,
  },
  Render: () => {
    const { format, clickFn } = useModel(ToolbarModel);
    return (
      <ToolbarButton
        id={toolbarPrefixId + Bold.info.id}
        title={tooltipShortcut(Bold.info.title, `B`)}
        icon={Bold.info.icon}
        highlight={format.bold}
        onClick={clickFn.clickBold}
      />
    );
  },
} as const;

/** 斜体 */
const Italic = {
  info: {
    id: ToolbarBtnType.Italic,
    icon: 'IcItalic',
    title: '斜体',
    border: false,
  },
  Render: () => {
    const { format, clickFn } = useModel(ToolbarModel);
    return (
      <ToolbarButton
        id={toolbarPrefixId + Italic.info.id}
        title={tooltipShortcut(Italic.info.title, `I`)}
        icon={Italic.info.icon}
        highlight={format.italic}
        onClick={clickFn.clickItalic}
      />
    );
  },
} as const;

/** 下划线 */
const Underline = {
  info: {
    id: ToolbarBtnType.Underline,
    icon: 'IcUnderline',
    title: '下划线',
    border: false,
  },
  Render: () => {
    const { format, clickFn } = useModel(ToolbarModel);
    return (
      <ToolbarButton
        id={toolbarPrefixId + Underline.info.id}
        title={tooltipShortcut(Underline.info.title, `U`)}
        icon={Underline.info.icon}
        highlight={format.underline}
        onClick={clickFn.clickUnderline}
      />
    );
  },
} as const;

/** 中划线 */
const Delete = {
  info: {
    id: ToolbarBtnType.Delete,
    icon: 'IcDelete',
    title: '中划线',
    border: false,
  },
  Render: () => {
    const { format, clickFn } = useModel(ToolbarModel);

    return (
      <ToolbarButton
        id={toolbarPrefixId + Delete.info.id}
        title={tooltipShortcut(Delete.info.title, `Shift+S`)}
        icon={Delete.info.icon}
        highlight={format.lineThrough}
        onClick={clickFn.clickDelete}
      />
    );
  },
} as const;

/** 代码 */
const Code = {
  info: {
    id: ToolbarBtnType.Code,
    icon: 'IcCode',
    title: '代码',
    border: false,
  },
  Render: () => {
    const { queryResult, clickFn } = useModel(ToolbarModel);
    const { inCode } = queryResult;

    return (
      <ToolbarButton
        id={toolbarPrefixId + Code.info.id}
        title={tooltipShortcut(Code.info.title, `E`)}
        icon={Code.info.icon}
        highlight={inCode}
        onClick={clickFn.clickCode}
      />
    );
  },
} as const;

/** 公式 */
const Equation = {
  info: {
    id: ToolbarBtnType.Equation,
    icon: 'IcEquation',
    title: '公式',
    border: false,
  },
  Render: () => {
    const { queryResult, clickFn, disableClick } = useModel(ToolbarModel);
    const { inEquation } = queryResult;

    return (
      <ToolbarButton
        id={toolbarPrefixId + Equation.info.id}
        title={tooltipShortcut(Equation.info.title, `Shift+X`)}
        disabled={disableClick.disableEquation}
        icon={Equation.info.icon}
        highlight={inEquation}
        onClick={clickFn.clickEquation}
      />
    );
  },
} as const;

/** 链接 */
const Url = {
  info: {
    id: ToolbarBtnType.Url,
    icon: 'IcLink',
    title: '链接',
    border: false,
  },
  Render: () => {
    const { queryResult, clickFn, disableClick } = useModel(ToolbarModel);
    const { inLink } = queryResult;

    return (
      <ToolbarButton
        id={toolbarPrefixId + Url.info.id}
        title={tooltipShortcut(Url.info.title, `K`)}
        boxClassName="border-r border-grey6"
        icon={Url.info.icon}
        disabled={disableClick.disableUrl}
        labelBorder
        highlight={inLink !== null}
        onClick={clickFn.clickUrl}
      />
    );
  },
} as const;

/** 着色 */
const Color = {
  info: {
    id: ToolbarBtnType.Color,
    icon: 'IcText',
    title: '着色',
    border: false,
  },
  Render: () => {
    const { clickFn, format } = useModel(ToolbarModel);

    return (
      <ToolbarButton
        arrow
        id={toolbarPrefixId + Color.info.id}
        title={Color.info.title}
        icon={Color.info.icon}
        onClick={clickFn.clickColor}
        iconClass={cx(
          'rounded-full w-5 h-5 p-1',
          css({
            color: `${getBlockTextColor(format.color ?? undefined)}!important`,
            background: getBlockBackgroundColor(format.backgroundColor ?? undefined),
          })
        )}
      />
    );
  },
} as const;

/** 更多按钮 */
const More = {
  info: {
    id: ToolbarBtnType.More,
    icon: 'IcMore',
    title: '更多',
    border: true,
  },
  Render: (props: RenderProps) => {
    const { showBorder } = props;
    const { clickFn } = useModel(ToolbarModel);

    const clickItem = (event: MouseEvent, itemId: string) => {
      if (!ToolbarBtnType[itemId as ToolbarBtnType]) {
        // 不是基础操作，就是自定义AI操作
        clickFn.clickCustomPrompt(itemId, event);
        return;
      }
      const id = toolbarPrefixId + itemId;
      const dom = document.getElementById(id);
      if (dom) {
        dom.click();
      } else {
        const fn = clickFn[`click${itemId as ToolbarBtnType}`];
        fn(event);
      }
    };

    return (
      <Tooltip
        id={toolbarPrefixId + More.info.id}
        interactive
        popupClass={'next-modal'}
        theme="none"
        placement={'bottom'}
        maxWidth={'none'}
        offset={[0, 4]}
        animation="shift-away"
        popup={<MoreToolbarList onClickItem={clickItem} />}
        delay={[0, 300]}
      >
        <ToolbarButton
          title={More.info.title}
          boxClassName={showBorder ? 'border-l border-grey6' : ''}
          icon={More.info.icon}
        />
      </Tooltip>
    );
  },
} as const;

export const ToolbarBaseButton = {
  AI,
  AiExplain,
  Turn,
  LinkPage,
  Comment,
  Bold,
  Italic,
  Underline,
  Delete,
  Code,
  Equation,
  Url,
  Color,
  More,
};

export const RenderAiShortcut = (props: RenderProps) => {
  const { id } = props;
  const { userToolbarShortcut } = useModel(ToolbarModel);
  const { clickFn } = useModel(ToolbarModel);

  if (!id) return null;
  const cur = userToolbarShortcut[id];

  if (!cur) return null;

  return (
    <ToolbarButton
      id={toolbarPrefixId + id}
      title={cur?.title}
      onClick={(ev) => clickFn.clickCustomPrompt(cur.id, ev)}
    />
  );
};

// #region MoreToolbarList
interface MoreToolbarListProps {
  openSetting?: boolean;
  className?: string;
  onClickItem?: (ev: MouseEvent, id: string) => void;
}
const MoreToolbarList: FC<MoreToolbarListProps> = (props) => {
  const { openSetting = true, className, onClickItem } = props;
  const userToolbarShortcut = useUserToolbarShortcut();
  const updateUser = useUpdateUser();
  const openModal = useOpenModal();
  const { enableAI } = useEnableAI();

  const onFixedBtn = (id: string) => {
    const cur = cloneDeep(userToolbarShortcut[id]);
    if (cur) {
      cur.fixed = !cur.fixed;
      const newConfig = { ...userToolbarShortcut, [id]: cur };
      void updateUser({ setting: { toolbarShortcut: newConfig } });
    }
  };

  const handleClick = (itemId: string, event: MouseEvent) => {
    onClickItem?.(event, itemId);
  };

  const clickSetting = () => {
    openModal.modal({
      modalId: Modals.TOOLBAR_ID,
      content: () => <SettingToolbar />,
    });
  };

  const items = useMemo(() => {
    return toolbarShortcutToArray(userToolbarShortcut)
      .filter((btn) => {
        if (!__BUILD_IN__ && btn.id === ToolbarBtnType.AiExplain) {
          return false;
        }
        if (
          btn.id &&
          [ToolbarBtnType.AI, ToolbarBtnType.AiExplain].includes(btn.id as ToolbarBtnType) &&
          !enableAI
        ) {
          return false;
        }
        return true;
      })
      .map((item) => {
        const baseBtn = ToolbarBaseButton[item.id as ToolbarBtnType];
        return {
          title: baseBtn?.info.title,
          icon: baseBtn?.info.icon,
          ...item,
        };
      });
  }, [enableAI, userToolbarShortcut]);

  const onChangeSort = (newList: typeof items) => {
    const newArray = newList.map((item) => pickToolbarShortcutMap(item));
    const newConfig = toolbarShortcutToMap(newArray);
    void updateUser({ setting: { toolbarShortcut: newConfig } });
  };

  const renderItem = (item: typeof items[number]) => (
    <div
      key={item.id}
      className="flex px-2 py-1.5 animate-hover justify-between animate-click"
      onClick={(e) => handleClick(item.id, e)}
    >
      <div className="flex items-center justify-center">
        <Icon name={item.icon} size="small" className="mr-1.5" />
        <span>{item.title}</span>
      </div>
      <Tooltip className="ml-2" onClick={(e) => e.stopPropagation()}>
        <IconUiSizeBox
          size={20}
          className="animate-click animate-hover"
          onClick={() => onFixedBtn(item.id)}
        >
          <Icon
            size="small"
            name={'IcQuickAccess'}
            className={item.fixed ? 'text-yellow' : 'text-grey3'}
          />
        </IconUiSizeBox>
      </Tooltip>
    </div>
  );

  const renderTitle = (title: string) => (
    <div className="text-grey3 flex items-center justify-between px-1.5 py-2 sticky top-0 bg-white2">
      <span>{title}</span>
      <Icon onClick={clickSetting} name="IcSettings" size="small" />
    </div>
  );

  return (
    <div
      className={cx(
        'px-1.5 pb-1.5 text-t3 overflow-y-auto max-h-[40vh] w-48 select-none',
        className
      )}
    >
      {openSetting && renderTitle('快捷工具栏')}
      <SortableList
        disabled={openSetting}
        items={items}
        onChange={onChangeSort}
        modifiers={[restrictToParentElement]}
        renderItemContent={({ item }) => renderItem(item)}
      />
    </div>
  );
};
// #endregion

// #region 设置 Toolbar
const SettingToolbar: FC = () => {
  const [activeId] = useState('');

  const placeholder = (
    <div className="text-grey3 py-3">可拖拽排序，点击右侧按钮固定常用的工具栏按钮</div>
  );

  const editor = (
    <>
      <div className="flex justify-between py-2">
        <input
          placeholder="未命名 Prompt"
          type="text"
          className="text-t2-bold w-full bg-transparent pr-2"
        />
        <Button colorType="active">保存</Button>
      </div>
      <textarea
        placeholder={`编辑 Prompt ，使用 {input} 作为引用内容的占位符`}
        className="flex-1 bg-transparent resize-none"
      />
    </>
  );

  return (
    <>
      <div className="flex">
        <MoreToolbarList className="py-2" openSetting={false} />
        <form className="w-96 bg-grey9 px-3 text-t2 flex flex-col pb-3">
          {activeId ? editor : placeholder}
        </form>
      </div>
    </>
  );
};
// #endregion
