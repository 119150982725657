import { cx } from '@flowus/common/cx';
import { deepEqual } from '@flowus/common/utils/tools';
import { CollectionViewType } from '@next-space/fe-api-idl';
import { sum } from 'lodash-es';
import type { FC } from 'react';
import { memo, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { LoadingContainer } from 'src/common/components/loading-container';
import { HoverMenu } from 'src/editor/editor/uikit/hover-menu';
import { Selector } from 'src/editor/editor/uikit/selector';
import { useBiTableGroupsAndProvideRecordWalker } from 'src/hooks/collection-view/table-groups';
import { EMPTY_GROUP_NAME } from 'src/hooks/collection-view/table-groups/const';
import type { TableGroupData } from 'src/hooks/collection-view/table-groups/select-collection-groups';
import { useProperties } from 'src/hooks/collection-view/use-properties';
import { $appUiStateCache, setAppUiState, useTableGroupIsUnFolded } from 'src/services/app';
import { useObservableStore } from 'src/services/rxjs-redux/hook';
import type { CollectionViewProps } from 'src/views/main/page-bitable/types';
import { useScrollRef } from 'src/views/main/page-doc/context';
import { AddGroup } from '../add-group';
import type { CollectionProperty } from '../bitable-manager/property-list';
import { getGroupUniqueId } from '../bitable-utils';
import { getPropertyWidth, isEnableVirtuoso, OVERSCAN } from '../const';
import { useBitable } from '../context';
import { GroupHeader } from '../group-header';
import { BitableLoadMoreContext } from '../hooks';
import { useUpdateGroups } from '../hooks/ues-check-group';
import { ToggleHiddenGroup } from '../toggle-hidden-group';
import { Body } from './body';
import { FreezeColumnLine } from './body/freeze-column-line';
import { Footer } from './footer';
import { Header } from './header';
import { RelationContext } from '../relation-context';

export const TableView: FC<CollectionViewProps> = memo(({ className }) => {
  const { viewId, readonly, viewType, embed } = useBitable();
  const { tableGroups, child2ParentMap, parent2ChildMap, filteredRecordIdSet } =
    useBiTableGroupsAndProvideRecordWalker(viewId);
  const [properties = [], timelineTableProperties = []] = useProperties(viewId, {
    visible: true,
    includeIdNumber: true,
  });
  const contextValue = useMemo(() => {
    return { child2ParentMap, parent2ChildMap, filteredRecordIdSet };
  }, [child2ParentMap, filteredRecordIdSet, parent2ChildMap]);

  useInitGroupFoldStatus();
  useUpdateGroups(tableGroups);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const scrollContainer = useScrollRef();
  const renderProperties =
    viewType === CollectionViewType.TIMELINE ? timelineTableProperties : properties;

  const [loading, setLoading] = useState(true);
  useLayoutEffect(() => {
    // 这是为了确保 wrapperRef.current 拿到正确的节点
    if (!tableGroups?.withoutValidGroup) {
      setLoading(false);
    }
  }, [tableGroups?.withoutValidGroup]);

  const getWidth = () => {
    let width: number | undefined = sum(renderProperties.map((item) => getPropertyWidth(item)));
    if (wrapperRef.current) {
      if (width <= (wrapperRef.current.parentElement?.clientWidth ?? 0)) {
        width = undefined;
      } else {
        width += 48; // 这里的 48 是加属性那一列的宽度
      }
    }

    return width;
  };

  if (!tableGroups) return null;
  const { withoutValidGroup, sortedRecordIds, visibleGroups, hiddenGroups } = tableGroups;

  // const renderIds = getIds(sortedRecordIds);
  if (withoutValidGroup) {
    return (
      <RelationContext.Provider value={contextValue}>
        <div
          className={cx('relative min-w-max print:border-l', !embed && '-mr-24 pr-24', className)}
        >
          <Header isGroup={false} properties={renderProperties} recordIds={sortedRecordIds} />
          <FreezeColumnLine />
          <BitableLoadMoreContext recordIds={sortedRecordIds} pagination={true}>
            <Body embed={embed} properties={renderProperties} />

            <Footer recordIds={sortedRecordIds} showPagination={true} />
          </BitableLoadMoreContext>

          {!readonly && !embed && <Selector type="collection" />}
          {!embed && <HoverMenu readonly={readonly} />}
        </div>
      </RelationContext.Provider>
    );
  }

  const style = { width: getWidth() };
  return (
    <RelationContext.Provider value={contextValue}>
      <div
        ref={wrapperRef}
        className={cx('relative min-w-max', !embed && '-mr-24 pr-24', className)}
      >
        {loading && <LoadingContainer className="h-[300px]" />}
        {!loading && scrollContainer?.current && (
          <>
            {isEnableVirtuoso(hiddenGroups.length) ? (
              <Virtuoso
                computeItemKey={(index) => index}
                style={style}
                customScrollParent={scrollContainer.current}
                overscan={OVERSCAN}
                increaseViewportBy={OVERSCAN}
                totalCount={visibleGroups.length}
                itemContent={(index) => {
                  const tableGroup = visibleGroups[index];
                  if (!tableGroup) return null;
                  return (
                    <TableGroup
                      key={tableGroup.value}
                      tableGroup={tableGroup}
                      withoutValidGroup={withoutValidGroup}
                      embed={embed}
                      properties={renderProperties}
                    />
                  );
                }}
              />
            ) : (
              <>
                {visibleGroups.map((tableGroup) => (
                  <TableGroup
                    key={tableGroup.value}
                    tableGroup={tableGroup}
                    withoutValidGroup={withoutValidGroup}
                    embed={embed}
                    properties={renderProperties}
                  />
                ))}
              </>
            )}

            <ToggleHiddenGroup
              hiddenGroupsLength={hiddenGroups.length}
              hiddenGroups={
                isEnableVirtuoso(hiddenGroups.length) ? (
                  <Virtuoso
                    computeItemKey={(index) => index}
                    style={style}
                    customScrollParent={scrollContainer.current}
                    overscan={OVERSCAN}
                    increaseViewportBy={OVERSCAN}
                    totalCount={hiddenGroups.length}
                    itemContent={(index) => {
                      const tableGroup = hiddenGroups[index];
                      if (!tableGroup) return null;
                      return (
                        <TableGroup
                          key={tableGroup.value}
                          tableGroup={tableGroup}
                          withoutValidGroup={withoutValidGroup}
                          embed={embed}
                          properties={renderProperties}
                        />
                      );
                    }}
                  />
                ) : (
                  <>
                    {hiddenGroups.map((tableGroup) => (
                      <TableGroup
                        key={tableGroup.value}
                        tableGroup={tableGroup}
                        withoutValidGroup={withoutValidGroup}
                        embed={embed}
                        properties={renderProperties}
                      />
                    ))}
                  </>
                )
              }
            />

            <AddGroup />

            {!readonly && !embed && <Selector type="collection" />}
            {!embed && <HoverMenu readonly={readonly} />}
          </>
        )}
      </div>
    </RelationContext.Provider>
  );
});

interface GalleryGroupProps {
  tableGroup: TableGroupData;
  withoutValidGroup?: boolean;
  embed?: boolean;
  properties: CollectionProperty[];
  index?: number;
}

export const TableGroup: FC<GalleryGroupProps> = memo((props) => {
  const { tableGroup, embed, withoutValidGroup, properties, index } = props;
  const { viewId, viewType } = useBitable();
  const { recordIds, value } = tableGroup;
  const isUnFolded = useTableGroupIsUnFolded(viewId, value);

  return (
    <div
      className={'pb-5'}
      data-group-property={tableGroup.groupProperty}
      data-group-value={tableGroup.value}
    >
      <GroupHeader tableGroup={tableGroup} isInTable={true} index={index} />

      {isUnFolded && (
        <>
          <Header recordIds={recordIds} isGroup={true} groupName={value} properties={properties} />
          <FreezeColumnLine />
          <BitableLoadMoreContext
            recordIds={recordIds}
            disable={viewType === CollectionViewType.TIMELINE}
          >
            <Body
              embed={embed}
              tableGroup={tableGroup}
              withoutValidGroup={withoutValidGroup}
              properties={properties}
            />
            <Footer recordIds={recordIds} />
          </BitableLoadMoreContext>
        </>
      )}
    </div>
  );
}, deepEqual);

/**
 * timeline 中，分组时 table 部分和 timeline 部分需要共享数据，干脆所有视图的分组控制都放到 redux 管理。对应看板来说，对应子分组
 */
export const useInitGroupFoldStatus = () => {
  const { viewId } = useBitable();
  const groups = useObservableStore(
    ({ collectionViews }) => {
      return collectionViews[viewId]?.format.collectionGroups;
    },
    [viewId]
  );

  useEffect(() => {
    const groupFoldStatus: Record<string, boolean> = {};

    (groups ?? []).forEach((group) => {
      groupFoldStatus[group.value ?? EMPTY_GROUP_NAME] =
        localStorage.getItem(getGroupUniqueId(viewId, group.value ?? EMPTY_GROUP_NAME)) !== 'false';
    });

    const tableGroupFoldStatus = $appUiStateCache.$tableGroupFoldStatus ?? {};
    setAppUiState({
      $tableGroupFoldStatus: {
        ...tableGroupFoldStatus,
        [viewId]: groupFoldStatus,
      },
    });
  }, [groups, viewId]);
};
