import { cx } from '@flowus/common/cx';
import type { SegmentDTO } from '@next-space/fe-api-idl';
import { CollectionSchemaType, TextType } from '@next-space/fe-api-idl';
import { throttle } from 'lodash-es';
import type { FC } from 'react';
import { memo, useEffect, useRef } from 'react';
import { PropertyValues } from 'src/bitable/board-view/card/property-values';
import { useBitable } from 'src/bitable/context';
import { useCheckRecordColor } from 'src/bitable/hooks/use-check-card-color';
import { RichTextEditor } from 'src/bitable/table-view/cell/rich-text-editor';
import { Site } from 'src/bitable/table-view/cell/types';
import { Icon } from 'src/common/components/icon';
import { useOpenModal } from 'src/common/components/next-modal';
import { ILLEGAL_TEXT, UNTITLED } from 'src/common/const';
import { globalListenerHelper } from 'src/common/utils/global-listener-helper';
import { BlockDefaultIcon } from 'src/components/block-default-icon';
import { IconTrigger } from 'src/components/icon-trigger';
import { BlockDrop } from 'src/editor/editor/plugin/dnd/block-drop';
import { TITLE_EDITOR_PLUGINS } from 'src/editor/editor/uikit/editable/plugins';
import { RichText } from 'src/editor/editor/uikit/editable/rich-text';
import { buildDateSegment } from 'src/editor/utils/segments';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { useRecordBgColor } from 'src/hooks/block/use-record-color';
import { useProperties } from 'src/hooks/collection-view/use-properties';
import { useReadonly } from 'src/hooks/page';
import { useOpenPage } from 'src/hooks/page/use-open-page';
import { usePermissions } from 'src/hooks/share/use-permissions';
import { useTransaction } from 'src/hooks/use-transaction';
import { updateBlock } from 'src/redux/managers/block/update';
import { uiActions } from 'src/redux/reducers/ui';
import { dispatch, getState } from 'src/redux/store';
import { setAppUiState, useNewCreatedRecord } from 'src/services/app';
import { centerOfRectangle, distanceBetween } from 'src/utils/collision';
import { getDateTimeStamp, ONE_DAY } from 'src/utils/date-utils';
import { numberToPercent } from 'src/utils/number';
import { usePickBlock } from 'src/utils/pick-block';
import { BlockDiscussionsBadge } from 'src/views/comments/block-discussions-badge';
import { Direction, DirectionMap } from '../../const';
import { getDatePropertyFromBlock } from '../../table-view/cell/helpers';
import { autoScrollY, rafY } from '../../timeline-view/utils/auto-scroll';
import { updateDate } from '../../timeline-view/utils/get-timeline-dates';
import { useCalender } from './context';
import { updateHoverHighlight } from './utils/dom';
import { getCalendarRenderTime } from './utils/get-calendar-render-time';
import {
  useOpenPageWay,
  useShowingTablePageIcon,
} from 'src/hooks/collection-view/use-collection-view';

export const CARD_PADDING = 4;
export const CARD_GAP = 4;
export const PROPERTY_HEIGHT = 22;
export const PROPERTY_TOP = 36;

export const getCardHeight = (length: number) => {
  return CARD_PADDING * 2 + PROPERTY_HEIGHT * length + CARD_GAP;
};

export interface RenderCard {
  recordId: string;
  rawStartTime: number | undefined;
  rawEndTime: number | undefined;
  renderStartTime: number;
  renderEndTime: number;
  weekStartDate: number;
  weekEndDate: number;
  isStartTime: boolean;
  isEndTime: boolean;
  order: number;
  calendarBy: string;
  calendarByType?: CollectionSchemaType;
  calendarByEnd: string;
  calendarByEndType?: CollectionSchemaType;
}
type CardProps = RenderCard & { weekFirstDay: number };

export const Card: FC<CardProps> = memo((props) => {
  const {
    recordId,
    renderStartTime,
    renderEndTime,
    weekStartDate,
    weekEndDate,
    isStartTime,
    isEndTime,
    weekFirstDay,
    order,
    calendarBy,
    calendarByEnd,
    calendarByType,
    calendarByEndType,
  } = props;
  const { container } = useCalender();
  const { viewId } = useBitable();
  const openWay = useOpenPageWay(viewId);
  const openPage = useOpenPage();
  const readonly = useReadonly(recordId, false);
  const [visibleProperty = []] = useProperties(viewId, { visible: true });
  const visiblePropertyLength = visibleProperty.length;
  const { illegal } = usePermissions(recordId);
  const block = usePickBlock(recordId, ['data', 'subNodes'], ['segments', 'icon']);
  const weekDateRef = useRef({
    renderStartTime,
    renderEndTime,
    weekStartDate,
    weekEndDate,
  });
  weekDateRef.current = {
    renderStartTime,
    renderEndTime,
    weekStartDate,
    weekEndDate,
  };
  if (__HOST_TEST__ || __HOST_LOCAL__) {
    console.log(recordId, 'renderStartTime: ', renderStartTime);
  }
  const transaction = useTransaction();
  const mouseDownPos = useRef({ x: 0, y: 0 });
  const bgColor = useRecordBgColor(viewId, recordId);

  useCheckRecordColor(viewId, recordId);

  /* 新建记录进入编辑状态 */
  const openModal = useOpenModal();
  const newCreateRecord = useNewCreatedRecord();
  const isNewCreateRecordId = recordId === newCreateRecord?.id && viewId === newCreateRecord.viewId;
  const blockRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isNewCreateRecordId) return;
    const blockNode = blockRef.current;
    if (!blockNode) return;

    openModal.dropdown({
      placement: 'bottom-start',
      popcorn: blockNode,
      offset: [-2, -blockNode.clientHeight - 3],
      closeAfterCallBack: () => {
        setAppUiState({ $newCreatedRecord: undefined });
      },
      content({ onCloseModal }) {
        return (
          <div className="next-modal max-h-[80vh]" style={{ width: blockNode.clientWidth + 4 }}>
            <RichTextEditor
              site={Site.CELL}
              viewId={viewId}
              recordId={recordId}
              propertyId={'title'}
              onUpdate={() => {}}
              onClose={onCloseModal}
            />
          </div>
        );
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStretch = (event: React.MouseEvent, direction: Direction) => {
    event.preventDefault();
    event.stopPropagation();

    if (readonly) return;

    const containerNode = container.current;
    const pageNode = containerNode?.closest('[data-page-id]') as HTMLElement | null;
    if (!containerNode || !pageNode) return;
    const isLeftDirection = direction === Direction.left;
    let targetNode: HTMLElement | undefined;
    let lastCursorPos = {
      x: event.clientX,
      y: event.clientY,
    };

    const save = (ignoreOp = false) => {
      const viewInfo = getViewFormat(viewId);
      if (!viewInfo) return;
      const { calendarBy, calendarByEnd } = viewInfo;
      if (!calendarBy || !calendarByEnd) return;

      const { blocks } = getState();
      const block = blocks[recordId];
      if (!block) return;

      transaction(() => {
        updateBlock(
          recordId,
          { data: { collectionProperties: { ...block.data.collectionProperties } } },
          ignoreOp
        );
      });
    };

    const handleMouseMove = throttle(
      (event: MouseEvent) => {
        lastCursorPos = {
          x: event.clientX,
          y: event.clientY,
        };

        autoScrollY({ y: event.clientY, container: pageNode });
        updateRecord(event.clientX, event.clientY);

        save(true);
      },
      500,
      {
        leading: false,
        trailing: true,
      }
    );

    const handleMouseUp = () => {
      pageNode.removeEventListener('scroll', handleScroll);
      globalListenerHelper.removeEventListener('mousemove', handleMouseMove);
      globalListenerHelper.removeEventListener('mouseup', handleMouseUp);
      cancelAnimationFrame(rafY);

      save();
    };

    const handleScroll = () => updateRecord(lastCursorPos.x, lastCursorPos.y);

    const updateRecord = (x: number, y: number) => {
      let minDistanceToCenter = Infinity;
      const calenderTodoNodes = containerNode.querySelectorAll(
        '.calender-date'
      ) as NodeListOf<HTMLElement>;
      calenderTodoNodes.forEach((node) => {
        const rect = node.getBoundingClientRect();
        const distBetween = distanceBetween(centerOfRectangle(rect), { x, y });
        if (distBetween < minDistanceToCenter) {
          minDistanceToCenter = distBetween;
          targetNode = node;
        }
      });
      if (!targetNode) return;
      let newDate = parseInt(targetNode?.dataset.date ?? '', 10);
      if (!newDate) return;
      newDate -= ONE_DAY / 2;

      if (
        (isLeftDirection &&
          (newDate > getDateTimeStamp(weekDateRef.current.renderEndTime) ||
            newDate === weekDateRef.current.weekStartDate)) ||
        (!isLeftDirection &&
          (newDate < getDateTimeStamp(weekDateRef.current.renderStartTime) ||
            newDate === weekDateRef.current.weekEndDate))
      ) {
        return;
      }

      const viewInfo = getViewFormat(viewId);
      if (!viewInfo) return;
      const { calendarBy, calendarByEnd } = viewInfo;
      if (!calendarBy || !calendarByEnd) return;

      const { blocks } = getState();
      const block = blocks[recordId];
      if (!block) return;

      const newPropertyValue: Record<string, SegmentDTO[]> = {};
      const startTimeType = getDatePropertyFromBlock(block, calendarBy)?.textType;
      newPropertyValue[calendarBy] = isLeftDirection
        ? [
            buildDateSegment({
              from: updateDate(renderStartTime, newDate),
              includeTime: startTimeType === TextType.DATETIME,
            }),
          ]
        : [
            buildDateSegment({
              from: new Date(renderStartTime),
              includeTime: startTimeType === TextType.DATETIME,
            }),
          ];
      const endTimeType = getDatePropertyFromBlock(block, calendarByEnd)?.textType;
      newPropertyValue[calendarByEnd] = isLeftDirection
        ? [
            buildDateSegment({
              from:
                renderEndTime > renderStartTime
                  ? new Date(renderEndTime)
                  : updateDate(renderEndTime, renderStartTime),
              includeTime: endTimeType === TextType.DATETIME,
            }),
          ]
        : [
            buildDateSegment({
              from: updateDate(renderEndTime, newDate),
              includeTime: endTimeType === TextType.DATETIME,
            }),
          ];

      updateBlock(
        recordId,
        {
          data: {
            collectionProperties: {
              ...block.data.collectionProperties,
              ...newPropertyValue,
            },
          },
        },
        true
      );
    };

    pageNode.addEventListener('scroll', handleScroll);
    globalListenerHelper.addEventListener('mousemove', handleMouseMove);
    globalListenerHelper.addEventListener('mouseup', handleMouseUp);
  };

  const handleTranslate = (event: React.MouseEvent) => {
    event.preventDefault();

    const { ui } = getState();
    const ids = ui.selectedBlocks.map((item) => item.blockId);
    if (!ids.includes(recordId)) {
      dispatch(uiActions.updateSelectBlocks([{ blockId: recordId, viewId }]));
    }

    if (readonly) return;

    const disableType = [CollectionSchemaType.CREATED_AT, CollectionSchemaType.UPDATED_AT];
    if (
      (calendarByType && disableType.includes(calendarByType)) ||
      (calendarByEndType && disableType.includes(calendarByEndType))
    ) {
      return;
    }

    mouseDownPos.current = {
      x: event.clientX,
      y: event.clientY,
    };

    const containerNode = container.current;
    const pageNode = containerNode?.closest('[data-page-id]') as HTMLElement | null;
    if (!containerNode || !pageNode) return;
    // 计算当前落点是第几个日期
    const sourceCardNode = event.currentTarget as HTMLElement;
    const { parentElement } = sourceCardNode;
    if (!parentElement) return;

    const sourceCardNodeRect = sourceCardNode.getBoundingClientRect();
    const weekWidth = parentElement.clientWidth / (parseFloat(parentElement.style.width) / 100);
    const dateNodeWidth = weekWidth / 7;
    const dragStartDate =
      weekStartDate +
      Math.floor((event.clientX - sourceCardNodeRect.left) / dateNodeWidth) * ONE_DAY;
    let isMoving = false;
    let overlayContainer: HTMLDivElement | undefined;
    let targetNode: HTMLElement | undefined;
    let lastCursorPos = {
      x: event.clientX,
      y: event.clientY,
    };

    const handleMouseMove = (event: MouseEvent) => {
      lastCursorPos = {
        x: event.clientX,
        y: event.clientY,
      };

      if (
        !isMoving &&
        Math.abs(event.clientX - mouseDownPos.current.x) < 5 &&
        Math.abs(event.clientY - mouseDownPos.current.y) < 5
      ) {
        return;
      }

      if (!isMoving) {
        isMoving = true;

        overlayContainer = document.createElement('div');
        overlayContainer.style.pointerEvents = 'none';
        overlayContainer.style.opacity = '0.5';
        overlayContainer.style.position = 'fixed';
        overlayContainer.style.inset = '0';
        overlayContainer.style.zIndex = '100';

        const { ui } = getState();
        const ids = ui.selectedBlocks.map((item) => item.blockId);
        ids.forEach((uuid) => {
          const currentCardNodes = containerNode.querySelectorAll(`[data-record-id="${uuid}"]`);
          currentCardNodes.forEach((node) => {
            const nodeRect = node.getBoundingClientRect();
            const clonedNode = node.cloneNode(true) as HTMLElement;
            clonedNode.style.position = 'absolute';
            clonedNode.style.width = `${nodeRect.width}px`;
            clonedNode.style.height = `${nodeRect.height}px`;
            clonedNode.style.top = `${nodeRect.top}px`;
            clonedNode.style.left = `${nodeRect.left}px`;
            overlayContainer?.appendChild(clonedNode);
          });
        });
        document.body.appendChild(overlayContainer);
      }

      autoScrollY({ y: event.clientY, container: pageNode });
      updatePosition(event.clientX, event.clientY);
    };

    const updatePosition = (x: number, y: number) => {
      if (!overlayContainer) return;
      overlayContainer.style.transform = `translate(${x - mouseDownPos.current.x}px, ${
        y - mouseDownPos.current.y
      }px)`;

      const result = updateHoverHighlight(x, y, containerNode);
      targetNode = result.targetNode;
    };

    const handleMouseUp = () => {
      pageNode.removeEventListener('scroll', handleScroll);
      globalListenerHelper.removeEventListener('mousemove', handleMouseMove);
      globalListenerHelper.removeEventListener('mouseup', handleMouseUp);
      cancelAnimationFrame(rafY);
      overlayContainer?.remove();
      const currentHoverDate = containerNode.querySelector('.calender-date.drag-hover-highlight');
      currentHoverDate?.classList.remove('drag-hover-highlight');
      if (!isMoving) return;

      transaction(() => {
        if (!targetNode) return;

        const viewInfo = getViewFormat(viewId);
        if (!viewInfo) return;
        const { calendarBy, calendarByEnd } = viewInfo;
        if (!calendarBy || !calendarByEnd) return;

        const { blocks, ui } = getState();
        let newDropDate = parseInt(targetNode?.dataset.date ?? '', 10);
        if (!newDropDate) return;
        newDropDate -= ONE_DAY / 2;
        if (newDropDate === dragStartDate) return;

        const offsetDate = newDropDate - dragStartDate;

        const ids = ui.selectedBlocks.map((item) => item.blockId);
        ids.forEach((uuid) => {
          const { renderStartTime, renderEndTime } = getCalendarRenderTime({
            recordId: uuid,
            calendarBy,
            calendarByEnd,
            blocks,
          });
          if (!renderStartTime || !renderEndTime) return;

          const block = blocks[uuid];
          if (!block) return;

          const newStartTime = renderStartTime + offsetDate;
          const newEndTime = newStartTime + renderEndTime - renderStartTime;

          const newPropertyValue: Record<string, SegmentDTO[]> = {};
          const startTimeType = getDatePropertyFromBlock(block, calendarBy)?.textType;
          newPropertyValue[calendarBy] = [
            buildDateSegment({
              from: updateDate(renderStartTime, newStartTime),
              includeTime: startTimeType === TextType.DATETIME,
            }),
          ];
          const endTimeType = getDatePropertyFromBlock(block, calendarByEnd)?.textType;
          newPropertyValue[calendarByEnd] = [
            buildDateSegment({
              from: updateDate(renderEndTime, newEndTime),
              includeTime: endTimeType === TextType.DATETIME,
            }),
          ];

          updateBlock(uuid, {
            data: {
              collectionProperties: {
                ...block.data.collectionProperties,
                ...newPropertyValue,
              },
            },
          });
        });
      });
    };

    const handleScroll = () => {
      updatePosition(lastCursorPos.x, lastCursorPos.y);
    };

    pageNode.addEventListener('scroll', handleScroll);
    globalListenerHelper.addEventListener('mousemove', handleMouseMove);
    globalListenerHelper.addEventListener('mouseup', handleMouseUp);
  };

  const style = {
    top: (order - 1) * getCardHeight(visiblePropertyLength) + PROPERTY_TOP,
    left: numberToPercent((weekStartDate - weekFirstDay) / ONE_DAY / 7),
    width: numberToPercent(((weekEndDate - weekStartDate) / ONE_DAY + 1) / 7),
    height: getCardHeight(visiblePropertyLength),
  };

  const showIcon = (block?.subNodes ?? []).length > 0 || Boolean(block?.data.icon?.type);
  const isShowPageIcon = useShowingTablePageIcon(viewId);

  return (
    <div
      style={style}
      className={cx('absolute pb-1 transition-all', { 'pl-1.5': isStartTime, 'pr-1.5': isEndTime })}
    >
      <BlockDrop
        key={recordId}
        blockRef={blockRef}
        id={recordId}
        viewId={viewId}
        // 这个属性会导致日历item无法拖拽，似乎跟onMouseDown有冲突
        // showAnchor
        data-record-id={recordId}
        data-no-cancel-selected
        data-date={renderStartTime}
        data-disable-select
        onMouseDown={handleTranslate}
        className="w-full h-full cursor-pointer"
      >
        <div className="bg-white1 relative h-full">
          <div
            className={cx('h-full p-1', {
              border: !bgColor,
              'rounded-l-l': isStartTime,
              'rounded-r': isEndTime,
            })}
            onClick={(event) =>
              openPage(recordId, {
                illegal,
                forceOpenInRight: event.altKey,
                openWay,
                forceOpenNewTab: event.ctrlKey || event.metaKey,
              })
            }
            style={{ backgroundColor: bgColor }}
          >
            {illegal && (
              <>
                <IconTrigger
                  className="text-t2-medium mr-1 inline p-0 align-middle opacity-30"
                  blockId={recordId}
                  trigger={false}
                  iconSize={16}
                  defaultIcon={<Icon name="IcPages" size="small" />}
                />
                <span className="border-b border-grey6 opacity-30">{ILLEGAL_TEXT}</span>
              </>
            )}
            {!illegal && (
              <>
                <div className="flex">
                  {showIcon && isShowPageIcon && (
                    <IconTrigger
                      tooltipClassName="align-middle"
                      className="mr-1.5 inline-flex p-0 align-middle"
                      blockId={recordId}
                      // trigger={false}
                      iconSize={16}
                      defaultIcon={<BlockDefaultIcon uuid={recordId} size="small" />}
                    />
                  )}

                  <RichText
                    className="text-t4-medium text-ellipsis whitespace-nowrap align-middle leading-[22px]"
                    plugins={TITLE_EDITOR_PLUGINS}
                    segments={block?.data.segments}
                    placeholder={UNTITLED}
                    interactable={false}
                  />

                  <BlockDiscussionsBadge
                    className="inline-flex align-middle pointer-events-none"
                    blockId={recordId}
                  />
                </div>

                <PropertyValues recordId={recordId} />
              </>
            )}
          </div>
        </div>

        {!readonly &&
          calendarBy !== calendarByEnd &&
          DirectionMap.map((direction) => {
            if (
              direction === Direction.left &&
              isStartTime &&
              calendarByType !== CollectionSchemaType.CREATED_AT &&
              calendarByType !== CollectionSchemaType.UPDATED_AT
            ) {
              return (
                <div
                  key={direction}
                  className="absolute top-0 left-0 bottom-0 z-10 w-[4px] rounded-full py-0.5 hover:cursor-col-resize hover:bg-black"
                  onMouseDown={(event) => handleStretch(event, direction)}
                />
              );
            }

            if (
              direction === Direction.right &&
              isEndTime &&
              calendarByEndType !== CollectionSchemaType.CREATED_AT &&
              calendarByEndType !== CollectionSchemaType.UPDATED_AT
            ) {
              return (
                <div
                  key={direction}
                  className="absolute top-0 right-0 bottom-0 z-10 w-[4px] rounded-full py-0.5 hover:cursor-col-resize hover:bg-black"
                  onMouseDown={(event) => handleStretch(event, direction)}
                />
              );
            }

            return null;
          })}
      </BlockDrop>
    </div>
  );
});
