import {
  LOCAL_KEY_THEME_MODE,
  setLocalStorage,
  themeDarkOption,
  useListenerSystemDarkMode,
} from '@flowus/common';
import { globalListenerHelper } from '@flowus/common/hooks/global-listener-helper';
import isHotkey from 'is-hotkey';
import { useEffect } from 'react';
import { Images } from 'src/image';
import {
  $appUiStateCache,
  setAppUiState,
  useAppUiState,
  useThemeState,
} from 'src/services/app/hook';
import { SettingThemeMode } from 'src/services/app/type';
import { $searchParams } from 'src/utils';
import { judgeSharePage } from 'src/utils/getPageId';
import { getLocalStorageThemeMode } from './get-local-storage-theme-mode';

export { useListenerSystemDarkMode } from '@flowus/common';

export const getIsDarkMode = () => {
  return $appUiStateCache.$isDarkMode;
};

export const useIsDarkMode = () => {
  return useAppUiState((state) => state.$isDarkMode);
};

export const isDarkThemeKey = (mode?: SettingThemeMode | null) => {
  if (mode === SettingThemeMode.dark) {
    return true;
  }
  return false;
};

export const useTheme = () => {
  const systemDark = useListenerSystemDarkMode();
  const isDarkMode = useIsDarkMode();
  const theme = useThemeState();

  // 监听dark mode变化，是否加载dark主题
  useEffect(() => {
    const isSharePage = judgeSharePage();
    const localThemeMode = getLocalStorageThemeMode();

    if (isSharePage && $searchParams.blog) {
      if ($searchParams.blog === 'walles') {
        themeDarkOption.add();
      } else if ($searchParams.blog === 'buildin') {
        themeDarkOption.remove();
      }
      return;
    }

    if (!localThemeMode && isSharePage) {
      if (systemDark && !getIsDarkMode()) {
        themeDarkOption.add();
      } else {
        themeDarkOption.remove();
      }
      return;
    }
    if (!getIsDarkMode()) {
      if (localThemeMode === SettingThemeMode.dark) {
        themeDarkOption.add();
      }
      if (localThemeMode === SettingThemeMode.system && systemDark) {
        themeDarkOption.add();
      }
    } else {
      if (localThemeMode === SettingThemeMode.light) {
        themeDarkOption.remove();
      }
      if (localThemeMode === SettingThemeMode.system && !systemDark) {
        themeDarkOption.remove();
      }
    }
  }, [theme, systemDark]);

  useEffect(() => {
    Images.reload(isDarkMode);
  }, [isDarkMode]);

  useEffect(() => {
    const refresh = () => {
      const isDark = document.body.classList.contains('dark');
      setAppUiState({ $isDarkMode: isDark });
    };

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.attributeName === 'class') {
          refresh();
        }
      });
    });
    refresh();

    observer.observe(document.body, { attributes: true });

    return () => observer.disconnect();
  }, []);
};

export const useSwitchTheme = () => {
  useEffect(() => {
    const callback = (event: KeyboardEvent) => {
      if (isHotkey('mod+shift+l')(event)) {
        event.preventDefault();
        const isDarkMode = getIsDarkMode();
        const $theme = isDarkMode ? SettingThemeMode.light : SettingThemeMode.dark;
        setLocalStorage(LOCAL_KEY_THEME_MODE, $theme);
        setAppUiState({ $theme });
      }
    };
    globalListenerHelper.addEventListener('keydown', callback);
    return () => {
      globalListenerHelper.removeEventListener('keydown', callback);
    };
  }, []);
};
