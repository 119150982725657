import { emitter } from '@flowus/common';
import { isSafari } from 'react-device-detect';

/**
 * 执行document.execCommand("xxx")时，safari并不能响应，因此这里会发个event做特殊处理
 */
const execCommand = (cmd: string) => {
  if (isSafari) {
    emitter.emit('executeCmd', cmd);
  } else {
    document.execCommand(cmd);
  }
};

export const ExecuteCmdUtil = {
  execCommand,
};
