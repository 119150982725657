import { fastEqual } from '@flowus/common/utils/tools';
import { globalRxTimer } from './timer';
import type { ObservableDataValueType } from './types';

export interface PatchOptions {
  force?: boolean;
}

type Subscriber<T = any> = (value: T) => void;

export class ObservableObject {
  private observableGroupMap: {
    normal: Map<string, ObservableDataValueType>;
    select: Map<string, ObservableDataValueType>;
  };

  constructor() {
    this.observableGroupMap = {
      normal: new Map(),
      select: new Map(),
    };
  }

  getObsGroup(id: string) {
    return isSelectPrefixId(id) ? this.observableGroupMap.select : this.observableGroupMap.normal;
  }

  subscribe(id: string, subscriber: Subscriber) {
    const obs = this.get(id);
    obs.subscribers.add(subscriber);
    // 返回取消订阅函数
    return () => obs.subscribers.delete(subscriber);
  }

  patch(id: string, value?: any, opt?: PatchOptions) {
    if (!id || typeof id !== 'string') {
      throw new Error('Invalid id: id must be a non-empty string');
    }

    globalRxTimer.run();

    const obsGroup = this.getObsGroup(id);
    const existingObs = obsGroup.get(id);
    const newValue = value ?? (typeof id === 'symbol' ? id : Symbol(id));

    if (existingObs) {
      if (opt?.force || !fastEqual(existingObs.value, newValue)) {
        existingObs.value = newValue;
        // 通知所有订阅者
        existingObs.subscribers.forEach((subscriber) => subscriber(newValue));
      }
      return existingObs;
    }

    const newObs: ObservableDataValueType = {
      value: newValue,
      subscribers: new Set(),
    };
    obsGroup.set(id, newObs);
    return newObs;
  }

  onlyPatch(id: string, value?: any, opt?: PatchOptions) {
    const obsGroup = this.getObsGroup(id);
    if (obsGroup.has(id)) {
      return this.patch(id, value, opt);
    }
  }

  get(id: string): ObservableDataValueType {
    if (!id || typeof id !== 'string') {
      throw new Error('Invalid id: id must be a non-empty string');
    }

    const obsGroup = this.getObsGroup(id);
    const obs = obsGroup.get(id);
    if (obs) {
      return obs;
    }

    // 对于特殊 id 的处理保持不变
    if (isSelectPrefixId(id) || isPermissionKey(id)) {
      return this.patch(id);
    }

    return this.patch(id);
  }

  deleteObs(id: string) {
    const obsGroup = this.getObsGroup(id);
    const obs = obsGroup.get(id);
    if (obs) {
      obs.subscribers.clear();
      obsGroup.delete(id);
      globalRxTimer.run();
    }
  }

  clear() {
    for (const [, obs] of this.observableGroupMap.normal) {
      obs.subscribers.clear();
    }
    for (const [, obs] of this.observableGroupMap.select) {
      obs.subscribers.clear();
    }
    this.observableGroupMap.normal.clear();
    this.observableGroupMap.select.clear();
  }

  getMap() {
    return this.observableGroupMap;
  }

  has(id: string) {
    return this.getObsGroup(id).has(id);
  }
}

// #region select
export const prefixSelectBlock = 'select=block';
export const prefixSelectCell = 'select=cell';
export const resetSelectValue = false;
export const isSelectPrefixId = (id = '') => {
  if (typeof id === 'string') {
    return id?.startsWith?.(prefixSelectBlock) || id?.startsWith?.(prefixSelectCell);
  }
  return false;
};

// #region selectBlock
export const getSelectedBlocksKey = (item: {
  blockId?: string;
  syncId?: string;
  viewId?: string;
  all?: boolean;
}) => {
  if (item.viewId && item.blockId && item.syncId) {
    return `${prefixSelectBlock}&blockId=${item.blockId}&syncId=${item.syncId}&viewId=${item.viewId}`;
  }
  if (item.blockId) {
    return getSelectedBlocksKeyByBlock(item.blockId);
  }
  if (item.syncId) {
    return getSelectedBlocksKeyBySync(item.syncId);
  }
  if (item.viewId) {
    return getSelectedBlocksKeyByView(item.viewId);
  }
  if (item.all) {
    return getSelectedBlocksKeyByAll();
  }
  return `${prefixSelectBlock}`;
};

export const getSelectedBlocksKeyBySync = (syncId: string) => {
  return `${prefixSelectBlock}&syncId=${syncId}`;
};

export const getSelectedBlocksKeyByBlock = (blockId: string) => {
  return `${prefixSelectBlock}&blockId=${blockId}`;
};

export const getSelectedBlocksKeyByView = (viewId: string) => {
  return `${prefixSelectBlock}&viewId=${viewId}`;
};

export const getSelectedBlocksKeyByAll = () => {
  return `${prefixSelectBlock}&all`;
};
// #endregion

// #region selectCell
export const getSelectedCellKey = (item: {
  recordId?: string;
  viewId?: string;
  propertyId?: string;
  all?: boolean;
}) => {
  if (item.propertyId && item.recordId && item.viewId) {
    return `${prefixSelectCell}&recordId=${item.recordId}&viewId=${item.viewId}&propertyId=${item.propertyId}`;
  }

  if (item.recordId) {
    return `${prefixSelectCell}&recordId=${item.recordId}`;
  }

  if (item.viewId) {
    return `${prefixSelectCell}&viewId=${item.viewId}`;
  }

  if (item.propertyId) {
    return `${prefixSelectCell}&propertyId=${item.propertyId}`;
  }

  if (item.all) {
    return `${prefixSelectCell}&all`;
  }

  return prefixSelectCell;
};
// #endregion

// #endregion

// #region simpleTable
export const prefixSimpleTable = '&simpleTable';
export const isSimpleTableKey = (id = '') => {
  if (typeof id === 'string') {
    return id.startsWith(prefixSimpleTable);
  }
  return false;
};
// #endregion

// #region permission 专用
export const prefixPermission = '&update=permission';
export const getPermissionKey = (id?: string) => {
  if (id) {
    return `${prefixPermission}&id=${id}`;
  }
  return prefixPermission;
};

export const isPermissionKey = (id?: string) => {
  if (typeof id === 'string') {
    return id?.includes(prefixPermission);
  }
};
// #endregion

// #region ignoreDeep
export const prefixIgnoreDeep = '&update=ignoreDeep';
export const getIgnoreDeepKey = (id?: string) => {
  if (id) {
    return `${prefixIgnoreDeep}&id=${id}`;
  }
  return prefixIgnoreDeep;
};
// #endregion

// #region ignoreOtherData
export const prefixIgnoreOtherData = '&update=ignoreOtherData';
export const getIgnoreOtherData = (id?: string) => {
  if (id) {
    return `${prefixIgnoreOtherData}&id=${id}`;
  }
  return prefixIgnoreOtherData;
};
// #endregion

// #region isCut
export const prefixIsCut = '&isCut';
export const getIsCutKey = (id?: string) => {
  if (id) {
    return `${prefixIsCut}&id=${id}`;
  }
  return prefixIsCut;
};

// #endregion

// #region 其他特殊场景的细节事件
export enum RxPatchKey {
  /** 监听 users 变化 */
  ALL_USERS = 'ALL_USERS',
  /** 监听 select block 数据长度，有或无 */
  HAS_SELECT_BLOCK = 'HAS_SELECT_BLOCK',
  /** 监听 select cell 数据长度，有或无 */
  HAS_SELECT_CELL = 'HAS_SELECT_CELL',
  /** 监听所有评论 */
  ALL_DISCUSSIONS = 'ALL_DISCUSSIONS',
}

// #endregion

// #region utils
export const omitComplexTypes = <T extends {}>(obj: T): T => {
  const result = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      if (
        value === null ||
        value instanceof Date ||
        value instanceof Error ||
        (typeof value !== 'object' && typeof value !== 'function')
      ) {
        // @ts-ignore type
        result[key] = value;
      }
    }
  }
  return result as T;
};
// #endregion
