import { createContext, useContext } from 'react';
// import { $localState } from 'src/services/app-support/local-state';

export enum PageScene {
  MAIN = 'main',
  PAGE_HISTORY = 'page_history',
  PAGE_FEEDS = 'page_feeds',
  TEMPLATE = 'template',
  SHARE = 'share',
  NOTIFICATION = 'notification',
  UPDATE_LOG = 'update_log',
  FORM_ENG_PAGE = 'form_end_page',
  PAGE_LITE_PREVIEW = 'page-lite-preview', // 页面块hover展示的预览页 和 AI 返回结果的临时页面
  ONLY_IMAGE = 'only-image', // 卡片展示付费ui需要用到
  PAGE_CHART = 'page_chart',
  PAGE_AI_CHAT = 'page_ai_chat',
}
export const PageSceneContext = createContext<PageScene | undefined>(undefined);
export const usePageScene = () => {
  const pageScene = useContext(PageSceneContext);
  return pageScene;
};

// export const OpenRxContext = createContext<boolean>($localState.getState().beta);
// export const OpenRxContext = createContext<boolean>(true);

export const useOpenRx = () => {
  // return $localState((state) => state.beta);
  // const openRx = useContext(OpenRxContext);
  // return openRx;
  return true;
};
