import { cx } from '@flowus/common/cx';
import dayjs from 'dayjs';
import type { FC } from 'react';
import React, { useRef, useState } from 'react';
import { useBitable } from 'src/bitable/context';
import { useInsertRecordUI } from 'src/editor/editor/uikit/use-insert-record-ui';
import { buildDateSegment } from 'src/editor/utils/segments';
import { getViewFormat } from 'src/hooks/block/get-view-format';
import { getDateTimeStamp, ONE_HOURS, ONE_MOMENT } from 'src/utils/date-utils';
import { CalendarViewType, PADDING_LEFT, PADDING_TOP, UNIT_HEIGHT } from '../const';
import { RecordItem } from '../record-item';
import { TodayCursor } from '../today';
import { DayViewBackground } from './day-view-background';
import { Header } from './header';
import { useCategoryRecord } from './use-category-record';

export const CalendarDayView: FC = () => {
  const [currentDate, setToday] = useState(getDateTimeStamp(Date.now()));
  const containerRef = useRef<HTMLDivElement>(null);

  const { viewId, readonly } = useBitable();
  // const calendarType = useCalendarType(viewId);
  // const openModal = useOpenModal();

  const insertRecord = useInsertRecordUI({ ignoreSorters: true });

  const { allDayRecord, timeRangeRecord } = useCategoryRecord(currentDate);

  // const handleClick = (event: React.MouseEvent) => {
  //   if (isLocked || managerReadonly) return;

  //   const items = CalendarTypeMap.map((item) => ({
  //     type: ListItemType.OPERABLE_BLOCK_TEXT,
  //     data: { title: item.name, ...item, selected: item.type === calendarType },
  //   }));

  //   openModal.dropdown({
  //     popcorn: event.currentTarget,
  //     placement: 'bottom',
  //     content: ({ onCloseModal }) => {
  //       return (
  //         <ListView
  //           className="next-modal w-[100px] py-2"
  //           items={items}
  //           onItemClick={({ data }) => {
  //             updateViewFormat(viewId, { calendarType: data.type });
  //             onCloseModal();
  //           }}
  //         />
  //       );
  //     },
  //   });
  // };

  const handleDoubleClick = (event: React.MouseEvent) => {
    if (readonly) return;
    const containerNode = containerRef.current;
    if (!containerNode) return;

    const containerRect = containerNode.getBoundingClientRect();
    const offsetTop = event.clientY - PADDING_TOP - containerRect.top;

    const index = Math.floor(offsetTop / UNIT_HEIGHT);
    const startTime = currentDate + index * ONE_MOMENT;
    const endTime = startTime + ONE_HOURS;

    const viewInfo = getViewFormat(viewId);
    if (!viewInfo) return;

    const { calendarBy, calendarByEnd } = viewInfo;
    if (calendarBy && calendarByEnd) {
      void insertRecord({
        viewId,
        where: { last: true },
        propertyValues: {
          [calendarBy]: [buildDateSegment({ from: new Date(startTime), includeTime: true })],
          [calendarByEnd]: [buildDateSegment({ from: new Date(endTime), includeTime: true })],
        },
      });
    }
  };

  const addAllDayEvent = (event: React.MouseEvent) => {
    if (readonly) return;

    if (event.target !== event.currentTarget) return;

    const viewInfo = getViewFormat(viewId);
    if (!viewInfo) return;

    const { calendarBy, calendarByEnd } = viewInfo;
    if (calendarBy && calendarByEnd) {
      void insertRecord({
        viewId,
        where: { last: true },
        propertyValues: {
          [calendarBy]: [buildDateSegment({ from: new Date(currentDate) })],
          [calendarByEnd]: [buildDateSegment({ from: new Date(currentDate) })],
        },
      });
    }
  };

  return (
    <div className="relative flex flex-1 flex-col min-w-[300px]" data-is-calendar={true}>
      <div className="sticky top-0 bg-white1 z-10 ">
        <Header setToday={setToday} currentDate={currentDate} />

        <div
          className={'border min-h-[62px] flex'}
          style={{ boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.06)' }}
        >
          <div
            className={`flex items-center justify-center text-grey3 text-t2-medium flex-shrink-0 `}
            style={{ width: PADDING_LEFT }}
          >
            全天
          </div>
          <div className="border-l flex-1" onDoubleClick={addAllDayEvent}>
            <p className="pt-2 pl-3 text-t2-medium flex mb-1.5">
              <span
                className={cx(
                  currentDate === getDateTimeStamp(Date.now()) &&
                    'w-6 h-6 rounded-full flex items-center justify-center -mt-0.5 -ml-1 bg-red text-white'
                )}
              >
                {dayjs(currentDate).format('DD')}
              </span>
            </p>

            <div
              className="flex-1 p-1.5 pt-0 max-h-[150px] overflow-auto"
              onDoubleClick={addAllDayEvent}
            >
              {allDayRecord.map((item) => {
                return (
                  <RecordItem
                    key={item.uuid}
                    base={currentDate}
                    record={item}
                    viewType={CalendarViewType.DAY}
                    container={containerRef}
                    isAllDay
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div
        className="flex-1 border-x border-b"
        ref={containerRef}
        style={{ paddingLeft: PADDING_LEFT }}
      >
        <div
          className="relative border-l bg-grey8"
          style={{
            padding: `${PADDING_TOP}px 0`,
            height: 24 * UNIT_HEIGHT * 4 + PADDING_TOP * 2,
          }}
        >
          <DayViewBackground />

          <div className="absolute inset-0 overflow-hidden" onDoubleClick={handleDoubleClick}>
            {timeRangeRecord.map((item) => {
              return (
                <RecordItem
                  key={item.uuid}
                  base={currentDate}
                  record={item}
                  viewType={CalendarViewType.DAY}
                  container={containerRef}
                />
              );
            })}
          </div>

          <TodayCursor date={currentDate} />
        </div>
      </div>
    </div>
  );
};
