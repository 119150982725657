import { throttle } from 'lodash-es';
import { observableStoreOp } from './map';
import type { ObservableDataValueType } from './types';

export const globalRxTimer = {
  run: throttle(() => {
    const map = observableStoreOp.getMap();
    const clean = (item: ObservableDataValueType, key: string) => {
      const observed = item.subscribers.size;
      if (!observed) {
        observableStoreOp.deleteObs(key);
      }
    };
    map.normal.forEach(clean);
    map.select.forEach(clean);
  }, 3000),
};
